import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  ElementRef,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { Router } from '@angular/router';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { Employee } from 'src/app/modules/shared/models/employee';
import { AddModalButtonOptions } from 'src/app/modules/shared/enums/addModalButtonOptions.enum';
import { TaskDetailsViewModel } from '../../../viewModels/projects/taskDetailsViewModel';
import { AddModalKeyboardShortcuts } from 'src/app/modules/shared/enums/addModalKeyboardShortcuts.enum';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { Subscription, Observable } from 'rxjs';
import { TaskService } from '../../../services/task-service';
import { ValidatedViewModel } from 'src/app/modules/shared/viewModels/validatedViewModel';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { OperationTypes } from 'src/app/modules/shared/enums/operationTypes';
import { TimeZoneService } from 'src/app/modules/shared/services/timeZone.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { TaskUtilities } from 'src/app/modules/shared/utilities/task.utilities';
import { MilestoneTypes } from 'src/app/modules/shared/enums/milestoneTypes';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { BsModalEventsEmitter } from 'src/app/modules/shared/events/bsModal.events';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { TaskPriorities } from 'src/app/modules/shared/enums/taskPriorities';

@Component({
  selector: 'app-task-add-modal',
  templateUrl: './task-add-modal.component.html',
  styleUrls: ['./task-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskAddModalComponent implements OnInit, OnDestroy {
  /**
   * When the user clicks the close button this event is fired.
   *
   * NOTE!!!
   * This event MUST NOT be confused with closing the modal
   * so in order for all the callbacks subscribed to
   * this event to work correctly it shouldn't be called INSIDE any function
   * which closes the modal.
   */
  @Output() cancel = new EventEmitter<void>();

  public task: TaskDetailsViewModel = new TaskDetailsViewModel();
  public filterTypes = FilterTypes;
  public filters: FilterViewModel[] = [];
  public isMilestoneActivated = false;
  public addModalButtonOptions = AddModalButtonOptions;
  public buttonOptions = AddModalButtonOptions.getOptions(this.translateService);
  public dateReseter = false;
  public keyboardShortcuts = AddModalKeyboardShortcuts.getKeyShortsAsObj(this.translateService);
  public form: UntypedFormGroup;
  public isLoading = false;
  public isHiddenOptionVisible = false;
  private mobileMaxWidth = Constants.sm;
  public isMobile: boolean = window.innerWidth < this.mobileMaxWidth;
  public editableFieldTypes = EditableFieldTypes;
  public completionArr: { visibleValue: string; value: number }[] = TaskUtilities.getTaskCompletionArray();
  public triggerErrors = false;
  public validationErrors: string[];
  selectedProject: FilterViewModel;
  selectedTaskGroup: FilterViewModel;
  selectedRag: FilterViewModel;
  selectedButtonOption: number = 1;
  referrer: string = '';
  taskSvg = IconUtilities.getSvgForFilterType(FilterTypes.Task);
  objectTypes = ObjectTypes;
  public localisedTask = 'Task';
  public localisedProject = 'Project*';
  public localisedDepartment: string = 'Department';
  public employee: Employee;
  public disableProjectsDropdown: boolean = false;
  public disableTaskGroupDropdown: boolean = false;
  public disableRagDropdown: boolean = false;
  public readonly T = T;

  private allowedFilters: FilterViewModel[];
  private subscriptions = new Subscription();

  private isTouched = false;
  priorityTitles: string[] = [];
  @ViewChild('task_add_modal_dropdown') taskAddModalDropdown: ElementRef;
  public currentSelectedPriorityFilter: FilterViewModel;


  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      event.preventDefault();
      if (event.ctrlKey || event.metaKey) {
        if (event.shiftKey) {
          this.handleAction(
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
          );
          return;
        }
        this.handleAction(
          AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
        );
        return;
      }
      this.handleAction(AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService));
      return;
    }
  }

  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    this.isMobile = window.innerWidth < this.mobileMaxWidth;
    this.changeDetectorRef.detectChanges();
  }

  constructor(
    private bsModalRef: BsModalRef,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private taskService: TaskService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private elemRef: ElementRef,
    private allowedFiltersService: AllowedFiltersService,
    private confirmationService: ConfirmationService,
    private readonly timeZoneService: TimeZoneService,
    private readonly localisationService: LocalisationService,
    private alertService: AlertService,
    private readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly translateService: TranslateService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.localisedTask = this.localisationService.localiseObjectType(ObjectTypes.Task);
    this.localisedProject = this.localisationService.localiseObjectType(ObjectTypes.Project);
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);

    this.initPriorityFilterTitles();

    this.form = this.fb.group({
      taskTitle: [
        '',
        { validators: [Validators.required, this.wtValidators.title(), this.wtValidators.restrictedChars([';'])], updateOn: 'blur' },
      ],
      description: ['', Validators.maxLength(1000)],
      priority: [0],
    });

    this.subscriptions.add(
      this.form.valueChanges.subscribe((res) => {
        this.isTouched = true;
      })
    );

    this.initAllowedFilters();

    if (this.selectedProject) {
      this.filters.push(this.selectedProject);
      this.disableProjectsDropdown = true;
    }

    if (this.selectedTaskGroup) {
      this.filters.push(this.selectedTaskGroup);
      this.disableTaskGroupDropdown = true;
    }

    if (this.selectedRag) {
      this.filters.push(this.selectedRag);
      this.disableRagDropdown = true;
    } else {
      this.filters.push(FilterUtilities.GenerateFilter(FilterTypes.RAG, 0));
    }

    if (this.referrer) {
      if (this.referrer === 'gantt') {
        this.buttonOptions = this.buttonOptions.filter(
          (o) => o != AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
        );
        this.selectedButtonOption = 1;
      }
    }

    this.filters = this.filters.slice();

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initPriorityFilterTitles(): void {
    const one_star = this.localisationService.localiseFilterValueByFilterType(
      TaskPriorities.One_Star,
      FilterTypes.Task_Priority
    );
    const two_stars = this.localisationService.localiseFilterValueByFilterType(
      TaskPriorities.Two_Star,
      FilterTypes.Task_Priority
    );
    const three_stars = this.localisationService.localiseFilterValueByFilterType(
      TaskPriorities.Three_Star,
      FilterTypes.Task_Priority
    );

    this.priorityTitles = [one_star, two_stars, three_stars];
  }

  public clearPriority(): void {
    this.form.controls.priority.setValue(0);
  }
  validateRequiredFilters(filtersForSearch: FilterTypes[]): boolean {
    let isValid = true;
    filtersForSearch.forEach((currentFilterType) => {
      if (!this.filters.find((f) => f.filterType === currentFilterType)) {
        isValid = false;
      }
    });

    return isValid;
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  setAccountForCurrentOwner() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (s: any) => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.employee.id.toString()
      );
      if (empFilter !== null && this.filters.indexOf(empFilter) < 0) {
        this.filters = this.filters.slice();
        this.filters.push(empFilter);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  setMyDepartment() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (f) => f.filterType === FilterTypes.Department && f.filterValue.toString() === this.employee.departmentId.toString()
      );
      if (!this.filters.some((x) => x.id == empFilter.id)) {
        if (empFilter && this.filters.indexOf(empFilter) < 0) {
          this.filters = this.filters.slice();
          this.filters.push(empFilter);
        }
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  updateDescription($event) {
    this.form.controls.description.setValue($event);
  }

  showHiddenOptions() {
    this.isHiddenOptionVisible = !this.isHiddenOptionVisible;
    this.changeDetectorRef.detectChanges();
  }

  handleAction(action: string) {
    const isFiltersValid = this.validateRequiredFilters([FilterTypes.Project, FilterTypes.RAG]);
    if (!this.form.valid || !isFiltersValid) {
      this.triggerErrors = true;
      return;
    }
    if (action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService)) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res: ValidatedViewModel) => {
          const errors = res.errorList;
          const newTask = res.returnModel as TaskDetailsViewModel;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.allowedFiltersService.refreshFilters(
              OperationTypes.Create,
              [{ id: newTask.id, title: newTask.title, relatedObjectId: newTask.taskGroupId }],
              FilterTypes.Task
            );
            this.isLoading = false;
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedTask }));
            this.bsModalEventsEmitter.broadcastBsModalActionHandleClicked(newTask);
            this.closeModal();
          }
        })
      );
    } else if (
      action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
    ) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res: ValidatedViewModel) => {
          const errors = res.errorList;
          const newTask = res.returnModel as TaskDetailsViewModel;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.allowedFiltersService.refreshFilters(
              OperationTypes.Create,
              [{ id: newTask.id, title: newTask.title, relatedObjectId: newTask.taskGroupId }],
              FilterTypes.Task
            );
            this.isLoading = false;
            const currentTask = res.returnModel;
            void this.router.navigate([`v2/planning/list/tasks/${currentTask.id}`]);
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedTask }));
            this.closeModal();
          }
        })
      );
    } else if (
      action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
    ) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res) => {
          const errors = res.errorList;
          const newTask = res.returnModel as TaskDetailsViewModel;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.allowedFiltersService.refreshFilters(
              OperationTypes.Create,
              [{ id: newTask.id, title: newTask.title, relatedObjectId: newTask.taskGroupId }],
              FilterTypes.Task
            );
            this.isLoading = false;
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedTask }));
            this.resetModal();
          }
        })
      );
    }
  }

  handleSubmit(): Observable<ValidatedViewModel> {
    this.task.filters = this.filters;
    this.task.title = this.form.controls.taskTitle.value.trim();
    this.task.description = this.form.controls.description.value ? this.form.controls.description.value.trim() : null;
    this.task.priority = +this.form.controls.priority.value;
    this.task.filters = this.task.filters.filter(f => f.filterType !== FilterTypes.Priority);
    if(this.task.priority) {
      this.task.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Priority, this.task.priority));
    }
    // Populate primitive properties from Filter list
    this.task.taskGroupId = this.getFilterValue(FilterTypes.Task_Group);

    this.task.milestoneType = this.getFilterValue(FilterTypes.Milestone_Type);
    this.task.rag = this.getFilterValue(FilterTypes.RAG);
    return this.taskService.add(this.task);
  }

  // Get dates
  get getStartDate() {
    if (this.task.start) {
      return this.task.start;
    }
    return '';
  }

  get getDueDate() {
    if (this.task.due) {
      return this.task.due;
    }
    return '';
  }
  // Set dates
  setStartDate(dateAsIsoString: string) {
    this.task.start = dateAsIsoString;
    this.populateDateInFilter(FilterDateOptions.Start_Date, dateAsIsoString);

    if (this.isMilestoneActivated && this.task.due) {
      this.task.due = this.task.start;

      this.populateDateInFilter(FilterDateOptions.Due_Date, dateAsIsoString);
    }
  }

  setDueDate(dateAsIsoString: string) {
    this.task.due = dateAsIsoString;
    this.populateDateInFilter(FilterDateOptions.Due_Date, dateAsIsoString);

    if (this.isMilestoneActivated || !this.task.start) {
      this.task.start = this.task.due;
      this.populateDateInFilter(FilterDateOptions.Start_Date, dateAsIsoString);
    }
  }

  populateDateInFilter(filterOption: FilterDateOptions, value: any) {
    const due = this.filters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty === filterOption);
    if (!due) {
      this.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Date, value, value, filterOption));
    } else {
      due.filterValue = value;
    }
  }

  resetModal() {
    this.form.reset();
    this.filters = [];
    this.resetDateComponent();
  }

  resetDateComponent() {
    const selectedTaskGroup = this.createDeepCopy(this.selectedTaskGroup);
    this.dateReseter = true;
    this.changeDetectorRef.detectChanges();
    this.dateReseter = false;
    this.changeDetectorRef.detectChanges();
    this.selectedTaskGroup = this.createDeepCopy(selectedTaskGroup);
  }

  // Creating deep copy for "any" type of object.
  createDeepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  initAllowedFilters() {
    // Load them on ngOnInit (in the beggining) so when the client click on "Assign Me" the assign of the filter will be instant;
    this.allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();
  }

  confirmClose() {
    if (this.isTouched) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
        () => {
          this.bsModalEventsEmitter.broadcastBsModalCloseClicked({});
          this.closeModal();
          this.cancel.next();
        },
        () => {
          //handle cancel
        },
        this.translateService.instant(T.common.confirm),
        false,
        this.translateService.instant(T.common.stay),
        this.translateService.instant(T.common.leave),
        'danger'
      );
    } else {
      this.closeModal();
      this.cancel.next();
    }
  }

  handleCompletion(event) {
    this.task.percentageComplete = event.value;
  }

  // ** FILTER METHODS **

  handleProjectChange(filters: FilterViewModel[]) {
    // When Project selected, clear Task Groups
    this.selectedProject = filters.find((f) => f.filterType === FilterTypes.Project);
    this.selectedTaskGroup = null;
    this.filters = filters.filter((f) => f.filterType !== FilterTypes.Task_Group);
    this.changeDetectorRef.detectChanges();
    this.isTouched = true;
  }

  handleFilterChange(filters: FilterViewModel[]) {
    this.selectedProject = filters.find((f) => f.filterType == FilterTypes.Project);
    this.selectedTaskGroup = filters.find((f) => f.filterType == FilterTypes.Task_Group);
    this.filters = filters;
    this.changeDetectorRef.detectChanges();

    if (filters.length > 1) {
      this.isTouched = true;
    }
  }

  handleMilestoneChange(filters: FilterViewModel[]) {
    this.isMilestoneActivated =
      filters.find((f) => f.filterType == FilterTypes.Milestone_Type)?.filterValue != MilestoneTypes.No_Milestone;
    this.filters = filters;

    if (this.isMilestoneActivated) {
      if (this.task.due) {
        this.task.start = this.task.due;
        this.populateDateInFilter(FilterDateOptions.Start_Date, this.task.due);
      } else {
        this.task.start = this.timeZoneService.getCurrentMomentInLocalisedTimezone().toISOString();
        this.populateDateInFilter(FilterDateOptions.Start_Date, this.task.start);
        this.task.due = this.timeZoneService.getCurrentMomentInLocalisedTimezone().toISOString();
        this.populateDateInFilter(FilterDateOptions.Due_Date, this.task.start);
      }
    }

    this.changeDetectorRef.detectChanges();
    this.isTouched = true;
  }

  getFilterValue(filterType: FilterTypes): number {
    const filter: FilterViewModel = this.task.filters.find((f) => f.filterType === filterType);
    return filter ? filter.filterValue : 0;
  }

  public onApply($event): void {
    this.currentSelectedPriorityFilter = $event;
    this.form.patchValue({priority: this.currentSelectedPriorityFilter.filterValue})
  }
}
