<div class="wraper-safe-random-54781273825-view-encapsulation-none">
  <ng-container *ngIf="isLoading && !singleItemHistory">
    <app-loading [displayLogo]="false">
      <p>{{ T.common.item_is_loading | translate: { item: T.common.history | translate } }}...</p>
    </app-loading>
  </ng-container>
  <header class="section-header" id="top" *ngIf="showHeader">
    <h3>{{ title }}</h3>
    <div class="subtitle">{{ description }}</div>
  </header>
  <ng-container *ngIf="!hideToggle && isMobile">
    <div class="comments-toggle-wrapper-mobile">
      <app-toggle-button
        [fillWidth]="true"
        [objects]="toggleOptions"
        [currentlySelectedObject]="selectedStatus"
        [bindProperty]="'value'"
        (clicked)="toggleStateChange($event)"
      ></app-toggle-button>
    </div>
  </ng-container>
  <ng-container *ngIf="!hideToggle && !isMobile">
    <div class="comments-toggle-wrapper" *ngIf="showToggle">
      <div class="history-title">
        {{ title }}
      </div>
      <div class="history-toggle-wrapper">
        <app-toggle-button
          [fillWidth]="true"
          [objects]="toggleOptions"
          [currentlySelectedObject]="selectedStatus"
          [bindProperty]="'visualText'"
          (clicked)="toggleStateChange($event)"
        ></app-toggle-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isControlModule && showHeader && !singleItemHistory">
    <app-incident-quick-add
      *ngIf="moduleType | canCreate: filterType"
      (logAdded)="loadData()"
      (incidentAdded)="loadData()"
    ></app-incident-quick-add>
  </ng-container>

  <ng-container *ngIf="showInput && canEdit">
    <div class="input-wrapper">
      <app-rich-text-editor
        [commentType]="selectedCommentType"
        [objectId]="objectId"
        [objectType]="objectType"
        [autoFocus]="false"
        [childHubAccountId]="childHubAccountId"
      >
      </app-rich-text-editor>
    </div>
  </ng-container>

  <ng-container *ngIf="headlineStatusCheckbox">
    <div class="headline-status-checkbox-wrapper mb-2">
      <app-checkbox-with-label
      (clicked)="toggleMarkAsHeadlineStatus()"
      [intialState]="'unchecked'"
      [label]="'Mark as Headline Status'">
    </app-checkbox-with-label>
    </div>
  </ng-container>

  <app-skeleton-history-loading
    *ngIf="isLoading && singleItemHistory"
    [count]="skeletonCount"
  ></app-skeleton-history-loading>
  <ng-container *ngIf="!isLoading">
    <ng-template #template let-dates="dates" let-dailyData="entries">
      <ng-container *ngFor="let date of dates">
        <!--date header-->
        <section class="feed-date" [ngClass]="{ margin: !singleItemHistory }">
          <div class="activity-feed-date-container">
            <div class="label-outer">
              <div class="label-inner" [class.grey-bg]="backgroundTheme === 'GREY'">
                {{ GetHeaderDate(date) }}
              </div>
            </div>
            <div class="activity-feed-line" [class.grey-bg]="backgroundTheme === 'GREY'"></div>
            <div
              class="activity-feed-vertical-line"
              [class.grey-bg]="backgroundTheme === 'GREY'"
              *ngIf="singleItemHistory"
            ></div>
            <div class="activity-feed-date-point" [class.grey-bg]="backgroundTheme === 'GREY'"></div>
          </div>
        </section>

        <!--container wrapper-->
        <section class="feed-item" [ngStyle]="{ paddingTop: singleItemHistory ? '0' : '1.5rem' }">
          <ng-container *ngIf="!singleItemHistory">
            <ng-container *ngFor="let historyObjectId of getHistoryObjectKeysForDate(dailyData[date])">
              <app-card-history-feed
                [objectId]="getObjectId(historyObjectId, dailyData[date])"
                [ragStatus]="getObjectRAGStatus(historyObjectId, dailyData[date])"
                [title]="getTitle(historyObjectId, dailyData[date])"
                [objectType]="getObjectType(historyObjectId, dailyData[date])"
                [objectSubType]="getObjectSubType(historyObjectId, dailyData[date])"
              >
              </app-card-history-feed>

              <div class="activity-feed-history-outer-container mb-3" [class.pl-3]="!singleItemHistory">
                <div class="activity-feed-history-container">
                  <div class="activity-feed-vertical-line" [class.grey-bg]="backgroundTheme === 'GREY'"></div>
                  <article class="activity-feed-content-wrapper">
                    <div
                      class="item-content-wrapper"
                      *ngFor="let historyEntry of getHistoryEntries(historyObjectId, dailyData[date])"
                      [innerHTML]="historyEntry.phrase"
                    ></div>
                  </article>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="singleItemHistory">
            <div class="activity-feed-history-outer-container" [class.pl-3]="!singleItemHistory">
              <div class="activity-feed-history-container">
                <div class="activity-feed-vertical-line" [class.grey-bg]="backgroundTheme === 'GREY'"></div>
                <article *ngIf="!showOnlyComments" class="activity-feed-content-wrapper">
                  <div
                    class="item-content-wrapper"
                    *ngFor="let historyEntry of dailyData[date]"
                    [innerHTML]="historyEntry.phrase | safeHtml"
                    routeTransform
                    ngImagePreview
                    (click)="handleClick($event)"
                  ></div>
                </article>
                <article *ngIf="showOnlyComments" class="activity-feed-content-wrapper">
                  <div
                    class="item-content-wrapper"
                    *ngFor="let historyEntry of comments[date]"
                    [innerHTML]="historyEntry.phrase | safeHtml"
                    routeTransform
                    ngImagePreview
                    (click)="handleClick($event)"
                  ></div>
                </article>
              </div>
            </div>
          </ng-container>
        </section>
      </ng-container>
    </ng-template>

    <div
      *ngIf="!showOnlyComments"
      [ngClass]="!hideToggle ? 'container-margin-top' : ''"
      #activityFeedContainer
      id="activity-feed-container"
    >
      <ng-container *ngIf="showIncidentHistoryTabs && showHeader; else nonTabsFeed">
        <ng-template
          *ngIf="currentlySelectedTab === allIncidentsHistoryId"
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ dates: historyItemDates, entries: historyMultipleEntries }"
        ></ng-template>

        <!-- <app-tabset (tabChanged)="onTabSelect($event.id)">
          <app-tab id="{{allIncidentsHistoryId}}">
            <ng-template appTabHeading>Show All</ng-template>
            <ng-template *ngIf="currentlySelectedTab === allIncidentsHistoryId" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ dates: historyItemDates, entries: historyMultipleEntries }"></ng-template>
          </app-tab>
          <app-tab id="{{incidentsHistoryId}}">
            <ng-template appTabHeading>Incidents</ng-template>
            <ng-template *ngIf="currentlySelectedTab === incidentsHistoryId" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ dates: historyIncidentsItemKeys, entries: historyIncidentsEntries }"></ng-template>
          </app-tab>
          <app-tab id="{{logsHistoryId}}">
            <ng-template appTabHeading>Logs</ng-template>
            <ng-template *ngIf="currentlySelectedTab === logsHistoryId" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ dates: historyLogsItemKeys, entries: historyLogsEntries }"></ng-template>
          </app-tab>
        </app-tabset> -->
      </ng-container>

      <ng-template
        #nonTabsFeed
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{
          dates: historyItemDates,
          entries: singleItemHistory ? historyEntries : historyMultipleEntries
        }"
      ></ng-template>

      <!--date header-->
      <!--<section class="feed-date">
        <div class="activity-feed-date-container">
          <div class="label-outer">
            <div class="label-inner">
              Today
            </div>
          </div>
          <div class="activity-feed-line"></div>
          <div class="activity-feed-vertical-line" *ngIf="singleItemHistory"></div>
          <div class="activity-feed-date-point"></div>
        </div>
      </section> -->

      <!--container wrapper-->
      <!-- <section class="feed-item" [ngStyle]="{paddingTop: singleItemHistory ? '0' : '1.5rem'}">
        <app-card-history-feed *ngIf="!singleItemHistory" [itemRagStatus]="ragStatus" [itemType]="itemType">
        </app-card-history-feed>
        <div class="activity-feed-history-outer-container pl-3" [class.pl-3]="!singleItemHistory">
          <div class="activity-feed-history-container">
            <div class="activity-feed-vertical-line"></div>
            <article class="activity-feed-content-wrapper">

              <div class="item-content-wrapper">
                <div class="top">
                  <div class="time">11:09 AM</div>
                  Risk Chris Added Risk for history updated:
                  <div class="avatar"><img class="employee-icon"
                      src="/Resources6257f1d2-f339-4fb8-871a-6b45a5187f53.jpg" /></div>
                  <div class="history-action">Wetrack Admini updated the Due Date from
                    11/9/2019 to 11/9/2019
                  </div>
                </div>
              </div>

            </article>
          </div>
        </div>
      </section> -->
    </div>

    <div *ngIf="showOnlyComments" #activityFeedContainer id="activity-feed-container">
      <ng-container *ngIf="showIncidentHistoryTabs && showHeader; else nonTabsFeed">
        <ng-template
          *ngIf="currentlySelectedTab === allIncidentsHistoryId"
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ dates: commentsDates, entries: comments }"
        ></ng-template>
      </ng-container>

      <ng-template
        #nonTabsFeed
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ dates: commentsDates, entries: comments }"
      ></ng-template>

      <ng-container *ngIf="commentsDates.length === 0">
        <app-empty-state
          [hideIcon]="true"
          [showAddItemBtn]="false"
          [itemTitle]="'test'"
          [message]="T.common.no_item_to_display | translate: { item: T.common.comment.many | translate }"
        ></app-empty-state>
      </ng-container>
    </div>
  </ng-container>
</div>
