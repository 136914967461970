import { Injectable } from '@angular/core';
import { Configuration } from '../../../app.constants';
import { Observable } from 'rxjs';
import { GanttDataChange } from 'src/app/modules/gantt/models/gantt/view-models/ganttDataChange';
import { LocaliseGanttViewModel } from 'src/app/modules/gantt/models/gantt/view-models/localiseGanttColumnsViewModel';
import { AuthenticationService } from './authentication.service';
import { GanttViewModel } from '../../gantt/models/gantt/view-models/GanttViewModel';
import { GanttDataLinkViewModel } from '../../gantt/models/gantt/view-models/GanttDataLinkViewModel';
import { GanttColumnViewModel } from '../../gantt/models/gantt/view-models/ganttColumnViewModel';
import { Account } from '../models/account';
import { HttpClient } from '@angular/common/http';
import { Employee } from '../models/employee';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { ProjectDetailsViewModel } from '../../planning/viewModels/projects/projectDetailsViewModel';
import { GanttDataItemViewModel } from '../../gantt/models/gantt/view-models/GanttDataItemViewModel';
import { TaskGroupListItemViewModel } from '../../planning/viewModels/taskGroupListItemViewModel';
import { TaskDetailsViewModel } from '../../planning/viewModels/projects/taskDetailsViewModel';

@Injectable()
export class GanttService {
  public actionUrl: string;
  public employeeCustomGanttSettingUrl: string;
  public currentAccount: Account;
  public currentEmployee: Employee;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.currentAccount = this.authenticationService.getCurrentAccount();
    this.actionUrl = this.configuration.buildEndpoint(`Gantt/`);
    this.employeeCustomGanttSettingUrl = `EmployeeCustomGanttSettings/`;
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
  }

  //// New Methods

  GetHubGanttData(filters: FilterViewModel[]): Observable<GanttViewModel> {
    return this.http.post<GanttViewModel>(this.actionUrl + 'GetHubGanttData', filters);
  }

  GetGanttData(filters: FilterViewModel[]): Observable<GanttViewModel> {
    return this.http.post<GanttViewModel>(this.actionUrl + 'GetGanttData', filters);
  }

  UpdateTaskDates(changedItem: GanttDataChange): Observable<GanttViewModel> {
    return this.http.post<GanttViewModel>(this.actionUrl + 'UpdateTaskDates', changedItem);
  }

  AddTaskDependency(changedItem: GanttDataLinkViewModel): Observable<any> {
    return this.http.post(this.actionUrl + 'AddTaskDependency', changedItem);
  }

  DeleteTaskDependency(changedItem: GanttDataLinkViewModel): Observable<any> {
    return this.http.post(this.actionUrl + 'DeleteTaskDependency', changedItem);
  }

  // Methods called when Entities are added through the modals on gantt view
  GetProjectGanttItem(project: ProjectDetailsViewModel): Observable<GanttDataItemViewModel> {
    return this.http.post<GanttDataItemViewModel>(this.actionUrl + 'GetProjectGanttItem', project);
  }

  GetTaskGroupGanttItem(taskGroup: TaskGroupListItemViewModel): Observable<GanttDataItemViewModel> {
    return this.http.post<GanttDataItemViewModel>(this.actionUrl + 'GetTaskGroupGanttItem', taskGroup);
  }

  GetTaskGanttItem(task: TaskDetailsViewModel): Observable<GanttDataItemViewModel> {
    return this.http.post<GanttDataItemViewModel>(this.actionUrl + 'GetTaskGanttItem', task);
  }

  GetSubTaskGanttItem(task: TaskDetailsViewModel): Observable<GanttDataItemViewModel> {
    return this.http.post<GanttDataItemViewModel>(this.actionUrl + 'GetSubTaskGanttItem', task);
  }

  public LocaliseGanttColumns = (columns: GanttColumnViewModel[]): Observable<GanttColumnViewModel[]> => {
    const vm = new LocaliseGanttViewModel();
    vm.ganttColumns = columns;
    vm.accountId = this.authenticationService.getCurrentAccount().id;

    return this.http.post<GanttColumnViewModel[]>(
      this.actionUrl + 'utilities/localisation/Columns',
      JSON.stringify(vm),
      this.authenticationService.getHttpOptions()
    );
  };

  /**
   * Get expanded task dependencies by provided context
   * @param context filtered data context
   */
  public GetTaskDependencyWithContext(context: string): Observable<GanttViewModel> {
    return this.http.get<GanttViewModel>(this.actionUrl + `TaskDependency/Get/${this.currentEmployee.id}/${context}`);
  }

  public getGanttDataForRunsheets(runsheetId: number): Observable<any> {
    return this.http.post(this.actionUrl + `GetGanttDataForRunsheetDetails/${runsheetId}`, []);
  }
}
