import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { PEAPViewModel } from '../../models/peaps/peap';
import { ValidatedViewModel } from '../../viewModels/validatedViewModel';
import { Employee } from '../../models/employee';
import { AuthenticationService } from '../authentication.service';
import { FilterViewModel } from '../../models/filter/filterViewModel';
import { ObjectEventModel } from '../../models/objectEventModel';

@Injectable()
export class PEAPService {
  private baseUrl: string = '';
  private employee: Employee;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly configuration: Configuration,
    private readonly authenticationService: AuthenticationService
  ) {
    this.baseUrl = this.configuration.buildEndpoint('PEAP/');
    this.employee = this.authenticationService.getCurrentEmployee();
  }

  add(viewModel: PEAPViewModel): Observable<ValidatedViewModel> {
    return this.httpClient.post<ValidatedViewModel>(`${this.baseUrl}Add`, viewModel);
  }

  addMutiple(viewModel: PEAPViewModel[]): Observable<ValidatedViewModel> {
    return this.httpClient.post<ValidatedViewModel>(`${this.baseUrl}Add`, viewModel);
  }

  update(viewModel: PEAPViewModel): Observable<PEAPViewModel> {
    return this.httpClient.put<PEAPViewModel>(`${this.baseUrl}Update`, viewModel);
  }

  delete(peapId: number): Observable<PEAPViewModel> {
    return this.httpClient.delete<PEAPViewModel>(`${this.baseUrl}Delete/${peapId}`);
  }

  getList(filters: FilterViewModel[]): Observable<PEAPViewModel[]> {
    return this.httpClient.post<PEAPViewModel[]>(`${this.baseUrl}GetList`, filters);
  }

  getDetails(id: number): Observable<PEAPViewModel> {
    return this.httpClient.get<PEAPViewModel>(`${this.baseUrl}GetDetails/${id}`);
  }

  updatePEAPInfoWhenCheckIsChanged(peaps: PEAPViewModel[], res: ObjectEventModel) {
    peaps.forEach((p) => {
      let matchingCheck = p.checks.find((c) => c.id == res.globalObjectId);

      if (matchingCheck) {
        p.checks[p.checks.indexOf(matchingCheck)] = res.model;
        p.checks = p.checks.slice();
      } else {
        p.checkpoints.forEach((ch) => {
          matchingCheck = ch.checks.find((c) => c.id == res.globalObjectId);
          if (matchingCheck) {
            ch.checks[ch.checks.indexOf(matchingCheck)] = res.model;
            ch.checks = ch.checks.slice();
          }
        });
      }
    });

    peaps = peaps.slice();

    return peaps;
  }
}
