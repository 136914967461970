import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { FilterTypes } from '../../../enums/filterTypes';
import { Constants } from '../../../models/constants';

@Component({
  selector: 'app-headline-status',
  templateUrl: './headline-status.component.html',
  styleUrls: ['./headline-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadlineStatusComponent implements OnInit, OnChanges {
  @ViewChild('headlineInput') headlineInput: ElementRef<HTMLElement>;
  @Input() object: any;
  @Input() isReadOnly = false;
  @Input() useSmallContainer = false;
  @Output() onChanged: EventEmitter<any> = new EventEmitter();

  headlineStatus: string;
  editState = false;
  public readonly T = T;

  constructor(private readonly elementRef: ElementRef, private readonly changeDetectorRef: ChangeDetectorRef) {}

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.editState == true && event.key === 'Enter') {
      event.preventDefault();
      this.onSave();
    }
  }

  @HostListener('document:mousedown', ['$event'])
  clickout(event: MouseEvent) {
    if (this.useSmallContainer && this.editState == true && !this.elementRef.nativeElement.contains(event.target)) {
      this.onSave();
    }
  }

  ngOnInit() {
    this.headlineStatus = JSON.parse(JSON.stringify(this.object.headlineStatus));
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  onSave() {
    if (this.headlineStatus !== this.object.headlineStatus) {
      const headlineFilter = this.object.filters.find((f) => f.filterType == FilterTypes.Headline_Status);
      if (headlineFilter) {
        headlineFilter.value = JSON.parse(JSON.stringify(this.headlineStatus));
        headlineFilter.filterAction = FilterActionTypes.Update;
      }
      this.object.headlineStatus = JSON.parse(JSON.stringify(this.headlineStatus));
      this.onChanged.emit(this.object);
    }

    this.editState = false;
    this.changeDetectorRef.markForCheck();
  }

  onMakeEditable() {
    if (!this.isReadOnly && !this.editState) {
      this.editState = true;

      if (this.useSmallContainer) {
        setTimeout(() => {
          this.headlineInput.nativeElement.focus();
        }, 0);
      }
    }
  }

  onCancel() {
    this.editState = false;
    this.headlineStatus = this.object.headlineStatus;
    this.changeDetectorRef.markForCheck();
  }

  get isMobile() {
    return window.innerWidth <= Constants.xs;
  }
}
