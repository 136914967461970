import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { PEAPDashboardStatisticsViewModel } from '../../models/peaps/peapdashboardstatistics';
import { PEAPZoneBreakdownViewModel } from '../../models/peaps/zoneBreakdownViewModel';
import { PEAPsSetupViewModel } from 'src/app/modules/settings/viewModels/peapSetupViewModel';

@Injectable()
export class PEAPDashboardService {
  private baseUrl: string = '';

  constructor(private readonly httpClient: HttpClient, private readonly configuration: Configuration) {
    this.baseUrl = this.configuration.buildEndpoint('PEAPDashboard/');
  }

  getStatistics(): Observable<PEAPDashboardStatisticsViewModel> {
    return this.httpClient.get<PEAPDashboardStatisticsViewModel>(`${this.baseUrl}Statistics`);
  }

  getZoneBreakdowns(): Observable<PEAPZoneBreakdownViewModel[]> {
    return this.httpClient.get<PEAPZoneBreakdownViewModel[]>(`${this.baseUrl}ZoneBreakdown`);
  }

  getSetupData(): Observable<PEAPsSetupViewModel> {
    return this.httpClient.get<PEAPsSetupViewModel>(`${this.baseUrl}Setup`);
  }
}
