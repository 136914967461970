import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgDropdownDirective } from '../../../directives/ngDropdown.directive';
import { SortDropdownItem } from '../../../models/sortDropdownItem';


@Component({
  selector: 'app-sorting-dropdown',
  templateUrl: './sorting-dropdown.component.html',
  styleUrls: ['./sorting-dropdown.component.scss'],
})
export class SortingDropdownComponent implements OnInit, OnChanges {
  @Input() selectedSortingDropdownItem: SortDropdownItem;
  @Input() sortDropdownItems: SortDropdownItem[];
  @Output() changeSelectedSortingDropdownItem = new EventEmitter<SortDropdownItem>();

  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;

  currentlySelectedSortingOption: SortDropdownItem;

  ngOnInit(): void {
    if(!this.selectedSortingDropdownItem) {
      this.selectedSortingDropdownItem = this.sortDropdownItems[0];
    }
    this.assignCurrentlySelectedOption();
  }

  ngOnChanges(): void {
    this.assignCurrentlySelectedOption();
  }

  assignCurrentlySelectedOption(): void {
    this.currentlySelectedSortingOption = this.selectedSortingDropdownItem;
  }

  onSelectEntry(entry: SortDropdownItem): void {
    this.currentlySelectedSortingOption = entry;
  }

  onDropdownToggle(isVisible: boolean): void {
    if(!isVisible) this.assignCurrentlySelectedOption();
  }

  onCancel(): void {
    this.closeDropdown();
    this.assignCurrentlySelectedOption();
  }

  onApply(): void {
    this.changeSelectedSortingDropdownItem.emit(this.currentlySelectedSortingOption);
    this.closeDropdown();
  }

  closeDropdown(): void {
    this.ngDropdownDirective.unVisible();
    this.assignCurrentlySelectedOption();
  }
}
