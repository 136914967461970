import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { IndicatorPriorities } from '../enums/indicatorPriorities';
import { IndicatorTrackingDateTypes } from '../enums/indicatorTrackingDateTypes';
import { IndicatorTrendRAGs } from '../enums/indicatorTrendRAGs';
import { IndicatorTrendStatuses } from '../enums/indicatorTrendStatuses';
import { IndicatorUpdateExpectedDirections } from '../enums/indicatorUpdateExpectedDirections';
import { IndicatorUpdateTypes } from '../enums/indicatorUpdateTypes';
import { SustainabilityScopes } from '../enums/sustainability_scopesTypes';
import { ClimatiqIndicatorEmissionsDataSelectorViewModel } from './climatiq/climatiqIndicatorEmissionsDataSelectorViewModel';
import { IndicatorTargetIconViewModel } from './indicatorTargetIconViewModel';
import { IndicatorTargetSimpleViewModel } from './indicatorTargetSimpleViewModel';
import { IndicatorTotalEmissionsBreakdownViewModel } from './indicatorTotalEmissionsBreakdownViewModel';
import { IndicatorUpdateSimpleViewModel } from './indicatorUpdateSimpleViewModel';

export class IndicatorViewModel extends ModifiableEntityViewModel {
  refCode: string;
  startDate: string;
  dueDate: string;
  indicatorCategoryId: number;
  updateType: IndicatorUpdateTypes;
  unitOfMeasurementAbbreviation: string;
  baselineValue: number;
  trackingDateValue: number;
  trackingDateType: IndicatorTrackingDateTypes;
  mostRecentValue: number;
  priority: IndicatorPriorities;
  trendRAG: IndicatorTrendRAGs;
  trendStatus: IndicatorTrendStatuses;
  latestUpdateInfo: IndicatorUpdateSimpleViewModel;
  nextUpdateInfo: IndicatorUpdateSimpleViewModel;
  nextTarget: IndicatorTargetSimpleViewModel;
  targetsAchieved: IndicatorTargetIconViewModel[];
  description: string;
  createdByName: string;
  created: string;
  updateFrequencySummary: string;
  eventId: number;
  latestIndicatorUpdateId: number;
  expectedDirection?: IndicatorUpdateExpectedDirections;
  indicatorFrameworkId?: number;
  indicatorTemplateId?: number;
  active: boolean;
  unitTypeId:number;
  conversionUnitId:number;
  defaultUnitId:number;
  conversionUnitTitle: string;
  conversionUnitAbbreviation: string;
  canChangeTrackingPeriod: boolean;
  totalUpdatesWithValues: number;
  emissionsData: ClimatiqIndicatorEmissionsDataSelectorViewModel; // Only populated during Add Indicator modal (Ignore for details page)
  trackCo2Emissions: boolean;
  co2eTotal: number;
  totalCo2EmissionsBreakdown: IndicatorTotalEmissionsBreakdownViewModel[];
  sustainabilityScope: SustainabilityScopes;
}
