import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModifiableEntityViewModel } from 'src/app/modules/incidents/viewModels/modifiableEntityViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectTypes } from '../../../enums/objectTypes';
import { IApiService } from '../../../interfaces/iHttpService';
import { LightFilterUpdateModel } from '../../../models/filter/LightFilterUpdateModel';
import { AlertService } from '../../../services/alert.service';
import { IncidentsService } from '../../../services/incidents.service';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { ConvertHTMLEntitiesPipe } from '../../../pipes/convert-HTML-entities.pipe';

@Component({
  selector: 'app-update-headline-status-modal',
  templateUrl: 'update-headline-status-modal.component.html',
  styleUrls: ['update-headline-status-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateHeadlineStatusModalComponent implements OnInit, OnDestroy, AfterViewInit {
  activeLightUpdate = true;
  subscriptions: Subscription[] = [];
  headlineStatus: string;
  object: ModifiableEntityViewModel;
  service: IApiService;
  headlineStatusFilterType = FilterTypes.Headline_Status;
  public readonly T = T;
  constructor(
    public bsModalRef: BsModalRef,
    private readonly elemRef: ElementRef,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private convertHtmlEntities: ConvertHTMLEntitiesPipe
  ) {}

  ngOnInit(): void {
    const matchingFilter = this.object.filters.find((f) => f.filterType === this.headlineStatusFilterType);
    this.headlineStatus = matchingFilter ? matchingFilter.filterValue as string : '';
    if(this.headlineStatus) {
      this.headlineStatus = this.convertHtmlEntities.transform(this.headlineStatus) as string;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.setModalContainerDisplayFlex();
  }

  onClose(): void {
    this.bsModalRef.hide();
  }

  onUpdateHeadlineStatus(): void {
    let filter = this.object.filters.find((f) => f.filterType === this.headlineStatusFilterType);

    this.headlineStatus = this.convertHtmlEntities.transform(this.headlineStatus) as string;

    if (!filter) {
      filter = FilterUtilities.GenerateFilter(this.headlineStatusFilterType, this.headlineStatus, this.headlineStatus);
      this.object.filters.push(filter);
    } else {
      filter.filterValue = this.headlineStatus;
      filter.filterText = this.headlineStatus;
    }

    filter.filterAction = FilterActionTypes.Update;
    this.object['headlineStatus'] = this.headlineStatus;

    if (this.activeLightUpdate) {
      const requestModel: LightFilterUpdateModel = {
        globalObjectId: this.object.id,
        objectType: ObjectTypes.IncidentItem,
        accountId: 0,
        changes: [filter],
        updated: ''
      };

      const currentService = this.service as IncidentsService;
      currentService.updateLight(requestModel).subscribe((res) => {
        const updateFilter = res.changes.find(
          (d) => d.filterType === FilterTypes.Date && d.dateProperty === FilterDateOptions.Updated
        );
        this.object.updated = updateFilter.filterValue;
        this.object = Object.assign({}, this.object);

        void this.alertService.success(this.translateService.instant(T.common.headline_status_updated));
        this.bsModalRef.hide();
      });
    } else {
      const date = new Date();

      this.object['headlineStatusUpdated'] = new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds()
        )
      ).toJSON();

      this.subscriptions.push(
        this.service.update(this.object).subscribe((res) => {
          void this.alertService.success(this.translateService.instant(T.common.headline_status_updated));
          this.bsModalRef.hide();
        })
      );
    }
  }

  get isHeadlineStatusChanged(): boolean {
    let result = false;
    const matchingFilter = this.object.filters.find((f) => f.filterType === this.headlineStatusFilterType);
    result = (!matchingFilter && !this.headlineStatus.length) || matchingFilter.filterValue === this.headlineStatus;

    return result;
  }

  setModalContainerDisplayFlex(): void {
    const modalDialog = this.elemRef.nativeElement.closest('[role=document]');
    (modalDialog as HTMLElement).classList.add('overflow-visible');
  }
}
