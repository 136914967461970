import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgDatepickerComponent } from '../components/common/ngdatepicker/ngdatepicker.component';
import { WindowEventsEmitter } from '../events/window.events';
import { DynamicComponentsService } from '../services/dynamicComponents.service';
import { isChildOf } from '../utilities/html.utilities';
import { NgMinMaxDateStrategies } from '../models/rangeDatepicker/rangeDatepickerModels.model';

@Directive({
  selector: '[ngdatepicker]',
  host: {
    '(click)': 'onClick($event)',
  },
})
export class NgDatepickerDirective implements OnInit, OnChanges, OnDestroy {
  private readonly marginTop: number = 4;

  @Input() date: string | Date;
  @Input() minDate: string | Date;
  @Input() maxDate: string | Date;
  @Input() helperDate: string | Date;
  @Input() isEndDate: boolean;
  // In case where the calendar needs to use predefined active dates , set this to true
  @Input() usePredefinedAvailableDates: boolean = false;
  // Predefined dates that will be shown in the calendar as clickable, !!!EVERY OTHER DATE will be DISABLED
  @Input() predefinedAvailableDates: string[];
  @Input() minMaxDateStrategy: NgMinMaxDateStrategies = NgMinMaxDateStrategies.DisableDate;

  @Output() dateChanged: EventEmitter<string> = new EventEmitter();

  static ngDatepickerComponentRef: ComponentRef<NgDatepickerComponent>;

  private _clickOutEventSubscription: Subscription = null;
  private _routerEventSubscription: Subscription = null;
  private onHideSubscription = new Subscription();
  private listeners = [];

  constructor(
    private readonly dynamicComponentsService: DynamicComponentsService,
    private readonly appRef: ApplicationRef,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly windowEvents: WindowEventsEmitter,
    private readonly renderer: Renderer2,
    private readonly router: Router
  ) {
    if (!NgDatepickerDirective.ngDatepickerComponentRef) {
      this.appendToBody();
      this.initGlobalScrollHandler();
    }
  }

  ngOnInit() {
    this.onHideSubscription.add(
      this.getNgDatepickerInstance().onHide.subscribe((r) => {
        this.unsubscribeFromEvents();
      })
    );
  }

  ngOnChanges() {
    if (this.getNgDatepickerInstance().visible && this.getNgDatepickerInstance().directiveElementRef == this.elementRef) {
      this.updateInstance();
    }
  }

  ngOnDestroy() {
    this.unsubscribeFromEvents();
    this.onHideSubscription.unsubscribe();
  }

  subscribeForWindowClick() {
    this._clickOutEventSubscription = this.windowEvents.windowClickEventTriggered$.subscribe((e: Event) => {
      this.onDocumentClick(e);
    });
  }
  unsubscribeFromWindowClick() {
    if (this._clickOutEventSubscription) {
      this._clickOutEventSubscription.unsubscribe();
    }
  }

  // We need to close the datepicker when route changes
  subscribeForRouteChange() {
    this._routerEventSubscription = this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => {
      this.getNgDatepickerInstance().hide();
      this.unsubscribeFromEvents();
    });
  }
  unsubscribeFromRouterEvents() {
    if (this._routerEventSubscription) {
      this._routerEventSubscription.unsubscribe();
    }
  }

  getNgDatepickerInstance(): NgDatepickerComponent {
    return NgDatepickerDirective.ngDatepickerComponentRef.instance;
  }
  getNgDatepickerElement(): HTMLElement {
    return this.getDomElement(NgDatepickerDirective.ngDatepickerComponentRef);
  }

  get datepickerChangeDetectorRef(): ChangeDetectorRef {
    return NgDatepickerDirective.ngDatepickerComponentRef.changeDetectorRef;
  }

  onClick() {
    this.updatePosition();
    this.updateInstance();
    this.getNgDatepickerInstance().show();
    this.subscribeForWindowClick();
    this.subscribeForRouteChange();
  }

  onDocumentClick({ target }: Event) {
    if (!this.getNgDatepickerInstance().visible) {
      return;
    }

    if (this.getNgDatepickerInstance().directiveElementRef !== this.elementRef) {
      return;
    }

    const targetIsNotPartOfTheDatepicker =
      target !== this.getNgDatepickerElement() && !isChildOf(this.getNgDatepickerElement(), target as HTMLElement);
    const targetIsNotPartOfTheElementRef =
      target !== this.elementRef.nativeElement && !isChildOf(this.elementRef.nativeElement, target as HTMLElement);

    if (targetIsNotPartOfTheDatepicker && targetIsNotPartOfTheElementRef) {
      this.getNgDatepickerInstance().hide();
      this.unsubscribeFromEvents();
    }
  }

  private getDomElement<T>(componentRef: ComponentRef<T>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<T>).rootNodes[0] as HTMLElement;
  }

  private appendToBody() {
    NgDatepickerDirective.ngDatepickerComponentRef = this.dynamicComponentsService.createComponentElement(
      NgDatepickerComponent,
      {}
    );

    this.appRef.attachView(NgDatepickerDirective.ngDatepickerComponentRef.hostView);

    document.body.appendChild(this.getDomElement(NgDatepickerDirective.ngDatepickerComponentRef));
  }

  private removeFromBody() {
    this.getDomElement(NgDatepickerDirective.ngDatepickerComponentRef).remove();

    this.appRef.detachView(NgDatepickerDirective.ngDatepickerComponentRef.hostView);
  }
  private updateInstance() {
    if (!this.getNgDatepickerInstance()) {
      return;
    }

    this.getNgDatepickerInstance().minMaxDateStrategy = this.minMaxDateStrategy;
    this.getNgDatepickerInstance().date = this.date;
    this.getNgDatepickerInstance().minDate = this.minDate;
    this.getNgDatepickerInstance().maxDate = this.maxDate;
    this.getNgDatepickerInstance().helperDate = this.helperDate;
    this.getNgDatepickerInstance().dateChanged = this.dateChanged;
    this.getNgDatepickerInstance().isEndDate = this.isEndDate;
    this.getNgDatepickerInstance().usePredefinedAvailableDates = this.usePredefinedAvailableDates;
    this.getNgDatepickerInstance().predefinedAvailableDates = this.predefinedAvailableDates;
    this.getNgDatepickerInstance().initMonth();
    this.getNgDatepickerInstance().initYear();
    this.getNgDatepickerInstance().directiveElementRef = this.elementRef;

    this.datepickerChangeDetectorRef.detectChanges();
  }

  private updatePosition() {
    if (!this.getNgDatepickerElement()) {
      return;
    }

    const { left, top, width, height } = this.elementRef.nativeElement.getBoundingClientRect();

    const datepicker = this.getNgDatepickerElement().firstElementChild as HTMLElement;
    const datepickerBoundingRect = datepicker.getBoundingClientRect();

    const elementFitsOnTheRight: boolean = left + datepickerBoundingRect.width <= window.innerWidth;

    if (elementFitsOnTheRight) {
      datepicker.style.left = `${left}px`;
    } else {
      datepicker.style.left = `${left + width - datepickerBoundingRect.width}px`;
    }

    const elementFitsBeneath: boolean = top + height + this.marginTop + datepickerBoundingRect.height <= window.innerHeight;

    if (elementFitsBeneath) {
      datepicker.style.top = `${top + height + this.marginTop}px`;
    } else {
      datepicker.style.top = `${top - this.marginTop - datepickerBoundingRect.height}px`;
    }
  }

  private initGlobalScrollHandler() {
    const listener = this.renderer.listen(document.body, 'scroll', () => {
      this.updatePosition();
    });
    this.listeners.push(listener);
  }

  private destroyGlobalScrollHandler() {
    this.listeners.forEach((listener) => listener());
  }

  private unsubscribeFromEvents() {
    this.unsubscribeFromWindowClick();
    this.unsubscribeFromRouterEvents();
  }
}
