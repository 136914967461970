import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { FilterViewModel } from '../models/filter/filterViewModel';

@Injectable({
  providedIn: 'root',
})
export class HistoryFiltersService {
  private url: string;

  constructor(private http: HttpClient, private configuration: Configuration, private readonly storage: StorageMap) {
    this.url = this.configuration.buildEndpoint(`Filters/`);
  }

  /**
   * Get Filters used in history filtrations
   */
  public getHistoryFilters(): Observable<FilterViewModel[]> {
    return this.http.post<FilterViewModel[]>(`${this.url}historyFilters`, {});
  }
}
