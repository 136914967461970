import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { CheckpointTypeViewModel } from '../../models/peaps/checkpointType';
import { Observable } from 'rxjs';
import { ValidatedViewModel } from '../../viewModels/validatedViewModel';

@Injectable()
export class CheckpointTypeService {
  private baseUrl: string = '';

  constructor(private readonly httpClient: HttpClient, private readonly configuration: Configuration) {
    this.baseUrl = this.configuration.buildEndpoint('CheckpointType/');
  }

  add(checkpointTypeViewModel: CheckpointTypeViewModel): Observable<ValidatedViewModel> {
    return this.httpClient.post<ValidatedViewModel>(`${this.baseUrl}Add`, checkpointTypeViewModel);
  }

  update(checkpointTypeViewModel: CheckpointTypeViewModel): Observable<CheckpointTypeViewModel> {
    return this.httpClient.put<CheckpointTypeViewModel>(`${this.baseUrl}Update`, checkpointTypeViewModel);
  }

  delete(checkpointTypeId: number): Observable<CheckpointTypeViewModel> {
    return this.httpClient.delete<CheckpointTypeViewModel>(`${this.baseUrl}Delete/${checkpointTypeId}`);
  }

  deleteMutiple(checkpointTypeIds: number[]): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}DeleteMutiple`, checkpointTypeIds);
  }

  getList(): Observable<CheckpointTypeViewModel[]> {
    return this.httpClient.get<CheckpointTypeViewModel[]>(`${this.baseUrl}GetList`);
  }
}
