import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ChecklistResponseTypes } from 'src/app/modules/shared/enums/checklists/checklistEnums';
import { PaginationManager } from 'src/app/modules/shared/managers/paginationManager';
import { ChecklistItemViewModel } from 'src/app/modules/shared/models/checklists/checklistItemViewModel';
import { Constants } from 'src/app/modules/shared/models/constants';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-checklist-group-card',
  templateUrl: './checklist-group-card.component.html',
  styleUrls: ['./checklist-group-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistGroupCardComponent implements OnInit, OnChanges {
  @Input() groupTitle: string;
  @Input() checklistItems: ChecklistItemViewModel[] = [];
  @Input() canEdit: boolean = true;
  @Input() expanded: boolean = false;
  @Input() draggable: boolean = false;
  @Output() expand = new EventEmitter<string>();
  @Output() itemCompleted = new EventEmitter<ChecklistItemViewModel>();
  @Output() viewItem = new EventEmitter<ChecklistItemViewModel>();

  public paginationId: string;
  public completedChecks: number = 0;
  public readonly T = T;

  constructor(private readonly paginationManager: PaginationManager,
    private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.paginationId = this.paginationManager.generateID();
    this.checksCompletition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.checklistItems?.currentValue) {
      this.checksCompletition();
    }
  }

  checksCompletition(): void {
    let completedChecks: number = 0;
    this.checklistItems.forEach((item) => {
      if (item.responseType === ChecklistResponseTypes.Mark_as_Complete && item.response === 'true') {
        completedChecks++;
      } else if (item.responseType === ChecklistResponseTypes.Text_Response && item.response && item.response.length > 0) {
         completedChecks++;
      } else if (item.responseType === ChecklistResponseTypes.Numeric_Response && item.response && +item.response > 0) {
         completedChecks++;
      } else if(item.response !== undefined && parseInt(item.response) > 0) {
        completedChecks++
      }
    });

    this.completedChecks = completedChecks;
    this.changeDetectorRef.markForCheck();
  }

  onExpandClicked() {
    this.expand.emit(this.groupTitle);
  }

  onView(item: ChecklistItemViewModel) {
    this.viewItem.emit(item);
  }

  updateItem(item: ChecklistItemViewModel) {
    this.itemCompleted.emit(item);
  }

  public get isMobile(): boolean {
    return window.innerWidth < Constants.xs;
  }
}
