import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { PEAPTemplateViewModel } from '../../models/peaps/peapTemplate';
import { ValidatedViewModel } from '../../viewModels/validatedViewModel';

@Injectable()
export class PEAPTemplateService {
  private baseUrl: string = '';

  constructor(private readonly httpClient: HttpClient, private readonly configuration: Configuration) {
    this.baseUrl = this.configuration.buildEndpoint('PEAPTemplate/');
  }

  add(viewModel: PEAPTemplateViewModel): Observable<ValidatedViewModel> {
    return this.httpClient.post<ValidatedViewModel>(`${this.baseUrl}Add/`, viewModel);
  }

  update(viewModel: PEAPTemplateViewModel): Observable<PEAPTemplateViewModel> {
    return this.httpClient.put<PEAPTemplateViewModel>(`${this.baseUrl}Update`, viewModel);
  }

  delete(peapTemplateId: number): Observable<PEAPTemplateViewModel> {
    return this.httpClient.delete<PEAPTemplateViewModel>(`${this.baseUrl}Delete/${peapTemplateId}`);
  }

  getList(): Observable<PEAPTemplateViewModel[]> {
    return this.httpClient.get<PEAPTemplateViewModel[]>(`${this.baseUrl}GetList`);
  }
}
