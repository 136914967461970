import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { NotificationTypes } from '../enums/notificationTypes';
import { ObjectTypes } from '../enums/objectTypes';
import { Employee } from '../models/employee';
import { EmployeeDetailsViewModel } from '../viewModels/employeeDetailsViewModel';
import { CachingService } from './caching.service';

@Injectable()
export class NotificationsService {
  private _notifications = new BehaviorSubject<NotificationItemViewModel[]>([]);
  private _unreadCount = new BehaviorSubject<number>(0);
  private actionUrl: string = '';
  private employee: Employee;

  public notifications = this._notifications.asObservable();
  public unreadNotificationsCount = this._unreadCount.asObservable();
  public forceShowNotifications = false;

  constructor(private http: HttpClient, private cachingService: CachingService, private configuration: Configuration) {
    const account = this.cachingService.GetAccountInfo();
    if (account) this.employee = this.cachingService.GetEmployee(account.id);
    this.actionUrl = this.configuration.buildEndpoint('Notification/');
  }

  addNotifications(notifications: NotificationItemViewModel[]) {
    const current = this._notifications.getValue();
    current.unshift(...notifications);

    this.updateValues(current);
  }

  private updateValues(notifications: NotificationItemViewModel[]) {
    this._notifications.next(notifications);
    const unread = notifications.filter((c) => !c.isRead) || [];
    this._unreadCount.next(unread.length);
  }

  markAsRead(notification: NotificationItemViewModel) {
    this.http.put<NotificationItemViewModel>(`${this.actionUrl}Update`, notification).subscribe(() => {
      const notifications = this._notifications.getValue();
      const matching = notifications.find((n) => n.id === notification.id);

      if (matching) {
        matching.isRead = true;
      }

      this.updateValues(notifications);
    });
  }

  markAsUnread(notification: NotificationItemViewModel) {
    this.http.put<NotificationItemViewModel>(`${this.actionUrl}Update`, notification).subscribe(() => {
      const notifications = this._notifications.getValue();
      const matching = notifications.find((n) => n.id === notification.id);

      if (matching) {
        matching.isRead = false;
      }

      this.updateValues(notifications);
    });
  }

  markAllRead() {
    this.http.get<void>(`${this.actionUrl}MarkAllAsRead`).subscribe(() => {
      const notifications = this._notifications.getValue();
      notifications.forEach((n) => (n.isRead = true));

      this.updateValues(notifications);
    });
  }

  clear(notification: NotificationItemViewModel) {
    this.http.put<NotificationItemViewModel>(`${this.actionUrl}Update`, notification).subscribe(() => {
      const notifications = this._notifications.getValue();
      const matching = notifications.find((n) => n.id === notification.id);

      if (matching) {
        notifications.splice(notifications.indexOf(matching), 1);
      }

      this.updateValues(notifications);
    });
  }

  clearAll() {
    this.http.get<void>(`${this.actionUrl}ClearAll`).subscribe(() => {
      const notifications: NotificationItemViewModel[] = [];

      this.updateValues(notifications);
    });
  }

  getNotifications() {
    if (!this.employee) {
      const account = this.cachingService.GetAccountInfo();
      if (account) this.employee = this.cachingService.GetEmployee(account.id);
    }

    this.http.get<NotificationItemViewModel[]>(`${this.actionUrl}List`).subscribe((notifications) => {
      this.updateValues(notifications);
    });
  }
}

export class NotificationItemViewModel {
  public id: number;
  public message: string;
  public notificationType: NotificationTypes;
  public accountId: number;
  public employeeId: number;
  public globalObjectId: number;
  public globalObjectType: ObjectTypes;
  public isRead: boolean;
  public isDeleted: boolean;
  public created: string;
  public itemTitle: string;
  public createdBy: EmployeeDetailsViewModel;
  public link: string;
}
