import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import { Subscription } from 'rxjs';
import { SideNavModel } from 'src/app/modules/shared/models/sideNav';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Configuration } from 'src/app/app.constants';
import { UrlService } from 'src/app/modules/shared/services/url.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { NavigationStart, Router } from '@angular/router';
import { ModuleService } from 'src/app/modules/shared/services/module.service';
import { NavigationItem } from 'src/app/modules/shared/models/navigationItem';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { InformationModalComponent } from '../../../modals/information-modal/information-modal.component';
import { SelectAccountComponent } from '../../../modals/select-account/select-account.component';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { NotificationsService } from 'src/app/modules/shared/services/notifications.service';
import { NotificationsCenterComponent } from '../../../notifications/center/notifications-center.component';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { EmployeeRoleTypes } from 'src/app/modules/shared/enums/employees/EmployeeRoleTypes';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { ModuleIconTypes } from 'src/app/modules/shared/enums/moduleIconTypes';
import { UtilitiesService } from 'src/app/modules/shared/services/utilities.service';
import { Capacitor } from '@capacitor/core';
import { LoginEventsEmitter } from 'src/app/modules/shared/events/login.events';
import { Constants } from 'src/app/modules/shared/models/constants';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import * as IntercomMobile from '@capacitor-community/intercom';
import { HttpClient } from '@angular/common/http';
import { WtStorageService } from 'src/app/modules/shared/services/wt-storage.service';
import { authConfig } from 'src/app/modules/auth/auth.config';
import { EmployeeAvatarSizes } from 'src/app/modules/shared/enums/employeeAvatarSizes';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit, OnChanges {
  @Input() currentAccount: Account;
  @Input() currentEmployee: Employee;

  @ViewChild('sideNavbar', { static: true }) sideNav: ElementRef;

  public hubAccountUrl:string;
  public logoUrl: string;
  public logoLink: string;
  public brandingUrl: string;
  public sidebarIsCollapsed = false;
  public subscriptions: Subscription[] = [];
  public isAuthenticated = false;
  public ShowRiskAndIssues = false;
  public showIndicatorFrameworks = false;
  public sideNavModel: SideNavModel;
  avatarSize: EmployeeAvatarSizes = EmployeeAvatarSizes.Medium;

  public accountHubNavigationItem: NavigationItem;
  public accountHubNavModels: NavigationItem[] = [];
  public activeAccountHubNavModel: NavigationItem;
  public accountHubNavElements: NavigationItem[] = [];
  public hubManagementNavigationItem: NavigationItem;

  public eanbleAccountHub = true;
  public activatedRoute = '';
  public accountDropdownIsExpanded = false;
  public bsModalRef: BsModalRef;
  public currentRoute = '';
  public isHubAccount: boolean = false;
  public isChildHubAccount: boolean = false;

  isModulesDropdownExpanded = false;
  isAccountOptionsExpanded = false;
  isSupportExpanded = false;
  pendingNotifications: number;
  public settingsRoute = '/v2/settings';

  // Icons
  public myTrackIcon = IconUtilities.getModuleIcon(ModuleTypes.My_Track, 24, ModuleIconTypes.Grey_Colour);
  public reportingIcon = IconUtilities.getModuleIcon(ModuleTypes.Reporting, 24, ModuleIconTypes.Grey_Colour);
  public planningIcon = IconUtilities.getModuleIcon(ModuleTypes.Planning, 24, ModuleIconTypes.Grey_Colour);
  public sustainabilityIcon = IconUtilities.getModuleIcon(ModuleTypes.Sustainability, 24, ModuleIconTypes.Grey_Colour);
  public controlIcon = IconUtilities.getModuleIcon(ModuleTypes.Incidents, 24, ModuleIconTypes.Grey_Colour);
  public riskIcon = IconUtilities.getModuleIcon(ModuleTypes.Risk, 24, ModuleIconTypes.Grey_Colour);

  public windowMobileWidth = Constants.xs;
  public isMobileScreen = false;
  public isSidebarToggled: boolean;
  public isNativeDevice: boolean = false;
  public T = T;

  private modalConfig = { backdrop: true, ignoreBackdropClick: true };
  private actionUrl: string = '';
  public selectedMobileModule: ModuleTypes;
  constructor(
    private readonly authenticationService: AuthenticationService,
    public readonly configuration: Configuration,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly modalService: BsModalService,
    private readonly urlService: UrlService,
    private readonly localisationService: LocalisationService,
    public readonly moduleService: ModuleService,
    private readonly cachingService: CachingService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly notificationsService: NotificationsService,
    private readonly utilitiesService: UtilitiesService,
    private readonly loginEventEmitter: LoginEventsEmitter,
    private readonly translateService: TranslateService,
    private http: HttpClient,
    private readonly wtStorage: WtStorageService
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`Logout`);
  }

  ngOnInit(): void {
    this.accountHubNavModels.push(new NavigationItem(this.translateService.instant(T.hub.common.all_accounts), '/v2/hub/table', this.myTrackIcon, false, '0'));
    this.accountHubNavModels.push(new NavigationItem(this.translateService.instant(T.hub.common.this_account), '/v2/my-track', this.myTrackIcon, false, '1'));

    this.activeAccountHubNavModel = this.isHubSelected()
      ? this.accountHubNavModels.at(0)
      : this.accountHubNavModels.at(-1);

    this.accountHubNavElements.push(new NavigationItem('Home', '/v2/hub', 'home', false, 'home'));
    this.accountHubNavElements.push(new NavigationItem(this.translateService.instant(T.common.planning), '/v2/hub/planning', this.planningIcon, false, 'hub_planning'));
    this.accountHubNavElements.push(new NavigationItem(this.translateService.instant(T.hub.common.work_table), '/v2/hub/table', 'grid_view', false, '1'));
    this.accountHubNavElements.push(new NavigationItem(this.translateService.instant(T.hub.common.gantt_chart), '/v2/hub/gantt', 'access_time', false, '2'));
    this.accountHubNavElements.push(new NavigationItem(this.translateService.instant(T.common.control), '/v2/hub/control', this.controlIcon, false, 'hub_control'));
    this.accountHubNavElements.push(new NavigationItem(this.translateService.instant(T.common.risk), '/v2/hub/risk', this.riskIcon, false, 'hub_risk'));
    this.accountHubNavElements.push(new NavigationItem(this.translateService.instant(T.common.report.many), '/v2/reports', 'list_alt', false, '3'));

    if(this.currentAccount.useSustainability) {
      this.accountHubNavElements.push(new NavigationItem('Sustainability Dashboard', '/v2/hub/sustainability/dashboard', 'grid_view', false, '4'));
      this.accountHubNavElements.push(new NavigationItem('Sustainability Data', '/v2/hub/sustainability/data', 'grid_view', false, '4'));
    }

    this.hubManagementNavigationItem = new NavigationItem(this.translateService.instant(T.hub.common.hub_management), '/v2/hub/account', 'settings', false, '5');

    this.isNativeDevice = Capacitor.getPlatform() !== 'web';

    if (window.innerWidth < this.windowMobileWidth) {
      this.isMobileScreen = true;
    }
    if (this.currentAccount) {
      this.isHubAccount = this.currentAccount.isHubAccount;
      this.isChildHubAccount = this.currentAccount.isChiildHubAccount;
      this.hubAccountUrl = this.urlService.builLogoUrlForHub(this.currentAccount.hubAccountLogo,this.configuration.BackEndAPI);
      this.logoUrl = this.urlService.buildResourceUrl(this.currentAccount.localLogoURL);
      this.ShowRiskAndIssues = this.currentAccount.showRiskAndIssues;
      this.showIndicatorFrameworks = this.currentAccount.useIndicatorFrameworks;
      this.brandingUrl = this.currentAccount.useWhitelabeling
        ? this.currentAccount.alternativeBrandingLogoURL
        : '/assets/images/layout/wetrack-logo-white.png';

      this.initSideNavModel();
    }

    this.currentRoute = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.url;
      }
    });


    this.utilitiesService.sidebarToggled$.subscribe((isToggled) => {
      this.isSidebarToggled = isToggled
      this.selectedMobileModule = this.moduleService.currentModule;
    })

    this.subscriptions.push(
      this.loginEventEmitter.currentAccountChanged$.subscribe((res) => {
        if (res) {
          this.currentAccount = res;
          this.logoUrl = this.urlService.buildResourceUrl(this.currentAccount.localLogoURL);
          this.initSideNavModel();
          this.changeDetectorRef.detectChanges();
        }
      })
    );
  }

  ngOnChanges() {
    if (this.currentEmployee) {
      this.isAuthenticated = !!this.currentEmployee;
      this.logoUrl = this.urlService.buildResourceUrl(this.currentAccount.localLogoURL);
      this.ShowRiskAndIssues = this.currentAccount.showRiskAndIssues;
      this.showIndicatorFrameworks = this.currentAccount.useIndicatorFrameworks;

      if (this.notificationsService.forceShowNotifications) {
        this.openNotifications();
        this.notificationsService.forceShowNotifications = false;
      }

      this.subscriptions.push(
        this.notificationsService.unreadNotificationsCount.subscribe((res) => {
          this.pendingNotifications = res;
        })
      );
    } else {
      this.currentEmployee = this.authenticationService.getCurrentEmployee();
      if (this.currentEmployee) {
        this.isAuthenticated = !!this.currentEmployee;
      }
    }

    this.currentAccount = this.authenticationService.getCurrentAccount();
    this.initSideNavModel();
    this.changeDetectorRef.markForCheck();
  }

  public navigateToAccountHub(): void {
     window.location.href = this.currentAccount.hubAccountUrl;
  }

  private getSettingsRouteBasedOnModule(moduleType: ModuleTypes): string {
    let settingsRoute = '/v2/settings';
    switch (moduleType) {
      case ModuleTypes.Planning:
        settingsRoute += '/venues-and-risks';
        break;
      case ModuleTypes.Incidents:
        settingsRoute += '/incidents';
        break;
      case ModuleTypes.Runsheets:
        settingsRoute += '/runsheets';
        break;
      case ModuleTypes.Sustainability:
        settingsRoute += '/sustainability';
        break;
      case ModuleTypes.Risk:
        settingsRoute +='/risk';
        break;
      default:
        break;
    }
    return settingsRoute;
  }

  private initSideNavModel() {
    if (!this.isAuthenticated) {
      return;
    }
    this.sideNavModel = new SideNavModel();
    if (this.currentAccount.usePlanning) {
      if (this.IsSingleAccountSelected()) {
        this.addMyTrackItem();
      }
    }

    if (this.IsSingleAccountSelected()) this.addReportingItem();

    this.addProjectsItem();
    this.addRiskItems();
    this.addIncidentItems();
    this.addRunsheetItem();
    this.addSustainabilityItems();
    this.addAccountHubnavItem();
    this.initCurrentlySelectedNavigationItem();
  }

  public isHubSelected() {
    return window.location.href.indexOf('hub') > -1;
  }
  public IsSingleAccountSelected() {
    return !this.eanbleAccountHub || (this.activeAccountHubNavModel && this.activeAccountHubNavModel.idAttr == '1');
  }

  private addMyTrackItem() {
    const myTrackItem = new NavigationItem(
      this.translateService.instant(T.common.my_track),
      '/v2/mytrack/planning',
      '',
      false,
      'mytrack/planning'
    );
    myTrackItem.moduleType = ModuleTypes.My_Track;
    myTrackItem.isGrouped = false;
    this.sideNavModel.viewModeItems.push(myTrackItem);
  }

  private addReportingItem() {
    const reportingItem = new NavigationItem(
      this.translateService.instant(T.common.report.many),
      '/v2/reports',
      '',
      false,
      'reporting'
    );
    reportingItem.moduleType = ModuleTypes.Reporting;
    reportingItem.isGrouped = false;
    this.sideNavModel.viewModeItems.push(reportingItem);
  }

  private addProjectsItem() {
    const projectsItem = new NavigationItem(
      this.translateService.instant(T.common.planning),
      'v2/planning',
      this.getModuleIcon(ModuleTypes.Planning),
      !(
        this.currentAccount.usePlanning &&
        (EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Planning_User) ||
          EmployeeUtil.IsAdmin(this.currentEmployee))
      ),
      'projects'
    );
    projectsItem.defaultUrl = 'v2/planning/list';
    projectsItem.moduleType = ModuleTypes.Planning;

    const projectsDashboardBetaItem = new NavigationItem(
      this.translateService.instant(T.common.dashboard),
      'v2/planning/dashboard',
      'dashboard',
      false,
      'projects_dashboard'
    );
    projectsItem.children.push(projectsDashboardBetaItem);

    const projectsProjectsItem_new = new NavigationItem(
      `${this.localisationService.localiseObjectType(ObjectTypes.Project, true)} & ${this.localisationService.localiseObjectType(
        ObjectTypes.Task,
        true
      )}`,
      '/v2/planning/list',
      'folder_open',
      false,
      'projects_projects',
      undefined,
      true
    );
    projectsItem.children.push(projectsProjectsItem_new);

    if (!this.isMobileScreen) {
      const projectsGanttBetaItem = new NavigationItem(
        this.translateService.instant(T.common.gantt),
        '/v2/gantt',
        'access_time',
        false,
        'projects_gantt',
        undefined,
        true
      );
      projectsGanttBetaItem.showOnMobile = false;
      projectsItem.children.push(projectsGanttBetaItem);
    }

    // Calendar implementation starts here
    const projectsCalendarItem = new NavigationItem(
      this.translateService.instant(T.common.calendar),
      '/v2/planning/calendar',
      'event',
      false,
      'projects_calendar',
      undefined,
      true
    );
    projectsCalendarItem.isRemoteUrl = false;
    projectsItem.children.push(projectsCalendarItem);
    // Calendar implementation ends here

    const projectsActivityItem = new NavigationItem(
      this.translateService.instant(T.common.history),
      '/v2/planning/history',
      'history',
      false,
      'projects_history',
      undefined,
      true
    );
    projectsItem.children.push(projectsActivityItem);

    this.sideNavModel.viewModeItems.push(projectsItem);
  }

  private addAccountHubnavItem() {
    this.accountHubNavigationItem = new NavigationItem(
      this.localisationService.capitalizeEachWord(this.localisationService.localiseObjectType(ObjectTypes.Account_Hub)),
      '/v2/hub/',
      this.getModuleIcon(ModuleTypes.AccountHub),
      !(
        this.currentAccount.isHubAccount &&
        (EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.AccountHub_User) ||
          EmployeeUtil.IsAdmin(this.currentEmployee))
      ),
      'accountHub'
    );

    this.accountHubNavigationItem.defaultUrl = '/v2/hub/table';
    this.accountHubNavigationItem.moduleType = ModuleTypes.AccountHub;

    this.accountHubNavigationItem.children = [];
    this.accountHubNavigationItem.children.push(
      new NavigationItem('All Accounts', '/v2/hub/table', this.myTrackIcon, false, '0')
    );
    this.accountHubNavigationItem.children.push(new NavigationItem('This Account', '/v2/my-track', this.myTrackIcon, false, '1'));
    this.accountHubNavigationItem.children.push(new NavigationItem('Work Table', '/v2/hub/table', 'table_chart', false, '0'));
    this.accountHubNavigationItem.children.push(new NavigationItem('Gantt Chart', '/v2/hub/gantt', 'access_time', false, '0'));
    this.accountHubNavigationItem.children.push(new NavigationItem('Reports', '/v2/my-track', '', false, '0'));
    this.accountHubNavigationItem.children.push(new NavigationItem('Account Manager', '/v2/my-track', '', false, '0'));
  }
  private addRunsheetItem() {
    const runsheetsItem = new NavigationItem(
      this.localisationService.capitalizeEachWord(this.localisationService.localiseObjectType(ObjectTypes.Runsheet)),
      '/v2/runsheets',
      this.getModuleIcon(ModuleTypes.Runsheets),
      !(
        this.currentAccount.useRunSheets &&
        (EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Runsheets_User) ||
          EmployeeUtil.IsAdmin(this.currentEmployee))
      ),
      'runsheets'
    );
    runsheetsItem.defaultUrl = '/v2/runsheets/list';
    runsheetsItem.moduleType = ModuleTypes.Runsheets;

    const runsheetsListItemNew = new NavigationItem(
      this.translateService.instant(T.common.list),
      '/v2/runsheets/list',
      'notes',
      !this.currentAccount.useRunSheets,
      'runsheets_list'
    );
    runsheetsItem.children.push(runsheetsListItemNew);

    const runsheetCalendarNewItem = new NavigationItem(
      this.translateService.instant(T.common.calendar),
      '/v2/runsheets/calendar',
      'event',
      !this.currentAccount.useRunSheets,
      'runsheets_calendar'
    );
    runsheetsItem.children.push(runsheetCalendarNewItem);

    const runsheetMapViewItemNew = new NavigationItem(
      this.translateService.instant(T.common.map_view),
      '/v2/runsheets/map',
      'location_on',
      !this.currentAccount.useRunSheets,
      'runsheets_map'
    );
    runsheetsItem.children.push(runsheetMapViewItemNew);

    this.sideNavModel.viewModeItems.push(runsheetsItem);
  }

  private addIncidentItems() {
    const incidentsItem = new NavigationItem(
      this.translateService.instant(T.common.control),
      'v2/control/list',
      this.getModuleIcon(ModuleTypes.Incidents),
      !(
        this.currentAccount.useIMS &&
        (EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Incidents_User) ||
          EmployeeUtil.IsAdmin(this.currentEmployee))
      ),
      'incidents'
    );
    incidentsItem.defaultUrl = 'v2/control/list';
    incidentsItem.moduleType = ModuleTypes.Incidents;

    const incidentDashboardItem = new NavigationItem(
      this.translateService.instant(T.common.dashboard),
      '/v2/control/dashboard',
      'dashboard',
      !this.currentAccount.useIMS,
      'incidents_dashboard'
    );
    incidentsItem.children.push(incidentDashboardItem);


    if(this.currentAccount.useIMS && this.currentAccount.usePublicIncidentReporting){
      const publicIncidentReportsItem = new NavigationItem(
        this.translateService.instant(T.common.incoming_reports),
        '/v2/control/incident-reports',
        'notification_important',
        false,
        'public_incident_reports'
      );
      incidentsItem.children.push(publicIncidentReportsItem);
    }


    const localisedIncident = this.localisationService.localiseObjectType(ObjectTypes.IncidentItem, true);
    const incidentListItem = new NavigationItem(
      this.translateService.instant(T.common.list_type, { type: localisedIncident }),
      '/v2/control/list',
      'error',
      !this.currentAccount.useIMS,
      'incidents_incidents'
    );
    incidentsItem.children.push(incidentListItem);

    const translatedLogs = this.translateService.instant(T.defaultLocalizations.log.many);
    const incidentLogListItem = new NavigationItem(
      this.translateService.instant(T.common.list_type, { type: translatedLogs }),
      '/v2/control/logs',
      'receipt',
      !this.currentAccount.useIMS,
      'incidents_logs'
    );
    incidentsItem.children.push(incidentLogListItem);

    const localisedJobs = this.localisationService.localiseObjectType(ObjectTypes.Job, true);
    if (
      this.currentAccount.useJobs &&
      (EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Jobs_User) || EmployeeUtil.IsAdmin(this.currentEmployee))
    ) {
      const incidentJobListItem = new NavigationItem(
        this.translateService.instant(T.common.list_type, { type: localisedJobs }),
        '/v2/control/jobs',
        'assignment_turned_in',
        !this.currentAccount.useIMS || !this.currentAccount.useJobs,
        'incidents_jobs'
      );
      incidentsItem.children.push(incidentJobListItem);
    }

    const incidentMapViewItem = new NavigationItem(
      this.translateService.instant(T.common.map_view),
      '/v2/control/map',
      'location_on',
      !this.currentAccount.useIMS,
      'incidents_map'
    );
    incidentsItem.children.push(incidentMapViewItem);

    const controlCalendarItem = new NavigationItem(
      this.translateService.instant(T.common.calendar),
      '/v2/control/calendar',
      'event',
      false,
      'control_calendar',
      undefined,
      true
    );
    incidentsItem.children.push(controlCalendarItem);

    const activityItem = new NavigationItem(
      this.translateService.instant(T.common.history),
      'v2/control/history',
      'history',
      !this.currentAccount.useIMS,
      'incidents_history'
    );
    incidentsItem.children.push(activityItem);

    if (
      this.currentAccount.usePeaps &&
      (EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.PEAPS_User) || EmployeeUtil.IsAdmin(this.currentEmployee))
    ) {
      this.addPEAPItems(incidentsItem);
    }

    this.sideNavModel.viewModeItems.push(incidentsItem);
  }

  private addRiskItems() {
    const riskItem = new NavigationItem(
      this.localisationService.localiseObjectType(ObjectTypes.Risk),
      '/v2/risk/risks-issues',
      this.getModuleIcon(ModuleTypes.Risk),
      !this.currentAccount.showRiskAndIssues,
      'risks'
    );
    riskItem.defaultUrl = '/v2/risk/risks-issues';
    riskItem.moduleType = ModuleTypes.Risk;

    const riskDashboardItem = new NavigationItem(
      this.translateService.instant(T.common.dashboard),
      '/v2/risk/dashboard',
      'dashboard',
      !this.currentAccount.useIMS,
      'risks_dashboard'
    );
    riskItem.children.push(riskDashboardItem);

    const risksAndIssuesItem = new NavigationItem(
      `${this.localisationService.localiseObjectType(ObjectTypes.Risk, true)} & ${this.translateService.instant(
        T.defaultLocalizations.issue.many
      )}`,
      '/v2/risk/risks-issues',
      'warning',
      !this.currentAccount.showRiskAndIssues,
      'risks_issues'
    );

    riskItem.children.push(risksAndIssuesItem);

    const controlsAndActionsItem = new NavigationItem(
      `${this.localisationService.localiseObjectType(ObjectTypes.Risk_Action_Item, true)}`,
      '/v2/risk/risk-actions',
      'fact_check',
      !this.currentAccount.showRiskAndIssues,
      'risk-actions'
    );

    riskItem.children.push(controlsAndActionsItem);

    if(this.currentAccount.useRiskAssesment) {
      const riskAssessmentsItems = new NavigationItem(
        `${this.localisationService.localiseObjectType(ObjectTypes.Risk_Assesment, true)}`,
        '/v2/risk/risk-assessments',
        'security',
        !this.currentAccount.showRiskAndIssues,
        'risk-assessments'
      );

      riskItem.children.push(riskAssessmentsItems);
    }

    const historyItem = new NavigationItem(
      this.translateService.instant(T.common.history),
      'v2/risk/history',
      'history',
      !this.currentAccount.useIMS,
      'incidents_history'
    );

    riskItem.children.push(historyItem);

    this.sideNavModel.viewModeItems.push(riskItem);
  }

  private addSustainabilityItems() {
    const sustainabilityItem = new NavigationItem(
      this.translateService.instant(T.common.sustainability),
      'v2/sustainability/dashboard',
      this.getModuleIcon(ModuleTypes.Sustainability),
      !(
        this.currentAccount.useSustainability &&
        (EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Sustainability_User) ||
          EmployeeUtil.IsAdmin(this.currentEmployee))
      ),
      'sustainability'
    );
    sustainabilityItem.defaultUrl = 'v2/sustainability/indicators';
    sustainabilityItem.moduleType = ModuleTypes.Sustainability;

    const sustainabilityDashboardItem = new NavigationItem(
      this.translateService.instant(T.common.dashboard),
      'v2/sustainability/dashboard',
      'dashboard',
      false,
      'sus_dashboard'
    );
    sustainabilityItem.children.push(sustainabilityDashboardItem);

    const sustainabilityEmissionsOverviewItem = new NavigationItem(
      this.translateService.instant(T.sustainability.emissions_overview),
      'v2/sustainability/emissions-overview',
      'dashboard',
      false,
      'sus_emissions_overview'
    );
    sustainabilityItem.children.push(sustainabilityEmissionsOverviewItem);

    const sustainabilityIndicatorItem = new NavigationItem(
      this.localisationService.localiseObjectType(ObjectTypes.Indicator, true),
      '/v2/sustainability/indicators',
      'format_list_bulleted',
      !this.currentAccount.useSustainability,
      'sus_indicators'
    );
    sustainabilityItem.children.push(sustainabilityIndicatorItem);

    const sustainabilityAchievementsItem = new NavigationItem(
      this.translateService.instant(T.common.achievement.many),
      '/v2/sustainability/achievements',
      'stars',
      !this.currentAccount.useSustainability,
      'sus_achievements'
    );
    sustainabilityItem.children.push(sustainabilityAchievementsItem);

    this.sideNavModel.viewModeItems.push(sustainabilityItem);
  }

  private initCurrentlySelectedNavigationItem() {
    const isAdmin = EmployeeUtil.IsAdmin(this.currentEmployee);

    // Check If user have the permissions for current module before init the sidebar

    const moduleTypesToIterate = [
      ModuleTypes.Planning,
      ModuleTypes.Incidents,
      ModuleTypes.Runsheets,
      ModuleTypes.AccountHub,
      ModuleTypes.Sustainability,
    ];

    const hasPermissionsByModuleTypeObject = {
      [ModuleTypes.Planning]: () =>
        this.currentAccount.usePlanning &&
        (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Planning_User)),
      [ModuleTypes.Incidents]: () =>
        (this.currentAccount.useIMS || this.currentAccount.useIMSOnly) &&
        (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Incidents_User)),
      [ModuleTypes.Runsheets]: () =>
        this.currentAccount.useRunSheets &&
        (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Runsheets_User)),
      [ModuleTypes.AccountHub]: () => true,
      // this.currentAccount.useAccountHub &&
      // (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.AccountHub_User)),
      [ModuleTypes.Sustainability]: () =>
        this.currentAccount.useSustainability &&
        (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Sustainability_User)),
    };

    const currentModuleType = this.moduleService.currentModule;
    this.selectedMobileModule = currentModuleType;
    let currentModule: NavigationItem;

    if (
      (currentModuleType === ModuleTypes.Planning || currentModuleType === ModuleTypes.Venues) &&
      hasPermissionsByModuleTypeObject[ModuleTypes.Planning]()
    ) {
      currentModule = this.sideNavModel.viewModeItems.find((i) => i.moduleType === ModuleTypes.Planning);
    } else if (currentModuleType === ModuleTypes.Incidents && hasPermissionsByModuleTypeObject[ModuleTypes.Incidents]()) {
      currentModule = this.sideNavModel.viewModeItems.find((i) => i.moduleType === ModuleTypes.Incidents);
    } else if (currentModuleType === ModuleTypes.Runsheets && hasPermissionsByModuleTypeObject[ModuleTypes.Runsheets]()) {
      currentModule = this.sideNavModel.viewModeItems.find((i) => i.moduleType === ModuleTypes.Runsheets);
    } else if (
      currentModuleType === ModuleTypes.Sustainability &&
      hasPermissionsByModuleTypeObject[ModuleTypes.Sustainability]()
    ) {
      currentModule = this.sideNavModel.viewModeItems.find((i) => i.moduleType === ModuleTypes.Sustainability);
    } else {
      // In case we dont have permissions for currentModule we need to render the first module that we have permissions for
      // Based on Priority -> Planning,Incidents,Readiness,Runsheets
      for (let i = 0; i < moduleTypesToIterate.length; i++) {
        const type = moduleTypesToIterate[i];
        const hasPermissionsForModuleType = hasPermissionsByModuleTypeObject[type]();
        if (hasPermissionsForModuleType) {
          currentModule = this.sideNavModel.viewModeItems.find((i) => i.moduleType === type);
          break;
        }
      }
    }

    // if (!currentModule) {
    //   currentModule = this.sideNavModel.viewModeItems.find(i => i.moduleType === ModuleTypes.Planning);
    // }

    this.changeSelectedItem(currentModule, false);
  }

  private addPEAPItems(incidentsItem: NavigationItem) {
    const localisedPEAPs = this.localisationService.localiseObjectType(ObjectTypes.PEAP, true);

    const peapsDashboardItem = new NavigationItem(
      this.translateService.instant(T.common.summary_type, { type: localisedPEAPs }),
      '/v2/control/peaps-dashboard',
      'dashboard',
      !this.currentAccount.useIMS,
      'peaps_summary'
    );
    incidentsItem.children.push(peapsDashboardItem);

    const peapsListItem = new NavigationItem(
      this.translateService.instant(T.common.list_type, { type: localisedPEAPs }),
      '/v2/control/peaps-list',
      'fact_check',
      !this.currentAccount.useIMS,
      'peaps_list'
    );
    incidentsItem.children.push(peapsListItem);
  }

  private changeSelectedItem(item: NavigationItem, navigateToDefault = false) {
    if (item && !item.isDisabled) {
      // This is dummy implementation for those accounts that have access to the old system's Readiness and Runsheets
      // if(item.idAttr == "readiness"){
      //   this.readinessOldSystemRedirect();
      // } else
      // if (item.idAttr == "runsheets"){
      //   this.runsheetsOldSystemRedirect();
      // }else {
      // }
      this.sideNavModel.selectedItem = item;
      if (item.isGrouped) {
        this.sideNavModel.selectedGroupItem = item;
      }

      if (navigateToDefault && item.defaultUrl) {
        void this.router.navigate([item.defaultUrl]);
      }

      this.settingsRoute = this.getSettingsRouteBasedOnModule(item.moduleType);
      this.changeDetectorRef.detectChanges();
    } else {
      if (this.currentAccount.useIMSOnly) {
        document.location.href = '/v2/control/dashboard';
      }

      const initialState = {
        title: 'WeTrack ' + item?.name,
        message: this.translateService.instant(T.common.no_account_module_access, { moduleName: item?.name }),
      };

      if (
        (item?.name === 'Incidents' && !this.currentEmployee.incidentsUser)
      ) {
        initialState.message = this.translateService.instant(T.common.no_employee_module_access, { moduleName: item?.name });
      }

      if(item){
        const modalParams = Object.assign({}, this.modalConfig, { initialState });
        this.bsModalRef = this.modalService.show(InformationModalComponent, modalParams);
      }

    }
  }

  validateLastLetter(value: string): string {
    if (!value.endsWith('s')) value = value + 's';
    return value;
  }

  localiseString(str: string, capitalize: boolean = true) {
    return this.localisationService
      .localiseStringCapitalize(str, capitalize)
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.substr(1))
      .join(' ');
  }

  openSwitchAccountModal() {
    const modalConfig: ModalOptions<SelectAccountComponent> = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-md',
      animated: true,
      initialState: {},
    };

    this.bsModalRef = this.modalService.show(SelectAccountComponent, modalConfig);
  }

  changeSelectedViewMode(selectedModule: NavigationItem, navigateToDefaultUrl = false) {
    this.changeSelectedItem(selectedModule, navigateToDefaultUrl);
  }

  changeSelectedAccountHub(selectdItem: NavigationItem) {
    this.activeAccountHubNavModel = selectdItem;
    this.initSideNavModel();
  }

  logout() {
    this.http.post<object>(
      this.actionUrl + '/Revocation', {
        ClientId: authConfig.clientId,
        Token: this.wtStorage.getItem('access_token')
      },
      this.authenticationService.getHttpOptions())
      .subscribe(response => { });

    const dashboardLayout = JSON.parse(localStorage.getItem('dashboardLayout'));
    localStorage.clear();

    if (dashboardLayout) {
      localStorage.setItem('dashboardLayout', JSON.stringify(dashboardLayout));
    }

    this.cachingService.RemoveEmployeeData(this.currentAccount.id);
    this.cachingService.RemoveAccountData();
    this.cachingService.removeFixedEventPromptCookie();
    this.cachingService.removeFixedZonePromptCookie();
    this.authService.logOut();
  }

  openNotifications() {
    this.bsModalRef = this.modalService.show(NotificationsCenterComponent, {
      class: 'modal-notifications-center',
    });
  }

  getSelectedModuleIcon(moduleType: ModuleTypes): string {
    if (this.sideNavModel && this.sideNavModel.selectedItem) {
      const selectedModuleType = this.moduleService.currentModule;
      const moduleIconType = selectedModuleType === moduleType ? ModuleIconTypes.White : ModuleIconTypes.Grey_Colour;
      const iconSVG = IconUtilities.getModuleIcon(moduleType, 24, moduleIconType);

      return iconSVG;
    }

    return '';
  }

  getModuleIcon(moduleType: ModuleTypes): string {
    const iconSVG = IconUtilities.getModuleIcon(moduleType, 24, ModuleIconTypes.Colourway_2);

    return iconSVG;
  }

  get groupedNavigationModuleItems() {
    return this.sideNavModel?.viewModeItems.filter((item) => item.isGrouped);
  }

  get nonGroupedNavigationModuleItem() {
    return this.sideNavModel?.viewModeItems.filter((item) => !item.isGrouped);
  }

  collapseSidebarOnMenuItemSelect() {
    if (this.isMobileScreen) {
      this.collapseSidebar();
    }
  }

  collapseSidebar() {
    this.utilitiesService.broadcastSidebarToggle(false);
  }
  expandSidebar() {
    this.utilitiesService.broadcastSidebarToggle(true);
  }

  readinessOldSystemRedirect() {
    const idToReadinessUrlObj = {
      102: 'https://iocv2.wetrack.com/v2/readiness/capabilities',
      265: 'https://stadiumv2.wetrack.com/v2/readiness/capabilities',
      216: 'https://mev2.wetrack.com/v2/readiness/capabilities',
      364: 'https://tracktownusav2.wetrack.com/v2/readiness/capabilities',
    };

    const urlToRedirect = idToReadinessUrlObj[this.currentAccount.id];
    if (urlToRedirect) {
      window.open(urlToRedirect, '_self');
    }
  }

  runsheetsOldSystemRedirect() {
    const idToRunsheetsUrlObj = {
      365: 'https://euroleaguev2.wetrack.com/v2/runsheets/list',
      378: 'https://euroleaguetestv2.wetrack.com/v2/runsheets/list',
      318: 'https://teamenglandv2.wetrack.com/v2/runsheets/list',
      265: 'https://stadiumv2.wetrack.com/v2/runsheets/list',
      216: 'https://mev2.wetrack.com/v2/runsheets/list',
      364: 'https://tracktownusav2.wetrack.com/v2/runsheets/list',
    };

    const urlToRedirect = idToRunsheetsUrlObj[this.currentAccount.id];
    if (urlToRedirect) {
      window.open(urlToRedirect, '_self');
    }
  }

  getAccountPrivacyPolicy() {
    return this.urlService.getAccountPrivacyPolicyURL(this.currentAccount);
  }

  getAccountTermsOfUse() {
    return this.urlService.getAccountTermsOfUseURL(this.currentAccount);
  }

  openChat() {
    if (Capacitor.getPlatform() !== 'web') {
      void IntercomMobile.Intercom.displayMessenger();
    }
  }

  public onGroupedModuleClicked(navigationItem: NavigationItem): void {
    if (!this.isMobileScreen) {
      void this.router.navigate([navigationItem.url]);
    } else {
      this.selectedMobileModule = navigationItem.moduleType;
    }
  }

  public checkSelectedModule(navigationItem: NavigationItem): boolean {
    if (!this.isMobileScreen) {
      return navigationItem.moduleType === this.moduleService.currentModule;
    } else {
      return navigationItem.moduleType === this.selectedMobileModule;
    }
  }
}
