import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { AuthenticationService } from './authentication.service';
import { ZoneViewModel } from '../viewModels/zoneViewModel';
import { SeverityChartModel } from '../../incidents/chartModels/severityChartModel';
import { HttpClient } from '@angular/common/http';
import { ValidatedViewModel } from '../viewModels/validatedViewModel';
import { AreaViewModel } from '../viewModels/areaViewModel';
import { MentionItem } from '../models/mentions/MentionItem';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { IncidentListItemViewModel } from '../../incidents/viewModels/incidentListItemViewModel';
import { IncidentDetailsItemViewModel } from '../../incidents/viewModels/incidentDetailsItemViewModel';
import { IncidentItemViewModel } from '../../incidents/viewModels/incidentItemViewModel';
import { IApiService } from '../interfaces/iHttpService';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { ObjectTypes } from '../enums/objectTypes';
import { LightFilterUpdateModel } from '../models/filter/LightFilterUpdateModel';
import { IncidentSummaryViewModel } from '../../incidents/models/incidentSummaryViewModel';
import { ControlOverviewViewModel } from '../../incidents/models/controlOverviewViewModel';

@Injectable()
export class IncidentsService implements IApiService {
  private actionUrl: string;
  private insidentSettingsActionUrl: string;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`Incident/`);
    this.insidentSettingsActionUrl = this.configuration.buildEndpoint(`IncidentsSettings/`);
  }

  updateLight(item: LightFilterUpdateModel) {
    return this.http.post<LightFilterUpdateModel>(`${this.actionUrl}UpdateLight`, item);
  }
  pinnedList(
    objectType: ObjectTypes,
    filters: FilterViewModel[],
    showPinnedOnly?: boolean
  ): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }

  //#region new methods

  public list(filters: FilterViewModel[]): Observable<IncidentListItemViewModel[]> {
    return this.http.post<IncidentListItemViewModel[]>(`${this.actionUrl}GetList`, filters);
  }

  public details(incidentId: number): Observable<IncidentDetailsItemViewModel> {
    return this.http.get<IncidentDetailsItemViewModel>(`${this.actionUrl}GetDetails/${incidentId}`);
  }

  public update(incident: IncidentItemViewModel): Observable<IncidentItemViewModel> {
    return this.http.post<IncidentItemViewModel>(`${this.actionUrl}Update`, incident);
  }

  public delete(incidentId: number): Observable<void> {
    return this.http.delete<void>(`${this.actionUrl}Delete/${incidentId}`);
  }

  archive(incidentId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive_New/${incidentId}/${archive}`, null);
  }

  bulkArchive(incidentIds: number[]) {
    return this.http.post(`${this.actionUrl}BulkArchive`, incidentIds);
  }

  public add(incident: IncidentDetailsItemViewModel) {
    return this.http.post<ValidatedViewModel>(this.actionUrl + 'Add', incident, this.authenticationService.getHttpOptions());
  }

  updateLozenges(incident: IncidentListItemViewModel): Observable<any> {
    // TODO Add this to the BE
    return of('Must add logic in the BE');
    // return this.http.post(`${this.actionUrl}UpdateLozenges/${employeeId}`, incident);
  }

  //#endregion

  public GetLogsList(filters: FilterViewModel[]): Observable<IncidentListItemViewModel[]> {
    return this.http.post<IncidentListItemViewModel[]>(`${this.actionUrl}GetLogsList`, filters);
  }

  public GetIncidentsMentionFeedList = (): Observable<MentionItem[]> => {
    return this.http.get<MentionItem[]>(`${this.actionUrl}MentionFeedList`, this.authenticationService.getHttpOptions());
  };

  public GetZones = (): Observable<ZoneViewModel[]> => {
    return this.http.get<ZoneViewModel[]>(this.actionUrl + 'Nomenclatures/Zones', this.authenticationService.getHttpOptions());
  };

  public GetAreas(accountID: number): Observable<AreaViewModel[]> {
    return this.http.get<AreaViewModel[]>(`${this.insidentSettingsActionUrl}Areas/All`);
  }

  public GetSeverityChartBreakdown = (filters: FilterViewModel[]): Observable<SeverityChartModel> => {
    return this.http.post<SeverityChartModel>(
      `${this.actionUrl}SeverityChartBreakdown`,
      filters,
      this.authenticationService.getHttpOptions()
    );
  };

  public getSummary(): Observable<IncidentSummaryViewModel> {
    return this.http.get<IncidentSummaryViewModel>(`${this.actionUrl}getSummary`);
  }

  public getOverview(): Observable<ControlOverviewViewModel> {
    return this.http.get<ControlOverviewViewModel>(`${this.actionUrl}GetControlOverview`);
  }
}
