import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { trigger, transition, query, stagger, animate, style, state } from '@angular/animations';
import { AuthenticationService } from '../../../services/authentication.service';
import { CachingService } from '../../../services/caching.service';
import { CollapseAnimation } from '../../../utilities/animations/collapse.animation';
import { FilterTypes } from '../../../enums/filterTypes';
import { IDropdownState } from '../../../models/interfaces/dropdown-state.interface';
import { Employee } from '../../../models/employee';
import { Account } from '../../../models/account';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { Subscription } from 'rxjs';
import { DropdownEventsEmitter } from '../../../events/dropdown.events';
import { ModuleService } from '../../../services/module.service';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { IconUtilities } from '../../../utilities/icon.utilities';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { SortingService } from '../../../services/sorting.service';
import { IndicatorUpdateExpectedDirections } from 'src/app/modules/sustainability/enums/indicatorUpdateExpectedDirections';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { RagHelper } from '../../../utilities/rag.utilities';
import { RAGStatuses } from '../../../enums/ragStatuses';

@Component({
  selector: 'app-filter-dropdown-new',
  templateUrl: './filter-dropdown-new.component.html',
  styleUrls: ['./filter-dropdown-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    CollapseAnimation.slideInOut,
    trigger('listAnimation', [
      // each time the binding value changes
      transition('* => *', [
        query(':leave', [stagger(100, [animate('0.5s', style({ opacity: 0 }))])], { optional: true }),
        query(':enter', [style({ opacity: 0 }), stagger(100, [animate('0.5s', style({ opacity: 1 }))])], { optional: true }),
      ]),
    ]),
    trigger('loadInOut', [
      state(
        'true',
        style({
          display: 'none',
          transform: 'rotateX(-90deg)',
          maxHeight: '0px',
        })
      ),
      state(
        'false',
        style({
          display: 'block',
          transform: 'rotateX(0)',
          maxHeight: '600px',
        })
      ),
      transition('1 => 0', animate('500ms ease-in-out')),
      transition('0 => 1', animate('500ms ease-in-out')),
    ]),
  ],
})
export class FilterDropdownNewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filterType: FilterTypes;
  @Input() label: string;
  @Input() appliedFilters: FilterViewModel[];
  @Input() readonly: boolean = true;
  @Input() singleSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() typeahead: boolean;
  @Input() disableMargin: boolean = false;
  @Input() currentlySelectedSettingValue: string;
  @Input() summaryLabel: string;
  @Input() showOnlyActive: boolean = false;
  @Input() validationMessage: string = 'This field is required.';
  @Input() relatedObjectId: number = 0;
  @Input() excludeFilterValues: [];
  @Input() displayError: boolean;
  @Input() disabled: boolean;
  @Input() disableRequiredText = false;
  /** Default True, This will filter the dropdown option by current ObjectType from the route. Set false if you need it in modal*/
  @Input() filterByModuleObjectType = true;
  @Input() usePredefinedFilters: boolean = false;
  @Input() predefinedFilters: FilterViewModel[] = [];
  @Input() useSearch: boolean = true;

  @Output() dropdownStateChanged = new EventEmitter<IDropdownState>();
  @Output() onFilterUpdate = new EventEmitter<FilterViewModel[]>();
  @Output() isValid = new EventEmitter<boolean>();

  public areAllSelected: boolean = false;
  public readonly T = T;

  account: Account;
  employee: Employee;
  settings: FilterViewModel[] = [];
  loading: boolean = true;
  active: boolean;
  subscriptions: Subscription[] = [];
  originalAppliedFilters: FilterViewModel[];
  typeahead$: EventEmitter<string>;
  ngOnInitHasBeenExecuted: boolean = false;
  icon: string;
  svg: string;
  valid: boolean = true;
  currentlySelectedFilterSettings: FilterViewModel[];

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly cachingService: CachingService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly dropdownEventsEmitter: DropdownEventsEmitter,
    private readonly moduleService: ModuleService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly sortingService: SortingService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.setAppliedFilters();

    this.account = this.cachingService.GetAccountInfo();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.icon = IconUtilities.getIconForFilterType(this.filterType);
    if (!this.icon || !this.icon.length) {
      this.svg = IconUtilities.getSvgForFilterType(this.filterType, 24, true, '#939699');
    }
    this.originalAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];

    if (!this.label || !this.label.length) {
      this.label = this.localisationService.localiseFilterType(this.filterType);
    }

    if (!this.summaryLabel || !this.summaryLabel.length) {
      this.summaryLabel = this.localisationService.localiseFilterType(this.filterType);
    }

    this.setSelectedValue();

    if (!this.usePredefinedFilters) {
      this.initDropdownValues();
    } else {
      this.initPredefinedFilters();
    }

    this.setCurrentlySelectedFilterSettings();

    this.ngOnInitHasBeenExecuted = true;
    this.checkIfAllAreSelected();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!this.ngOnInitHasBeenExecuted) {
      return;
    }

    if (this.displayError) {
      this.setValidationState();
    }

    this.setAppliedFilters();

    this.originalAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];

    if (this.singleSelect) {
      if (simpleChanges.currentlySelectedSettingValue && simpleChanges.currentlySelectedSettingValue.currentValue) {
        this.setInitialSingleSelectFilter();
      } else {
        this.setSelectedValue();
      }
    } else {
      if (simpleChanges.appliedFilters && simpleChanges.appliedFilters.currentValue) {
        this.setCurrentlySelectedFilterSettings();
      }
    }

    if (!this.usePredefinedFilters) {
      this.initDropdownValues();
    } else {
      this.initPredefinedFilters();
    }
  }

  setAppliedFilters() {
    if (this.appliedFilters) {
      this.appliedFilters = this.appliedFilters.filter((a) => a !== undefined);
    } else {
      this.appliedFilters = [];
    }
  }

  setSelectedValue() {
    if (this.singleSelect && !this.currentlySelectedSettingValue) {
      if (this.appliedFilters) {
        const matchingFilter = this.appliedFilters.find((f) => f.filterType === this.filterType);

        if (matchingFilter) {
          this.currentlySelectedSettingValue = JSON.parse(JSON.stringify(matchingFilter.filterValue as string)) as string;
          this.changeDetectorRef.markForCheck();
        }
      }
    }
  }

  initPredefinedFilters() {
    if (this.predefinedFilters) {
      this.settings = this.predefinedFilters;
    }
  }

  initDropdownValues() {
    const shouldBeLocalisedValue = FilterUtilities.IsFilterTypeWithLocalisedValues(this.filterType);
    let settingsByType = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterType);

    /** hub specific logic as we are passing acocuntId as filter parameter we need to refine what comes form the cache */
    settingsByType = settingsByType.filter((s) => !s.accountId || s.accountId === this.account.id);

    settingsByType = FilterUtilities.setFilterText(settingsByType, this.localisationService, this.translateService);

    settingsByType.forEach((s) => {
      if (s.filterText === null || s.filterText === undefined) {
        s.filterText = '';
      }

      if (shouldBeLocalisedValue) {
        if (s.filterType === FilterTypes.RAG) {
          s.filterText = RagHelper.getRAGDescriptionTextFromValue(
            s.filterValue as RAGStatuses,
            this.account.id,
            false,
            this.translateService,
            this.localisationService
          );
        } else {
          s.filterText = this.localisationService.localiseFilterValueByFilterType(+s.filterValue, s.filterType);
        }
      }
    });

    if (this.filterType === FilterTypes.Owner) {
      settingsByType = settingsByType.filter((s) => !s.isExternal);
    }

    if (this.filterType === FilterTypes.Expected_Direction) {
      settingsByType.forEach((element) => {
        if (element.filterValue === IndicatorUpdateExpectedDirections.Up) {
          element.filterText =
            `${element.filterText} - ` + this.translateService.instant(T.settings.imports_and_exports.import_guides.increasing);
        }
        if (element.filterValue === IndicatorUpdateExpectedDirections.Down) {
          element.filterText =
            `${element.filterText} - ` + this.translateService.instant(T.settings.imports_and_exports.import_guides.decreasing);
        }
      });
    }

    if (settingsByType && settingsByType.length) {
      this.settings = JSON.parse(JSON.stringify(settingsByType)) as FilterViewModel[];
      this.loading = false;

      if (this.showOnlyActive || this.filterType === FilterTypes.Event) {
        this.settings = this.settings.filter((s) => s.isActive);
      }

      if (this.filterByModuleObjectType && this.moduleService.currentObjectTypes.length) {
        if (this.filterType === FilterTypes.Tag) {
          const tagGroupFilters = this.allowedFiltersService
            .getCachedAllowedFiltersByType(FilterTypes.Tag_Group)
            .filter(
              (s) =>
                !s.displayForGlobalObjectType ||
                this.moduleService.currentObjectTypes.some((oType) => oType === s.displayForGlobalObjectType)
            );
          this.settings = this.settings.filter(
            (s) => s.relatedObjectId === 0 || tagGroupFilters.some((tg) => +tg.filterValue === s.relatedObjectId)
          );
        } else {
          this.settings = this.settings.filter(
            (s) =>
              !s.displayForGlobalObjectType || this.moduleService.currentObjectTypes.indexOf(s.displayForGlobalObjectType) > -1
          );
          this.changeDetectorRef.markForCheck();
        }
      }

      if (this.relatedObjectId > 0) {
        this.settings = this.settings.filter(
          (f) => f.relatedObjectId && f.relatedObjectId.toString() === this.relatedObjectId.toString()
        );
        this.changeDetectorRef.markForCheck();
      }

      if (this.filterType === FilterTypes.Project) {
        this.settings = FilterUtilities.FilterFiltersByPermissions(this.employee, settingsByType, this.filterType);
      }

      if (this.excludeFilterValues) {
        /**
         * This will remove excludeFilterValues from this.settings array (allowed dropdown values)
         * You may want to do it simple like the commented version but keep in mind that filterValues sometimes are string or number
         * and the enums that we use on the FE are with numbers
         *
         * this.settings = this.settings.filter(f => !(this.excludeFilterValues as any).includes(f.filterValue)); */
        this.settings = this.settings.filter((allowedFilter) => {
          return (
            this.excludeFilterValues.filter((filterForExclude) => {
              return filterForExclude == allowedFilter.filterValue;
            }).length === 0
          );
        });
      }

      this.settings = this.sortingService.sortFilters(this.settings, this.filterType);

      this.setInitialSingleSelectFilter();

      this.changeDetectorRef.detectChanges();
    }
  }

  setInitialSingleSelectFilter() {
    const filteredAppliedFilters = this.appliedFilters.filter((f) => f.filterType === this.filterType);
    if (
      this.singleSelect &&
      this.currentlySelectedSettingValue &&
      (!filteredAppliedFilters ||
        !filteredAppliedFilters.find((f) => f.filterValue as string === this.currentlySelectedSettingValue.toString()))
    ) {
      const setting = this.settings.find((s) => s.filterValue as string === this.currentlySelectedSettingValue.toString());

      if (setting) {
        this.onApplyChanges([setting]);
        this.onFilterUpdate.next(this.appliedFilters);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onDropdownStateChanged(dropdownState: IDropdownState) {
    this.active = dropdownState.isToggled;
    this.changeDetectorRef.detectChanges();

    if (!this.active) {
      this.setValidationState();
    }

    this.dropdownStateChanged.emit(dropdownState);
  }

  onFilterSettingSelected(fs: FilterViewModel) {
    if (this.singleSelect) {
      this.appliedFilters = this.appliedFilters.filter((s) => s.filterType !== this.filterType);
      this.currentlySelectedSettingValue = fs.filterValue as string;
      this.appliedFilters.push(fs);
      this.changeDetectorRef.detectChanges();
      this.onFilterUpdate.emit(this.appliedFilters);
    } else {
      if (this.appliedFilters.find((s) => s.filterType === this.filterType && +s.filterValue === +fs.filterValue && s.filterText === fs.filterText)) {
        // const filtersWihoutTheRemovedOne = this.appliedFilters.filter(f => f.id !== fs.id);
        this.onFilterSettingRemoved(fs);
      } else {
        this.onFilterSettingAdded(fs);
      }

      this.checkIfAllAreSelected();
    }
    this.setValidationState();
  }

  onMakePrimary(primaryFilter: FilterViewModel) {
    this.appliedFilters.forEach((f) => (f.isPrimary = false));
    const primaryAppliedFilter = this.appliedFilters.find(
      (s) => s.filterType === primaryFilter.filterType && +s.filterValue === +primaryFilter.filterValue
    );
    if (primaryAppliedFilter) primaryAppliedFilter.isPrimary = primaryFilter.isPrimary;
    this.setCurrentlySelectedFilterSettings();
    this.onFilterUpdate.emit(this.appliedFilters);
    this.changeDetectorRef.detectChanges();
  }

  onFilterSettingCleared() {
    this.currentlySelectedSettingValue = undefined;
    this.appliedFilters = this.appliedFilters.filter((s) => s.filterType !== this.filterType);
    this.changeDetectorRef.detectChanges();
    this.onFilterUpdate.emit(this.appliedFilters);
    this.setValidationState();
    this.checkIfAllAreSelected();
  }

  onFilterSettingAdded(addedFilter: FilterViewModel) {
    this.appliedFilters.push(addedFilter);
    this.onFilterUpdate.emit(this.appliedFilters);
    this.setCurrentlySelectedFilterSettings();
    this.setValidationState();
    this.changeDetectorRef.detectChanges();
    this.dropdownEventsEmitter.broadcastDropdownPositionChanged();
  }

  onFilterSettingRemoved(removedFilter: FilterViewModel) {
    if (this.singleSelect) {
      this.currentlySelectedSettingValue = undefined;
    }
    const copiedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];
    const filtersToEmit = copiedFilters.filter((f) => !(f.filterValue.toString() === removedFilter.filterValue.toString() && f.filterType === removedFilter.filterType));
    this.setCurrentlySelectedFilterSettings();
    this.onFilterUpdate.emit(filtersToEmit);
    this.setValidationState();
    this.checkIfAllAreSelected();
    this.changeDetectorRef.detectChanges();
    this.dropdownEventsEmitter.broadcastDropdownPositionChanged();
  }

  onFilterSettingsUpdated(fs: FilterViewModel[]) {
    this.appliedFilters = fs.slice();

    this.setCurrentlySelectedFilterSettings();
    this.changeDetectorRef.detectChanges();
    this.dropdownEventsEmitter.broadcastDropdownPositionChanged();
    this.onFilterUpdate.emit(this.appliedFilters);
  }

  setCurrentlySelectedFilterSettings() {
    this.currentlySelectedFilterSettings = [];

    if (this.appliedFilters && this.appliedFilters.length) {
      let filterSettings = this.appliedFilters
        .filter((a) => a.filterType === this.filterType)
        .sort((a, b) => +b.isPrimary - +a.isPrimary);
      if (this.relatedObjectId && this.filterType === FilterTypes.Tag) {
        filterSettings = filterSettings.filter((f) => f.relatedObjectId === this.relatedObjectId);
      }
      const filters = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterType);
      if (filters && !this.usePredefinedFilters) {
        filterSettings.forEach((s) => {
          const matching = filters.find((f) => (<string | number>f.filterValue).toString() === (<string | number>s.filterValue).toString());
          if (matching) {
            matching.isPrimary = s.isPrimary;
            this.currentlySelectedFilterSettings.push(matching);
          } else {
            this.currentlySelectedFilterSettings.push(s);
          }
        });
      } else {
        this.currentlySelectedFilterSettings = filterSettings;
      }
    }

    this.changeDetectorRef.markForCheck();
  }

  get currentlySelectedSetting(): FilterViewModel {
    if (!this.settings) {
      return null;
    }

    return this.settings.find((s) => +s.filterValue === +this.currentlySelectedSettingValue);
  }

  onApplyChanges(filters: FilterViewModel[]) {
    this.appliedFilters = this.appliedFilters.filter((s) => s.filterType !== this.filterType);
    this.appliedFilters.push(...filters);
    this.originalAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];
    this.setCurrentlySelectedFilterSettings();
    this.setValidationState();
    this.checkIfAllAreSelected();
    this.changeDetectorRef.markForCheck();
  }

  onCancelChanges() {
    this.appliedFilters = JSON.parse(JSON.stringify(this.originalAppliedFilters)) as FilterViewModel[];
    this.setCurrentlySelectedFilterSettings();
  }

  setValidationState() {
    if (!this.required || this.appliedFilters.filter((s) => s.filterType === this.filterType).length > 0) {
      this.valid = true;
    } else {
      this.valid = false;
    }

    this.isValid.emit(this.valid);
  }

  onSelectAll(): void {
    if (!this.singleSelect) {
      if (this.appliedFilters.length) {
        // Here we need to check for any filters already being selected and if any, I'm gonna unselect them by emitting them again
        // because otherwise the once already being selected will get unselected even though they still should be selected!
        this.emitAllFiltersOnSelectAll();
      }

      this.appliedFilters = this.appliedFilters.filter((f) => f.filterType !== this.filterType);
      this.appliedFilters = this.appliedFilters.concat(this.settings.slice());
      this.emitAllFiltersOnSelectAll();
      this.checkIfAllAreSelected();

      this.changeDetectorRef.markForCheck();
    }
  }

  onClearAll(): void {
    this.appliedFilters = this.appliedFilters.filter((f) => f.filterType !== this.filterType);
    this.emitAllFiltersOnSelectAll();
    this.checkIfAllAreSelected();

    this.changeDetectorRef.detectChanges();
  }

  emitAllFiltersOnSelectAll(): void {
    this.onFilterUpdate.emit(this.appliedFilters);
  }

  private checkIfAllAreSelected(): void {
    if (!this.settings.length) {
      this.areAllSelected = true;
    } else {
      this.areAllSelected = this.appliedFilters.filter((x) => x.filterType === this.filterType).length === this.settings.length;
    }
  }
}
