<ng-container *ngIf="isFixedEvent">
  <app-fixed-filters
    [filterType]="filterTypeSelectorViewModel.filterType"
    [mobile]="mobile"
    [filters]="filters"
    [appliedFilters]="appliedFilters"
    (onFiltersUpdated)="updateFilters($event)"
  ></app-fixed-filters>
</ng-container>
<ng-container *ngIf="isFixedZone">
  <app-fixed-filters
    [filterType]="filterTypeSelectorViewModel.filterType"
    [mobile]="mobile"
    [filters]="filters"
    [appliedFilters]="appliedFilters"
    (onFiltersUpdated)="updateFilters($event)"
  ></app-fixed-filters>
</ng-container>
<ng-container *ngIf="isFixedChannel">
  <app-fixed-filters
    [filterType]="filterTypeSelectorViewModel.filterType"
    [mobile]="mobile"
    [filters]="filters"
    [appliedFilters]="appliedFilters"
    (onFiltersUpdated)="updateFilters($event)"
  ></app-fixed-filters>
</ng-container>
<ng-container *ngIf="filterTypeSelectorViewModel !== undefined && !isFixedFilter">
  <ng-container [ngSwitch]="filterTypeSelectorViewModel.filterSelectorType">
    <ng-container *ngSwitchCase="filterSelectorTypes.Dropdown">
      <ng-container *ngIf="canEdit">
        <app-floating-dropdown
          [singleSelect]="singleSelect"
          [filters]="filters"
          [showTitle]="showTitle"
          [useClearAllButton]="useClearAllButton"
          [appliedFilters]="localAppliedFilters"
          [filterType]="filterTypeSelectorViewModel.filterType"
          [mobile]="mobile"
          [customTitle]="customTitle"
          [canClickOnStar]="canClickOnStar"
          [showOnlyActive]="showOnlyActive"
          [lozengeInlineEdit]="lozengeInlineEdit"
          [isFilterRequired]="isFilterRequired"
          (filtersUpdated)="updateFilters($event)"
        ></app-floating-dropdown>
      </ng-container>
      <ng-container *ngIf="!canEdit">
        <app-filters-inline-edit-lozenges [appliedFilters]="appliedFilters"></app-filters-inline-edit-lozenges>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="filterSelectorTypes.Multiple_Checkboxes">
      <ng-container *ngIf="canEdit">
        <!-- Add output-->
        <app-filters-color
          [filters]="filters"
          [appliedFilters]="appliedFilters"
          [includeComment]="false"
          [mobile]="mobile"
          [filterType]="getProperFilterType()"
          (filtersWithCommentAdded)="updateFilterColor($event)"
        ></app-filters-color>
      </ng-container>
      <ng-container *ngIf="!canEdit"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="filterSelectorTypes.Date">
      <ng-container *ngIf="canEdit">
        <app-filters-date
          [filters]="appliedFilters"
          [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
          (filterAdded)="onDateFilterAdded($event)"
          [mobile]="mobile"
        ></app-filters-date>
      </ng-container>
      <ng-container *ngIf="!canEdit"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>
