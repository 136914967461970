import { RiskStatusTypes } from '../../risk/enums/riskStatusTypes';
import { Colors } from '../enums/colors';
import { RiskRAGStatuses } from '../enums/planning/riskRagStatuses';
import { RAGBreakdown } from '../models/rag/ragBreakdown';

export class RiskRagHelper {
  public static getRagStatusObjects(
    accountID: number,
    useOnlyColor: boolean
  ): { value: RiskRAGStatuses; color: string; description: string }[] {
    const ragStatuses: { value: RiskRAGStatuses; color: string; description: string }[] = [];
    ragStatuses.push({
      value: RiskRAGStatuses.Low,
      description: this.getRAGDescriptionTextFromValue(RiskRAGStatuses.Low, accountID, useOnlyColor),
      color: this.getRagBoxFromValue(RiskRAGStatuses.Low),
    });
    ragStatuses.push({
      value: RiskRAGStatuses.Minor,
      description: this.getRAGDescriptionTextFromValue(RiskRAGStatuses.Minor, accountID, useOnlyColor),
      color: this.getRagBoxFromValue(RiskRAGStatuses.Minor),
    });
    ragStatuses.push({
      value: RiskRAGStatuses.Medium,
      description: this.getRAGDescriptionTextFromValue(RiskRAGStatuses.Medium, accountID, useOnlyColor),
      color: this.getRagBoxFromValue(RiskRAGStatuses.Medium),
    });
    ragStatuses.push({
      value: RiskRAGStatuses.High,
      description: this.getRAGDescriptionTextFromValue(RiskRAGStatuses.High, accountID, useOnlyColor),
      color: this.getRagBoxFromValue(RiskRAGStatuses.High),
    });
    ragStatuses.push({
      value: RiskRAGStatuses.Extreme,
      description: this.getRAGDescriptionTextFromValue(RiskRAGStatuses.Extreme, accountID, useOnlyColor),
      color: this.getRagBoxFromValue(RiskRAGStatuses.Extreme),
    });
    return ragStatuses;
  }

  public static getRAGColourTextFromValue(ragStatus: RiskRAGStatuses) {
    switch (ragStatus) {
      case RiskRAGStatuses.Low:
        return 'Low';
      case RiskRAGStatuses.Minor:
        return 'Minor';
      case RiskRAGStatuses.Medium:
        return 'Medium';
      case RiskRAGStatuses.High:
        return 'High';
      case RiskRAGStatuses.Extreme:
        return 'Extreme';
    }
  }

  public static getRAGColourHexFromValue(ragStatus: RiskRAGStatuses) {
    switch (ragStatus) {
      case RiskRAGStatuses.Low:
        return Colors.Green;
      case RiskRAGStatuses.Minor:
        return Colors.Yellow;
      case RiskRAGStatuses.Medium:
        return Colors.Amber;
      case RiskRAGStatuses.High:
        return Colors.Orange;
      case RiskRAGStatuses.Extreme:
        return Colors.Red;
    }
  }

  public static getRagBoxFromValue(ragStatus: RiskRAGStatuses) {
    switch (ragStatus) {
      case RiskRAGStatuses.Low:
        return 'rag-box-0';
      case RiskRAGStatuses.Minor:
        return 'rag-box-1';
      case RiskRAGStatuses.Medium:
        return 'rag-box-2';
      case RiskRAGStatuses.High:
        return 'rag-box-3';
      case RiskRAGStatuses.Extreme:
        return 'rag-box-4';
    }
  }

  public static getRAGDescriptionTextFromValue(ragStatus: RiskRAGStatuses, accountID: number, useOnlyColor: boolean) {
    const color = this.getRAGColourTextFromValue(ragStatus);

    if (useOnlyColor) {
      return color;
    }

    let description: string = '';
    switch (ragStatus) {
      case RiskRAGStatuses.Low:
        description = 'Low';
        break;
      case RiskRAGStatuses.Minor:
        description = 'Minor';
        break;
      case RiskRAGStatuses.Medium:
        description = 'Medium';
        break;
      case RiskRAGStatuses.High:
        description = 'High';
        break;
      case RiskRAGStatuses.Extreme:
        description = 'Extreme';
        break;
    }

    return color + ' (' + description + ')';
  }

  public static getRAGDescriptionFromValue(ragStatus: RiskRAGStatuses) {
    let description: string = '';
    switch (ragStatus) {
      case RiskRAGStatuses.Low:
        description = 'Low';
        break;
      case RiskRAGStatuses.Minor:
        description = 'Minor';
        break;
      case RiskRAGStatuses.Medium:
        description = 'Medium';
        break;
      case RiskRAGStatuses.High:
        description = 'High';
        break;
      case RiskRAGStatuses.Extreme:
        description = 'Extreme';
        break;
    }
    return description;
  }

  public static getTotalRAGBreakdownBar(rags: RiskRAGStatuses[]): RAGBreakdown[] {
    const list = [
      {
        ragStatus: RiskRAGStatuses.Low,
        colour: RiskRagHelper.getRAGColourHexFromValue(RiskRAGStatuses.Low),
        count: rags.filter((r) => r === RiskRAGStatuses.Low).length,
      },
      {
        ragStatus: RiskRAGStatuses.Minor,
        colour: RiskRagHelper.getRAGColourHexFromValue(RiskRAGStatuses.Minor),
        count: rags.filter((r) => r === RiskRAGStatuses.Minor).length,
      },
      {
        ragStatus: RiskRAGStatuses.Medium,
        colour: RiskRagHelper.getRAGColourHexFromValue(RiskRAGStatuses.Medium),
        count: rags.filter((r) => r === RiskRAGStatuses.Medium).length,
      },
      {
        ragStatus: RiskRAGStatuses.High,
        colour: RiskRagHelper.getRAGColourHexFromValue(RiskRAGStatuses.High),
        count: rags.filter((r) => r === RiskRAGStatuses.High).length,
      },
      {
        ragStatus: RiskRAGStatuses.Extreme,
        colour: RiskRagHelper.getRAGColourHexFromValue(RiskRAGStatuses.Extreme),
        count: rags.filter((r) => r === RiskRAGStatuses.Extreme).length,
      },
    ];

    return list;
  }

  static getRiskStatusColorHex(status: RiskStatusTypes): string {
    switch (status) {
      case RiskStatusTypes.Open: {
        return Colors.Black;
      }
      case RiskStatusTypes.Draft: {
        return Colors.Grey;
      }
      case RiskStatusTypes.Closed: {
        return Colors.Blue_Dark;
      }
      default: {
        return '#000000';
      }
    }
  }
}
