import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { EmployeeViewModel } from 'src/app/modules/shared/viewModels/employeeViewModel';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { ShareItemViewModel } from '../../../viewModels/utilities/shareItemViewModel';
import { UtilitiesService } from '../../../services/utilities.service';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';
import { AlertService } from '../../../services/alert.service';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-share-item-modal',
  templateUrl: 'share-item-modal.component.html',
  styleUrls: ['share-item-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareItemModal implements OnInit, OnDestroy {
  //Inputs
  id = 0;
  objectType = ObjectTypes.IncidentItem;
  incidentSubType = IncidentItemTypes.Incident;
  title = '';

  shareItemViewModel: ShareItemViewModel = new ShareItemViewModel();
  subscriptions: Subscription[] = [];
  employees: EmployeeViewModel[] = [];
  selectedEmployees: EmployeeViewModel[] = [];
  selectedEmployee: EmployeeViewModel;
  foundEmployees: EmployeeViewModel[] = [];
  loading: boolean = false;
  selectedGroupIds: number[] = [];
  typeahead$: EventEmitter<string>;
  ownerFilterType = FilterTypes.Owner;
  public customItemTitle: string = undefined;
  public localisedObjectType: string = 'item';
  public appliedOwnersFilters: FilterViewModel[] = [];
  public localisedEmployee: string = 'Employee';
  public localisedEmployeeGroup: string = 'User Group';
  filterTypes = FilterTypes;
  public readonly T = T;

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly localisationService: LocalisationService,
    private readonly utilityService: UtilitiesService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.shareItemViewModel.employeeID = this.authenticationService.getCurrentEmployee().id;
    this.shareItemViewModel.objectType = this.objectType;
    this.customItemTitle
      ? (this.localisedObjectType = this.customItemTitle)
      : (this.localisedObjectType = this.localisationService.localiseObjectType(this.objectType));
    this.localisedEmployee = this.localisationService.localiseObjectType(ObjectTypes.Employee);
    this.localisedEmployeeGroup = this.localisationService.localiseObjectType(ObjectTypes.User_Group);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onSubmit() {
    this.loading = true;
    this.shareItemViewModel.id = this.id;
    this.shareItemViewModel.url = window.location.href;
    this.shareItemViewModel.title = this.title;
    this.shareItemViewModel.userGroupRecipients = this.selectedGroupIds;
    if (this.objectType === ObjectTypes.IncidentItem) {
      this.shareItemViewModel.incidentSubType = this.incidentSubType;
    }

    if (this.shareItemViewModel.url.endsWith('list') || this.shareItemViewModel.url.endsWith('control/logs')) {
      if (this.objectType === ObjectTypes.IncidentItem || this.objectType === ObjectTypes.Job) {
        this.shareItemViewModel.url += '/' + this.id;
      }

      if (this.objectType === ObjectTypes.Project) {
        this.shareItemViewModel.url += '/projects/' + this.id;
      }

      if (this.objectType === ObjectTypes.Task) {
        this.shareItemViewModel.url += '/tasks/' + this.id;
      }
    }

    if (this.shareItemViewModel.url.endsWith('control/jobs') && this.objectType === ObjectTypes.Job) {
        this.shareItemViewModel.url += '/' + this.id;
    }

    if (this.shareItemViewModel.url.endsWith('risk/risks-issues') && this.objectType === ObjectTypes.Risk) {
        this.shareItemViewModel.url += '/risk/' + this.id;
    }

    this.shareItemViewModel.recipients = this.selectedEmployees;
    this.utilityService.Share(this.shareItemViewModel).subscribe(() => {
      void this.alertService.success(
        this.translateService.instant(T.common.item_shared_successfully, { item: this.localisedObjectType })
      );
      (this.loading = false), this.bsModalRef.hide();
    });
  }

  onFilterSettingRemoved(employee: EmployeeViewModel) {
    this.selectedEmployees.splice(this.selectedEmployees.indexOf(employee), 1);
  }
  onOwnerFilterUpdated(filters: FilterViewModel[]) {
    this.selectedEmployees = [];
    this.appliedOwnersFilters = filters;
    this.selectedGroupIds = [];
    for (const filter of filters) {
      if (filter.filterType == FilterTypes.Owner) {
        const [firstName, surname] = filter.filterText.split(' ');
        this.selectedEmployees.push({
          id: +filter.filterValue,
          firstName,
          surname,
          username: firstName + ' ' + surname,
          avatarHtml: '',
          thumbnailURL: '',
          itemOwnerId: 0,
          primary: filter.isPrimary,
        });
      } else if (filter.filterType === FilterTypes.User_Group) {
        this.selectedGroupIds.push(+filter.filterValue);
      }
    }
  }

  onEditorChange(input: string) {
    this.shareItemViewModel.comment = input;
  }

  localiseString(str: string, capitalize: boolean = true) {
    if (capitalize) {
      return this.capitalizeFirstLetter(this.localisationService.localise(str));
    } else {
      return this.localisationService.localise(str);
    }
  }

  capitalizeFirstLetter(str: string) {
    if (!str.startsWith('fa')) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str.charAt(0).toUpperCase() + str.charAt(1).toUpperCase() + str.slice(2);
    }
  }
}
