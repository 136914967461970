// MODULES

import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataTableModule } from "@pascalhonegger/ng-datatable";
import { CalendarDateFormatter, CalendarModule, CalendarMomentDateFormatter, DateAdapter, MOMENT } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { GridsterModule } from 'angular-gridster2';
import { DynamicModule } from 'ng-dynamic-component';
import { DragulaModule } from 'ng2-dragula';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCropperModule } from 'ngx-image-cropper';
import { QuillModule } from 'ngx-quill';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper-v-13';
import { EventsService } from '../settings/services/events-service';
import { DocumentCardComponent } from './components/cards/document-card/document-card.component';
import { HeaderBarComponent } from './components/common/app/header-bar/header-bar.component';
import { SideNavbarComponent } from './components/common/app/side-navbar/side-navbar.component';
import { BubbleComponent } from './components/common/bubble/bubble.component';
import { ButtonComponent } from './components/common/button/button.component';
import { ButtonBarRoundedComponent } from './components/common/buttons/button-bar-rounded/button-bar-rounded.component';
import { ButtonIconCircleComponent } from './components/common/buttons/button-icon-circle/button-icon-circle.component';
import { ButtonIconSquareComponent } from './components/common/buttons/button-icon-square/button-icon-square.component';
import { ButtonRectangleComponent } from './components/common/buttons/button-rectangle/button-rectangle.component';
import { ButtonTransparentComponent } from './components/common/buttons/button-transparent/button-transparent.component';
import { ToggleButtonComponent } from './components/common/buttons/toggle-button/toggle-button.component';
import { CheckboxWithLabelComponent } from './components/common/checkbox-with-label/checkbox-with-label.component';
import { CheckboxComponent } from './components/common/checkbox/checkbox.component';
import { ChecklistItemCardComponent } from './components/common/checklists/checklist-item-card/checklist-item-card.component';
import { ChecklistItemsListComponent } from './components/common/checklists/checklist-items-list/checklist-items-list.component';
import { ChecklistsDetailsSectionComponent } from './components/common/checklists/checklists-details-section/checklists-details-section.component';
import { ChecklistItemAddModalComponent } from './components/common/checklists/modals/checklist-item-add/checklist-item-add.component';
import { ChecklistItemsAddModal } from './components/common/checklists/modals/checklist-items-add.component/checklist-items-add.component';
import { ChecklistTemplateListComponent } from './components/common/checklists/template-list/template-list.component';
import { DashboardCountdownComponent } from './components/common/dashboard-v2/dashboard-widgets/dashboard-countdown/dashboard-countdown.component';
import { CountdownDashboardSettingsComponent } from './components/common/dashboard-sidebar/countdown-dashboard-settings/countdown-dashboard-settings.component';
import { DashboardSidebarComponent } from './components/common/dashboard-sidebar/dashboard-sidebar.component';
import { DashboardWidgetOptionsComponent } from './components/common/dashboard-sidebar/dashboard-widget-options/dashboard-widget-options';
import { InfoWidgetDashboardSettingsComponent } from './components/common/dashboard-sidebar/info-widget-dashboard-settings/info-widget-dashboard-settings.component';
import { VenueDashboardSettingsComponent } from './components/common/dashboard-sidebar/venue-dashboard-settings/venue-dashboard-settings.component';
import { DashboardWidgetConfigureComponent } from './components/common/dashboard/dashboard-info-widget-configure/dashboard-info-widget-configure.component';
import { DashboardComponent } from './components/common/dashboard/dashboard.component';
import { NativeDatepickerComponent } from './components/common/datepicker/datepicker.component';
import { DetailsEditableFieldComponent } from './components/common/details-editable-field/details-editable-field.component';
import { DetailsListViewComponent } from './components/common/details-list-view/details-list-view.component';
import { DetailsLozengeComponent } from './components/common/details-lozenge/details-lozenge.component';
import { DocumentUploadComponent } from './components/common/uploads/document-upload/document-upload.component';
import { DropdownContentComponent } from './components/common/dropdown-content/dropdown-content.component';
import { DropdownTextItemComponent } from './components/common/dropdown-text-item/dropdown-text-item.component';
import { EmployeeAvatarComponent } from './components/common/employee-avatar/employee-avatar.component';
import { EmptyStatePlaceholderComponent } from './components/common/empty-state-placeholder/empty-state-placeholder.component';
import { ExpandCollapseButtonComponent } from './components/common/expand-collapse-button/expand-collapse-button.component';
import { FloatingSidebarComponent } from './components/common/floating-sidebar/floating-sidebar.component';
import { HeliumBubbleComponent } from './components/common/helium-bubble/helium-bubble.component';
import { HyperlinkComponent } from './components/common/hyperlink/hyperlink.component';
import { ImagePreviewComponent } from './components/common/image-preview/image-preview.component';
import { InformationBubbleComponent } from './components/common/information-bubble/information-bubble.component';
import { ListDetailsSwitchPaneComponent } from './components/common/list-details-switch-pane/list-details-switch-pane.component';
import { ListHeaderComponent } from './components/common/list-header/list-header.component';
import { ListSearchInputFixedComponent } from './components/common/list-search-input-fixed/list-search-input-fixed.component';
import { ListSearchInputComponent } from './components/common/list-search-input/list-search-input.component';
import { LoadingComponent } from './components/common/loading/loading.component';
import { DetailsMapComponent } from './components/common/maps/details-map/details-map.component';
import { IncidentMapComponent } from './components/common/maps/incident-map/incident-map.component';
import { LocationMapv2Component } from './components/common/maps/location-map/location-map-v2.component';

import { ZoneMapComponent } from './components/common/maps/zone-map/zone-map.component';
import { ModalBodyComponent } from './components/common/modal-body/modal-body.component';
import { ModalFooterComponent } from './components/common/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/common/modal-header/modal-header.component';
import { ModalComponent } from './components/common/modal/modal.component';
import { NgDatepickerComponent } from './components/common/ngdatepicker/ngdatepicker.component';
import { NgDropdownComponent } from './components/common/ngDropdown/ngDropdown.component';
import { NgImagePreviewComponent } from './components/common/ngImagePrewview/ngImagePreview.component';
import { NoResultsComponent } from './components/common/no-results/no-results.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { OptionalDateAndTimeComponent } from './components/common/optional-date-and-time/optional-date-and-time.component';
import { PaginatedCardsComponent } from './components/common/paginated-cards/paginated-cards.component';
import { PopupComponent } from './components/common/popup/popup.component';
import { IncidentQuickAddComponent } from './components/common/quick-add/incident-quick-add.component';
import { RadioButtonComponent } from './components/common/radio-button/radio-button.component';
import { PaginationComponent } from './components/common/responsive-table/pagination/pagination.component';
import { ResponsiveTableComponent } from './components/common/responsive-table/responsive-table/responsive-table.component';
import { RichTextEditorComponent } from './components/common/rich-text-editor/rich-text-editor.component';
import { SearchBarComponent } from './components/common/search-bar/search-bar.component';
import { SearchInputComponent } from './components/common/search-input/search-input.component';
import { SettingsAccordionGroupComponent } from './components/common/settings-accordion/settings-accordion-group/settings-accordion-group.component';
import { SettingsAccordionComponent } from './components/common/settings-accordion/settings-accordion.component';
import { SortButtonPopupComponent } from './components/common/sort-button-popup/sort-button-popup.component';
import { SplitPaneComponent } from './components/common/split-pane/split-pane.component';
import { StatusPickerComponent } from './components/common/status-picker/status-picker.component';
import { SubscribersSectionComponent } from './components/common/subscribers-section/subscribers-section.component';
import { TabComponent } from './components/common/tab/tab.component';
import { TabsetComponent } from './components/common/tabset/tabset.component';
import { TextAreaComponent } from './components/common/text-area/text-area.component';
import { TextFieldComponent } from './components/common/text-field/text-field.component';
import { ThreadedBeadsComponent } from './components/common/threaded-beads/threaded-beads.component';
import { NativeTimepickerComponent } from './components/common/timepicker/timepicker.component';
import { ToggleSwitchComponent } from './components/common/toggle-switch/toggle-switch.component';
import { TooltipComponent } from './components/common/tooltip/tooltip.component';
import { ValidationErrorComponent } from './components/common/validation-error/validation-error.component';
import { CheckboxDropdownComponent } from './components/dropdowns/checkbox-dropdown/checkbox-dropdown.component';
import { SelectDropdownComponent } from './components/dropdowns/select-dropdown/select-dropdown.component';
import { FiltersAddComponent } from './components/filters-rebuild/filters-add/filters-add.component';
import { FiltersColorComponent } from './components/filters-rebuild/filters-color/filters-color.component';
import { FiltersDateComponent } from './components/filters-rebuild/filters-date/filters-date.component';
import { FiltersDesktopFooterComponent } from './components/filters-rebuild/filters-desktop-footer/filters-desktop-footer.component';
import { FiltersMultiselectComponent } from './components/filters-rebuild/filters-desktop-multiselect/filters-multiselect.component';
import { FiltersEntriesInformationComponent } from './components/filters-rebuild/filters-entries-information/filters-entries-information.component';
import { FiltersExcludeSelectedComponent } from './components/filters-rebuild/filters-exclude-selected/filters-exclude-selected.component';
import { FiltersFavoriteComponent } from './components/filters-rebuild/filters-favorite/filters-favorite.component';
import { FiltersIncludeCommentComponent } from './components/filters-rebuild/filters-include-comment/filters-include-comment.component';
import { FiltersInlineEditLozengeComponent } from './components/filters-rebuild/filters-inline-edit-lozenge/filters-inline-edit-lozenge.component';
import { FiltersInlineEditLozengesComponent } from './components/filters-rebuild/filters-inline-edit-lozenges/filters-inline-edit-lozenges.component';
import { FiltersListEntriesComponent } from './components/filters-rebuild/filters-list-entries/filters-list-entries.component';
import { FiltersListEntryComponent } from './components/filters-rebuild/filters-list-entry/filters-list-entry.component';
import { FiltersLiveEventEntryComponent } from './components/filters-rebuild/filters-live-event-entry/filters-live-event-entry.component';
import { FiltersInlineEditContainerComponent } from './components/filters-rebuild/filters-lozenge-inline-edit/filters-inline-edit-container.component';
import { FilterLozengeComponent } from './components/filters-rebuild/filters-lozenge/filters-lozenge.component';
import { FiltersMobileFooterComponent } from './components/filters-rebuild/filters-mobile-footer/filters-mobile-footer.component';
import { FiltersMobileHeaderComponent } from './components/filters-rebuild/filters-mobile-header/filters-mobile-header.component';
import { FiltersSearchInputComponent } from './components/filters-rebuild/filters-search-input/filters-search-input.component';
import { FiltersComponent } from './components/filters-rebuild/filters/filters.component';
import { AddUpdateModal } from './components/modals/add-update/add-update-modal.component';
import { AddExternalUserModal } from './components/modals/add-external-user-modal/add-external-user-modal.component';
import { ChooseDependencyModalComponent } from './components/modals/choose-dependency/choose-dependency.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal';
import { ModalConfirmCreateDependComponent } from './components/modals/create-dependency-modal/create-dependency-modal.component';
import { ModalConfirmDeleteComponent } from './components/modals/delete-modal/delete-modal.component';
import { DocumentDetailsModalComponent } from './components/modals/document-details/document-details-modal.component';
import { EmployeeFilterSettingAddModalComponent } from './components/modals/employee-filter-setting-add/employee-filter-setting-add-modal.component';
import { ImageCropperModalComponent } from './components/modals/image-cropper/image-cropper-modal.component';
import { InformationModalComponent } from './components/modals/information-modal/information-modal.component';
import { FixedEventDropdownSelectModal } from './components/modals/fixed-event-dropdown-select/fixed-event-dropdown-select.component';
import { FixedZoneDropdownSelectModalComponent } from './components/modals/fixed-zone-dropdown-select/fixed-zone-dropdown-select.component';
import { AddWidgetLocationModal } from './components/modals/location-select/add-widget-location-modal.component';
import { ModalConfirmationSaveComponent } from './components/modals/modal-confirmation-save/modal-confirmation-save.component';
import { SelectAccountComponent } from './components/modals/select-account/select-account.component';
import { ModalConfirmUpdateComponent } from './components/modals/update-item-modal/update-item-modal.component';
import { VideoModalComponent } from './components/modals/video/video-modal.component';
import { CardLozengeComponent } from './components/rebuild/card-lozenge/card-lozenge.component';
import { CardLozengesListComponent } from './components/rebuild/card-lozenges-list/card-lozenges-list.component';
import { RAGBreakdownBarComponent } from './components/rebuild/rag-breakdown-bar/rag-breakdown-bar.component';
import { SimpleRAGBreakdownBarComponent } from './components/rebuild/simple-rag-breakdown-bar/simple-rag-breakdown-bar.component';
import { AutofocusDirective } from './directives/autoFocus.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { DragDropDirective } from './directives/file-drag-drop.directive';
import { HrefOutDirective } from './directives/hrefOut.directive';
import { InputTrimDirective } from './directives/input-trim.directive';
import { LocalisationDirective } from './directives/localisation/localisation.directive';
import { NgBlockScreenDirective } from './directives/ngBlockScreen.directive';
import { DateMaskDirective } from './directives/ngDateMask/ngDateMask.directive';
import { NgDatepickerDirective } from './directives/ngdatepicker.directive';
import { NgDropdownDirective } from './directives/ngDropdown.directive';
import { NgImagePreviewDirective } from './directives/ngImagePreview.directive';
import { PopupDirective } from './directives/popup.directive';
import { ResizableDirective } from './directives/resizable.directive';
import { RouteTransformDirective } from './directives/routeTransofrmDirective';
import { TabHeadingDirective } from './directives/tabHeading.directive';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { InputValidationDirective } from './directives/validation.directive';
import { BsModalEventsEmitter } from './events/bsModal.events';
import { CommentEventsEmitter } from './events/comments.events';
import { DashboardEventEmitters } from './events/dashboard.events';
import { DropdownEventsEmitter } from './events/dropdown.events';
import { FloatingSidebarEventEmitters } from './events/floating-sidebar.events';
import { GanttEventsEmitter } from './events/gantt.events';
import { HeaderEventsEmitter } from './events/header.events';
import { IncidentsEventsEmitter } from './events/incidents.events';
import { LocationManagerEventEmitter } from './events/locationManager.events';
import { LoginEventsEmitter } from './events/login.events';
import { ObjectEventEmitters } from './events/object.events';
import { PopupEventsEmitter } from './events/popup.events';
import { SidebarEventsEmitter } from './events/sidebar.events';
import { TooltipEventsEmitter } from './events/tooltip.events';
import { WindowEventsEmitter } from './events/window.events';
import { AdminGuard } from './guards/admin.guard';
import { IMSGuard } from './guards/ims.guard';
import { PEAPsGuard } from './guards/peaps.guard';
import { RunsheetsGuard } from './guards/runsheets.guard';
import { AuthenticationManager } from './managers/authenticationManager';
import { CommentsManager } from './managers/comments.manager';
import { IncidentsManager } from './managers/incidents.manager';
import { PaginationManager } from './managers/paginationManager';
import { DateToISOPipe } from './pipes/dateToISO.pipe';
import { DisplayTaskForAgendaPipe } from './pipes/display-task-for-agenda.pipe';
import { DisplayTaskForDailyCellPipe } from './pipes/display-task-for-daily-cell.pipe';
import { FilterFilterTypes } from './pipes/filter-filter-types.pipe';
import { LastUpdatedDatePipe } from './pipes/last-updated-date.pipe';
import { LocalisedDatePipe } from './pipes/localised-date.pipe';
import { LocalisedTimePipe } from './pipes/localised-time.pipe';
import { LocalisedWeekdayPipe } from './pipes/localised-weekday.pipe';
import { LocaliseFilterTypetPipe } from './pipes/localiseFilterType.pipe';
import { LocaliseObjectTypePipe } from './pipes/localiseObjectType.pipe';
import { LocaliseTextPipe } from './pipes/localiseText.pipe';
import { NumberPaddingPipe } from './pipes/number-padding.pipe';
import { ReplaceUnderscoresPipe } from './pipes/replace-underscores.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { modalConfigProviders } from './providers/modal-config.providers';
import { AccountSettingsService } from './services/account-settings.service';
import { ActivityService } from './services/activity.service';
import { AlertService } from './services/alert.service';
import { AuthenticationService } from './services/authentication.service';
import { CachingService } from './services/caching.service';
import { ChecklistItemService } from './services/checklist-item-service';
import { ChecklistItemTemplateService } from './services/checklist-item-template.service';
import { ChecklistTemplateService } from './services/checklist-template.service';
import { CommentsService } from './services/comments.service';
import { ConfigurationService } from './services/configuration.service';
import { ConfirmationService } from './services/confirmation.service';
import { ContinousService } from './services/continuous.service';
import { DependentTasksService } from './services/dependentTasks.service';
import { DocumentService } from './services/document.service';
import { DocumentCategoryService } from './services/documentCategory.service';
import { DynamicComponentsService } from './services/dynamicComponents.service';
import { EmployeeSettingsService } from './services/employee-settings.service';
import { GanttService } from './services/gantt.service';
import { GeoLocationService } from './services/geo-location.service';
import { IncidentChannelsService } from './services/incident-channels-service';
import { IncidentsService } from './services/incidents.service';
import { IncidentsSettingsService } from './services/indicents-settings.service';
import { LocalisationService } from './services/localisation.service';
import { LocationService } from './services/location.service';
import { ModuleService } from './services/module.service';
import { NotificationService } from './services/notification.service';
import { CheckTemplateService } from './services/peaps/check-template.service';
import { CheckService } from './services/peaps/check.service';
import { CheckpointTemplateService } from './services/peaps/checkpoint-template.service';
import { CheckpointTypeService } from './services/peaps/checkpoint-type.service';
import { PEAPDashboardService } from './services/peaps/peap-dashboard.service';
import { PEAPGroupService } from './services/peaps/peap-group.service';
import { PEAPTemplateService } from './services/peaps/peap-template.service';
import { PEAPService } from './services/peaps/peap.service';
import { SlackService } from './services/slack.service';
import { SubscriptionService } from './services/subscription.service';
import { TimeZoneService } from './services/timeZone.service';
import { UrlService } from './services/url.service';
import { UtilitiesService } from './services/utilities.service';
import { WeatherDataService } from './services/weather-data.service';
import { FilterSortingModalComponent } from './components/modals/filters-sorting/filters-sorting-modal.component';
import { SortingService } from './services/sorting.service';
import { DetailsDropdownComponent } from './components/common/details/details-dropdown/details-dropdown.component';
import { ButtonDropdownComponent } from './components/common/buttons/button-dropdown/button-dropdown.component';
import { ListFilteringService } from './services/utilities/list-filtering.service';
import { FilterDropdownNewComponent } from './components/filters-rebuild/filter-dropdown-new/filter-dropdown-new.component';
import { ShareItemModal } from './components/modals/share-item/share-item-modal.component';
import { SearchDropdownComponent } from './components/rebuild/search-dropdown/search-dropdown.component';
import { DetailsIconFilterComponent } from './components/common/details-icon-filter/details-icon-filter.component';
import { ButtonSortRectangleNewComponent } from './components/common/buttons/button-sort-rectangle-new/button-sort-rectangle-new.component';
import { FiltersSingleselectComponent } from './components/filters-rebuild/filters-desktop-singleselect/filters-singleselect.component';
import { DateSliderComponent } from './components/common/date-slider/date-slider.component';
import { DetailsDatepickerComponent } from './components/common/details-datepicker/details-datepicker.component';
import { ConvertNumPipe } from './pipes/convertNum.pipe';
import { DetailsIconComponent } from './components/common/details-icon/details-icon.component';
import { SidePaneHeaderComponent } from './components/common/side-pane-header/side-pane-header.component';
import { NgRangeDatepickerComponent } from './components/common/ng-range-datepicker/ng-range-datepicker.component';
import { RangeDatepickerDirective } from './directives/range-datepicker.directive';
import { SimpleCardComponent } from './components/cards/simple-card/simple-card.component';
import { LinkedItemsListComponent } from './components/rebuild/linked-items-list/linked-items-list.component';
import { LinkedItemsAddModalComponent } from './components/modals/linked-items-add-modal/linked-items-add-modal.component';
import { LinkedItemsDetailsListComponent } from './components/rebuild/linked-items-details-list/linked-items-details-list.component';
import { FiltersSimpleComponent } from './components/filters-rebuild/filters-simple/filters-simple.component';
import { FiltersLozengeContainerComponent } from './components/filters-rebuild/filters-lozenge-container/filters-lozenge-container.component';
import { DetailsTagsComponent } from './components/common/details-tags/details-tags.component';
import { UploadContainerComponent } from './components/common/uploads/upload-container/upload-container.component';
import { AddModalTagGroupsComponent } from './components/filters-rebuild/add-modal-tag-groups/add-modal-tag-groups.component';
import { FilterTagComponent } from './components/filters-rebuild/filter-tag/filter-tag.component';
import { ButtonDropdownSimpleComponent } from './components/common/buttons/button-dropdown-simple/button-dropdown-simple.component';
import { ObjectTagService } from '../settings/services/objectTag-service';
import { HistoryFeedNewComponent } from './components/common/history-feed-new/history-feed-new.component';
import { CardHistoryFeedComponent } from './components/cards/history-feed-card/card-history-feed.component';
import { CardIconComponent } from './components/common/card-icon/card-icon.component';
import { LocationDetailsComponent } from './components/common/location-details/location-details.component';
import { DecodeHtmlString } from './pipes/html-string.pipe';
import { RAGDropdownComponent } from './components/common/rag-dropdown/rag-dropdown.component';
import { NotificationsService } from './services/notifications.service';
import { NotificationsCenterComponent } from './components/notifications/center/notifications-center.component';
import { QuickAddInputComponent } from './components/common/quick-add-input/quick-add-input.component';
import { HasWritePermissionPipe } from './pipes/has-write-permission.pipe';
import { CanCreatePipe } from './pipes/can-create.pipe';
import { CanDeletePipe } from './pipes/can-delete.pipe';
import { ReactiveErrorComponent } from './components/common/reactive-error/reactive-error.component';
import { ItemHistoryModalComponent } from './components/modals/item-history-modal/item-history-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AdminAccountGuard } from './guards/admin-account-guard';
import { AngularResizeEventModule } from 'angular-resize-event';
import { NgBlockScreenComponent } from './components/common/block-screen/block-screen.component';
import { SimpleDropdownComponent } from './components/common/simple-dropdown/simple-dropdown.component';
import { PercentageOfCompletionComponent } from './components/common/percentage-of-completion/percentage-of-completion';
import { WtTimepickerDirective } from './directives/wt-timepicker.directive';
import { WtTimepickerComponent } from './components/common/wt-timepicker/wt-timepicker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DetailsTimepickerComponent } from './components/common/details-timepicker/details-timepicker.component';
import { DashboardWeatherOWComponent } from './components/common/dashboard-v2/dashboard-widgets/dashboard-weather-ow/dashboard-weather-ow.component';
import { RatingModule } from 'ngx-bootstrap/rating';

import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { ChecklistGroupCardComponent } from './components/common/checklists/checklist-group-card/checklist-group-card.component';
import { SummaryBarComponent } from './components/common/summary-bar/summary-bar.component';
import { RangeTimeLozengeComponent } from './components/rebuild/range-time-lozenge/range-time-lozenge.component';
import { DocumentManagerGeneralSettingsComponent } from './components/documents/document-manager-general-settings/document-manager-general-settings.component';
import { TimeBoxFieldsComponent } from './components/common/time-box-fields/time-box-fields.component';

import { MyTrackGuard } from './guards/mytrack.guard';
import { SvgButtonComponent } from './components/common/buttons/svg-button/svg-button.component';
import { DashboardWidgetContentComponent } from './components/common/dashboard/dashboard-widget-content/dashboard-widget-content.component';
import { SustainabilityGuard } from './guards/sustainability.guard';
import { ButtonLoaderComponent } from './components/common/buttons/button-loader/button-loader.component';
import { IonicModule } from '@ionic/angular';
import { HeadlineStatusComponent } from './components/common/headline-status/headline-status.component';
import { SignalREventEmitters } from './events/signalr.events';
import { ZoneAreaToggleButtonComponent } from './components/common/maps/controls/zone-area-toggle-button/zone-area-toggle-button.component';
import { SearchFieldComponent } from './components/common/maps/controls/search-field/search-field.component';
import { MyLocationButtonComponent } from './components/common/maps/controls/my-location-button/my-location-button.component';
import { NoLocationButtonComponent } from './components/common/maps/controls/no-location-button/no-location-button.component';
import { W3wGridButtonComponent } from './components/common/maps/controls/w3w-grid-button/w3w-grid-button.component';
import { SkeletonCardLoadingComponent } from './components/common/skeleton-ui-loading/skeletion-card-loading/skeleton-card-loading.component';
import { SkeletonHistoryLoadingComponent } from './components/common/skeleton-ui-loading/skeleton-history-loading/skeleton-history-loading.component';
import { SkeletonDetailsLoadingComponent } from './components/common/skeleton-ui-loading/skeleton-details-loading/skeleton-details-loading.component';
import { SkeletonListHeaderLoadingComponent } from './components/common/skeleton-ui-loading/skeleton-list-header-loading/skeleton-list-header-loading.component';
import { MapSidebarComponent } from './components/common/maps/controls/map-sidebar/map-sidebar.component';
import { MapViewsGroupButtonComponent } from './components/common/maps/controls/map-views-group-button/map-views-group-button.component';
import { LeaveGuardService } from './guards/leave.guard';
import { TargetBadgeIconsComponent } from '../sustainability/components/trend-icons/target-badge-icons/target-badge-icons.component';
import { UpdateHeadlineStatusModalComponent } from './components/modals/headline-status/update-headline-status-modal.component';
import { GlobalAlertsBarComponent } from './components/global-alerts-bar/global-alerts-bar.component';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { HrComponent } from './components/common/hr/hr.component';
import { WellComponent } from './components/common/well/well.component';
import { BadgeComponent } from './components/common/badge/badge.component';
import { KanbanEventsEmitter } from './events/kanban.events';
import { OwnerAvatarComponent } from './components/common/owner-avatar/owner-avatar.component';
import { OwnersAvatarGroupComponent } from './components/common/owners-avatar-group/owners-avatar-group.component';
import { ComponentEventEmitter } from './events/component.events';
import { BackButtonComponent } from './components/common/back-button/back-button.component';
import { FixedFiltersComponent } from './components/filters-rebuild/fixed-filters/fixed-filters.component';
import { DetailsHeaderComponent } from './components/common/details-header/details-header.component';
import { TabContentDirective } from './directives/tabContent.directive';
import { TabsetOnDemandLoadingComponent } from './components/common/tabset-on-demand-loading/tabset-on-demand-loading.component';
import { IconComponent } from './components/common/icon/icon.component';
import { FiltersByTypePipe } from './pipes/filters-by-type.pipe';
import { FiltersTextPipe } from './pipes/filters-text-pipe';
import { DaysDiffBadgeComponent } from './components/common/days-diff-badge/days-diff-badge.component';
import { WtTranslationModule } from '../wt-translation/wt-translation.module';
import { JobStatusDropdownComponent } from './components/common/job-status-dropdown/job-status-dropdown.component';
import { ResizeDirective } from './directives/only-for-screen.directive';
import { FixedFilterLozengesComponent } from './components/filters-rebuild/fixed-filter-lozenges/fixed-filter-lozenges.component';
import { FixedFiltersIndicatorsComponent } from './components/filters-rebuild/fixed-filters-indicators/fixed-filters-indicators.component';
import { KeyPluralPipe } from './pipes/key-plural.pipe';
import { RichTextEditorV2Component } from './components/common/rich-text-editor-v2/rich-text-editor-v2.component';
import { AccordionDetailsComponent } from './components/common/accordion-details/accordion-details.component';
import { AccordionItemDetails } from './directives/accordion/accordion-item-details.directive';
import { AccordionContent } from './directives/accordion/accordion-content.directive';
import { AccordionHeader } from './directives/accordion/accordion-header.directive';
import { AccordionTitle } from './directives/accordion/accordion-title.directive';
import { CopyTextFieldComponent } from './components/common/copy-text-field/copy-text-field.component';
import { IncidentLogsService } from './services/incident-logs.service';
import { SimpleEditableFieldComponent } from './components/common/simple-editable-field/simple-editable-field.component';
import { EditableLogoComponent } from './components/common/editable-logo/editable-logo.component';
import { SplitPaneDetailsComponent } from './components/common/split-pane-details/split-pane-details.component';
import { DashboardWidgetContentComponentV2 } from './components/common/dashboard-v2/dashboard-widget-content/dashboard-widget-content.component';
import { VideoPreviewComponent } from './components/common/video-preview/video-preview.component';
import { DashboardContentComponent } from './components/common/dashboard-v2/dashboard-content/dashboard-content.component';
import { AttachmentCardComponent } from './components/documents/attachment-card/attachment-card.component';
import { ProfileImageComponent } from '../settings/components/common/components/profile-image/profile-image.component';
import { AttachmentsEventsEmitter } from './events/attachments.events';
import { DashboardWidgetConfigModalComponent } from './components/common/dashboard-widget-config-modal/dashboard-widget-config-modal.component';
import { WeatherWidgetDashboardSettingsComponent } from './components/common/dashboard-sidebar/weather-widget-dashboard-settings/weather-widget-dashboard-settings.component';
import { DashboardWeatherComponentNew } from './components/common/dashboard-v2/dashboard-widgets/dashboard-weather-new/dashboard-weather-new.component';
import { LinkedItemsAccordionListComponent } from './components/linked-items-accordion-list/linked-items-accordion-list.component';
import { DashboardLayoutOptionsComponent } from './components/common/dashboard-layout-options/dashboard-layout-options.component';
import { SaveDashboardViewComponent } from './components/modals/save-dashboard-view/save-dashboard-view.component';
import { DashboardLayoutService } from './services/dashboard-layout.service';
import { AvatarFilterSelectorComponent } from './components/common/avatar-filter-selector/avatar-filter-selector.component';
import { DetailsTextAreaComponent } from './components/common/details-text-area/details-text-area.component';
import { SubscribersAccordionComponent } from './components/subscribers-accordion/subscribers-accordion.component';
import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx';
import { TaskDetailsModalComponent } from './components/modals/task-details-modal/task-details-modal.component';
import { ComboBoxComponent } from './components/common/combo-box/combo-box.component';
import { ProjectDetailsModalComponent } from './components/modals/project-details-modal/project-details-modal.component';
import { SimpleFiltersContainerComponent } from './components/filters-rebuild/simple-filters-container/simple-filters-container.component';
import { DocumentManagerGeneralSettingsMobileComponent } from './components/documents/document-manager-general-settings-mobile/document-manager-general-settings-mobile.component';
import { DocumentManagerGeneralSettingsDesktopComponent } from './components/documents/document-manager-general-settings-desktop/document-manager-general-settings-desktop.component';
import { AttachmentsListHeaderComponent } from './components/documents/attachments-list-header/attachments-list-header.component';
import { AttachmentsBoardViewComponent } from './components/documents/attachments-board-view/attachments-board-view.component';
import { AttachmentsListViewComponent } from './components/documents/attachments-list-view/attachments-list-view.component';
import { DocumentCategoryAddModalComponent } from './components/documents/modals/document-category-add/document-category-add-modal.component';
import { DocumentManagerCoreComponent } from './components/documents/document-manager-core/document-manager-core.component';
import { DueIndicationBadgeComponent } from './components/common/due-indication-badge/due-indication-badge.component';
import { FilterSortingHeadersComponent } from './components/modals/filters-sorting/filter-sorting-headers/filter-sorting-headers.component';
import { DashboardWidgetModalComponent } from './components/common/dashboard-v2/dashboard-widget-modal/dashboard-widget-modal.component';
import { HubGuard } from './guards/hub.guard';
import { EmptyMapComponent } from './components/common/maps/empty-map/empty-map.component';
import { CancelDrawingPolygonButtonComponent } from './components/common/maps/controls/cancel-drawing-polygon-button/cancel-drawing-polygon-button.component';
import { RatingDropdownComponent } from './components/common/rating-dropdown/rating-dropdown.component';
import { TrafficLayerButtonComponent } from './components/common/maps/controls/traffic-layer-button/traffic-layer-button.component';
import { OrderPipe } from './pipes/order.pipe';
import { DropdownOptionComponent } from './components/dropdown/dropdown-option/dropdown-option.component';
import { FloatingDropdownComponent } from './components/dropdown/floating-dropdown/floating-dropdown.component';
import { ChipComponent } from './components/dropdown/chip/chip.component';
import { MapsLoaderService } from './services/mapsLoader.service';
import { SideFiltersComponent } from './components/filters/side-filters/side-filters.component';
import { ExpandableFilterSelectorComponent } from './components/filters/expandable-filter-selector/expandable-filter-selector.component';
import { DateExpandableFilterSelectorComponent } from './components/filters/date-expandable-filter-selector/date-expandable-filter-selector.component';
import { TagExpandableFilterSelectorComponent } from './components/filters/tag-expandable-filter-selector/tag-expandable-filter-selector.component';
import { WtButtonComponent } from './components/common/buttons/wt-button/wt-button.component';
import { QuickFiltersComponent } from './components/filters/quick-filters/quick-filters.component';
import { DashboardCustomiseLayoutComponent } from './components/common/dashboard-customise-layout/dashboard-customise-layout.component';
import { HeadlineStatusCardIconComponent } from './components/common/headline-status-card-icon/headline-status-card-icon.component';
import { UpdateDescriptionModalComponent } from './components/modals/description/update-description-status-modal.component';
import { InitialsAvatarComponent } from './components/common/initials-avatar/initials-avatar.component';
import { UpdateTimeModalComponent } from './components/modals/edit-time/edit-time-modal.component';
import { RiskRatingDropdownComponent } from './components/common/risk-rating-dropdown/risk-rating-dropdown.component';
import { PriorityComponent } from './components/common/rating/priority.component';
import { GanttLayoutOptionsComponent } from './components/common/gantt-layout-options/gantt-layout-options.component';
import { GanttLayoutsViewSettingsModalComponent } from './components/modals/gantt-layouts-view-settings-modal/gantt-layouts-view-settings-modal.component';
import { GanttLayoutViewSaveModalComponent } from './components/modals/gantt-layout-view-save-modal/gantt-layout-view-save-modal.component';
import { TopListHeaderComponent } from './components/common/top-list-header/top-list-header.component';
import { SelectComponent } from './components/common/select/select.component';
import { BreadcrumbComponent } from './components/common/breadcrumb/breadcrumb.component';
import { SortingDropdownComponent } from './components/common/sorting-dropdown/sorting-dropdown.component';
import { ConvertHTMLEntitiesPipe } from './pipes/convert-HTML-entities.pipe';
import { TextTruncatePipe } from './pipes/text-truncate.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { UNSDGIconComponent } from './components/common/unsdg-icon/unsdg-icon.component';
import { CardIndicatorUpdateComponent } from './components/cards/card-indicator-update/card-indicator-update.component';
import { CardSustainabilityAccountIndicatorTotalComponent } from './components/cards/card-sus-account-indicator-total/card-sus-account-indicator-total.component';
import { CardSustainabilityDashboardComponent } from './components/cards/card-sustainability-dashboard/card-sustainability-dashboard.component';
import { ChatComponent } from './components/common/chat/chat.component';
import { BulkArchiveComponent } from './components/common/bulk-archive/bulk-archive.component';
import { IncidentDetailsModalComponent } from './components/modals/incident-details-modal/incident-details-modal.component';
import { SeverityPickerComponent } from '../incidents/components/common/severity-picker/severity-picker.component';
import { SeveritySelectorComponent } from '../incidents/components/common/severity-selector/severity-selector.component';

const SHARED_COMPONENTS = [
  GanttLayoutViewSaveModalComponent,
  GanttLayoutsViewSettingsModalComponent,
  GanttLayoutOptionsComponent,
  TrafficLayerButtonComponent,
  CancelDrawingPolygonButtonComponent,
  EmptyMapComponent,
  ComboBoxComponent,
  TaskDetailsModalComponent,
  ProjectDetailsModalComponent,
  WeatherWidgetDashboardSettingsComponent,
  DashboardWidgetConfigModalComponent,
  DashboardContentComponent,
  DashboardWidgetContentComponentV2,
  EditableLogoComponent,
  RichTextEditorV2Component,
  ConfirmationModalComponent,
  ChooseDependencyModalComponent,
  InformationModalComponent,
  DocumentDetailsModalComponent,
  VideoModalComponent,
  ImageCropperModalComponent,
  AddUpdateModal,
  AddExternalUserModal,
  EmployeeFilterSettingAddModalComponent,
  DashboardCountdownComponent,
  AddWidgetLocationModal,
  ChecklistItemsAddModal,
  ChecklistItemAddModalComponent,
  NativeDatepickerComponent,
  NativeTimepickerComponent,
  BubbleComponent,
  CheckboxComponent,
  DropdownContentComponent,
  DropdownTextItemComponent,
  HeliumBubbleComponent,
  InformationBubbleComponent,
  LoadingComponent,
  IncidentMapComponent,
  LocationMapv2Component,
  HistoryFeedNewComponent,
  DocumentUploadComponent,
  ImagePreviewComponent,
  VideoPreviewComponent,
  DashboardComponent,
  ListSearchInputComponent,
  IncidentQuickAddComponent,
  DashboardSidebarComponent,
  DashboardWidgetOptionsComponent,
  VenueDashboardSettingsComponent,
  InfoWidgetDashboardSettingsComponent,
  CountdownDashboardSettingsComponent,
  DetailsMapComponent,
  ChecklistItemCardComponent,
  ChecklistItemsListComponent,
  DashboardWidgetConfigureComponent,
  ButtonComponent,
  CheckboxDropdownComponent,
  SelectDropdownComponent,
  DocumentCardComponent,
  NotFoundComponent,
  PaginatedCardsComponent,
  PopupComponent,
  RadioButtonComponent,
  SearchInputComponent,
  SettingsAccordionComponent,
  SettingsAccordionGroupComponent,
  SplitPaneComponent,
  SplitPaneDetailsComponent,
  StatusPickerComponent,
  ThreadedBeadsComponent,
  TooltipComponent,
  ZoneMapComponent,
  SubscribersSectionComponent,
  BackButtonComponent,
  SearchBarComponent,
  FloatingSidebarComponent,
  ListDetailsSwitchPaneComponent,
  ToggleSwitchComponent,
  DetailsListViewComponent,
  DashboardWeatherComponentNew,
  DashboardWeatherOWComponent,
  DashboardCountdownComponent,
  NoResultsComponent,
  OptionalDateAndTimeComponent,
  SortButtonPopupComponent,
  ChecklistsDetailsSectionComponent,
  ChecklistTemplateListComponent,
  ModalComponent,
  ModalHeaderComponent,
  ModalBodyComponent,
  ModalFooterComponent,
  FixedEventDropdownSelectModal,
  FixedZoneDropdownSelectModalComponent,
  SelectAccountComponent,
  ChecklistTemplateListComponent,
  FilterLozengeComponent,
  FiltersComponent,
  FiltersSimpleComponent,
  FiltersLozengeContainerComponent,
  FiltersFavoriteComponent,
  FiltersAddComponent,
  FiltersInlineEditContainerComponent,
  FiltersColorComponent,
  FiltersMobileHeaderComponent,
  FiltersMobileFooterComponent,
  FiltersDesktopFooterComponent,
  FiltersListEntryComponent,
  FiltersListEntriesComponent,
  FiltersIncludeCommentComponent,
  FiltersExcludeSelectedComponent,
  FiltersSearchInputComponent,
  FiltersEntriesInformationComponent,
  FiltersInlineEditLozengeComponent,
  FiltersInlineEditLozengesComponent,
  FiltersMultiselectComponent,
  FiltersLiveEventEntryComponent,
  FixedFiltersComponent,
  FiltersDateComponent,
  ButtonTransparentComponent,
  ButtonIconCircleComponent,
  ButtonIconSquareComponent,
  ButtonRectangleComponent,
  ButtonBarRoundedComponent,
  CheckboxWithLabelComponent,
  HyperlinkComponent,
  EmployeeAvatarComponent,
  ModalConfirmCreateDependComponent,
  ModalConfirmDeleteComponent,
  ModalConfirmUpdateComponent,
  EmployeeAvatarComponent,
  ModalConfirmationSaveComponent,
  ExpandCollapseButtonComponent,
  EmployeeAvatarComponent,
  ResponsiveTableComponent,
  PaginationComponent,
  ToggleButtonComponent,
  NgDatepickerComponent,
  NgDropdownComponent,
  NgImagePreviewComponent,
  TabComponent,
  TabsetComponent,
  TextAreaComponent,
  TextFieldComponent,
  ValidationErrorComponent,
  CardLozengeComponent,
  SimpleRAGBreakdownBarComponent,
  RAGBreakdownBarComponent,
  SideNavbarComponent,
  EmptyStatePlaceholderComponent,
  CardLozengesListComponent,
  HeaderBarComponent,
  RichTextEditorComponent,
  DetailsHeaderComponent,
  DetailsLozengeComponent,
  DetailsEditableFieldComponent,
  ListSearchInputFixedComponent,
  ListHeaderComponent,
  DetailsDropdownComponent,
  FilterSortingModalComponent,
  ButtonDropdownComponent,
  FilterDropdownNewComponent,
  ShareItemModal,
  ItemHistoryModalComponent,
  SearchDropdownComponent,
  DetailsIconFilterComponent,
  ButtonSortRectangleNewComponent,
  FiltersSingleselectComponent,
  DateSliderComponent,
  DetailsDatepickerComponent,
  DetailsIconComponent,
  SidePaneHeaderComponent,
  NgRangeDatepickerComponent,
  RangeDatepickerDirective,
  SimpleCardComponent,
  LinkedItemsListComponent,
  LinkedItemsAddModalComponent,
  LinkedItemsDetailsListComponent,
  DetailsTagsComponent,
  UploadContainerComponent,
  AddModalTagGroupsComponent,
  FilterTagComponent,
  ButtonDropdownSimpleComponent,
  CardHistoryFeedComponent,
  CardIconComponent,
  LocationDetailsComponent,
  RAGDropdownComponent,
  NotificationsCenterComponent,
  QuickAddInputComponent,
  ReactiveErrorComponent,
  NgBlockScreenComponent,
  SimpleDropdownComponent,
  PercentageOfCompletionComponent,
  WtTimepickerComponent,
  DetailsTimepickerComponent,
  SummaryBarComponent,
  ChecklistGroupCardComponent,
  RangeTimeLozengeComponent,
  SummaryBarComponent,
  ChecklistGroupCardComponent,
  DocumentManagerGeneralSettingsComponent,
  DocumentManagerGeneralSettingsMobileComponent,
  DocumentManagerGeneralSettingsDesktopComponent,
  TimeBoxFieldsComponent,
  SvgButtonComponent,
  DashboardWidgetContentComponent,
  ButtonLoaderComponent,
  HeadlineStatusComponent,
  ZoneAreaToggleButtonComponent,
  SearchFieldComponent,
  MyLocationButtonComponent,
  NoLocationButtonComponent,
  SkeletonCardLoadingComponent,
  SkeletonDetailsLoadingComponent,
  SkeletonListHeaderLoadingComponent,
  SkeletonHistoryLoadingComponent,
  W3wGridButtonComponent,
  MapSidebarComponent,
  TargetBadgeIconsComponent,
  UpdateHeadlineStatusModalComponent,
  GlobalAlertsBarComponent,
  HrComponent,
  WellComponent,
  BadgeComponent,
  MapViewsGroupButtonComponent,
  OwnerAvatarComponent,
  OwnersAvatarGroupComponent,
  TabsetOnDemandLoadingComponent,
  IconComponent,
  DaysDiffBadgeComponent,
  JobStatusDropdownComponent,
  FixedFilterLozengesComponent,
  FixedFiltersIndicatorsComponent,
  AccordionDetailsComponent,
  CopyTextFieldComponent,
  SimpleEditableFieldComponent,
  AttachmentCardComponent,
  AttachmentsListHeaderComponent,
  AttachmentsBoardViewComponent,
  AttachmentsListViewComponent,
  ProfileImageComponent,
  LinkedItemsAccordionListComponent,
  DashboardLayoutOptionsComponent,
  SaveDashboardViewComponent,
  DetailsTextAreaComponent,
  SubscribersAccordionComponent,
  SimpleFiltersContainerComponent,
  DocumentCategoryAddModalComponent,
  DocumentManagerCoreComponent,
  FilterSortingHeadersComponent,
  DashboardWidgetModalComponent,
  RatingDropdownComponent,
  DropdownOptionComponent,
  FloatingDropdownComponent,
  ChipComponent,
  SideFiltersComponent,
  ExpandableFilterSelectorComponent,
  DateExpandableFilterSelectorComponent,
  TagExpandableFilterSelectorComponent,
  WtButtonComponent,
  QuickFiltersComponent,
  DashboardCustomiseLayoutComponent,
  HeadlineStatusCardIconComponent,
  UpdateDescriptionModalComponent,
  InitialsAvatarComponent,
  UpdateTimeModalComponent,
  RiskRatingDropdownComponent,
  PriorityComponent,
  TopListHeaderComponent,
  SelectComponent,
  BreadcrumbComponent,
  SortingDropdownComponent,
  UNSDGIconComponent,
  CardIndicatorUpdateComponent,
  CardSustainabilityAccountIndicatorTotalComponent,
  CardSustainabilityDashboardComponent,
  ChatComponent,
  BulkArchiveComponent,
  SeverityPickerComponent,
  SeveritySelectorComponent,
  IncidentDetailsModalComponent
];

const SHARED_DIRECTIVES = [
  DragDropDirective,
  DropdownDirective,
  InputTrimDirective,
  LocalisationDirective,
  PopupDirective,
  RouteTransformDirective,
  ResizeDirective,
  TooltipDirective,
  HrefOutDirective,
  ResizableDirective,
  AutofocusDirective,
  NgBlockScreenDirective,
  DateMaskDirective,
  NgDatepickerDirective,
  NgDropdownDirective,
  NgImagePreviewDirective,
  InputValidationDirective,
  TabHeadingDirective,
  TabContentDirective,
  WtTimepickerDirective,
  AccordionDetailsComponent,
  AccordionItemDetails,
  AccordionContent,
  AccordionTitle,
  AccordionHeader,
  AvatarFilterSelectorComponent,
  DueIndicationBadgeComponent
];

const SHARED_SERVICES = [
  MapsLoaderService,
  ActivityService,
  AlertService,
  CachingService,
  AuthenticationService,
  ConfirmationService,
  CommentsService,
  ConfigurationService,
  ContinousService,
  DependentTasksService,
  DocumentCategoryService,
  DocumentService,
  GanttService,
  GeoLocationService,
  WeatherDataService,
  LocalisationService,
  NotificationService,
  SubscriptionService,
  TimeZoneService,
  UrlService,
  UtilitiesService,
  LocationService,
  DynamicComponentsService,
  AccountSettingsService,
  IncidentChannelsService,
  EmployeeSettingsService,
  IncidentsSettingsService,
  IncidentsService,
  IncidentLogsService,
  SlackService,
  ModuleService,
  ChecklistTemplateService,
  ChecklistItemTemplateService,
  ChecklistItemService,
  EventsService,
  NgxImageCompressService,
  CheckpointTypeService,
  CheckTemplateService,
  CheckpointTemplateService,
  PEAPTemplateService,
  PEAPService,
  PEAPGroupService,
  CheckService,
  PEAPDashboardService,
  SortingService,
  ListFilteringService,
  ObjectTagService,
  NotificationsService,
  DashboardLayoutService,
];

// EVENT EMITTERS
const SHARED_EVENT_EMITTERS = [
  BsModalEventsEmitter,
  CommentEventsEmitter,
  DropdownEventsEmitter,
  GanttEventsEmitter,
  KanbanEventsEmitter,
  LocationManagerEventEmitter,
  HeaderEventsEmitter,
  LoginEventsEmitter,
  PopupEventsEmitter,
  SidebarEventsEmitter,
  TooltipEventsEmitter,
  WindowEventsEmitter,
  IncidentsEventsEmitter,
  FloatingSidebarEventEmitters,
  DashboardEventEmitters,
  ObjectEventEmitters,
  SignalREventEmitters,
  ComponentEventEmitter,
  AttachmentsEventsEmitter
];

const SHARED_MANAGERS = [AuthenticationManager, CommentsManager, IncidentsManager, PaginationManager];

// Pipes

const SHARED_PIPES = [
  DisplayTaskForAgendaPipe,
  DisplayTaskForDailyCellPipe,
  FilterFilterTypes,
  FiltersByTypePipe,
  FiltersTextPipe,
  LastUpdatedDatePipe,
  LocalisedDatePipe,
  LocalisedTimePipe,
  LocalisedWeekdayPipe,
  NumberPaddingPipe,
  ReplaceUnderscoresPipe,
  SafeHtmlPipe,
  SafeStylePipe,
  SafeUrlPipe,
  ImageCropperModalComponent,
  SubscribersSectionComponent,
  LocaliseTextPipe,
  DateToISOPipe,
  LocaliseFilterTypetPipe,
  LocaliseObjectTypePipe,
  ConvertNumPipe,
  DecodeHtmlString,
  HasWritePermissionPipe,
  CanCreatePipe,
  CanDeletePipe,
  DateDiffPipe,
  KeyPluralPipe,
  OrderPipe,
  ConvertHTMLEntitiesPipe,
  TextTruncatePipe,
  TimeAgoPipe
];

const SHARED_GUARDS = [
  IMSGuard,
  RunsheetsGuard,
  AdminGuard,
  PEAPsGuard,
  AdminAccountGuard,
  MyTrackGuard,
  SustainabilityGuard,
  LeaveGuardService,
  HubGuard
];

const SHARED_PROVIDERS = [modalConfigProviders, Smartlook];

const MODULES = [
  CommonModule,
  DataTableModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  ImageCropperModule,
  CalendarModule,
  BsDatepickerModule,
  BsDropdownModule,
  TimepickerModule,
  TabsModule,
  ModalModule,
  ImageCropperModule,
  GridsterModule,
  DynamicModule,
  ScrollingModule,
  DragulaModule,
  SwiperModule,
  QuillModule,
  AngularResizeEventModule,
  NgxMaterialTimepickerModule,
  IonicModule,
  RatingModule,
  WtTranslationModule,
  DragDropModule
];

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

const MODULES_WITH_PROVIDERS = [
  CalendarModule.forRoot(
    {
      provide: DateAdapter,
      useFactory: momentAdapterFactory,
    },
    {
      dateFormatter: {
        provide: CalendarDateFormatter,
        useClass: CalendarMomentDateFormatter,
      },
    }
  ),
  BsDatepickerModule.forRoot(),
  BsDropdownModule.forRoot(),
  TimepickerModule.forRoot(),
  TabsModule.forRoot(),
  ModalModule.forRoot(),

  DragulaModule.forRoot(),
  QuillModule.forRoot(),
];

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  imports: [...MODULES_WITH_PROVIDERS, ...MODULES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  exports: [...MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...MODULES,
        ...SHARED_SERVICES,
        ...SHARED_MANAGERS,
        ...SHARED_EVENT_EMITTERS,
        ...SHARED_PIPES,
        ...SHARED_GUARDS,
        ...SHARED_PROVIDERS,
        {
          provide: SWIPER_CONFIG,
          useValue: DEFAULT_SWIPER_CONFIG,
        },
        {
          provide: MOMENT,
          useValue: moment,
        },
      ],
    };
  }
}
