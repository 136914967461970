import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ModifiableEntityViewModel } from '../../../incidents/viewModels/modifiableEntityViewModel';
import { StateService } from '../../../planning/services/state.service';
import { FilterTypes } from '../../../shared/enums/filterTypes';
import { ObjectTypes } from '../../../shared/enums/objectTypes';
import { IApiService } from '../../../shared/interfaces/iHttpService';
import { FilterViewModel } from '../../../shared/models/filter/filterViewModel';
import { LightFilterUpdateModel } from '../../../shared/models/filter/LightFilterUpdateModel';
import { ValidatedViewModel } from '../../../shared/viewModels/validatedViewModel';
import { IndicatorUpdateListOptions } from '../../../sustainability/enums/indicatorUpdateListOptions';
import { IndicatorTemplateOrganisationPerformanceTrackerViewModel } from '../../viewModels/sustainability/indicator-templates/indicatorTemplateOrganisationPerformanceTrackerViewModel';
import { IndicatorTemplatePerformanceSummaryViewModel } from '../../viewModels/sustainability/indicator-templates/indicatorTemplatePerformanceSummaryViewModel';
import { IndicatorTemplateUpdatesSummaryViewModel } from '../../viewModels/sustainability/indicator-templates/indicatorTemplateUpdatesSummaryViewModel';
import { IndicatorTemplateFrameworkModalViewModel } from '../../viewModels/sustainability/indicator-templates/indicatorTemplateFrameworkModalViewModel';
import { IndicatorTemplateListViewModel } from '../../viewModels/sustainability/indicator-templates/indicatorTemplateListViewModel';
import { IndicatorTemplateSimpleViewModel } from '../../viewModels/sustainability/indicator-templates/indicatorTemplateSimpleViewModel';
import { IndicatorTemplateViewModel } from '../../viewModels/sustainability/indicator-templates/indicatorTemplateViewModel';
import { IndicatorUpdateCardViewModel } from '../../../sustainability/viewModels/indicatorUpdateCardViewModel';
import { IndicatorViewModel } from '../../../sustainability/viewModels/indicatorViewModel';
import { IndicatorTemplateAccountListViewModel } from '../../viewModels/sustainability/indicator-templates/indicatorTemplateAccountListViewModel';
import { IndicatorTemplateAccountMapViewModel } from '../../viewModels/sustainability/indicator-templates/indicatorTemplateAccountMapViewModel';

@Injectable({
  providedIn: 'root',
})
export class IndicatorTemplateService extends StateService<IndicatorTemplateListViewModel> implements IApiService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    super();
    this.actionUrl = this.configuration.buildEndpoint(`Sustainability/IndicatorTemplate/`);
  }

  //#region  Account Hub main list methods

  list(filters: FilterViewModel[]): Observable<IndicatorTemplateListViewModel[]> {
    return this.http.post<IndicatorTemplateListViewModel[]>(this.actionUrl + 'List', filters).pipe(
      map((x) => {
        {
          if (!filters.length) this.updateList(x);
          return x;
        }
      })
    );
  }

  //#endregion

  //#region   details page/summary methods

  add(indicatorTemplate: IndicatorTemplateViewModel) {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}Add`, indicatorTemplate);
  }

  update(indicatorTemplate: IndicatorTemplateViewModel, filterType: FilterTypes) {
    return this.http.post<IndicatorTemplateViewModel>(`${this.actionUrl}Update/${filterType}`, indicatorTemplate);
  }

  delete(indicatorTemplateId: number): Observable<void> {
    return this.http.post<void>(`${this.actionUrl}Delete/${indicatorTemplateId}`, null);
  }

  stopTracking(indicatorTemplateId: number): Observable<void> {
    return this.http.post<void>(`${this.actionUrl}StopTracking/${indicatorTemplateId}`, null);
  }

  simpleList(): Observable<IndicatorTemplateSimpleViewModel[]> {
    return this.http.get<IndicatorTemplateSimpleViewModel[]>(`${this.actionUrl}SimpleList`);
  }

  details(indicatorTemplateId: number): Observable<IndicatorTemplateViewModel> {
    return this.http.get<IndicatorTemplateViewModel>(`${this.actionUrl}Details/${indicatorTemplateId}`);
  }

  extendTrackingDuration(indicatorTemplateId: number, extendTrackingDurationModel: IndicatorTemplateViewModel) {
    return this.http.post<IndicatorTemplateViewModel>(
      `${this.actionUrl}ExtendTrackingDuration/${indicatorTemplateId}`,
      extendTrackingDurationModel
    );
  }

  // Summary page methods

  getPerformanceSummary(indicatorTemplateId: number): Observable<IndicatorTemplatePerformanceSummaryViewModel> {
    return this.http.get<IndicatorTemplatePerformanceSummaryViewModel>(
      `${this.actionUrl}PerformanceSummary/${indicatorTemplateId}`
    );
  }

  getUpdatesSummary(indicatorTemplateId: number): Observable<IndicatorTemplateUpdatesSummaryViewModel> {
    return this.http.get<IndicatorTemplateUpdatesSummaryViewModel>(`${this.actionUrl}UpdatesSummary/${indicatorTemplateId}`);
  }

  getIndicatorUpdates(
    indicatorTemplateId: number,
    listOption: IndicatorUpdateListOptions
  ): Observable<IndicatorUpdateCardViewModel[]> {
    return this.http.get<IndicatorUpdateCardViewModel[]>(
      `${this.actionUrl}IndicatorUpdates/${indicatorTemplateId}/${listOption}`
    );
  }

  getAccountPerformanceTracker(
    indicatorTemplateId: number
  ): Observable<IndicatorTemplateOrganisationPerformanceTrackerViewModel> {
    return this.http.get<IndicatorTemplateOrganisationPerformanceTrackerViewModel>(
      `${this.actionUrl}AccountPerformanceTracker/${indicatorTemplateId}`
    );
  }

  getAccountList(indicatorTemplateId: number): Observable<IndicatorTemplateAccountListViewModel[]> {
    return this.http.get<IndicatorTemplateAccountListViewModel[]>(
      `${this.actionUrl}AccountList/${indicatorTemplateId}`
    );
  }

  getAccountMapView(indicatorTemplateId: number): Observable<IndicatorTemplateAccountMapViewModel[]> {
    return this.http.get<IndicatorTemplateAccountMapViewModel[]>(
      `${this.actionUrl}AccountMapView/${indicatorTemplateId}`
    );
  }

    //#endregion


    //#region  obsolete

    getFrameworkModalList(): Observable<IndicatorTemplateFrameworkModalViewModel[]> {
      return this.http.get<IndicatorTemplateFrameworkModalViewModel[]>(`${this.actionUrl}FrameworkModalList`);
    }

    //#endregion





  updateLight(item: LightFilterUpdateModel) {
    throw new Error('Method not implemented.');
  }

  pinnedList(
    objectType: ObjectTypes,
    filters: FilterViewModel[],
    showPinnedOnly?: boolean
  ): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }

  updateLozenges(indicator: IndicatorViewModel): Observable<any> {
    return of('Must add logic in the BE');
  }

  archive(organisationId: number, archive: boolean) {
    return of('Must add logic in the BE');
  }
}
