<div (click)="navigateToDetailsPage()" class="card-container">
  <div class="card-inner">
    <div class="card-top">
      <div class="card-top-left">
        <i class="material-icons" *ngIf="isLogSubType">receipt</i>
        <!-- TODO - Create SVG icon for Logs? -->

        <app-details-icon
          *ngIf="!isLogSubType"
          [size]="24"
          [backgroundFill]="getObjectRAGStatusColor()"
          [type]="getCardIconType()"
          [ragStatus]="ragStatus"
        ></app-details-icon>

        <span class="title" [innerHTML]="title"></span>
      </div>

      <!-- Hidden until phase 2
        <div class="card-top-right" style="display: none;"> 
            <i class="material-icons">more_horiz</i>
        </div> -->
    </div>
  </div>
</div>
