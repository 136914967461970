import { IEnvironment } from "src/app/modules/shared/models/interfaces/environment.interface";
import * as baseEnv from 'src/environments/enviroment.base';

export const environment: IEnvironment = {
  ...baseEnv.environment,
  ... {
    API_URL: 'https://api-usuat.wetrack.com',
    Issuer: 'https://auth-usuat.wetrack.com',
    DOMAIN: 'usuat.wetrack.com',
    GANTT_EXPORT_URL: 'https://ganttexport-us.wetrack.com/gantt',
    DHX_GRID_EXPORT_URL: 'https://gridexport-us.wetrack.com/excel',
    CLUSTER_NAME:'WT-AMER'
  }
};
