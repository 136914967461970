<ng-container *ngIf="!useSmallContainer">
  <div class="wrapper">
    <div class="header">
      <div class="header-left">
        <div class="flex-wrapper">
          <div class="responsive-flex-wrapper">
            <i class="material-icons">record_voice_over</i>
          </div>
          <span>{{ T.defaultLocalizations.headline_status.one | translate }}</span>
        </div>
      </div>
      <div class="header-right" *ngIf="headlineStatus || editState">
        <div class="flex-wrapper">
          <span *ngIf="!editState && !isReadOnly" class="d-none d-sm-block">{{
            T.common.headline_status_message_last_updated
              | translate: { lastUpdated: object.headlineStatusUpdated | lastUpdatedDate }
          }}</span>
          <div class="responsive-flex-wrapper">
            <i class="material-icons">edit</i>
          </div>
          <span *ngIf="!editState && !isReadOnly" class="link" (click)="onMakeEditable()">{{
            T.common.update | translate
          }}</span>
          <span *ngIf="editState" style="cursor: pointer; margin-right: 8px" (click)="onCancel()">{{
            T.common.cancel | translate
          }}</span>
          <span *ngIf="editState" class="link" (click)="onSave()">{{ T.common.save | translate }}</span>
        </div>
      </div>
    </div>
    <div class="body" (click)="onMakeEditable()">
      <span *ngIf="headlineStatus && !editState">{{ headlineStatus }}</span>
      <ng-container *ngIf="!headlineStatus && !editState">
        <div *ngIf="!headlineStatus" class="add-item-wrapper">
          <div class="add-item-heading-wrapper">
            <i class="material-icons">add_circle</i>
            <span class="add-item-heading">{{ T.defaultLocalizations.headline_status.none | translate }}</span>
          </div>
          <div *ngIf="!isReadOnly" class="add-item-link-wrapper">
            <a class="add-item-link" (click)="onMakeEditable()">{{
              T.common.let_people_know_what_is_happening | translate
            }}</a>
          </div>
        </div>
      </ng-container>

      <app-text-field
        *ngIf="editState"
        [(ngModel)]="headlineStatus"
        [placeholder]="(T.defaultLocalizations.headline_status.one | translate) + '*'"
        [maxLength]="255"
        [minLength]="3"
        [required]="true"
        [autofocus]="true"
      >
      </app-text-field>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="useSmallContainer">
  <ng-container *ngIf="!isMobile">
    <div class="small-container-wrapper" [class.edit-state-container]="editState" (click)="onMakeEditable()">
      <div class="headline-container-wrapper" [class.edit-state]="editState">
        <span class="material-icons headline-status-icon">record_voice_over</span>
        <span class="headline-status-text" *ngIf="!editState" [class.grey-dark-text]="!headlineStatus">{{
          headlineStatus ? headlineStatus : (T.common.click_here_to_update_headline_status | translate)
        }}</span>
        <ng-container *ngIf="editState">
          <input
            #headlineInput
            [class.input-edit-state]="editState"
            [type]="'text'"
            [(ngModel)]="headlineStatus"
            autocomplete="off"
            [placeholder]="T.common.click_here_to_update_headline_status | translate"
            [required]="false"
            [minlength]="3"
            [maxlength]="255"
            inputValidation
          />
        </ng-container>
        <ng-container *ngIf="!editState && object.headlineStatusUpdated">
          <span class="last-updated-wrapper">
            {{
              T.common.headline_status_message_last_updated
                | translate: { lastUpdated: object.headlineStatusUpdated | lastUpdatedDate }
            }}
          </span>
        </ng-container>
        <ng-container *ngIf="!editState">
          <span class="update-button-wrapper">
            <span class="update-button">
              <span class="material-icons update-status-icon">edit</span
              ><span class="update-status-text">{{ T.common.update | translate }}</span>
            </span>
          </span>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <div class="small-container-wrapper mobile" [class.edit-state-container]="editState" (click)="onMakeEditable()">
      <div class="headline-container-wrapper" [class.edit-state]="editState">
        <div class="first-row">
          <span class="material-icons headline-status-icon">record_voice_over</span>
          <span class="headline-status-text" *ngIf="!editState" [class.grey-dark-text]="!headlineStatus">{{
            headlineStatus ? headlineStatus : (T.common.click_here_to_update_headline_status | translate)
          }}</span>
          <ng-container *ngIf="!editState">
            <span class="update-button-wrapper">
              <span class="update-button">
                <span class="material-icons update-status-icon">edit</span
                ><span class="update-status-text">{{ T.common.update | translate }}</span>
              </span>
            </span>
          </ng-container>
        </div>
        <div class="second-row">
          <ng-container *ngIf="editState">
            <input
              #headlineInput
              [class.input-edit-state]="editState"
              [type]="'text'"
              [(ngModel)]="headlineStatus"
              autocomplete="off"
              [placeholder]="T.common.click_here_to_update_headline_status | translate"
              [required]="false"
              [minlength]="3"
              [maxlength]="255"
              inputValidation
            />
          </ng-container>
          <ng-container *ngIf="!editState && object.headlineStatusUpdated">
            <span class="last-updated-wrapper">
              {{
                T.common.headline_status_message_last_updated
                  | translate: { lastUpdated: object.headlineStatusUpdated | lastUpdatedDate }
              }}
            </span>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
