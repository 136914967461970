import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { ChecklistTemplateViewModel } from '../models/checklists/checklistTemplateViewModel';
import { Employee } from '../models/employee';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { ValidatedViewModel } from '../viewModels/validatedViewModel';

@Injectable()
export class ChecklistTemplateService {
  private actionUrl: string = '';
  private employee: Employee;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.actionUrl = this.configuration.buildEndpoint(`ChecklistTemplate/`);
  }

  add(chechlistTemplateViewModel: ChecklistTemplateViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(
      this.actionUrl + `Add`,
      chechlistTemplateViewModel,
      this.authenticationService.getHttpOptions()
    );
  }

  update(chechlistTemplateViewModel: ChecklistTemplateViewModel): Observable<ChecklistTemplateViewModel> {
    return this.http.put<ChecklistTemplateViewModel>(
      this.actionUrl + `Update`,
      chechlistTemplateViewModel,
      this.authenticationService.getHttpOptions()
    );
  }

  delete(checklistTemplateId: number): Observable<ChecklistTemplateViewModel> {
    return this.http.delete<ChecklistTemplateViewModel>(
      this.actionUrl + `Delete/${checklistTemplateId}`,
      this.authenticationService.getHttpOptions()
    );
  }

  getList(includeEmpty: boolean = true): Observable<ChecklistTemplateViewModel[]> {
    return this.http.get<ChecklistTemplateViewModel[]>(
      this.actionUrl + `GetList/${includeEmpty}`,
      this.authenticationService.getHttpOptions()
    );
  }

  filterViewModelsByChecklists(filters: FilterViewModel[]): Observable<FilterViewModel[]> {
    return this.http.post<FilterViewModel[]>(
      this.actionUrl + `FilterModelsByChecklists`,
      filters,
      this.authenticationService.getHttpOptions()
    );
  }
}
