<!--
<ng-container *ngIf="isMobileDevice">
  <div class="row">
    <div class="col-sm-12 dashboard-mobile-container" *ngFor="let item of dashboardComponents; let i = index;">
        <ndc-dynamic class="dashboard-component" [ndcDynamicComponent]="getWidgetComponent(i)"></ndc-dynamic>
    </div>
  </div>
</ng-container>
-->

<ng-container>
  <gridster #gridster class="custom-scrollbar" style="background: transparent !important" [options]="options">
    <gridster-item [item]="item" *ngFor="let item of dashboard; let i = index">
      <ndc-dynamic class="dashboard-component" [ndcDynamicComponent]="getWidgetComponent(i)"></ndc-dynamic>
    </gridster-item>
  </gridster>
</ng-container>
