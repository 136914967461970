import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterTypes } from '../../../enums/filterTypes';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { RiskLikelihoods } from '../../../enums/risks/riskLikelihoods';
import { RiskImpact } from 'src/app/modules/risk/enums/riskImpact';
import { RiskRAGMatrixModel } from '../../../viewModels/planning/riskRAGMatrix';
import { Subscription } from 'rxjs';
import { RiskRAGMatrixService } from 'src/app/modules/shared/services/risk-rag-matrix.service';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AccountSettingsService } from '../../../services/account-settings.service';
import { EnumBasedLocalisationViewModel } from '../../../viewModels/enumBasedLocalisationViewModel';

@Component({
  selector: 'app-risk-rating-dropdown',
  templateUrl: './risk-rating-dropdown.component.html',
  styleUrls: ['./risk-rating-dropdown.component.scss']
})
export class RiskRatingDropdownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() filters: FilterViewModel[] = [];
  @Output() likelihoodAndImpactUpdated = new EventEmitter<FilterViewModel[]>();

  public readonly filterTypes = FilterTypes;
  public readonly riskLikelihoods = RiskLikelihoods;
  public readonly T = T;
  public likelihoods = EnumUtilities.items(RiskLikelihoods);
  public impacts = EnumUtilities.items(RiskImpact);
  public riskRAGMatrix: RiskRAGMatrixModel[] = [];
  public currentlySelectedLikelihood: RiskLikelihoods;
  public currentlySelectedImpact: RiskImpact;
  private allowedLikelihoodFilters: FilterViewModel[] = [];
  private allowedImpactFilters: FilterViewModel[] = [];
  likelihoodLocalisations: EnumBasedLocalisationViewModel[] = [];
  impactLocalisations: EnumBasedLocalisationViewModel[] = [];

  private subscriptions = new Subscription();

  constructor(
    private readonly accountSettingsService: AccountSettingsService,
    private readonly riskRAGMatrixService: RiskRAGMatrixService,
    private readonly cdr: ChangeDetectorRef,
    private readonly allowedFiltersService: AllowedFiltersService,
    public readonly bsModalRef: BsModalRef,
    ) { }

  public ngOnInit(): void {
    this.allowedLikelihoodFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Risk_Likelihood);
    this.allowedImpactFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Risk_Impact);

    this.subscriptions.add(
      this.accountSettingsService.getEnumBasedLocalisations(FilterTypes.Risk_Likelihood).subscribe((res) => {
        this.likelihoodLocalisations = res;
    }));

    this.subscriptions.add(
      this.accountSettingsService.getEnumBasedLocalisations(FilterTypes.Risk_Impact).subscribe((res) => {
        this.impactLocalisations = res;
    }));

    this.getRAGMatrix();
    this.setLikelihoodAndImpactFromFilters();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.filters){
      this.setLikelihoodAndImpactFromFilters();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(): void {
    this.bsModalRef.hide();
  };

  /**
   * Returns the rag color for the given likelihood and impact from the matrix
   */
  public getColour(likelihood: number, impact: number) {
    const matchingElement = this.riskRAGMatrix.find((r) => r.impact === impact && r.likelihood === likelihood);
    return matchingElement ? matchingElement.colour : '';
  }

  public getRAGMatrix() {
    this.subscriptions.add(
      this.riskRAGMatrixService._list$.subscribe((res) => {
        this.riskRAGMatrix = res;
        this.cdr.markForCheck();

      })
    );
  }

  /**
   * Sets local properties for the currently selected likelihood and impact
   */
  public onSelectLikelihoodAndImpact(likelihood: number, impact: number) {
    this.currentlySelectedLikelihood = likelihood;
    this.currentlySelectedImpact = impact;
  }

  /**
   * Emits the currently selected likelihood and impact as FilterViewModels and then closes the modal
   */
  public onSave(): void {
    const updatedLikelihoodFilter = this.allowedLikelihoodFilters.find((f) => f.filterValue === this.currentlySelectedLikelihood);
    const updatedImpactFilter = this.allowedImpactFilters.find((f) => f.filterValue === this.currentlySelectedImpact);
    this.likelihoodAndImpactUpdated.emit([updatedLikelihoodFilter, updatedImpactFilter]);
    this.onClose();
  }

  /**
   * Sets intially on load the currently selected likelihood and impact from the filters passed as input
   */
  private setLikelihoodAndImpactFromFilters(): void {
    this.currentlySelectedLikelihood = +this.filters.find((f) => f.filterType === FilterTypes.Risk_Likelihood)?.filterValue as RiskLikelihoods;
    this.currentlySelectedImpact = +this.filters.find((f) => f.filterType === FilterTypes.Risk_Impact)?.filterValue as RiskImpact;
  }

  /**
   * Return true if the given likelihood and impact are the currently selected ones
   */
  public isSelected(likelihood: number, impact: number): boolean {
    return this.currentlySelectedLikelihood === likelihood && this.currentlySelectedImpact === impact;
  }

  /**
   * Return true if the given impact is the currently selected one
   * Used for highlighting the selected impact down of the matrix
   */
  public isSelectedImpact(impact: number): boolean {
    return this.currentlySelectedImpact === impact;
  }

  /**
   * Return true if the given likelihood is the currently selected one
   * Used for highlighting the selected likelihood left of the matrix
   */
  public isSelectedLikelihood(likelihood: number): boolean {
    return this.currentlySelectedLikelihood === likelihood;
  }

  /**
   * Since impact can be customised for the account, we need to get the localised text for the currently selected impact from the allowed filters
   */
  public getLocalisedCurrentImpact(): string {
    return this.impactLocalisations?.find((f) => f.enumValue === this.currentlySelectedImpact)?.localisedValue;
  }

  /**
   * Since likelihood can be customised for the account, we need to get the localised text for the currently selected impact from the allowed filters
   */
  public getLocalisedCurrentLikelihood(): string {
    return this.likelihoodLocalisations?.find((f) => f.enumValue === this.currentlySelectedLikelihood)?.localisedValue;
  }
}
