import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import {
  ChecklistOptionSet1,
  ChecklistOptionSet2,
  ChecklistResponseTypes,
} from 'src/app/modules/shared/enums/checklists/checklistEnums';
import { ChecklistTypes } from 'src/app/modules/shared/enums/checklists/checklistTypes.enum';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { BsModalEventsEmitter } from 'src/app/modules/shared/events/bsModal.events';
import { ObjectEventEmitters } from 'src/app/modules/shared/events/object.events';
import { PaginationManager } from 'src/app/modules/shared/managers/paginationManager';
import { Account } from 'src/app/modules/shared/models/account';
import { ChecklistItemViewModel } from 'src/app/modules/shared/models/checklists/checklistItemViewModel';
import { Constants } from 'src/app/modules/shared/models/constants';
import { Employee } from 'src/app/modules/shared/models/employee';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { ChecklistItemService } from 'src/app/modules/shared/services/checklist-item-service';
import { ListOperationsService } from 'src/app/modules/shared/services/utilities/list-operations.service';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { T } from 'src/assets/i18n/translation-keys';
import { ChecklistItemAddModalComponent } from '../modals/checklist-item-add/checklist-item-add.component';
import { ChecklistItemsAddModal } from '../modals/checklist-items-add.component/checklist-items-add.component';

export interface ChecklistCounts {
  incompleteCount: number;
  failedOrPoorCount: number;
  unsureOrFairCount: number;
  percentageComplete: number;
  completed: number;
  total: number;
}

@Component({
  selector: 'app-checklist-items-list',
  templateUrl: 'checklist-items-list.component.html',
  styleUrls: ['checklist-items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistItemsListComponent implements OnInit, OnDestroy, OnChanges {
  private modalConfig = { backdrop: true, ignoreBackdropClick: true };
  public toggleOptions = [
    { value: 'All', displayText: this.translateService.instant(T.common.all) },
    { value: 'Incomplete', displayText: this.translateService.instant(T.common.incomplete.one) },
    { value: 'Complete', displayText: this.translateService.instant(T.common.complete.one) },
    { value: 'Failed', displayText: this.translateService.instant(T.common.failed.many) },
  ];
  private subscriptions: Subscription[] = [];
  private bsModalRef: BsModalRef;
  private employee: Employee;
  @Input() public selectedToggleOption = { value: 'All', displayText: this.translateService.instant(T.common.all) };
  @Input() globalObjectId: number;
  @Input() globalObjectType: number;
  @Input() filters: FilterViewModel[];
  @Input() showEmptyChecklists: boolean = true;
  @Input() showHeaderButtons: boolean = true;
  @Input() hideAddButtonFromHeader: boolean = false;
  @Input() showQuickAdd: boolean = false;
  @Input() quickAddPlaceholder: string = 'Quickly Add Item';
  @Input() checklistType: ChecklistTypes = ChecklistTypes.Incidents;
  @Input() canEdit: boolean = true;
  @Input() isMyTrack: boolean = false;
  @Input() expandFirstGroup: boolean = false;
  @Input() showChecklistSummary: boolean = false;
  @Input() useHeaderV2: boolean = false;
  @Output() jobStatusChanged = new EventEmitter<boolean>();
  @Output() checkListItemsUpdated = new EventEmitter<ChecklistItemViewModel[]>();
  @Output() hasIncompletedStarredChecklistItems = new EventEmitter<boolean>();
  @Output() countsChange: EventEmitter<ChecklistCounts> = new EventEmitter<ChecklistCounts>();

  filteredItems: ChecklistItemViewModel[] = [];
  checklistItems: ChecklistItemViewModel[] = [];
  loading = true;
  hideCompleted = false;
  account: Account;
  mobileWidth = Constants.xs;
  paginationId: string;
  public isOnInitExecuted: boolean = false;
  completedChecklists: number = 0;
  public showOwner = true;
  public readonly checklistTypes = ChecklistTypes;
  public groupedChecklistItems: { groupTitle: string; checklistItems: ChecklistItemViewModel[] }[] = [];
  public expandedGroupTitles: string[] = [];
  public useGroupedChecklist = false;
  public showJobStatusChangeConfirmModal: boolean = false;
  public readonly T = T;
  public localisedItem = 'Checklist';
  public localisedItems = 'Checklists';
  public percentageComplete: number = 0;
  public incompleteCount: number = 0;
  public failedOrPoorCount: number = 0;
  public unsureOrFairCount: number = 0;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly checklistItemService: ChecklistItemService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly modalService: BsModalService,
    private readonly alertService: AlertService,
    private readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly listOperationsService: ListOperationsService,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private readonly paginationManager: PaginationManager,
    private readonly translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.paginationId = this.paginationManager.generateID();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.account = this.authenticationService.getCurrentAccount();
    this.localisedItem = this.translateService.instant(T.defaultLocalizations.checklist_item.one);
    this.localisedItems = this.translateService.instant(T.defaultLocalizations.checklist_item.many);

    if (this.checklistType === ChecklistTypes.MyTrack) {
      this.showOwner = false;
    } else {
      this.useGroupedChecklist = true;
    }

    this.getList(true);
    this.initSubscriptions();
  }

  updateIncompleteStatus() {
    const starredChecklists = this.checklistItems.filter((i) => i.starred);
    const incompleteStarredChecklists = this.checklistItems.filter((i) => i.starred && !this.isCompleted(i));

    this.hasIncompletedStarredChecklistItems.next(starredChecklists.length ? incompleteStarredChecklists.length > 0 : false);
  }

  get mobile(): boolean {
    return this.elementRef.nativeElement.getBoundingClientRect().width <= Constants.xs;
  }

  isCompleted(item: ChecklistItemViewModel): boolean {
    let result = false;

    if (item.responseType === ChecklistResponseTypes.Mark_as_Complete) {
      result = item.response === 'true';
    } else if (item.responseType === ChecklistResponseTypes.Text_Response) {
      result = item.response && item.response.length > 0;
    } else if (item.responseType === ChecklistResponseTypes.Numeric_Response) {
      result = item.response && Number(item.response) > 0;
    } else {
      result = item.response !== undefined && parseInt(item.response) > 0;
    }
    return result;
  }

  isFailed(item: ChecklistItemViewModel): boolean {
    if (
      item.responseType === ChecklistResponseTypes.Options_Set_1 ||
      item.responseType === ChecklistResponseTypes.Options_Set_2
    ) {
      return (+item.response as ChecklistOptionSet1) === ChecklistOptionSet1.Fail || (+item.response as ChecklistOptionSet2) === ChecklistOptionSet2.Poor;
    }
    return false;
  }

  isUnsureOrFair(item: ChecklistItemViewModel): boolean {
    if (
      item.responseType === ChecklistResponseTypes.Options_Set_1 ||
      item.responseType === ChecklistResponseTypes.Options_Set_2
    ) {
      return (+item.response as ChecklistOptionSet1) === ChecklistOptionSet1.Unsure || (+item.response as ChecklistOptionSet2) === ChecklistOptionSet2.Fair;
    }
    return false;
  }

  onResized(): void {
    this.changeDetectorRef.detectChanges();
  }

  initSubscriptions() {
    this.subscriptions.push(
      this.objectEventEmitters.objectAdded$.subscribe((res) => {
        const model = res.model as ChecklistItemViewModel;
        if (res.globalObjectType === ObjectTypes.Checklist_Item) {
          if (model.globalObjectType === this.globalObjectType && model.globalObjectId === this.globalObjectId) {
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedItem }));
            this.updateList(this.listOperationsService.addObjectToList(model, this.checklistItems) as ChecklistItemViewModel[]);
            this.paginationManager.broadcastScrollToId(this.paginationId, res.globalObjectId);
          }
        }
      })
    );

    this.subscriptions.push(
      this.objectEventEmitters.listAdded$.subscribe((res) => {
        if (res.globalObjectType === ObjectTypes.Checklist_Item) {
          const checklistModles = res.model as ChecklistItemViewModel[];
          if (checklistModles[0].globalObjectType === this.globalObjectType && checklistModles[0].globalObjectId === this.globalObjectId) {
            const newItems: ChecklistItemViewModel[] = this.getNewChecklistItemsFromList(checklistModles);

            if (newItems.length > 0) {
              void this.alertService.success(this.translateService.instant(T.common.items_added, { items: this.localisedItems }));
              this.updateList(this.checklistItems.concat(newItems));
              this.paginationManager.broadcastScrollToId(this.paginationId, res.globalObjectId);
            }
          }
        }
      })
    );

    this.subscriptions.push(
      this.objectEventEmitters.objectUpdated$.subscribe((res) => {
        if (res.globalObjectType === ObjectTypes.Checklist_Item) {
          const model = res.model as ChecklistItemViewModel;
          if (model.globalObjectType === this.globalObjectType && model.globalObjectId === this.globalObjectId) {
            this.updateList(this.listOperationsService.updateObjectInList(res.model, this.checklistItems) as ChecklistItemViewModel[]);
          }
        }
      })
    );

    this.subscriptions.push(
      this.objectEventEmitters.objectDeleted$.subscribe((res) => {
        if (res.globalObjectType === ObjectTypes.Checklist_Item) {
          const model = res.model as ChecklistItemViewModel;
          if (model.globalObjectType === this.globalObjectType && model.globalObjectId === this.globalObjectId) {
            void this.alertService.success(this.translateService.instant(T.common.item_deleted, { item: this.localisedItem }));
            this.updateList(this.listOperationsService.deleteObjectFromList(res.model, this.checklistItems) as ChecklistItemViewModel[]);
          }
        }
      })
    );

    this.subscriptions.push(
      this.objectEventEmitters.refreshChecklists$.subscribe(() => {
        this.getList(false);
      })
    );
  }

  private getNewChecklistItemsFromList(checklistItems: ChecklistItemViewModel[]) {
    const newItems: ChecklistItemViewModel[] = [];

    if (checklistItems && checklistItems.length) {
      checklistItems.forEach((checklistItem) => {
        if (!this.checklistItems.find((c) => c.id === checklistItem.id)) {
          newItems.push(checklistItem);
        }
      });
    }

    return newItems;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.globalObjectId && this.isOnInitExecuted) {
      this.getList(false);
    }
  }

  isEmptyState(): boolean {
    return !this.filteredItems.length && this.checklistType !== this.checklistTypes.Evaluation;
  }

  getList(initial = false) {
    this.loading = true;
    this.subscriptions.push(
      this.checklistItemService.getListByGlobalObjectId(this.globalObjectId, this.globalObjectType).subscribe((res) => {
        if (this.globalObjectType === ObjectTypes.Job) {
          this.showJobStatusChangeConfirmModal = !res.some((x) => x.completed === true);
        }

        this.updateList(res);
        this.loading = false;

        this.onShowHidePastActivities(this.selectedToggleOption);
        if (initial) {
          this.isOnInitExecuted = true;
        }
        if (this.expandFirstGroup) {
          this.expandItem(this.groupedChecklistItems?.[0]?.groupTitle);
        }
      })
    );
  }

  initGroupedList() {
    if (this.checklistType !== ChecklistTypes.MyTrack) {
      this.filteredItems.forEach(checklist => {
        const title = checklist.checklistTemplateTitle ?? this.translateService.instant(T.defaultLocalizations.checklist_item.additional);
        let group = this.groupedChecklistItems.find(c => c.groupTitle === title)
        if(group) {
          if(!group.checklistItems)
           group.checklistItems = [];

          const matchingItem = group.checklistItems.find(c => c.id === checklist.id) || undefined;

          if(matchingItem) {
            group.checklistItems[group.checklistItems.indexOf(matchingItem)] = Object.assign({}, checklist);
          } else {
            group.checklistItems.push(checklist);
          }
        } else {
          group = {groupTitle: title, checklistItems: []};
          group.checklistItems.push(checklist);
          this.groupedChecklistItems.push(group);
        }
      });

      this.groupedChecklistItems.forEach(group =>  {
        group.checklistItems = group.checklistItems.sort((a, b) => a.viewOrder - b.viewOrder);
        group.checklistItems = group.checklistItems.slice();
      })
      this.changeDetectorRef.markForCheck();
    }
  }

  setFilteredItems() {
    this.filteredItems = JSON.parse(JSON.stringify(this.checklistItems)) as ChecklistItemViewModel[];

    if (this.selectedToggleOption.value === 'Incomplete') {
      this.filteredItems = this.filteredItems.filter((i) => !this.isCompleted(i));
      this.filteredItems = this.filteredItems.slice();
      this.completedChecklists = 0;
    } else if (this.selectedToggleOption.value === 'All') {
      const completed = this.filteredItems.filter((i) => this.isCompleted(i));

      this.completedChecklists = completed?.length ?? 0;
    } else if (this.selectedToggleOption.value === 'Complete') {
      this.filteredItems = this.filteredItems.filter((i) => this.isCompleted(i));
    } else if (this.selectedToggleOption.value === 'Failed') {
      this.filteredItems = this.filteredItems.filter((i) => this.isFailed(i));
    }

    this.initGroupedList();
    this.setCounts();
    this.changeDetectorRef.markForCheck();
  }

  checkUserAccessLevel() {
    const departmentIDs: number[] = [];
    this.filters.forEach((s) => {
      if (s.filterType === FilterTypes.Department) {
        departmentIDs.push(+s.filterValue);
      }
    });

    let moduleType: ModuleTypes;

    if (this.globalObjectType === ObjectTypes.IncidentItem) {
      moduleType = ModuleTypes.Incidents;
    }

    if (moduleType) {
      const employeeHasWritePermission = EmployeeUtil.hasWritePermission(
        this.employee,
        moduleType,
        FilterTypes.Department,
        departmentIDs,
        null
      );

      return employeeHasWritePermission;
    }

    return true;
  }

  addItems() {
    const initialState = {
      filterSettings: [],
      showFilters: false,
      showEmptyChecklists: this.showEmptyChecklists,
      objectType: this.globalObjectType,
    };
    const modalConfig = { ignoreBackdropClick: true };
    const modalParams = Object.assign({}, modalConfig, { initialState });
    const modalRef = this.modalService.show(ChecklistItemsAddModal, modalParams);
    this.bsModalEventsEmitter.broadcastBsModalStateChanged({ loaded: true });
    this.subscriptions.push(
      modalRef.content.onSubmit.subscribe((res: number[]) => {
        this.checklistItemService.addFromTemplate(res, this.globalObjectId, this.globalObjectType).subscribe((items) => {
          const newItems = this.getNewChecklistItemsFromList(items);

          if (newItems && newItems.length > 0) {
            void this.alertService.success(this.translateService.instant(T.common.items_added, { items: this.localisedItems }));
            this.updateList(this.checklistItems.concat(items));
          }
        });
      })
    );
  }

  addItem() {
    const initialState = {
      globalObjectId: this.globalObjectId,
      globalObjectType: this.globalObjectType,
      viewOrder: this.checklistItems.length,
      subHeading: undefined,
    };

    if (this.globalObjectType === ObjectTypes.Employee) {
      initialState.subHeading = this.translateService.instant(T.common.star_items_that_are_important);
    }

    if (this.checklistType === ChecklistTypes.MyTrack) {
      initialState['showDescription'] = false;
      initialState['showOwnerFilter'] = false;
    }

    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    const modalRef = this.modalService.show(ChecklistItemAddModalComponent, modalParams);
    this.subscriptions.push(
      modalRef.content.onSubmit.subscribe((checklistItem: ChecklistItemViewModel) => {
        this.checklistItemService.add(checklistItem).subscribe((res) => {
          const checklistModel = res.returnModel as ChecklistItemViewModel;
          this.objectEventEmitters.broadcastObjectAdded(checklistModel.id, ObjectTypes.Checklist_Item, res.returnModel);
        });
      })
    );
  }

  onView(item: ChecklistItemViewModel) {
    if (this.canEdit) {
      const initialState = {
        globalObjectId: this.globalObjectId,
        globalObjectType: this.globalObjectType,
        viewOrder: this.checklistItems.length,
        checklistItem: JSON.parse(JSON.stringify(item)) as ChecklistItemViewModel,
        subHeading: undefined,
      };

      if (this.globalObjectType === ObjectTypes.Employee) {
        initialState.subHeading = this.translateService.instant(T.common.star_items_that_are_important);
      }

      if (this.checklistType === ChecklistTypes.MyTrack) {
        initialState['showDescription'] = false;
        initialState['showOwnerFilter'] = false;
        initialState['showResponseTypeDD'] = false;
      }

      const modalParams = Object.assign({}, this.modalConfig, { initialState });
      const modalRef = this.modalService.show(ChecklistItemAddModalComponent, modalParams);
      this.subscriptions.push(
        modalRef.content.onSubmit.subscribe((res: ChecklistItemViewModel) => {
          this.updateItem(res);
        })
      );

      this.subscriptions.push(
        modalRef.content.onDelete.subscribe(() => {
          this.deleteItem(item);
        })
      );
    }
  }

  deleteItem(item: ChecklistItemViewModel) {
    this.subscriptions.push(
      this.checklistItemService.delete(item.id).subscribe((deletedItem) => {
        this.objectEventEmitters.broadcastObjectDeleted(deletedItem.id, ObjectTypes.Checklist_Item, deletedItem);
      })
    );
  }

  onShowHidePastActivities(state: {value: string, displayText: string}) {
    this.selectedToggleOption = state;
    this.hideCompleted = state.value === 'Incomplete';
    this.setFilteredItems();
  }

  updateItem(item: ChecklistItemViewModel) {
    this.subscriptions.push(
      this.checklistItemService.update(item).subscribe(() => {
        if (this.globalObjectType === ObjectTypes.Job) {
          this.jobStatusChanged.emit(this.showJobStatusChangeConfirmModal);
          this.showJobStatusChangeConfirmModal = false;
        }
      })
    );
  }

  get noResultsTitle(): string {
    if (!this.hideCompleted) return this.translateService.instant(T.control.no_checklists_yet);

    return this.translateService.instant(T.control.all_checklists_items_have_been_completed);
  }

  onItemsViewOrderChanged(items: ChecklistItemViewModel[]) {
    for (let index = 0; index < items.length; index++) {
      items[index].viewOrder = index;
    }

    this.subscriptions.push(
      this.checklistItemService.updateItems(items).subscribe((updatedItems) => {
        this.filteredItems = Object.assign([], updatedItems);

        this.checklistItems.forEach((i) => {
          const item = updatedItems.find((it) => it.id === i.id);
          if (item) {
            i.viewOrder = item.viewOrder;
          }
        });

        this.checklistItems.sort((a, b) => a.viewOrder - b.viewOrder);

        this.changeDetectorRef.markForCheck();
      })
    );
  }

  onQuickAddChange(title: string) {
    const checklistItem = new ChecklistItemViewModel();
    checklistItem.globalObjectId = this.globalObjectId;
    checklistItem.globalObjectType = this.globalObjectType;
    checklistItem.viewOrder = this.checklistItems.length;
    checklistItem.title = title;

    if (this.checklistType === ChecklistTypes.MyTrack) {
      checklistItem.responseType = ChecklistResponseTypes.Mark_as_Complete;
    }

    this.subscriptions.push(
      this.checklistItemService.add(checklistItem).subscribe((res) => {
        const model = res.returnModel as ChecklistItemViewModel;
        this.objectEventEmitters.broadcastObjectAdded(model.id, ObjectTypes.Checklist_Item, res.returnModel);
      })
    );
  }

  get isMobile(): boolean {
    return window.innerWidth <= this.mobileWidth;
  }

  get addFromTemplateText(): string {
    const translateKey = this.isMobile ? T.control.add_from_template : T.control.add_items_from_a_template;
    return this.translateService.instant(translateKey);
  }

  expandItem(groupTitle: string) {
    if (groupTitle) {
      const matching = this.expandedGroupTitles.find((t) => t === groupTitle);
      if (matching) {
        this.expandedGroupTitles.splice(this.expandedGroupTitles.indexOf(groupTitle), 1);
      } else {
        this.expandedGroupTitles.push(groupTitle);
      }
      this.expandedGroupTitles.slice();
      this.changeDetectorRef.detectChanges();
    }
  }

  isExpanded(groupTitle: string) {
    return this.expandedGroupTitles.indexOf(groupTitle) >= 0;
  }

  private updateList(updatedItems: ChecklistItemViewModel[]): void {
    this.checklistItems = updatedItems.slice();
    this.checkListItemsUpdated.next(this.checklistItems);
    this.setFilteredItems();
    this.updateIncompleteStatus();
    this.changeDetectorRef.markForCheck();
  }

  private setCounts(): void {
    this.percentageComplete =
      this.filteredItems.length > 0 ? Math.round((this.completedChecklists / this.filteredItems.length) * 100) : 0;
    this.incompleteCount = this.filteredItems.filter((i) => !this.isCompleted(i)).length;
    this.failedOrPoorCount = this.filteredItems.filter((i) => this.isFailed(i)).length;
    this.unsureOrFairCount = this.filteredItems.filter((i) => this.isUnsureOrFair(i)).length;

    this.countsChange.emit({
      incompleteCount: this.incompleteCount,
      failedOrPoorCount: this.failedOrPoorCount,
      unsureOrFairCount: this.unsureOrFairCount,
      percentageComplete: this.percentageComplete,
      completed: this.completedChecklists,
      total: this.filteredItems.length,
    });
  }

  public toggleExpand() {
    if (this.isExpandedAll) {
      this.expandedGroupTitles = [];
    }else {
      this.expandedGroupTitles = this.groupedChecklistItems.map((i) => i.groupTitle);
    }
    this.changeDetectorRef.markForCheck();
  }

  get isExpandedAll(): boolean {
    return this.expandedGroupTitles.length === this.groupedChecklistItems.length;
  }
}
