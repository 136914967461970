import { Component, OnInit, OnDestroy, ViewChild, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { GridsterConfig, GridsterItem, GridsterItemComponent, GridsterComponent } from 'angular-gridster2';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { HeaderEventsEmitter } from 'src/app/modules/shared/events/header.events';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import {
  DashboardWidgetViewModel,
  DashboardWidgetLayoutViewModel,
} from 'src/app/modules/settings/viewModels/dashboardWidgetViewModel';
import { DashboardWidgetComponent } from '../../../models/dashboardWidgetComponent';
import { DashboardEventEmitters } from '../../../events/dashboard.events';
import { IDashboardManager } from '../../../managers/iDashboardManager';
import { EmployeeSettingTypes } from 'src/app/modules/settings/enums/employeeSettingTypes';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { EmployeeUtil } from '../../../utilities/employee.utilities';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('gridster') gridster: GridsterComponent;

  @Input() dashboardComponents: DashboardWidgetComponent[] = [];
  @Input() dashboardWidgets: DashboardWidgetViewModel[] = [];
  @Input() options: GridsterConfig;
  @Input() dashboard: Array<GridsterItem> = [];
  @Input() dashboardManager: IDashboardManager;

  currentEmployee: Employee;
  currentAccount: Account;
  public useRiskAndIssues: boolean;
  public subscriptions: Subscription[] = [];
  public static updated = new Subject<any>();
  private hasBeenInitted = false;
  private componentIndexInGridster: { comp: any; index: number }[] = [];
  private pristineDashboard: Array<GridsterItem> = [];

  constructor(
    private employeeSettingsService: EmployeeSettingsService,
    private readonly cachingService: CachingService,
    private readonly headerEventsEmitter: HeaderEventsEmitter,
    private readonly dashboardEventEmitters: DashboardEventEmitters,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentAccount = this.cachingService.GetAccountInfo();
    this.currentEmployee = this.cachingService.GetEmployee(this.currentAccount.id);
    this.useRiskAndIssues = EmployeeUtil.hasAnyReadPermission(this.currentEmployee, ModuleTypes.Planning, FilterTypes.Risk);
    this.options.itemChangeCallback = this.itemChange;
    this.options.itemResizeCallback = this.itemResize;
    this.headerEventsEmitter.menuToggled$.subscribe((toggled) => {
      if (this.gridster) {
        // this.gridster.resize();
      }
    });

    this.getWidgets();

    this.subscriptions.push(
      DashboardComponent.updated.subscribe((res) => {
        if (this.hasBeenInitted) {
          // this.employeeSettingsService.updateEmployeeDashboardWidgets(this.currentEmployee.id, res, this.dashboardManager.employeeSettingType).subscribe(res => {
          // });
        }
      })
    );

    this.subscriptions.push(
      this.dashboardEventEmitters.dashboardWidgetComponentAdded$.subscribe((res) => {
        this.addWidgetComponent(res);
      })
    );

    this.subscriptions.push(
      this.dashboardEventEmitters.dashboardWidgetComponentRemoved$.subscribe((res) => {
        this.removeWidgetComponent(res);
      })
    );

    this.subscriptions.push(
      this.dashboardEventEmitters.gridLayoutResetHappened$.subscribe((res) => {
        this.restoreGrid();
      })
    );
  }

  private getWidgets() {
    this.hasBeenInitted = false;
    if (this.dashboardManager.employeeSettingType !== EmployeeSettingTypes.PEAPS_Dashboard_Widget_Layout) {
      this.subscriptions.push(
        this.employeeSettingsService
          .getEmployeeDashboardWidgetLayout(this.dashboardManager.employeeSettingType)
          .subscribe((widgets) => {
            const account = this.cachingService.GetAccountInfo();
            if (widgets && account.id == 159) {
              widgets = widgets.filter((a) => a.containerTagName != 'app-dashboard-weather-new');
            }

            if (widgets && (environment.enableExtarnalAPIs == 'false' || this.currentAccount.hideWeatherWidgets)) {
              widgets = widgets.filter((a) => a.containerTagName != 'app-dashboard-weather-ow');
            }

            this.dashboardWidgets = widgets;

            if (!this.dashboardWidgets || !this.dashboardWidgets.length) {
              this.initDefaultGrid();
            } else {
              this.dashboardWidgets = widgets;
              this.initStoredGrid();
            }
            this.hasBeenInitted = true;
          })
      );
    } else {
      this.initDefaultGrid();
    }
  }

  initGrid() {
    this.initDefaultGrid();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  initDefaultGridMobile() {}

  getWidgetComponent(index: number) {
    return this.dashboardComponents[index];
  }

  private restoreGrid() {
    if (this.pristineDashboard) {
      this.pristineDashboard.forEach((c, index) => {
        this.dashboard[index].x = c.x;
        this.dashboard[index].y = c.y;
        this.dashboard[index].rows = c.rows;
        this.dashboard[index].cols = c.cols;
      });
    }
    this.gridster.options.api.optionsChanged();
    this.initDefaultGrid();
  }

  private initDefaultGrid(): void {
    this.dashboardWidgets = this.dashboardManager.getDefaultWidgets();
    if (!this.currentAccount.showRiskAndIssues || !this.useRiskAndIssues) {
      this.dashboardWidgets = this.dashboardWidgets.filter((o) => {
        return o.containerTagName != 'app-planning-dashboard-risk-issue';
      });
    }
    if (environment.enableExtarnalAPIs == 'false' || this.currentAccount.hideWeatherWidgets) {
      this.dashboardWidgets = this.dashboardWidgets.filter((a) => a.containerTagName != 'app-dashboard-weather-ow');
    }

    if (this.currentAccount.id == 159) {
      this.dashboardWidgets = this.dashboardWidgets.filter((a) => a.containerTagName != 'app-dashboard-weather-new');
    }

    this.dashboardWidgets.forEach((c) => {
      const dashboardElement = this.dashboardManager.allDashboardWidgetComponents.find((x) => x.tagName == c.containerTagName);

      const component = dashboardElement?.component;
      this.dashboardComponents.push(component);

      const gridsterItem = {
        cols: c.layout.cols,
        rows: c.layout.rows,
        y: c.layout.y,
        x: c.layout.x,
        minItemRows: c.layout.minItemRows,
        minItemCols: c.layout.minItemCols,
        maxItemRows: c.layout.maxItemRows,
        maxItemCols: c.layout.maxItemCols,
      };
      this.dashboard.push(gridsterItem);
      this.pristineDashboard.push(Object.assign({}, gridsterItem));

      this.componentIndexInGridster.push({ comp: c.containerTagName, index: this.dashboard.length - 1 });
      this.changeDetectorRef.markForCheck();
    });

    this.employeeSettingsService
      .updateEmployeeDashboardWidgets(this.dashboardWidgets, this.dashboardManager.employeeSettingType)
      .subscribe(() => {
        this.dashboardEventEmitters.broadcastDashboardWidgetComponentsLoaded(this.dashboardComponents);
      });
  }

  initStoredGrid() {
    this.dashboardComponents = [];
    this.dashboard = [];
    this.dashboardWidgets.forEach((c) => {
      const dashboardElement = this.dashboardManager.allDashboardWidgetComponents.find((x) => x.tagName == c.containerTagName);

      const component = dashboardElement?.component;
      this.dashboardComponents.push(component);
      this.dashboard.push({
        cols: c.layout.cols,
        rows: c.layout.rows,
        y: c.layout.y,
        x: c.layout.x,
        minItemRows: c.layout.minItemRows,
        minItemCols: c.layout.minItemCols,
        maxItemRows: c.layout.maxItemRows,
        maxItemCols: c.layout.maxItemCols,
      });
      this.componentIndexInGridster.push({ comp: c.containerTagName, index: this.dashboard.length - 1 });
      this.changeDetectorRef.markForCheck();
    });
    this.dashboardEventEmitters.broadcastDashboardWidgetComponentsLoaded(this.dashboardComponents);
  }

  removeWidgetComponent(component: DashboardWidgetComponent) {
    const dashboardComponent = this.dashboardComponents.find((c) => c == component.component);
    const index = this.dashboardComponents.indexOf(dashboardComponent);
    this.dashboardComponents.splice(index, 1);
    const componentTagName = component.tagName;
    const widget = this.dashboardWidgets.find((x) => x.containerTagName == componentTagName);
    this.dashboardWidgets.splice(this.dashboardWidgets.indexOf(widget), 1);
    this.dashboard.splice(index, 1);
    this.pristineDashboard.splice(index, 1);
    this.changeDetectorRef.markForCheck();
  }

  addWidgetComponent(component: DashboardWidgetComponent) {
    this.getWidgets();
  }

  // gridster callback methods
  itemChange({}, itemComponent: GridsterItemComponent) {
    DashboardComponent.mapGridsterToViewModel(itemComponent);
  }

  itemResize({}, itemComponent: GridsterItemComponent) {
    DashboardComponent.mapGridsterToViewModel(itemComponent);
  }

  static mapGridsterToViewModel(gridsterItemComponent: GridsterItemComponent) {
    let widgets: DashboardWidgetViewModel[] = [];

    gridsterItemComponent.gridster.grid.forEach((i) => {
      try {
        const widget: DashboardWidgetViewModel = new DashboardWidgetViewModel();
        const widgetLayout: DashboardWidgetLayoutViewModel = new DashboardWidgetLayoutViewModel();
        widget.containerTagName = i.el.children[2].tagName.toLowerCase();
        widgetLayout.cols = i.item.cols;
        widgetLayout.rows = i.item.rows;
        widgetLayout.x = i.item.x;
        widgetLayout.y = i.item.y;
        widget.layout = widgetLayout;
        widgets.push(widget);
      } catch (err) {}
    });
    widgets = widgets.sort((a, b) => {
      return a.layout.y - b.layout.y || a.layout.x - b.layout.x;
    });
    DashboardComponent.updated.next(widgets);
  }
}
