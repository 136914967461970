<div class="details-text-area-wrapper">
  <div>
    <div class="first-row">
      <span class="label">{{ label }}</span>
      <app-button-transparent
        [hidden]="!modifiedInput || modifiedInput.length === 0"
        (clicked)="expandField()"
        [text]="isFieldExpanded ? (T.common.hide_full_description | translate) : (T.common.show_full_description | translate)"
        size="small"
        iconFontSize="16"
        icon="unfold_more"
        textColor="primary-text"
        class="ms-2"
      >
      </app-button-transparent>
    </div>
    <div
      [ngClass]="['second-row', isEditMode ? 'edit-mode': '']"
      (click)="toggleEditMode($event)">
      <ng-container *ngIf="!isEditMode">
        <div *ngIf="!modifiedInput || modifiedInput.length === 0"
          class="simple-text empty-state">
          {{ emptyStateMessage }}
        </div>
        <div
          #textArea
          *ngIf="modifiedInput && modifiedInput.length > 0"
          [ngClass]="['simple-text', isFieldExpanded ? 'expanded' : '']"
          [innerHTML]="modifiedInput | safeHtml"
        ></div>
      </ng-container>
      <div class="text-editor-container" (click)="$event.stopPropagation()" *ngIf="isEditMode">
        <app-rich-text-editor-v2
          [placeholder]="placeholder"
          [inputText]="modifiedInput"
          [showUndoAndRedo]="false"
          [showProfileImage]="false"
          [minLength]="minLength"
          [autoFocus]="true"
          (textChanged)="changeInput($event)"
          (canceled)="cancelEdit($event)"
          (saved)="onRichEditorSave($event)"
        ></app-rich-text-editor-v2>
      </div>
    </div>
  </div>
</div>
