import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { AuthGuardService } from '../../auth/auth-guard.service';
import { Observable, Observer, of } from 'rxjs';
import { EmployeeSettingsService } from '../services/employee-settings.service';
import { EmployeeUtil } from '../utilities/employee.utilities';
import { Account } from '../models/account';

@Injectable()
export class AdminGuard {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly authGuardService: AuthGuardService,
    private readonly employeeSettingsService: EmployeeSettingsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authGuardService.canActivate(route, state)) {
      const account = this.authService.getCurrentAccount();
      const employee = this.authService.getCurrentEmployee();
      if (!account || !employee) {
        return new Observable<boolean>((observer: Observer<boolean>) => {
          this.employeeSettingsService.getLoggedInEmployeeForGuards().subscribe((res) => {
            const result = this.checkPermissions(account);

            observer.next(result);
          });
        });
      } else {
        const result = this.checkPermissions(account);
        return of(result);
      }
    } else {
      return of(false);
    }
  }

  checkPermissions(account: Account): boolean {
    let result = false;
    const employee = this.authService.getCurrentEmployee();

    if (!account.isActive) {
      void this.router.navigateByUrl('v2/unauthorized');
    }

    if (EmployeeUtil.IsAdmin(employee)) {
      result = true;
    }

    if (!result) {
      void this.router.navigateByUrl('v2/settings');
    }

    return result;
  }
}
