import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { ImageCropperModalComponent } from 'src/app/modules/shared/components/modals/image-cropper/image-cropper-modal.component';
import { DocumentService } from 'src/app/modules/shared/services/document.service';
import { UrlService } from 'src/app/modules/shared/services/url.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCroppedEvent, base64ToFile, ImageCropperComponent } from 'ngx-image-cropper';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { T } from 'src/assets/i18n/translation-keys';
import { AttachmentsUtilities } from 'src/app/modules/shared/utilities/attachments.utilities';
import { TranslateService } from '@ngx-translate/core';
import { FilesUtilities } from 'src/app/modules/shared/utilities/files.utilities';

type CropImgModalInitialState = {
  imageChangedEvent: Event;
  aspectRatio: string;
  maintainAspectRatio: boolean;
  roundCropper: boolean;
  extension: string;
};

type CropImgModalInitialState2 = {
  imageChangedEvent: File;
  aspectRatio: string;
  maintainAspectRatio: boolean;
  roundCropper: boolean;
  extension: string;
};

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['profile-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileImageComponent implements OnDestroy {
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;
  @ViewChild('fileElement', { static: false }) fileElement: ElementRef<HTMLElement>;

  @Input() inlineCropImage = false;
  @Input() accountId: number;
  @Input() imageUrl: string;
  @Input() title = '';
  @Input() aspectRatio = '';
  @Input() maintainAspectRatio = false;
  @Input() roundCropper = false;
  @Input() isAccountLogo = false;

  @Output() save = new EventEmitter<string>();
  @Output() cropImageLoaded = new EventEmitter<void>();

  private bsModalRef: BsModalRef;
  private subscriptions: Subscription[] = [];
  private croppedImageFile: File;
  private dragEnterCounter = 0;

  public imageLoading = true;
  public imageCroppingState = false;
  public imageChangedEvent = null;
  public imageFile = null;
  public extension = '';

  public readonly T = T;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private readonly bsModalService: BsModalService,
    private readonly documentsService: DocumentService,
    private readonly urlService: UrlService,
    private translateService: TranslateService,
    private imageCompress: NgxImageCompressService,
    private alertService: AlertService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  get dragAndDrop(): boolean {
    return this.dragEnterCounter > 0;
  }

  get imageLink(): string {
    if (this.imageUrl) {
      if (this.imageUrl.startsWith('http')) {
        return this.imageUrl;
      } else if (this.isAccountLogo) {
        return this.imageUrl.startsWith('/Resource')
          ? this.urlService.buildResourceUrl(this.imageUrl)
          : this.urlService.buildResourceUrl(`/Resource/assets/clients/logo/${this.imageUrl}`);
      } else {
        return this.imageUrl.startsWith('/Resource')
          ? this.urlService.buildResourceUrl(this.imageUrl)
          : this.urlService.buildResourceUrl(`/Resource/library/data/${this.imageUrl}`);
      }
    }
    return '';
  }

  public fileChangeEvent(event: Event): void {
    const targetElement = event.target as HTMLInputElement;
    if (targetElement.files && targetElement.files.length) {
      const name = targetElement.files[0].name;
      this.extension = FilesUtilities.getProperFileExtention(name);

      if(!AttachmentsUtilities.VerifyImageExtension(this.extension)) {
        void this.alertService.error( this.translateService.instant(T.common.error_imageWrongExt));
        return;
      }

      if(this.inlineCropImage) {
        this.imageChangedEvent = event;
        this.imageCroppingState = true;
        this.changeDetectorRef.detectChanges();
      } else {
        const initialState = {
          imageChangedEvent: event,
          aspectRatio: this.aspectRatio,
          maintainAspectRatio: this.maintainAspectRatio,
          roundCropper: this.roundCropper,
          extension: this.extension,
        };

        this.initCroppingImageModal(initialState);
      }
    }
  }

  public cancelImageCropping() {
    this.imageCroppingState = false;
    this.changeDetectorRef.detectChanges();
  }

  public uploadCroppedImageFile(): Observable<string> {
    return this.documentsService.splitUploadFile(this.croppedImageFile, true, false, this.isAccountLogo);
  }

  public onImageCropped(event: ImageCroppedEvent) {
    this.croppedImageFile = this.convertBlobToFile(base64ToFile(event.base64), 'test.' + this.extension);
  }
  public onImageLoaded() {
    this.imageLoading = false;
    this.changeDetectorRef.detectChanges();

    this.cropImageLoaded.next();
  }
  public onCropperReady() {
    this.imageLoading = false;
    this.changeDetectorRef.detectChanges();
  }
  public onLoadImageFailed() {
    this.imageLoading = false;
    this.imageCroppingState = false;

    if(AttachmentsUtilities.VerifyImageExtension(this.extension)){
      void this.alertService.error( this.translateService.instant(T.common.error_imageLoad));
    } else {
      void this.alertService.error( this.translateService.instant(T.common.error_imageWrongExt));
    }

    if(this.bsModalRef) {
      this.bsModalRef.hide();
    }

  }

  public rotateLeft() {
    this.imageCropper.transform.rotate = 270;
  }
  public rotateRight() {
    this.imageCropper.transform.rotate = 90;
  }
  public flipHorizontal() {
    this.imageCropper.transform.flipH = true;
  }
  public flipVertical() {
    this.imageCropper.transform.flipV = true;
  }

  public openFileUpload() {
    if (this.fileElement) {
      const newEvent = new MouseEvent('click');
      this.fileElement.nativeElement.dispatchEvent(newEvent);
    }
  }

  public onDropHandler(ev: DragEvent) {
    const name = ev.dataTransfer.files[0].name;
    const lastDot = name.lastIndexOf('.');
    const ext = name.substring(lastDot + 1);

    this.imageFile = ev.dataTransfer.files[0];

    this.extension = ext;
    if (this.extension === 'jpg') {
      this.extension = 'jpeg';
    }

    this.dragEnterCounter = 0;

    if (this.inlineCropImage) {
      this.imageCroppingState = true;
    } else {
      const initialState = {
        imageFile: this.imageFile,
        aspectRatio: this.aspectRatio,
        maintainAspectRatio: this.maintainAspectRatio,
        roundCropper: this.roundCropper,
        extension: ext,
      };

      this.initCroppingImageModal(initialState);
    }

    this.changeDetectorRef.detectChanges();
  }

  public onDragEnterHandler(ev: Event): void {
    ev.preventDefault();
    this.dragEnterCounter++;
  }

  public onDragLeaveHandler(ev: Event): void {
    ev.preventDefault();
    if (this.dragEnterCounter > 0) {
      this.dragEnterCounter--;
    }
  }

  private convertBlobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName, { type: `image/${this.extension}` });
  };

  private initCroppingImageModal(state: CropImgModalInitialState | any) {
    if (!this.inlineCropImage) {
      const modalConfig: ModalOptions<ImageCropperModalComponent> = {
        backdrop: true,
        ignoreBackdropClick: true,
        animated: true,
        initialState: state,
      };

      this.bsModalRef = this.bsModalService.show(ImageCropperModalComponent, modalConfig);
      const modalContent = this.bsModalRef.content as ImageCropperModalComponent;
      this.subscriptions.push(
        modalContent.onCroppedImageChange.subscribe((res) => {
          this.documentsService.splitUploadFile(res, true, false, this.isAccountLogo).subscribe((res) => {
            this.save.next(res);
          });
        })
      );
    }
  }
}
