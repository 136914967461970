import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlTree, Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Observer, of } from 'rxjs';
import { AuthGuardService } from '../../auth/auth-guard.service';
import { EmployeeRoleTypes } from '../enums/employees/EmployeeRoleTypes';
import { AuthenticationService } from '../services/authentication.service';
import { EmployeeSettingsService } from '../services/employee-settings.service';
import { EmployeeUtil } from '../utilities/employee.utilities';

@Injectable({
  providedIn: 'root',
})
export class MyTrackGuard {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authGuardService: AuthGuardService,
    private readonly employeeSettingsService: EmployeeSettingsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const haveRedirectUrl = this.authGuardService.GetRedirectCookie();
    if (this.authGuardService.canActivate(route, state)) {
      const account = this.authService.getCurrentAccount();
      const employee = this.authService.getCurrentEmployee();
      if (!account || !employee) {
        return new Observable<boolean>((observer: Observer<boolean>) => {
          this.employeeSettingsService.getLoggedInEmployeeForGuards().subscribe((res) => {
            const result = this.checkPermissions(haveRedirectUrl);

            observer.next(result);
          });
        });
      } else {
        const result = this.checkPermissions(haveRedirectUrl);
        return of(result);
      }
    } else {
      return of(false);
    }
  }

  checkPermissions(url: string): boolean {
    let result = false;
    const account = this.authService.getCurrentAccount();
    const employee = this.authService.getCurrentEmployee();
    const hasPlanningRole = EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Planning_User) || EmployeeUtil.IsAdmin(employee);

    if (!account.isActive) {
      void this.router.navigateByUrl('v2/unauthorized');
    }

    if  (account.isHubAccount){

      if(!url || (url && url.indexOf('reports') === -1)){
        void this.router.navigateByUrl('v2/hub');
      }
      return true;
    }

    if (account.usePlanning && hasPlanningRole) {
      result = true;
    }

    if (!result && (account.useIMSOnly || account.useIMS) && !url) {
      void this.router.navigateByUrl('v2/control/list');
    } else if (!result && account.useRunSheets && !url) {
      void this.router.navigateByUrl('v2/runsheets/list');
    } else if (!result && account.useSustainability && !url) {
      void this.router.navigateByUrl('v2/sustainability/indicators');
    }

    return result;
  }
}
