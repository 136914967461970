import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataBlock } from '../enums/weather/darkSkyApi/DataBlock';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { Configuration } from 'src/app/app.constants';

@Injectable()
export class WeatherDataService {
  private readonly corsBypassURL = 'https://thingproxy.freeboard.io/fetch/';

  private readonly openWeatherApiBaseURL: string = 'https://api.openweathermap.org/data/2.5/onecall';
  private readonly openWeatherApiKey: string = 'd2699acdacd69ed46cb04fafaf067ea2';

  private url: string;

  constructor(
    private authenticationService: AuthenticationService,
    private httpClient: HttpClient,
    private configuration: Configuration
  ) {
    this.url = this.configuration.buildEndpoint(``);
  }

  public getWeatherDataFromOpenWeatherApi(lat: number, lng: number, excludeBlocks: DataBlock[]): Observable<any> {
    const openWeatherApiUrl = this.buildOpenWeatherApiURL(lat, lng, excludeBlocks);

    return this.httpClient.post(`${this.url}Utilities/DarkSky`, { url: openWeatherApiUrl });
  }

  private buildOpenWeatherApiURL(lat: number, lng: number, excludeBlocks: DataBlock[]): string {
    let urlStringBuilder: string = this.openWeatherApiBaseURL;
    urlStringBuilder += `?lat=${lat}&lon=${lng}&appid=${this.openWeatherApiKey}`;

    if (excludeBlocks && excludeBlocks.length > 0) {
      const excludedBlocks = excludeBlocks.join();
      urlStringBuilder += '&exclude=' + excludedBlocks;
    }

    urlStringBuilder += '&units=metric';

    return urlStringBuilder;
  }
}
