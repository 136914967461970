import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/app.constants';
import { ClimatiqRegionViewModel } from '../viewModels/climatiq/climatiqRegionViewModel';
import { Observable } from 'rxjs';
import { UnitTypeViewModel } from '../viewModels/unitTypeViewModel';
import { IndicatorCategoryViewModel } from '../viewModels/indicatorCategoryViewModel';
import { ClimatiqSourceViewModel } from '../viewModels/climatiq/climatiqSourceViewModel';
import { ClimatiqEmissionFactorSearchViewModel } from '../viewModels/climatiq/climatiqEmissionFactorSearchViewModel';
import { ClimatiqIndicatorEmissionsDataSelectorViewModel } from '../viewModels/climatiq/climatiqIndicatorEmissionsDataSelectorViewModel';

@Injectable({
  providedIn: 'root',
})
export class ClimatiqService {
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`Sustainability/Climatiq/`);
  }

  // Get Climatiq-related Data

  getClimatiqRegions(): Observable<ClimatiqRegionViewModel[]> {
    return this.http.get<ClimatiqRegionViewModel[]>(`${this.actionUrl}ClimatiqRegions`);
  }

  getClimatiqUnitTypes(): Observable<UnitTypeViewModel[]> {
    return this.http.get<UnitTypeViewModel[]>(`${this.actionUrl}ClimatiqUnitTypes`);
  }

  getClimatiqIndicatorCategories(): Observable<IndicatorCategoryViewModel[]> {
    return this.http.get<IndicatorCategoryViewModel[]>(`${this.actionUrl}ClimatiqIndicatorCategories`);
  }

  getClimatiqSources(): Observable<ClimatiqSourceViewModel[]> {
    return this.http.get<ClimatiqSourceViewModel[]>(`${this.actionUrl}ClimatiqSources`);
  }

  // Emission Factor data

  getEmissionFactorSearchResults(searchModel: ClimatiqEmissionFactorSearchViewModel){
    return this.http.post<ClimatiqIndicatorEmissionsDataSelectorViewModel[]>(`${this.actionUrl}EmissionFactorSearchResults`, searchModel);
  }



}
