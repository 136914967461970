<app-dropdown-option
*ngFor="let entry of entries"
[value]="entry"
[mobile]="mobile"
[useBorderBottom]="mobile"
[allowDeselect]="!required"
[useCheckbox]="useCheckbox"
[selected]="isSelected(entry)"
(selectionChange)="onSelect(entry)"
>
  <div style="margin-right: 12px; display: flex" *ngIf="filterType === filterTypes.Job_Priority">
    <span
      *ngFor="let item of [].constructor(3); let i = index"
      style="color: #cccccc"
      class="material-icons-outlined star"
      [class.wetrack-black]="i < entry.filterValue"
      >star
    </span>
  </div>
  <app-employee-avatar
  class="mr-8"
  *ngIf="filterType == filterTypes.Owner"
  [employee]="getEmployee(entry.filterValue)"
  ></app-employee-avatar>
  <div *ngIf="entry.colour" class="filled-round-box mr-8" [style.background]="entry.colour"></div>
  {{entry.filterText}}
  <span *ngIf="showPrimaryIcon" class="material-icons primary-icon" [class.primary-selected]="isPrimary(entry)" (click)="changePrimary(entry, $event)">{{isPrimary(entry) ? 'star' : 'star_outline'}}</span>
</app-dropdown-option>
