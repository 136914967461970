<app-loading *ngIf="isLoading"></app-loading>
<div *ngIf="!isLoading" class="add-modal-container">
  <div class="modal-header-container d-flex justify-content-between align-items-center">
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.cancel | translate"
      (clicked)="confirmClose()"
    ></app-button-transparent>
    <span class="modal-title">{{ T.common.add_item | translate: { item: localisedAction } }}</span>
    <span class="material-icons close-icon d-none d-md-block" (click)="confirmClose()">close</span>
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.save | translate"
      (clicked)="handleAction(addModalButtonOptions.Save_Close)"
    ></app-button-transparent>
  </div>
  <div class="validations-erros-container" *ngIf="validationErrors?.length > 0">
    <div class="validation-error" *ngFor="let error of validationErrors">
      <i class="material-icons">error_outline</i>
      {{ error }}
    </div>
  </div>
  <div class="modal-body-container row col-row-space custom-gutters" [formGroup]="form">
    <div class="visible-part modal-body-container row col-row-space custom-gutters">
      <div class="container-with-bottom-element col-md-6 col-12 order-md-1">
        <app-filter-dropdown-new
          class="modal-risk"
          [displayError]="triggerErrors"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk"
          [disableMargin]="true"
          [required]="true"
          [singleSelect]="false"
          [appliedFilters]="filters"
          [typeahead]="true"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="d-flex container-with-bottom-element col-md-6 col-12 order-md-3">
        <div class="drop-arrow">
          <div class="vertical-line"></div>
          <div class="horizontal-line"></div>
        </div>
        <app-text-field
          [displayError]="triggerErrors"
          [autofocus]="true"
          class="flex-fill modal-action"
          formControlName="actionTitle"
          [disableMargin]="true"
          [svg]="svg"
          [placeholder]="(T.common.item_title | translate: { item: localisedAction }) + '*'"
          [formGroup]="form"
          [maxLength]="250"
          [useReactiveMessages]="true"
        >
        </app-text-field>
      </div>
      <div class="col-md-3 col-12 order-md-2">
        <app-filter-dropdown-new
          [useSearch]="false"
          [displayError]="triggerErrors"
          [required]="true"
          [currentlySelectedSettingValue]="statusValue"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Risk_Action_Status"
          [disableMargin]="true"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          (onFilterUpdate)="handleFilterChange($event)"
          [label]="T.common.status.one | translate"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="col-md-6 col-12 order-md-4">
        <app-filter-dropdown-new
          [useSearch]="false"
          [displayError]="triggerErrors"
          [required]="true"
          [filterByModuleObjectType]="false"
          [currentlySelectedSettingValue]="typeValue"
          [filterType]="filterTypes.Risk_Action_Type"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          (onFilterUpdate)="handleFilterChange($event)"
          [label]="T.common.type.one | translate"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12 order-md-5">
        <div class="selection">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Owner"
            [appliedFilters]="filters"
            [disableMargin]="true"
            [typeahead]="true"
            [label]="T.common.assign_owner.many | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="bottom-element d-flex">
          <app-button-transparent
            size="small"
            icon="add"
            [text]="T.common.assign_me | translate"
            (clicked)="setAccountForCurrentOwner()"
          ></app-button-transparent>
        </div>
      </div>
      <div class="d-flex flex-column col-md-6 col-12 order-md-6">
        <app-filter-dropdown-new
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.User_Group"
          [appliedFilters]="filters"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="T.common.assign_group_s | translate"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="col-md-6 col-12 order-md-6 d-flex" *ngIf="!dateReseter">
        <app-datepicker
          [date]="getDueDate"
          (dateChanged)="setDueDate($event)"
          [placeholder]="T.defaultLocalizations.due_date.one | translate"
          [showPlaceholderFirst]="true"
          [disableMargin]="true"
        ></app-datepicker>
      </div>
      <div class="description col-12 order-md-8">
        <app-details-editable-field
          [input]="form.controls.description.value ? form.controls.description.value : ''"
          [header]="T.defaultLocalizations.description.one | translate"
          [type]="editableFieldTypes.RichText"
          [canEdit]="true"
          (onUpdate)="updateDescription($event)"
          (onInputChange)="updateDescription($event)"
          [placeholder]="T.common.add_description | translate"
        ></app-details-editable-field>
      </div>
    </div>
    <div class="buttons-row col-12 d-flex justify-content-between align-items-center">
      <app-button-transparent
        class="d-none d-lg-block"
        [app-tooltip]="{
          title: T.common.keyboard_shortcuts | translate,
          position: ['top'],
          templateRef: tooltipTemplate
        }"
        [text]="T.common.keyboard_shortcuts | translate"
        icon="keyboard"
      ></app-button-transparent>
      <div class="action-buttons">
        <app-button-rectangle
          class="button d-none d-md-block"
          [fitToContainer]="true"
          size="large"
          [text]="T.common.close | translate"
          buttonTheme="white"
          (clicked)="confirmClose()"
        ></app-button-rectangle>
        <app-button-dropdown
          class="button"
          [fitToContainer]="true"
          [selectedOption]="buttonOptions[1]"
          [options]="buttonOptions"
          (selected)="handleAction($event)"
        ></app-button-dropdown>
      </div>
    </div>
  </div>
  <ng-template #tooltipTemplate>
    <table class="styled-table">
      <tr *ngFor="let keybind of keyboardShortcuts">
        <td>
          <span class="key-shortcut">{{ keybind.key }}</span> -
          <span class="key-description">{{ keybind.description }}</span>
        </td>
      </tr>
    </table>
  </ng-template>
</div>
