import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterViewModelWithComment } from '../../../models/filter/filterViewModelWithComment.Model';
import { EqualityUtilities } from '../../../utilities/equality.utilities';
import { ValidationUtilities } from '../../../utilities/validation.utilities';

@Component({
  selector: 'app-filters-color',
  templateUrl: './filters-color.component.html',
  styleUrls: ['./filters-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.filters-color-mobile]': 'mobile',
  },
})
export class FiltersColorComponent implements OnInit {
  @Input() mobile: boolean;
  @Input() filters: FilterViewModel[] = [];
  @Input() filterType: FilterTypes;

  //** only applicable filters for this component */
  @Input() appliedFilters: FilterViewModel[];

  /** if the color picker is single select or multiple*/
  @Input() singleSelect: boolean = false;

  /** it will tirgger fitler apply when filter is selected  */
  @Input() singleSelectWithApply: boolean = false;

  /** if you like to include comment */
  @Input() includeComment: boolean = true;
  @Input() placeholder: string = 'Add comment';

  //** values for which comments are mendatory */
  @Input() commentRequiredForValues: any[] = [];
  @Output() filtersWithCommentAdded = new EventEmitter<FilterViewModelWithComment>();

  displayForObjectType: ObjectTypes;
  localAppliedFilters: FilterViewModel[];
  originalAppliedFilters: FilterViewModel[];

  comment: string;
  commentIsRequired: boolean = false;
  currentIncludeCommentState: boolean = false;
  public readonly T = T;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (this.isInputValid) {
        this.onApply();
      }
    }
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.reflectAppliedFiltersChange();
  }

  private reflectAppliedFiltersChange() {
    if (this.appliedFilters && this.appliedFilters.length > 0 && this.appliedFilters[0]) {
      this.displayForObjectType = this.appliedFilters[0].displayForGlobalObjectType;

      if (!this.localAppliedFilters || !this.localAppliedFilters.length)
        this.localAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters));

      this.originalAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters));
    } else {
      this.localAppliedFilters = [];
      this.originalAppliedFilters = [];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.appliedFilters &&
      !EqualityUtilities.arraysEqual(changes.appliedFilters.currentValue, changes.appliedFilters.previousValue || [])
    ) {
      this.reflectAppliedFiltersChange();
    }
  }

  onEntrySelected(entry: FilterViewModel): void {
    if (this.singleSelect) {
      this.localAppliedFilters = [];
      entry.displayForGlobalObjectType = this.displayForObjectType;
      this.localAppliedFilters.push(entry);

      if (!this.singleSelectWithApply) {
        const fVm = new FilterViewModelWithComment();
        fVm.filters = this.localAppliedFilters;
        this.filtersWithCommentAdded.next(fVm);
        this.onCancel();
      }
    } else {
      const selectedItem = this.localAppliedFilters.find(
        (a) => a.filterType == entry.filterType && a.filterValue == entry.filterValue
      );
      if (selectedItem) {
        this.localAppliedFilters = this.localAppliedFilters.filter((a) => a != selectedItem);
      } else {
        this.localAppliedFilters.push(entry);
      }
    }

    this.setIncludeComment();
    this.changeDetectorRef.markForCheck();
  }

  changeCurrentIncludeCommentState(state: boolean) {
    this.currentIncludeCommentState = state;
  }

  setIncludeComment() {
    if (this.includeComment && this.commentRequiredForValues) {
      const matching = this.commentRequiredForValues.find(
        (x) => x.toString() === this.localAppliedFilters[0].filterValue.toString()
      );
      this.commentIsRequired = matching !== undefined;

      if (!matching && !this.currentIncludeCommentState) {
        this.onApply();
      }
    }
  }

  get showFooter(): boolean {
    let result = true;

    if (this.singleSelect) {
      result = false;
    }

    if (this.includeComment && this.commentRequiredForValues && this.localAppliedFilters.length) {
      const matching = this.commentRequiredForValues.find(
        (x) => x.toString() === this.localAppliedFilters[0].filterValue.toString()
      );
      result = matching !== undefined;
    }

    if (this.currentIncludeCommentState) {
      result = true;
    }

    return result;
  }

  onCommentChanged(comment: string): void {
    this.comment = comment;
  }

  onCancel(): void {
    this.comment = undefined;
    this.currentIncludeCommentState = false;

    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  onClearAll(): void {
    this.localAppliedFilters = [];
    this.changeDetectorRef.markForCheck();
  }

  onApply(): void {
    const model = new FilterViewModelWithComment();
    model.filters = this.localAppliedFilters;

    if (this.comment) {
      model.comment = this.comment;
    }

    this.filtersWithCommentAdded.emit(model);
    this.onCancel();
  }

  get isFilterChanged(): boolean {
    return JSON.stringify(this.originalAppliedFilters) == JSON.stringify(this.localAppliedFilters);
  }

  isInputValid(): boolean {
    let isValid = true;
    if (this.singleSelect) {
      if (
        this.localAppliedFilters.length == 0 ||
        (this.commentRequiredForValues &&
          this.commentRequiredForValues.find((v) => v.toString() === this.localAppliedFilters[0].filterValue.toString()) !==
            undefined &&
          !ValidationUtilities.validateAgainstMinLength(this.comment, 1))
      ) {
        isValid = false;
      }
    }

    return isValid;
  }
}
