import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommentsService } from '../../../services/comments.service';
import { CommentsManager } from '../../../managers/comments.manager';
import { CommentEventsEmitter } from '../../../events/comments.events';
import { NotificationService } from '../../../services/notification.service';
import { UrlService } from '../../../services/url.service';
import { Employee } from '../../../models/employee';
import { ObjectTypes } from '../../../enums/objectTypes';
import { UpdateTypes } from '../../../enums/updateTypes';
import { NotificationViewModel } from '../../../viewModels/notificationViewModel';
import { Comment } from '../../../models/comment';
import 'quill-mention';
import { QuillEditorComponent } from 'ngx-quill';
import { EmployeeViewModel } from '../../../viewModels/employeeViewModel';
import { MentionItem } from '../../../models/mentions/MentionItem';
import { Constants } from 'src/app/modules/shared/models/constants';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: 'rich-text-editor.component.html',
  styleUrls: ['rich-text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() placeholder = this.translateService.instant(T.common.add_comment_or_update);
  @Input() defaultEditorData = '';
  @Input() objectType: ObjectTypes;
  @Input() objectId: number;
  @Input() relatedObjectId: number;
  @Input() commentType: UpdateTypes;
  @Input() customMentions: MentionItem[];
  @Input() showButtons = true;
  @Input() showShortcut = true;
  @Input() useMentions = true;
  @Input() broadcastCommentsCountUpdated = false;
  @Input() showProfileImage = true;
  @Input() showClear = true;
  @Input() autoFocus = true;
  @Input() showCommentAlert = true;
  @Input() isModal: boolean = false;
  @Input() minLength = 3;
  /**
   * Pass the employee id of the employee from a child Account
   */
  @Input() childHubAccountId: number = 0;

  @Output() onSubmit = new EventEmitter<string>();
  @Output() onChange = new EventEmitter<string>();
  @Output() onTextChanged = new EventEmitter<string>();
  @Output() onCancel = new EventEmitter<void>();
  @Output() onModalClose = new EventEmitter<void>();

  @ViewChild(QuillEditorComponent, { static: false }) editor: QuillEditorComponent;

  private subscriptions: Subscription[] = [];
  employee: Employee;

  employeeModels: EmployeeViewModel[] = [];
  commentModel: string;
  employeeIdsToMention: number[] = [];
  modules: {};
  isFocused = false;
  public allowedFormats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'mention',
  ];

  mentionedUserIds = [];
  isMobile: boolean = false;
  isLoading: boolean = false;
  private readonly mobileWidth = Constants.xs;
  public readonly T = T;

  constructor(
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    private commentsService: CommentsService,
    private commentsManager: CommentsManager,
    private commentEventsEmmitter: CommentEventsEmitter,
    private notificationService: NotificationService,
    private urlService: UrlService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.commentModel = this.defaultEditorData;
    this.employee = this.authenticationService.getCurrentEmployee();
    this.subscriptions.push(
      this.commentEventsEmmitter.commentSaveChangesClicked$.subscribe(() => {
        this.addComment(true);
      })
    );

    if (this.useMentions) {
      this.getFeed();
    } else {
      this.loadConfiguration();
      this.changeDetectorRef.markForCheck();
    }

    this.checkIsMobile();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objectId) {
      this.commentModel = '';
    }
    this.changeDetectorRef.detectChanges();
  }

  onNgModelChange(text: string) {
    if (text && this.stripHtml(text).length > 0) {
      this.onTextChanged.next(text);
    } else {
      this.onTextChanged.next('');
    }
  }

  getAvatarUrl(thumbnailUrl: string): string {
    return this.urlService.buildResourceUrl(thumbnailUrl);
  }

  getFeed() {
    this.subscriptions.push(
      this.commentsService.getAllEmployeesByAccountId().subscribe((res) => {
        this.employeeModels = res;

        this.employeeModels.forEach((m) => {
          m.thumbnailURL = this.urlService.buildResourceUrl(m.thumbnailURL);
        });
        this.loadConfiguration();
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  addEmployeeEmailsToMention(data: string) {
    this.employeeIdsToMention = [];
    const idKey = 'data-id="';

    if (data.indexOf(idKey) > -1) {
      const parts = data.split(idKey);
      if (parts.length > 1) {
        for (let i = 0; i < parts.length; i += 1) {
          const id = parts[i].slice(0, parts[i].indexOf('"'));

          if (id !== undefined && id !== null && id !== '' && !isNaN(+id)) {
            this.employeeIdsToMention.push(+id);
          }
        }
      }
    }
  }

  addComment(forceAdd: boolean) {
    if (forceAdd && !this.isLoading && this.stripHtml(this.commentModel).length >= 3) {
      if (!this.showClear && this.showButtons) {
        this.onSubmit.next(this.commentModel);
      } else {
        const regex = /(<(?!\/)[^>]*>)*(<\/[^>]*>)+/g;

        this.isLoading = true;
        this.addEmployeeEmailsToMention(this.commentModel);
        const comment = new Comment();
        comment.accountId = this.childHubAccountId ?? this.employee.accountId;
        comment.comment = this.commentModel;
        comment.created = new Date();
        comment.createdById = this.employee.id;
        comment.globalObjectId = this.objectId;
        comment.globalObjectType = this.objectType;
        comment.relatedGlobalObjectId = this.relatedObjectId;
        comment.commentType = this.commentType;
        comment.comment = comment.comment.replace(regex, '').trim();

        const notificationModel: NotificationViewModel = new NotificationViewModel();
        notificationModel.currentEmployeeId = this.employee.id;
        notificationModel.objectId = this.objectId ? this.objectId : 0;
        notificationModel.objectType = this.objectType;
        notificationModel.commentType = this.commentType;
        notificationModel.employeeIds = this.employeeIdsToMention;
        notificationModel.itemUrl = window.location.href;
        notificationModel.commentText = comment.comment;

        if (this.stripHtml(comment.comment).length >= 1) {
          this.commentsManager.addComment(comment, this.showCommentAlert).subscribe(() => {
            if (notificationModel.employeeIds.length > 0) {
              this.notificationService.send(notificationModel).subscribe(() => {});
            }
            this.commentModel = '';
            this.changeDetectorRef.markForCheck();
            if (this.broadcastCommentsCountUpdated) {
              this.commentEventsEmmitter.broadcastCommentCountUpdated(this.objectId);
            }
            this.isLoading = false;
          });
        }
      }
    }

    if (this.isModal && forceAdd) {
      this.onModalClose.next();
    }
    else{
      this.onSubmit.next(this.commentModel);
    }
  }

  loadConfiguration() {
    const modules = {
      mention: {
        onSelect: (item, insertItem) => {
          insertItem(item);
          this.mentionedUserIds.push(item.id);
        },
        mentionDenotationChars: ['@', '#'],
        source: (searchTerm, renderList, mentionChar) => {
          const values = [];

          if (this.useMentions) {
            if (mentionChar === '@') {
              this.employeeModels.forEach((m) =>
                values.push({
                  id: m.id,
                  value: `${m.firstName} ${m.surname}`
                })
              );
            } else if (mentionChar === '#' && this.customMentions) {
              this.customMentions.forEach((m) => {
                values.push({ id: m.id, value: `${m.salutation}` });
              });
            }

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              values.forEach((entry) => {
                if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(entry);
                }
              });
              renderList(matches, searchTerm);
            }
          }
        }
      },
    };

    this.modules = modules;
  }

  onEditorFocused(ev: any) {
    if (ev && ev.editor) {
      this.isFocused = true;
      ev.editor.container.classList.add('focus');
      ev.editor.container.nextElementSibling.classList.add('focus');

      const editor = this.editor.quillEditor.getModule('mention');
      const mentionContainer = editor.mentionContainer;
      mentionContainer.style.height = '300px';
      mentionContainer.style.overflowY = 'scroll';
    }
  }

  onEditorBlur(ev: any) {
    if (ev && ev.editor) {
      this.isFocused = false;
      ev.editor.container.classList.remove('focus');
      ev.editor.container.nextElementSibling.classList.remove('focus');
    }
  }

  changedEditor(ev: any) {
    if (!this.useMentions && ev && ev.text) {
      const value = ev.html;
      this.onChange.next(value);
    }
  }

  redo() {
    this.editor.quillEditor.history.redo();
  }

  undo() {
    this.editor.quillEditor.history.undo();
  }

  cancel() {
    this.commentModel = undefined;
    this.changeDetectorRef.markForCheck();
    this.onCancel.next();
  }

  clear() {
    this.commentModel = undefined;
    this.changeDetectorRef.markForCheck();
  }

  setFocus(editor: any) {
    if (this.autoFocus && editor) {
      editor.focus();
    }
  }

  get disableButton() {
    let result = true;
    if (this.minLength === 0) {
      result = false;
    } else if (this.commentModel) {
      result = this.stripHtml(this.commentModel).trim().length < this.minLength;
    }

    return result;
  }

  checkIsMobile(): void {
    window.innerWidth <= this.mobileWidth ? (this.isMobile = true) : (this.isMobile = false);
  }

  stripHtml(text: string): string {
    const regex = /(<([^>]*)>)/gi;

    return text.replace(regex, '').trim();
  }
}
