import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { Configuration } from 'src/app/app.constants';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { UtilitiesService } from 'src/app/modules/shared/services/utilities.service';
import { IncidentAddModalComponent } from 'src/app/modules/incidents/components/common/modals/incident-add/incident-add-modal.component';
import { Employee } from 'src/app/modules/shared/models/employee';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ReportRefs } from 'src/app/modules/shared/enums/reports/reportRefs';
import { Account } from 'src/app/modules/shared/models/account';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { ModuleService } from 'src/app/modules/shared/services/module.service';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { IncidentLogAddModal } from 'src/app/modules/incidents/components/common/modals/incident-log-add/incident-log-add-modal.component';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { ProjectAddModalComponent } from 'src/app/modules/planning/components/modals/project-add-modal/project-add-modal.component';
import { TaskAddModalComponent } from 'src/app/modules/planning/components/modals/task-add-modal/task-add-modal.component';
import { SubtaskAddModalComponent } from 'src/app/modules/planning/components/modals/subtask-add-modal/subtask-add-modal.component';
import { RiskAddModalComponent } from 'src/app/modules/planning/components/modals/risk-add-modal/risk-add-modal.component';
import { ActionRiskAddModalComponent } from 'src/app/modules/planning/components/modals/action-risk-add-modal/action-risk-add-modal.component';
import { TaskGroupAddModalComponent } from 'src/app/modules/planning/components/modals/task-group-add-modal/task-group-add-modal.component';
import { NotificationsService } from 'src/app/modules/shared/services/notifications.service';
import { CanCreatePipe } from 'src/app/modules/shared/pipes/can-create.pipe';
import { filter } from 'rxjs/operators';
import { RunsheetAddModalComponent } from 'src/app/modules/runsheets/components/runsheet-add-modal/runsheet-add-modal.component';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { ModalUtilityService } from 'src/app/modules/shared/services/utilities/modals-utilities.service';
import { Constants } from 'src/app/modules/shared/models/constants';
import { PlatformType } from 'src/app/modules/shared/enums/platform.enum';
import { LocationStrategy } from '@angular/common';
import { JobAddModalComponent } from 'src/app/modules/incidents/components/common/modals/job-add/job-add-modal.component';
import { IndicatorTemplateAddModalComponent } from 'src/app/modules/accountHub/components/modals/indicator-template-add-modal/indicator-template-add-modal.component';
import { IndicatorAddModalNewComponent } from 'src/app/modules/sustainability/components/modals/indicator-add-modal-new/indicator-add-modal-new.component';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBarComponent implements OnInit, OnChanges {
  @Input() currentAccount: Account;
  @Input() currentEmployee: Employee;

  bsModalRef: BsModalRef;
  subscriptions = new Subscription();
  isAuthenticated = false;
  filterSettingsAreLoaded = false;
  isSidebarExpanded = false;
  windowDesktopWidth = 1024;
  windowMobileWidth = 600;
  searchMode = false;
  searchModel: string;
  isMobileScreen = false;
  hideHeaderFiltersOverride = false;
  currentRoute = '';
  pendingNotifications = 0;
  public hasAnyAddPermissions = false;

  public readonly moduleTypes = ModuleTypes;
  public readonly filterTypes = FilterTypes;
  public readonly objectTypes = ObjectTypes;
  public readonly T: typeof T = T;
  public hideHeaderBar = false;
  public hidePartialBasicFilters = false;

  private modalConfig = { backdrop: true, ignoreBackdropClick: true };
  private lastNavigatedUrl: string = '';

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isMobileScreen = event.target.innerWidth < 600;
  }

  constructor(
    private readonly utilityModalService: ModalUtilityService,
    private readonly modalService: BsModalService,
    private readonly cachingService: CachingService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly utilitiesService: UtilitiesService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly configuration: Configuration,
    private readonly localisationService: LocalisationService,
    private readonly moduleService: ModuleService,
    private readonly authService: AuthService,
    private readonly notificationsService: NotificationsService,
    private readonly canCreatePipe: CanCreatePipe,
    private readonly translateService: TranslateService,
    private location: LocationStrategy
  ) {}

  ngOnInit() {
    if (window.innerWidth < this.windowMobileWidth) {
      this.isMobileScreen = true;
    }
    if (window.innerWidth >= this.windowDesktopWidth) {
      this.toggleSideNav();
    }

    if (this.currentEmployee) {
      this.isAuthenticated = !!this.currentEmployee;
    }

    // This is for first loading
    this.currentRoute = this.router.url;
    this.hasAnyAddPermissions = this.checkForAnyAddPermissions();
    this.checkRouteDataOnFirstLoad();

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.url;
      if (this.lastNavigatedUrl.startsWith('/v2/search') && !this.isSearchRoute) {
        this.hideSearch();
      }
      this.hasAnyAddPermissions = this.checkForAnyAddPermissions();
      this.lastNavigatedUrl = event.url;
      this.changeDetectorRef.markForCheck();
    });

    /**
     * When route changes, check for any additional data in route to hide the filters
     */
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const innermostSnapshot = this.getInnermostRouteSnapshot(this.route.snapshot);
      this.checkHideHeaderFiltersOverride(innermostSnapshot.data);
      this.checkHideHeaderBar(innermostSnapshot.data);
      this.checkForHideBasicFilterFunctionality(innermostSnapshot.data);
    });

    this.utilitiesService.sidebarToggled$.subscribe((isToggled) => {
      this.isSidebarExpanded = isToggled;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnChanges() {
    if (!this.currentAccount) {
      return;
    }

    if (this.currentEmployee && !this.isAuthenticated) {
      this.isAuthenticated = !!this.currentEmployee;
    }

    if (this.currentEmployee) {
      this.isAuthenticated = !!this.currentEmployee;
    }

    if (this.currentEmployee) {
      this.subscriptions.add(
        this.notificationsService.unreadNotificationsCount.subscribe((res) => {
          this.pendingNotifications = res;
        })
      );
    }
  }

  /**
   * On first load, check for any additional data in route to hide the header or filters within header
   */
  checkRouteDataOnFirstLoad() {
    const mostInnerSnapshot = this.getInnermostRouteSnapshot(this.route.snapshot);
    if (mostInnerSnapshot?.data) {
      this.checkHideHeaderFiltersOverride(mostInnerSnapshot.data);
      this.checkHideHeaderBar(mostInnerSnapshot.data);
      this.checkForHideBasicFilterFunctionality(mostInnerSnapshot.data);
    }
  }

  /**
   * Set the hideHeaderFiltersOverride if found in route data
   */
  checkHideHeaderFiltersOverride(data: Data) {
    this.hideHeaderFiltersOverride = data.hideHeaderFilters ? data.hideHeaderFilters : false;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Hides header bar if found in route data
   */
  checkHideHeaderBar(data: Data) {
    this.hideHeaderBar = false;
    const currentPlatform = window.innerWidth <= Constants.sm ? PlatformType.Mobile : PlatformType.Web;
    const hideHeaderForPlatforms = data.hideHeaderForPlatforms ? data.hideHeaderForPlatforms : [];
    this.hideHeaderBar = hideHeaderForPlatforms.some((platform) => platform === currentPlatform);
  }

  checkForHideBasicFilterFunctionality(data: Data) {
    this.hidePartialBasicFilters = data?.hideBasicHeaderFilters;
  }

  openProjectsModal(projectItem) {
    const modalConfig = { class: 'modal-large-add', ignoreBackdropClick: true };

    switch (projectItem.title) {
      case 'Task':
        this.bsModalRef = this.modalService.show(TaskAddModalComponent, modalConfig);
        break;
      case 'Risk/Issue':
        this.bsModalRef = this.modalService.show(RiskAddModalComponent, modalConfig);
        break;
      case 'Project':
        this.bsModalRef = this.modalService.show(ProjectAddModalComponent, modalConfig);
        break;
      case 'Sub-Task':
        this.bsModalRef = this.modalService.show(SubtaskAddModalComponent, modalConfig);
        break;
      case 'TaskGroup':
        const smallModalConfig = { class: 'modal-small-add', ignoreBackdropClick: true };
        this.bsModalRef = this.modalService.show(TaskGroupAddModalComponent, smallModalConfig);
        break;
      case 'Action':
        this.bsModalRef = this.modalService.show(ActionRiskAddModalComponent, modalConfig);
        break;
      default:
        break;
    }
  }
  openIncidentsModal() {
    const ngbModalOptions: ModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.bsModalRef = this.modalService.show(IncidentAddModalComponent, ngbModalOptions);
  }

  openLogsModal() {
    this.modalService.show(IncidentLogAddModal, this.modalConfig);
  }

  openJobsModal() {
    const modalConfig = { class: 'modal-full-screen', ignoreBackdropClick: true };
    this.modalService.show(JobAddModalComponent, modalConfig);
  }

  openAddRunsheetModal() {
    const modalConfig = { class: 'modal-large-add', ignoreBackdropClick: true };
    this.modalService.show(RunsheetAddModalComponent, modalConfig);
  }

  openAddIndicatorModal() {
    this.modalService.show(IndicatorAddModalNewComponent, {
      backdrop: true,
      class: 'modal-full-screen',
      ignoreBackdropClick: true,
    });
  }

  openAddIndicatorTemplateModal() {
    this.modalService.show(IndicatorTemplateAddModalComponent, {
      backdrop: true,
      class: 'modal-full-screen',
      ignoreBackdropClick: true,
    });
  }

  toggleSideNav(e?: Event) {
    e?.stopPropagation();
    this.utilitiesService.broadcastSidebarToggle(!this.isSidebarExpanded);
  }

  public setSidebarToggleButtonHidden(state) {
    const buttonElement = document.querySelector('.toggle-button-wrapper');
    if (buttonElement) {
      if (state) {
        buttonElement.classList.add('hidden');
      } else {
        buttonElement.classList.remove('hidden');
      }
    }
  }

  logout() {
    const dashboardLayout = JSON.parse(localStorage.getItem('dashboardLayout'));
    localStorage.clear();
    if (dashboardLayout) {
      localStorage.setItem('dashboardLayout', JSON.stringify(dashboardLayout));
    }

    this.cachingService.removeFixedEventPromptCookie();
    this.cachingService.removeFixedZonePromptCookie();
    this.authService.logOut();
  }

  get isSearchableModule(): boolean {
    return (
      !this.isSettingsRoute &&
      !this.isReportRoute &&
      !this.isRunsheetsRoute &&
      !this.isSustainabilityRoute &&
      !this.isAccountHubRoute
    );
  }

  get showProjectsAddLinks(): boolean {
    return (
      (this.isProjectsRoute || this.isSettingsRoute || this.isGanttRoute || this.isReportRoute) && this.currentAccount.usePlanning
    );
  }

  get isReportRoute(): boolean {
    return this.currentRoute.startsWith('/v2/reports');
  }
  get isMyTrackRoute(): boolean {
    return this.currentRoute.startsWith('/v2/mytrack');
  }
  get isSearchRoute(): boolean {
    return this.currentRoute.startsWith('/v2/search');
  }
  get isGanttRoute(): boolean {
    return this.currentRoute.startsWith('/v2/gantt');
  }
  get isIncidentsRoute(): boolean {
    return this.moduleService.isIncidentsModule && !this.currentRoute.startsWith('/v2/control/logs');
  }

  get isRiskRoute(): boolean {
    return this.currentRoute.startsWith('/v2/risk');
  }

  get isRunsheetsRoute(): boolean {
    return this.moduleService.isRunsheetModule;
  }

  get isSettingsRoute(): boolean {
    return this.currentRoute.startsWith('/v2/settings');
  }

  get isProjectsRoute(): boolean {
    return (
      this.moduleService.isVenuesModule &&
      !this.currentRoute.startsWith('/v2/settings') &&
      !this.currentRoute.startsWith('/v2/history')
    );
  }
  get isLogsRoute(): boolean {
    return this.currentRoute.startsWith('/v2/control/logs');
  }

  get isPlanningRoute(): boolean {
    return (
      this.moduleService.isVenuesModule ||
      (this.moduleService.isMyTrackModule && this.currentRoute.toLowerCase().includes('planning'))
    );
  }

  get isNotFoundRoute(): boolean {
    const isNotFound = (this.route.children[0]?.component as any)?.name === 'NotFoundComponent';
    return isNotFound;
  }

  get isSustainabilityRoute(): boolean {
    return this.currentRoute.startsWith('/v2/sustainability');
  }

  get isAccountHubRoute(): boolean {
    return this.currentRoute.startsWith('/v2/hub');
  }

  get isControlDashboardRoute(): boolean {
    return this.currentRoute.startsWith('/v2/control/dashboard');
  }

  get logsReportRefs(): ReportRefs {
    return ReportRefs.Custom_Logs;
  }

  get logsFilterTypes(): FilterTypes[] {
    const filterTypes: FilterTypes[] = [];
    filterTypes.push(FilterTypes.Filter_Dates_By);
    filterTypes.push(FilterTypes.Incident_Category);
    filterTypes.push(FilterTypes.Tag);
    filterTypes.push(FilterTypes.Created_By);
    return filterTypes;
  }

  LocaliseLabel(txt: string): string {
    return this.localisationService.localise(txt);
  }


  get canSeeRiskAddItems() {
    return this.isRiskRoute && this.currentAccount.showRiskAndIssues;
  }

  get canSeeIncidentAddItems() {
    return (
      (this.isIncidentsRoute || this.isLogsRoute || this.currentRoute.startsWith('/v2/history') || this.isSettingsRoute) &&
      this.currentAccount.useIMS
    );
  }

  get canSeeSustainabilityAddItems() {
    return this.isSustainabilityRoute;
  }
  get canSeeRunsheetsAddItems() {
    return (this.isRunsheetsRoute || this.isSettingsRoute) && this.currentAccount.useRunSheets;
  }

  /**
   * Show/hide filters whether it is a valid route or there is a HideFilters override in route data
   */
  get showFilters(): boolean {
    return (
      !this.isSettingsRoute &&
      !this.isReportRoute &&
      !this.isMyTrackRoute &&
      !this.isSearchRoute &&
      !this.hideHeaderFiltersOverride
    );
  }

  onSearch() {
    let searchInModule = '';
    if (this.isProjectsRoute) {
      searchInModule = ModuleTypes.Planning.toString();
    } else if (this.isIncidentsRoute || this.currentRoute.startsWith('/v2/control/logs')) {
      searchInModule = ModuleTypes.Incidents.toString();
    } else if (this.isRunsheetsRoute) {
      searchInModule = ModuleTypes.Runsheets.toString();
    } else {
      searchInModule = ModuleTypes.Planning.toString();
    }
    void this.router.navigate(['/v2/search'], { queryParams: { search: this.searchModel, searchIn: searchInModule } });
  }

  getPlaceholderText(): string {
    const localisedProjects = this.localisationService.localiseObjectType(ObjectTypes.Project, true);
    return this.isPlanningRoute
      ? this.translateService.instant(T.search.placeholder_for_objectType, { objectType: localisedProjects })
      : this.translateService.instant(T.search.placeholder_without_objectType);
  }

  showSearch() {
    this.searchModel = this.route.snapshot.queryParamMap.get('search');

    this.searchMode = true;
  }

  hideSearch() {
    this.searchMode = false;
    this.searchModel = undefined;
  }

  checkForAnyAddPermissions(): boolean {
    if (this.showProjectsAddLinks && this.canCreatePipe.transform(ModuleTypes.Planning, FilterTypes.Task)) return true;

    if (this.canSeeIncidentAddItems && this.canCreatePipe.transform(ModuleTypes.Incidents, FilterTypes.Incident)) return true;

    if (this.canSeeRunsheetsAddItems && this.canCreatePipe.transform(ModuleTypes.Runsheets, FilterTypes.Runsheet)) return true;

    if (this.isRiskRoute && this.canCreatePipe.transform(ModuleTypes.Risk, FilterTypes.Risk)) return true;

    if (this.isSustainabilityRoute) return true;

    return false;
  }

  public localiseSubTask(plural: boolean = false): string {
    return this.localisationService.localiseSubTask(plural);
  }

  /**
   * Iterates over the route snapshot to find the innermost route snapshot, the route that is currently active
   */
  private getInnermostRouteSnapshot(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    let innermostSnapshot = snapshot;
    while (innermostSnapshot.firstChild) {
      innermostSnapshot = innermostSnapshot.firstChild;
    }
    return innermostSnapshot;
  }
}
