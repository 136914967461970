import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

let inMemoryStorage: object = {};

@Injectable({
  providedIn: 'root',
})
export class WtStorageService {
  private isNativePlatform: boolean;

  constructor() {
    this.isNativePlatform = Capacitor.getPlatform() !== 'web';
  }

  getItem(key: string): string | null {
    if (this.isNativePlatform) {
      return inMemoryStorage[key];
    } else {
      return localStorage.getItem(key);
    }
  }

  removeItem(key: string): void {
    if (this.isNativePlatform) {
      delete inMemoryStorage[key];
      void Preferences.remove({ key: key });
    } else {
      localStorage.removeItem(key);
    }
  }

  setItem(key: string, data: string): void {
    if (this.isNativePlatform) {
      inMemoryStorage[key] = data;
      void Preferences.set({ key: key, value: data });
    } else {
      localStorage.setItem(key, data);
    }
  }

  clear(): void {
    if (this.isNativePlatform) {
      inMemoryStorage = {};
      void Preferences.clear();
    }
    const dashboardLayout = JSON.parse(localStorage.getItem('dashboardLayout'));
    localStorage.clear();
    if (dashboardLayout) {
      localStorage.setItem('dashboardLayout', JSON.stringify(dashboardLayout));
    }
  }

  /**
   * Because till this moment OAuthStorage works it synchronous data , we need to load the storage in memory
   */
  async loadAllDataInMemoryFromStorage() {
    const keysObj = await Preferences.keys();
    for (const key of keysObj.keys) {
      await Preferences.get({ key }).then((res) => {
        inMemoryStorage[key] = res.value;
      });
    }
  }
}
