import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { CheckViewModel } from '../../models/peaps/check';
import { AuthenticationService } from '../authentication.service';
import { Employee } from '../../models/employee';

@Injectable()
export class CheckService {
  private baseUrl: string = '';
  private employee: Employee;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly configuration: Configuration,
    private readonly authService: AuthenticationService
  ) {
    this.baseUrl = this.configuration.buildEndpoint('Check/');
    this.employee = this.authService.getCurrentEmployee();
  }

  update(viewModel: CheckViewModel): Observable<CheckViewModel> {
    return this.httpClient.put<CheckViewModel>(`${this.baseUrl}Update`, viewModel);
  }

  getDetails(checkId: number): Observable<CheckViewModel> {
    return this.httpClient.get<CheckViewModel>(`${this.baseUrl}GetDetails/${checkId}`);
  }

  getList(statusId: number): Observable<CheckViewModel[]> {
    return this.httpClient.get<CheckViewModel[]>(`${this.baseUrl}GetList/${statusId}`);
  }
}
