import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { AuthenticationService } from './authentication.service';
import { DependentTasks } from '../models/dependentTasks';
import { KeyValueItem } from '../models/keyValueItem';
import { Employee } from '../models/employee';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DependentTasksService {
  private currentEmployee: Employee;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
  }

  get(): Observable<DependentTasks> {
    return of(new DependentTasks());
  }

  update(dependentTasks: DependentTasks): Observable<DependentTasks> {
    return of(dependentTasks);
  }

  search(keyword: string): Observable<DependentTasks> {
    const url = this.configuration.buildEndpoint(`Gantt/utilities/Task/ByKeyword/${this.currentEmployee.id}/${keyword}`);
    const options = this.authenticationService.getRequestOptions();

    return this.http.get<DependentTasks>(url, options);
  }

  public GetDropDownValues = (filterType: number): Observable<KeyValueItem[]> => {
    const url = this.configuration.buildEndpoint(`Gantt/utilities/DropDowns/${this.currentEmployee.id}/${filterType}`);
    const options = this.authenticationService.getRequestOptions();

    return this.http.get<KeyValueItem[]>(url, options);
  };
}
