import { Injectable } from "@angular/core";
import { WtStorageService } from "../../shared/services/wt-storage.service";
import { GanttColumnSize } from "../utilities/gantt-column-size";

@Injectable()
export class GanttCachingService {
  private readonly localStorageKey = 'DHX_GANTT_GRID';

  /**
   * This is a temporary workaround of bug related to public gantt views.
   * ! In the future everything method accessing this variable will be removed.
   */
  private readonly localStorageGanttViewIdKey = 'GANTT_VEW_KEY';

  constructor(
    private readonly wtStorage: WtStorageService
  ) {}

  public saveCachedGanttViewID(id: number): void {
    this.wtStorage.setItem(this.localStorageGanttViewIdKey, `${id}`);
  }

  public getCachedGanttViewID(): number {
    let id = -1;
    if(this.wtStorage.getItem(this.localStorageGanttViewIdKey)) {
      try {
        id = +this.wtStorage.getItem(this.localStorageGanttViewIdKey);
      } catch(err) {
        id = -1;
      }
    }

    return id;
  }


  public getCachedGanttColumnsSize(): GanttColumnSize[] {
    if(this.wtStorage.getItem(this.localStorageKey)) {
      return JSON.parse(this.wtStorage.getItem(this.localStorageKey)) as GanttColumnSize[];
    }

    return [];
  }

  public saveCachedGanttColumnsSize(cachedData: GanttColumnSize[]): void {
    this.wtStorage.setItem(this.localStorageKey, JSON.stringify(cachedData));
  }

  public updateCachedGanttColumnSize(columnID: string, width: number) {
    const cachedColsData = this.getCachedGanttColumnsSize();
    const cached = cachedColsData.find(e => e.columnId === columnID);
    if(cached) {
      cached.size = width;
    } else {
      cachedColsData.push({
        columnId: columnID,
        size: width
      });
    }

    this.saveCachedGanttColumnsSize(cachedColsData);
  }

  public getCachedColumnSize(columnId: string): number {
    const cachedColsData = this.getCachedGanttColumnsSize();
    return cachedColsData.find(c => c.columnId === columnId)?.size ?? 0;
  }
}
