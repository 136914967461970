import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { CheckTemplateViewModel } from '../../models/peaps/checkTemplate';
import { ValidatedViewModel } from '../../viewModels/validatedViewModel';

@Injectable()
export class CheckTemplateService {
  private baseUrl: string = '';

  constructor(private readonly httpClient: HttpClient, private readonly configuration: Configuration) {
    this.baseUrl = this.configuration.buildEndpoint('CheckTemplate/');
  }

  add(viewModel: CheckTemplateViewModel): Observable<ValidatedViewModel> {
    return this.httpClient.post<ValidatedViewModel>(`${this.baseUrl}Add`, viewModel);
  }

  update(viewModel: CheckTemplateViewModel): Observable<CheckTemplateViewModel> {
    return this.httpClient.put<CheckTemplateViewModel>(`${this.baseUrl}Update`, viewModel);
  }

  updateViewOrders(viewModels: CheckTemplateViewModel[]): Observable<CheckTemplateViewModel[]> {
    return this.httpClient.put<CheckTemplateViewModel[]>(`${this.baseUrl}UpdateViewOrders`, viewModels);
  }

  delete(checkId: number): Observable<CheckTemplateViewModel> {
    return this.httpClient.delete<CheckTemplateViewModel>(`${this.baseUrl}Delete/${checkId}`);
  }

  getList(): Observable<CheckTemplateViewModel[]> {
    return this.httpClient.get<CheckTemplateViewModel[]>(`${this.baseUrl}GetList`);
  }
}
