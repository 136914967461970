import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ObjectTypes } from '../../shared/enums/objectTypes';
import { Account } from '../../shared/models/account';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { LightFilterUpdateModel } from '../../shared/models/filter/LightFilterUpdateModel';
import { ModifiableEntityViewModel } from '../../shared/viewModels/bases/modifiableEntityViewModel';
import { AccountHubDepartmentViewModel } from '../models/departments/accounthub-departments.model';
import { AccountHubDepartmentUpdateModel } from '../models/departments/accounthub-department-update.model';
import { AccountHubEmployeeViewModel } from '../models/employees/accounthub-employee.model';
import { AccountHubEmployeeUpdateModel } from '../models/employees/accountHub-employee-update.model';
import { AccountHubDepartmentCategoryViewModel } from '../models/departmentCategories/accounthub-department-categories.model';
import { AccountLocalisationViewModel } from '../../shared/viewModels/accountLocalisationViewModel';
import { AccountSettingViewModel } from '../../shared/viewModels/accountSettingViewModel';
import { HubUpdateGeneralSettingsViewModel } from '../models/settings/hub-update-general-settings.viewModel';
import { AccountSettingTypes } from '../../shared/enums/accountSettingTypes';
import { AccountDetailsViewModel } from '../../settings/viewModels/accountDetailsViewModel';
import { EnumBasedLocalisationViewModel } from '../../shared/viewModels/enumBasedLocalisationViewModel';
import { TaskAutoUpdateViewModel } from '../../settings/viewModels/taskAutoUpdateViewModel';

@Injectable({
  providedIn: 'root',
})
export class AccountHubService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`Hub/`);
  }

  updateObject(globalObjectId: number, accountID: number, objectType: ObjectTypes, changes: FilterViewModel[]): Observable<any> {
    const lightObject = new LightFilterUpdateModel();
    lightObject.globalObjectId = globalObjectId;
    lightObject.objectType = objectType;
    lightObject.accountId = accountID;
    lightObject.changes = changes;

    return this.http.post(`${this.actionUrl}UpdateLight`, lightObject);
  }

  getTable(objectType: ObjectTypes, filters: FilterViewModel[]): Observable<ModifiableEntityViewModel[]> {
    return this.http.post<ModifiableEntityViewModel[]>(`${this.actionUrl}table/${objectType}`, filters);
  }

  getReleatedAccounts(): Observable<Account[]> {
    return this.http.get<Account[]>(`${this.actionUrl}accounts`);
  }

  getHubEmployees():Observable<AccountHubEmployeeViewModel[]>{
    return this.http.get<AccountHubEmployeeViewModel[]>(`${this.actionUrl}employees`);
  }

  shareHubEmployees(updateModel:AccountHubEmployeeUpdateModel[]):Observable<void>{
    return this.http.post(`${this.actionUrl}employees/share`,updateModel).pipe(
      map((res: any) => res),
      catchError((err) => throwError(err))
    );
  }

  getAllowedFilters(): Observable<FilterViewModel[]> {
    return this.http.get<FilterViewModel[]>(`${this.actionUrl}users/allowedFilters`);
  }

  getHubDepartmentCategories(): Observable<AccountHubDepartmentCategoryViewModel[]> {
    return this.http.get<AccountHubDepartmentCategoryViewModel[]>(`${this.actionUrl}departmentCategory`);
  }


  getHubDepartments(): Observable<AccountHubDepartmentViewModel[]> {
    return this.http.get<AccountHubDepartmentViewModel[]>(`${this.actionUrl}departments`);
  }

  shareHubDepartment(updateModel:AccountHubDepartmentUpdateModel[]): Observable<any> {
    return this.http.post<ModifiableEntityViewModel[]>(`${this.actionUrl}departments/update`, updateModel);
  }

  archive(objectType: ObjectTypes, objectId: number): Observable<any> {
    return this.http.post(`${this.actionUrl}archive/${objectType}/${objectId}`, {});
  }


  //* Get all account localizations for parent and child accounts */
  getParentChildAccountLocalizations(): Observable<AccountLocalisationViewModel[]> {
    return this.http.get<AccountLocalisationViewModel[]>(`${this.actionUrl}accounts/localisations`);
  }

  //** Share Account localizations with all child accounts */
  shareAccountLocalisations(isShared: boolean): Observable<void> {
    return this.http.post<void>(this.actionUrl + `accounts/localisations/share/${isShared}`, {});
  }

  shareIncidentSeverities():Observable<void>{
    return this.http.get<void>(this.actionUrl + `control/settings/incident-severities/share`);
  }

  /** Share Incident Categories  & Checklists*/
  shareIncidentCategory(incidentCategoryId: number):Observable<void>{
    return this.http.get<void>(this.actionUrl + `control/settings/incident-category/share/${incidentCategoryId}`);
  }

  deleteIncidentCategory(incidentCategoryId: number):Observable<void>{
    return this.http.delete<void>(this.actionUrl + `control/settings/incident-category/delete/${incidentCategoryId}`);
  }

  shareChecklist(checklistId: number):Observable<void>{
    return this.http.get<void>(this.actionUrl + `control/settings/checklists/share/${checklistId}`);
  }


  /** Share account settings across all chlild accounts   */
  shareAccountGeneralSettings(settingsList:AccountSettingViewModel[],isFileUploaded:boolean): Observable<void> {

    const updateModel:HubUpdateGeneralSettingsViewModel =  {
      AccountSettings:settingsList,
      isFileUploaded:isFileUploaded
    };
    return this.http.post<void>(this.actionUrl + `accounts/settings/share`,updateModel);
  }

  /** Get account settings for all chlild accounts   */
  getChildAccountSettingTypes(settingTypes:AccountSettingTypes[]): Observable<AccountSettingViewModel[]> {
    return this.http.post<AccountSettingViewModel[]>(this.actionUrl + `accounts/childs/settings`,settingTypes);
  }

  /** Share Account Security settings with all child accounts */
  shareAccountSecurity(accountDetails:AccountDetailsViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + `accounts/settings/security`,accountDetails);
  }

  /**
   * Get account security settings for all chlild accounts   */
  getAccountSecurity(): Observable<AccountDetailsViewModel[]> {
    return this.http.get<AccountDetailsViewModel[]>(this.actionUrl + `accounts/childs/settings/security/all`);
  }


  getHubEnumBasedLocalisations(filterType:number):Observable<EnumBasedLocalisationViewModel[]>{
    return this.http.get<EnumBasedLocalisationViewModel[]>(this.actionUrl + `accounts/childs/enumbased/${filterType}`);
  }

/**
 * Share Hub Account Details across all child accounts
 */
  shareAccountDetails(hubAccountDetails:AccountDetailsViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + `accounts/childs/settings/share`,hubAccountDetails);
  }

  /**
   * Get child Account brands
   */
  getChildAccountBrands():Observable<AccountDetailsViewModel[]>{
    return this.http.get<AccountDetailsViewModel[]>(this.actionUrl + `accounts/childs/brands`);
  }


  shareAccountEnumBasedLocalisations(enumBasedLocalisation:EnumBasedLocalisationViewModel[]):Observable<void>{
    return this.http.post<void>(this.actionUrl + `accounts/childs/enumbased/share`,enumBasedLocalisation);
  }


  getPlanningTaskAutoRagUpdate():Observable<TaskAutoUpdateViewModel[]>{
    return this.http.get<TaskAutoUpdateViewModel[]>(this.actionUrl + `planning/settings/autorag`);
  }

  shareTaksRagAutoUpdate(model:TaskAutoUpdateViewModel[]):Observable<void>{
    return this.http.post<void>(this.actionUrl + `planning/settings/autorag/share`,model);
  }

  updateTaskRagAutoUpdate():Observable<void>{
    return this.http.post<void>(this.actionUrl + `planning/settings/autorag/refresh`,{});
  }

  updateBasicEmployeeDetails(employee:AccountHubEmployeeViewModel):Observable<void>{
    return this.http.post<void>(this.actionUrl + `employees/basic/update`,employee);
  }

  addBasicEmployeeDetails(employee:AccountHubEmployeeViewModel):Observable<void>{
    return this.http.post<void>(this.actionUrl + `employees/basic/add`,employee);
  }

  deleteHubEmpoloyee(employeeId:number):Observable<void>{
    return this.http.delete<void>(this.actionUrl + `employees/`+employeeId);
  }




}
