<div class="details-dropdown-wrapper">
  <span *ngIf="displayLabel" class="label">{{ label }}:</span>
  <div class="details-dropdown can-edit" *ngIf="canEdit">
    <div
      *forScreen="'desktop'"
      class="icon-title"
      (click)="showDropdownOptions($event)"
      ngDropdown
      [templateRef]="ddTemplate"
      [canOpen]="!handleClickExternally"
      (click)="onClick($event)"
    >
      <i class="material-icons dropdown-icon" *ngIf="showDropdownArrow && arrowPosition  === 'left'" [class.rotated-icon]="isDropdownOpen"
        >expand_more</i
      >
      <div class="d-flex flex-column">
        <span
          *ngIf="selectedFilterText"
          class="title text-truncate"
          [ngClass]="isStatus ? getStatusClass(selectedFilter) : ''"
          [class.status]="isStatus"
          >
          <i *ngIf="isAMilestoneFilter()" class="material-icons milestone-icon mr-2" [style.color]="getMilestoneIconColor()">flag</i>
          <span class="filter-text">{{ selectedFilterText }}</span>
          <i class="material-icons dropdown-icon left-icon" *ngIf="showDropdownArrow && arrowPosition === 'right' " [class.rotated-icon]="isDropdownOpen"
          >expand_more</i
          >
          </span>
        <span class="title text-truncate no-value" *ngIf="!selectedFilterText">{{
          T.common.no_item | translate: { item: label }
        }}</span>
        <ng-container *ngIf="statusBars">
          <div class="status-fill-boxes">
            <div
              class="status-fill-single-box"
              *ngFor="let item of settings; let i = index"
              [class.filled-box]="getSelectedFilterValue >= i + 1"
            ></div>
          </div>
        </ng-container>
      </div>
    </div>
    <ion-select
      *forScreen="'mobile'"
      [interfaceOptions]="{ cssClass: 'details-dropdown' }"
      interface="action-sheet"
      [placeholder]="selectedFilterText"
      (ionChange)="onFilterSettingSelected($event.detail.value)"
    >
      <ng-container *ngFor="let entry of restOptions">
        <ion-select-option [value]="entry">
          {{ entry.filterText }}
        </ion-select-option>
      </ng-container>
    </ion-select>
  </div>
  <div class="details-dropdown no-edit" *ngIf="!canEdit">
    <div class="icon-title" [app-tooltip]="{ message: canEditMessage, position: ['top'] }">
      <span
        *ngIf="selectedFilterText"
        class="title text-truncate"
        [ngClass]="[isStatus ? 'status' : '', isStatus ? getStatusClass(selectedFilter) : '' ]"
        >{{ selectedFilterText }}</span
      >
      <span class="title text-truncate no-value" *ngIf="!selectedFilterText">{{
        T.common.no_item | translate: { item: label }
      }}</span>
      <ng-container *ngIf="statusBars">
        <div class="status-fill-boxes">
          <div
            class="status-fill-single-box"
            *ngFor="let item of settings; let i = index"
            [class.filled-box]="getSelectedFilterValue >= i + 1"
          ></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #ddTemplate>
  <div class="template-wrapper">
    <app-filters-list-entries
      [required]="required"
      [entries]="restOptions"
      [selectedEntries]="[selectedFilter]"
      [mobile]="mobile"
      (entrySelected)="onFilterSettingSelected($event)"
    ></app-filters-list-entries>
  </div>
</ng-template>
