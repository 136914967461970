import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { UserAgentService } from '../../../services/user-agent.service';

@Component({
  selector: 'app-details-text-area',
  templateUrl: './details-text-area.component.html',
  styleUrls: ['./details-text-area.component.scss'],
})
export class DetailsTextAreaComponent implements OnInit, AfterViewInit {
  @Input() set input(val: string) {
    this.origintalInput = val;
    this.modifiedInput = val;
  }
  @Input() label: string;
  @Input() canEdit: boolean;
  @Input() placeholder: string;
  @Input() minLength = 3;
  @Input() emptyStateMessage: string = `${this.translateService.instant(T.common.click_to_add_description)}...`;
  @Output() update = new EventEmitter<string>();

  @ViewChild('textArea') textArea: ElementRef<HTMLElement>;

  public isFieldExpanded = false;
  public isEditMode = false;
  public origintalInput = '';
  public modifiedInput = '';
  public showEditButton = false;
  public isTouchDevice = false;
  public readonly T = T;

  constructor(
    private readonly translateService: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly userAgentService: UserAgentService
  ) {}

  ngOnInit(): void {
    this.userAgentService.isTouch$.subscribe((isTouch) => {
      this.isTouchDevice = isTouch;
    });
  }
  ngAfterViewInit(): void {
    //This is to fix the issue with the text area not expanding when the user clicks on the show_less button
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    }, 20);
  }

  /**
   * Toggles edit mode, which is used to show rich-text-editor
   */
  toggleEditMode(e?: Event) {
    e.stopPropagation();
    if (this.canEdit) {
      this.isEditMode = !this.isEditMode;
    }
  }

  /**
   * Value from rich text editor
   */
  changeInput(newValue: string) {
    this.modifiedInput = newValue;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Cancels edit mode and resets the input to the original value
   */
  cancelEdit(event?) {
    if (this.origintalInput !== this.modifiedInput) {
      this.modifiedInput = this.origintalInput;
    }
    event?.stopPropagation();
    this.isEditMode = false;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Saves the input and exits edit mode
   */
  onRichEditorSave(input: string) {
    this.update.next(input);
    this.isEditMode = false;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Check if the element is overflowing (e.g. should show "Show More" button")
   */
  isOverflowing(element: ElementRef<HTMLElement>) {
    if (element) {
      return (
        element.nativeElement.offsetHeight < element.nativeElement.scrollHeight ||
        element.nativeElement.offsetWidth < element.nativeElement.scrollWidth
      );
    }
  }

  /**
   * Toggles the field to be expanded or not
   */
  expandField() {
    this.isFieldExpanded = !this.isFieldExpanded;
    //This is to fix the issue with the text area not expanding when the user clicks on the show_less button
    setTimeout(() => {
      this.changeDetectorRef.markForCheck();
    }, 20);
  }
}
