import { Injectable } from '@angular/core';
import { Configuration } from '../../../app.constants';
import { Observable } from 'rxjs';
import { ObjectTypes } from '../enums/objectTypes';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { HistoryEntryViewModel } from '../viewModels/history-feed/historyEntryViewModel';
import { FilterViewModel } from '../models/filter/filterViewModel';

@Injectable()
export class ActivityService {
  constructor(private http: HttpClient, private configuration: Configuration, private authService: AuthenticationService) {}

  public getActivitiesForModule = (
    moduleType: ModuleTypes,
    objectType: ObjectTypes = ObjectTypes.Global,
    filters: FilterViewModel[]
  ): Observable<{ [key: string]: { key: number; value: HistoryEntryViewModel[] }[] }> => {
    const url = this.configuration.buildEndpoint(`History/ListByModule/${moduleType}/${objectType}`);
    const options = this.authService.getHttpOptions();

    return this.http.post<{ [key: string]: { key: number; value: HistoryEntryViewModel[] }[] }>(url, filters, options);
  };

  public getHistoryEntriesByItemId = (objectType: ObjectTypes, objectId: number): Observable<HistoryEntryViewModel[]> => {
    const url = this.configuration.buildEndpoint(`History/List/${objectType}/${objectId}`);
    const options = this.authService.getHttpOptions();

    return this.http.post<HistoryEntryViewModel[]>(url, options);
  };
}
