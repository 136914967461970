import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IncidentCategoryViewModel } from '../../settings/viewModels/incidentCategoryViewModel';
import { ZoneViewModel } from '../../settings/viewModels/zoneViewModel';
import { AreaViewModel } from '../../settings/viewModels/areaViewModel';
import { ValidatedViewModel } from '../viewModels/validatedViewModel';

@Injectable()
export class IncidentsSettingsService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`IncidentsSettings/`);
  }

  //categories

  getIncidentCategoriesViewModels(): Observable<IncidentCategoryViewModel[]> {
    return this.http.get<IncidentCategoryViewModel[]>(this.actionUrl + 'Categories/All');
  }

  addIncidentCategory(model: IncidentCategoryViewModel): Observable<IncidentCategoryViewModel> {
    return this.http.post<IncidentCategoryViewModel>(this.actionUrl + 'Categories/Add', model);
  }
  updateIncidentCategories(models: IncidentCategoryViewModel[]): Observable<void> {
    return this.http.put<void>(this.actionUrl + 'Categories/Update', models);
  }

  deleteIncidentCategories(models: IncidentCategoryViewModel[]): Observable<void> {
    return this.http.post<void>(
      this.actionUrl + 'Categories/Delete',
      models.map((m) => m.id)
    );
  }

  getIncidentZonesViewModels(): Observable<ZoneViewModel[]> {
    return this.http.get<ZoneViewModel[]>(this.actionUrl + 'Zones/All');
  }

  addZone(model: ZoneViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(this.actionUrl + 'Zones/Add', model);
  }

  updateZone(models: ZoneViewModel): Observable<ValidatedViewModel> {
    return this.http.put<ValidatedViewModel>(this.actionUrl + 'Zones/Update', models);
  }

  deleteIncidentZones(models: ZoneViewModel[]): Observable<void> {
    return this.http
      .post(
        this.actionUrl + 'Zones/Delete',
        models.map((m) => m.id)
      )
      .pipe(
        map((res: any) => res),
        catchError((err) => throwError(err))
      );
  }

  addArea(model: AreaViewModel): Observable<AreaViewModel> {
    return this.http.post<AreaViewModel>(`${this.actionUrl}Areas/Add`, model);
  }

  getAreas(): Observable<AreaViewModel[]> {
    return this.http.get<AreaViewModel[]>(`${this.actionUrl}Areas/All`);
  }

  updateArea(models: AreaViewModel): Observable<void> {
    return this.http.put<void>(this.actionUrl + 'Areas/Update', models);
  }

  deleteAreas(models: AreaViewModel[]): Observable<void> {
    return this.http
      .post(
        this.actionUrl + `Areas/Delete`,
        models.map((m) => m.id)
      )
      .pipe(
        map((res: any) => res),
        catchError((err) => throwError(err))
      );
  }
}
