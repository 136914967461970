<app-modal>
  <app-modal-header
    style="z-index: 50"
    [title]="'Manage Gantt Saved Views'"
    [showDelete]="false"
    (closed)="bsModalRef.hide()"
  ></app-modal-header>

  <app-modal-body class="custom-scrollbar">
    <ng-container *ngTemplateOutlet="manageSavedViews"></ng-container>
  </app-modal-body>

  <app-modal-footer>
    <ng-container>
      <app-button-rectangle
        class="button d-none d-md-block"
        [fitToContainer]="true"
        size="large"
        [text]="T.common.ok | translate"
        buttonTheme="primary"
        (clicked)="onClose()"
      ></app-button-rectangle>
    </ng-container>
  </app-modal-footer>
</app-modal>

<ng-template #manageSavedViews>
  <div class="modal-container">
    <div class="modal-info mt-2">
      <span class="modal-info-text">
        Some description goes here.
      </span>
    </div>
    <div class="saved-layouts-wrapper">
      <app-responsive-table
        [objects]="deletableLayouts"
        [tableHeaders]="tableHeaders"
        [selectableObjects]="false"
        [objectsPerPage]="50"
        [wholeRowSelection]="false"
        [adoptParentHeight]="false"
        [useSearch]="false"
      >
      </app-responsive-table>
    </div>
  </div>
</ng-template>

<ng-template #publicTemplate let-object="object">
  <ng-container *ngIf="object.public">
    <i class="material-icons">public</i>
  </ng-container>
</ng-template>

<ng-template #actionsTemplate let-object="object">
  <ng-container *ngIf="object.editable && !object.default">
    <div class="layouts-actions-container">
      <div class="action-delete" (click)="deleteLayout(object)">
        <i class="material-icons action-icon">delete</i>
      </div>
    </div>
  </ng-container>
</ng-template>
