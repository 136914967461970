import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../../app.constants';
import { AuthenticationService } from './authentication.service';
import { ItemSubscriptionViewModel } from '../models/itemSubscriptionViewModel';
import { SubscriberViewModel } from '../models/subscriberViewModel';
import { ObjectTypes } from '../enums/objectTypes';
import { ItemSubscriberUserGroupViewModel } from '../models/itemSubscriberUserGroupViewModel';
import { SubscriberUserGroupViewModel } from '../models/subscriberUserGroupViewModel copy';

@Injectable()
export class SubscriptionService {
  private actionUrl: string = '';
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`Subscriptions/`);
  }

  addItemSubscription(itemSubscriptionViewModel: ItemSubscriptionViewModel): Observable<SubscriberViewModel> {
    return this.http.post<SubscriberViewModel>(
      this.actionUrl + 'Subscribe',
      itemSubscriptionViewModel,
      this.authenticationService.getHttpOptions()
    );
  }

  removeItemSubscription(subscriptionId: number): Observable<any> {
    return this.http.delete(this.actionUrl + 'Unsubscribe/' + subscriptionId.toString(), this.authenticationService.getHttpOptions());
  }

  addItemGroupSubscription(
    itemSubscriptionViewModel: ItemSubscriberUserGroupViewModel
  ): Observable<SubscriberUserGroupViewModel> {
    return this.http.post<SubscriberUserGroupViewModel>(
      this.actionUrl + 'SubscribeGroup',
      itemSubscriptionViewModel,
      this.authenticationService.getHttpOptions()
    );
  }

  removeItemGroupSubscription(subscriptionId: number): Observable<any> {
    return this.http.delete(this.actionUrl + 'UnsubscribeGroup/' + subscriptionId.toString(), this.authenticationService.getHttpOptions());
  }

  removeItemSubscription_new(objectId: number, objectType: number): Observable<void> {
    return this.http.delete<void>(
      `${this.actionUrl}UnsubscribeFromItem/${objectId}/${objectType}`,
      this.authenticationService.getHttpOptions()
    );
  }

  getSubscribers(globalObjectId: number, globalObjectType: ObjectTypes): Observable<SubscriberViewModel[]> {
    return this.http.get<SubscriberViewModel[]>(
      this.actionUrl + 'Subscribers/' + globalObjectId.toString() + '/' + globalObjectType.toString(),
      this.authenticationService.getHttpOptions()
    );
  }

  getSubscriberGroups(globalObjectId: number, globalObjectType: ObjectTypes): Observable<SubscriberUserGroupViewModel[]> {
    return this.http.get<SubscriberUserGroupViewModel[]>(
      this.actionUrl + 'SubscriberGroups/' + globalObjectId.toString() + '/' + globalObjectType.toString(),
      this.authenticationService.getHttpOptions()
    );
  }
}
