import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { AuthenticationService } from './authentication.service';
import { ZoneViewModel } from '../viewModels/zoneViewModel';

@Injectable()
export class LocationService {
  baseUrl: string;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly configuration: Configuration,
    private readonly authenticationService: AuthenticationService
  ) {
    this.baseUrl = this.configuration.buildEndpoint(`Incident/`);
  }

  getZones() {
    return this.httpClient.get<ZoneViewModel[]>(
      this.baseUrl + `Nomenclatures/Zones`,
      this.authenticationService.getHttpOptions()
    );
  }
}
