<ng-container *forScreen="'mobile'">
  <div class="details-header">
    <div *ngIf="iconSvg" class="me-1" [innerHtml]="iconSvg | safeHtml"></div>

    <div #ref class="details-header-status-dropdown">
      <ng-content select="[header-object-title-mobile]"></ng-content>
    </div>
    <ng-container class="details-header-object-title" *ngIf="!ref.hasChildNodes()">
      {{ localisedItem }}
    </ng-container>

    <div class="d-flex align-items-center ms-auto">
      <ng-container *ngTemplateOutlet="backButton"></ng-container>
    </div>
    <ng-container *ngIf="showMoreButton">
      <ng-container *ngTemplateOutlet="moreButton"></ng-container>
    </ng-container>

    <div class="details-header-title">
      <ng-container *ngTemplateOutlet="titleField"></ng-container>
    </div>
  </div>
</ng-container>

<div *forScreen="'desktop'">
  <div class="details-header">
    <ng-container *ngIf="showBackNavigation">
      <div [class.me-4]="useBackButtonRightMargin">
        <ng-container *ngTemplateOutlet="backButton"></ng-container>
      </div>
    </ng-container>

    <div class="details-header-info d-flex align-items-center me-4">
      <ng-content select="[header-object-title-desktop]"></ng-content>
    </div>

    <div class="d-flex align-items-center ms-auto">
      <span *ngIf="item.refCode && showRefCode" class="details-header-ref">{{ item.refCode }}</span>
    </div>

    <div class="d-flex align-items-center ms-auto me-2">
      <span *ngIf="item.id && showId" class="details-header-ref">ID: {{ item.id }}</span>
    </div>
    <div class="action-button-container">
      <ng-content select="[header-action-button]"></ng-content>
    </div>
    <ng-container *ngIf="showMoreButton">
      <ng-container *ngTemplateOutlet="moreButton"></ng-container>
    </ng-container>

    <div class="details-header-title mt-2">
      <ng-container *ngTemplateOutlet="titleField"></ng-container>
    </div>
    <app-headline-status
      *ngIf="showHeadlineStatus"
      class="mb-2"
      [useSmallContainer]="true"
      [object]="item"
      (onChanged)="onHeadlineStatusUpdated($event)"
    ></app-headline-status>

    <div class="details-header-description task-details-border-bottom mt-2" *ngIf="showTaskDetailsButtons">
      <app-button-rectangle
        class="me-2"
        buttonTheme="white"
        icon="check_circle"
        [disabled]="!canCompleteTask"
        [text]="T.common.task_complete | translate"
        (click)="onCompleteTask()"
      ></app-button-rectangle>

      <app-button-rectangle
         class="me-2"
        buttonTheme="white"
        icon="add_link"
        [text]="T.common.add_dependency | translate"
        (click)="onAddTaskDependency()"
      ></app-button-rectangle>

      <app-button-rectangle
        buttonTheme="white"
        icon="chat"
        [text]="T.common.add_comment | translate"
        (click)="onAddTaskComment()"
      ></app-button-rectangle>
    </div>

    <div class="details-header-description task-details-border-bottom mt-2" *ngIf="showIncidentDetailsButtons">
      <app-button-rectangle
        class="me-2"
        buttonTheme="white"
        icon="check_circle"
        [disabled]="!canCloseIncident"
        [text]="isIncidentClosed ? (T.common.open_items | translate: { items: localisedItem }) : (T.common.close_items | translate: { items: localisedItem })"
        (click)="onCloseIncident()"
      ></app-button-rectangle>

      <app-button-rectangle
        class="me-2"
        buttonTheme="white"
        icon="add_link"
        [text]="T.common.create_item | translate: { item: localisedJob }"
        (click)="onCreateJob()"
      ></app-button-rectangle>

      <app-button-rectangle
        buttonTheme="white"
        icon="chat"
        [text]="T.common.add_comment | translate"
        (click)="onAddIncidentComment()"
      ></app-button-rectangle>
    </div>

    <div class="details-header-description task-details-border-bottom mt-2" *ngIf="showJobDetailsButtons">
      <app-button-rectangle
        class="me-2"
        buttonTheme="white"
        icon="check_circle"
        [text]="T.common.complete_item | translate: { item: localisedItem }"
        [disabled]="!canCompleteJob"
        (click)="onCompleteJob()"
      ></app-button-rectangle>

      <app-button-rectangle
        buttonTheme="white"
        icon="chat"
        [text]="T.common.add_comment | translate"
        (click)="onAddJobComment()"
      ></app-button-rectangle>
    </div>


    <div class="details-header-description mt-2" *ngIf="showDescription">
      <app-details-text-area
        [input]="item.description ? item.description : ''"
        [label]="T.defaultLocalizations.description.one | translate"
        [placeholder]="T.common.add_description | translate"
        [canEdit]="canEdit"
        (update)="updateDescription($event)"
      ></app-details-text-area>
    </div>
    <div class="details-header-description mt-4" *ngIf="additionalTextFieldProp">
      <app-details-text-area
        [input]="item[additionalTextFieldProp] ? item[additionalTextFieldProp] : ''"
        [label]="additionalTextFieldLabel"
        [placeholder]="T.common.add_item | translate: { item: additionalTextFieldLabel }"
        [canEdit]="canEdit"
        [emptyStateMessage]="T.common.click_to_add_additional_info | translate"
        (update)="updateAdditionalField($event)"
      ></app-details-text-area>
    </div>
  </div>
</div>

<ng-template #titleField>
  <div class="logo-title-container">
    <ng-container *ngIf="logoTemplate">
      <ng-container *ngTemplateOutlet="logoTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="useSimpleTitleEdit">
      <app-simple-editable-field
        [customWidth]="customSimpleEditableFieldWidth"
        [input]="item.title"
        [canEdit]="canEdit"
        [fontSize]="20"
        [lineClamp]="2"
        (onUpdate)="onTitleUpdate($event)"
      ></app-simple-editable-field>
    </ng-container>
    <ng-container *ngIf="!useSimpleTitleEdit">
      <app-details-editable-field
        [input]="item.title"
        [header]="T.defaultLocalizations.title.one | translate"
        [type]="editableFieldTypes.Header"
        [maxLength]="250"
        [canEdit]="canEdit"
        (onUpdate)="onTitleUpdate($event)"
        [placeholder]="T.defaultLocalizations.title.one | translate"
      ></app-details-editable-field>
    </ng-container>
  </div>
</ng-template>

<ng-template #moreButton>
  <app-button-icon-square
    class="ms-2"
    icon="more_horiz"
    (click)="$event.stopPropagation()"
    [closeUponSelection]="true"
    ngDropdown
    [templateRef]="moreButtonTemplate"
  ></app-button-icon-square>
</ng-template>

<ng-template #backButton>
  <app-back-button
    [label]="useBrowserBackNavigation ? (T.common.back | translate) : (T.common.back_to_list | translate)"
    (click)="goBack()"
  ></app-back-button>
</ng-template>
