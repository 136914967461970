import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { T } from 'src/assets/i18n/translation-keys';
import { BadgeVariant } from '../badge/badge.component';

@Component({
  selector: 'app-due-indication-badge',
  templateUrl: './due-indication-badge.component.html',
  styleUrls: ['./due-indication-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DueIndicationBadgeComponent {

  @Input() dueDate: string | Date;
  @Input() isComplete: boolean;
  @Input() variant: BadgeVariant = 'fat';

  constructor(private translateService: TranslateService) { }

  public getDueDateIndication(): { label: string; color: string } {
    if (!this.dueDate) {
      return null;
    }
    const dueDate = moment(this.dueDate);
    const today = moment();

    if (dueDate.isBefore(today) && !this.isComplete) {
      return { label: this.translateService.instant(T.common.overdue.one), color: 'var(--wt-red)' };
    } else if (dueDate.isSame(today, 'day')) {
      return { label: this.translateService.instant(T.common.due_today), color: 'var(--wt-black)' };
    } else if (dueDate.isSame(today.add(1, 'day'), 'day')) {
      return { label: this.translateService.instant(T.calendar.due_tomorrow), color: 'var(--wt-black)' };
    } else if (dueDate.isAfter(today.add(1, 'day'), 'day')) {
    const dueDays =  Math.abs(moment(this.dueDate).diff(moment(), 'days')) + 1;
      return {
        label: this.translateService.instant(T.calendar.due_in_count_days, { count: dueDays }),
        color: 'var(--wt-grey-dark)',
      };
    }
    return null;
  }

}
