import { Component, EventEmitter, OnInit } from '@angular/core';
import { EmployeeCustomGanttSettingViewModel } from '../../../models/gantt/ganttChartLayoutViewConfig';
import { T } from 'src/assets/i18n/translation-keys';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Employee } from '../../../models/employee';
import { AuthenticationService } from '../../../services/authentication.service';
import { WtValidators } from '../../../reactiveValidators/wtValidators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EmployeeUtil } from '../../../utilities/employee.utilities';

@Component({
  selector: 'app-gantt-layout-view-save-modal',
  templateUrl: './gantt-layout-view-save-modal.component.html',
  styleUrls: ['./gantt-layout-view-save-modal.component.scss']
})
export class GanttLayoutViewSaveModalComponent implements OnInit {
  onLayoutSave = new EventEmitter<EmployeeCustomGanttSettingViewModel>();
  onLayoutUpdate = new EventEmitter<EmployeeCustomGanttSettingViewModel>();

  public savedLayouts: EmployeeCustomGanttSettingViewModel[] = [];
  public layout: EmployeeCustomGanttSettingViewModel;
  public readonly T = T;
  public layoutForm: UntypedFormGroup;
  public isAdmin: boolean = false;
  public currentEmployee: Employee;
  public saveAsPublic: boolean = false;
  public selectedLayoutForUpdate: EmployeeCustomGanttSettingViewModel;

  constructor(
    private fb: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private readonly authenticationService: AuthenticationService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.isAdmin = EmployeeUtil.IsAdmin(this.currentEmployee);
    this.initNewForm();
  }

  get layoutFormIsValid(): boolean {
    return this.layoutForm.controls.title.valid;
  }

  /**
   * Make the currently selected layout as public
   */
  public onMakeLayoutAsPublic(isChecked: boolean): void {
    this.saveAsPublic = isChecked;
  }

  /**
   * Check if existing layout is selected and update the 'layoutForm' and 'selectedLayoutForUpdate' component properties
   */
  public onLayoutSelect(layout: EmployeeCustomGanttSettingViewModel): void {
    if (this.selectedLayoutForUpdate && this.selectedLayoutForUpdate.id === layout.id) {
      this.selectedLayoutForUpdate = undefined;
      this.layoutForm.controls.title.setValue('');
    } else {
      this.selectedLayoutForUpdate = layout;
      this.layoutForm.controls.title.setValue(layout.title);
    }
  }

  /**
   * Check if the current layout needs to update existing layout or save new  one
   */
  public saveNewLayout(): void {
    this.layout.title = (<string>this.layoutForm.controls.title.value).trim();
    this.layout.public = this.saveAsPublic;
    this.layout.default = false;

    if (this.selectedLayoutForUpdate) {
      this.layout.id = this.selectedLayoutForUpdate.id;
      this.onLayoutUpdate.next(this.layout);
    } else {
      this.layout.id = 0;
      this.onLayoutSave.next(this.layout);
    }
    this.bsModalRef.hide();
  }

  /**
   * Init layout form
   */
  public initNewForm(): void {
    this.layoutForm = this.fb.group({
      title: ['', { validators: [Validators.required, this.wtValidators.title(), this.wtValidators.restrictedChars([';'])] }],
    });
  }
}
