import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { ProjectListItemViewModel } from '../viewModels/projects/projectListItemViewModel';
import { ProjectDetailsViewModel } from '../viewModels/projects/projectDetailsViewModel';
import { ObjectTypes } from '../../shared/enums/objectTypes';
import { ValidatedViewModel } from '../../shared/viewModels/validatedViewModel';
import { StateService } from './state.service';
import { AllowedFiltersService } from '../../shared/services/allowed-filters.service';
import { OperationTypes } from '../../shared/enums/operationTypes';
import { FilterTypes } from '../../shared/enums/filterTypes';
import { map } from 'rxjs/operators';
import { IApiService } from '../../shared/interfaces/iHttpService';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { LightFilterUpdateModel } from '../../shared/models/filter/LightFilterUpdateModel';

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends StateService<ProjectListItemViewModel> implements IApiService {
  private actionUrl: string;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private readonly allowedFiltersService: AllowedFiltersService
  ) {
    super();
    this.actionUrl = this.configuration.buildEndpoint(`Project/`);
  }
  updateLight(item: LightFilterUpdateModel) {
    throw new Error('Method not implemented.');
  }

  pinnedList(
    objectType: ObjectTypes,
    filters: FilterViewModel[],
    showPinnedOnly: boolean = false
  ): Observable<ProjectListItemViewModel[]> {
    return this.http.post<ProjectListItemViewModel[]>(`${this.actionUrl}List/${objectType}/${showPinnedOnly}`, filters).pipe(
      map((res) => {
        if (!filters.length) this.updateList(res);
        return res;
      })
    );
  }

  details(projectId: number): Observable<ProjectDetailsViewModel> {
    return this.http.get<ProjectDetailsViewModel>(`${this.actionUrl}Details/${projectId}`);
  }

  add(project: ProjectDetailsViewModel) {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}Add`, project).pipe(
      map((x) => {
        this.addToList(x.returnModel as ProjectListItemViewModel);
        return x;
      })
    );
  }

  update(project: ModifiableEntityViewModel) {
    return this.http.post(`${this.actionUrl}Update`, project).pipe(
      map((x) => {
        this.updateInList(x as ProjectListItemViewModel);
        const project = x as ProjectDetailsViewModel;
        //this.allowedFiltersService.refreshFilters(OperationTypes.Update, [{id: project.id, title: project.title, relatedObjectId: 0}], FilterTypes.Project, true);
        return x as ModifiableEntityViewModel;
      })
    );
  }

  checkRefCodeUpdate(projectId: number, refCode: string) {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}CheckRefCodeUpdate/${projectId}/${refCode}`, null);
  }

  updateLozenges(project: ProjectListItemViewModel) {
    return this.http.post(`${this.actionUrl}UpdateLozenges`, project).pipe(
      map((x) => {
        this.updateInList(x as ProjectListItemViewModel);
        const project = x as ProjectDetailsViewModel;
        this.allowedFiltersService.refreshFilters(
          OperationTypes.Update,
          [{ id: project.id, title: project.title, relatedObjectId: 0 }],
          FilterTypes.Project,
          true
        );
        return x;
      })
    );
  }

  delete(projectId: number) {
    return this.http.post(`${this.actionUrl}Delete/${projectId}`, null).pipe(
      map((x) => {
        this.deleteFromList(x as ProjectListItemViewModel);
        return x;
      })
    );
  }

  archive(projectId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive/${projectId}/${archive}`, null).pipe(
      map((x) => {
        this.deleteFromList(x as ProjectListItemViewModel);
        return x;
      })
    );
  }

  bulkArchive(projectIds: number[]) {
    return this.http.post(`${this.actionUrl}BulkArchive`, projectIds);
  }

  list(filters: FilterViewModel[]): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }
}
