import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalEventsEmitter } from '../../../events/bsModal.events';
import { AuthenticationService } from '../../../services/authentication.service';
import { Employee } from '../../../models/employee';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { EmployeeSettingsService } from '../../../services/employee-settings.service';
import { FilterTypes } from '../../../enums/filterTypes';
import { EmployeeSettingTypes } from 'src/app/modules/settings/enums/employeeSettingTypes';
import { Subscription, forkJoin, switchMap, take } from 'rxjs';
import { EmployeeSettingViewModel } from '../../../viewModels/employeeSettingViewModel';
import { LocalisationService } from '../../../services/localisation.service';
import { EmployeeCardFilterSetting } from '../../../models/employeeCardFilterSetting';
import { SortingService } from '../../../services/sorting.service';
import { AccountSettingsService } from '../../../services/account-settings.service';
import { AccountSettingTypes } from '../../../enums/accountSettingTypes';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { EmployeeCardShowingFilter } from '../../../models/employeeCardShowingFilter';
import { TaskGroupSortOptions } from '../../../enums/taskGroupSortOptions';
import { EmployeeUtil } from '../../../utilities/employee.utilities';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { Account } from 'src/app/modules/shared/models/account';
import { SortingFiltersService } from '../../../services/sorting-filters.service';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';

@Component({
  selector: 'app-filters-sorting-modal',
  templateUrl: 'filters-sorting-modal.component.html',
  styleUrls: ['filters-sorting-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSortingModalComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public useRiskAndIssues: boolean;
  public account: Account;

  employee: Employee;
  headerText: string;
  infoForItem: string;
  showSortingOptions: boolean = true;
  objectType: ObjectTypes = ObjectTypes.Project;
  objectTypes = ObjectTypes;
  incidentItemType: IncidentItemTypes = IncidentItemTypes.Incident;

  filters: FilterTypeSelectorViewModel[] = [];
  employeeCardFilterSettings: EmployeeCardFilterSetting[] = [];
  employeeCardFilterSetting: EmployeeCardFilterSetting;
  employeeSetting: EmployeeSettingViewModel;
  taskGroupSortOptions: { key: number; value: string }[] = [];
  selectedTaskGroupOption: { key: number; value: string };
  defaultEmployeeCardFilterSetting: EmployeeCardFilterSetting[] = [];
  public localisedTaskGroup: string = 'Task Group';
  objectTypeTitle: string = EnumUtilities.items(ObjectTypes).find((item) => item.key === this.objectType).value;

  cardSubtitleSetting: EmployeeSettingViewModel;
  public readonly T = T;

  subTitleSettingType: EmployeeSettingTypes;
  subtitleSettingChanged = false;

  constructor(
    public readonly bsModalRef: BsModalRef,
    protected readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly authenticationService: AuthenticationService,
    private readonly sortingFiltersService: SortingFiltersService,
    private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly accountSettingsService: AccountSettingsService,
    public readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly sortingService: SortingService,
    private readonly translateService: TranslateService
  ) {}

  onClose() {
    this.bsModalRef.hide();
  }

  ngOnInit() {
    this.taskGroupSortOptions.push({
      key: TaskGroupSortOptions.A_Z,
      value: this.translateService.instant(this.T.common.sort_edit.a_z_alphabetically),
    });
    this.taskGroupSortOptions.push({
      key: TaskGroupSortOptions.RAG,
      value: this.translateService.instant(this.T.defaultLocalizations.rag.one),
    });
    this.taskGroupSortOptions.push({
      key: TaskGroupSortOptions.Task_Count,
      value: this.translateService.instant(this.T.common.task_count),
    });
    this.taskGroupSortOptions.push({
      key: TaskGroupSortOptions.Task_Due_Date,
      value: this.translateService.instant(this.T.common.task_due_date),
    });
    this.objectTypeTitle = this.localisationService.localiseObjectType(this.objectType);
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.useRiskAndIssues = EmployeeUtil.hasAnyReadPermission(this.employee, ModuleTypes.Planning, FilterTypes.Risk);
    this.localisedTaskGroup = this.localisationService.localiseObjectType(ObjectTypes.Task_Group);

    if(!this.infoForItem) {
      this.infoForItem = this.translateService.instant(T.common.sort_edit.sort_edit_modal_info_for_item, {
        item: this.objectTypeTitle,
      });
    }

    this.subscriptions.add(
      this.sortingFiltersService.getSortingModalFilterTypes([this.objectType]).subscribe((res) => {
        this.filters = this.removeFiltersBasedOnAccountSetting(res);
        this.filters = this.filters.sort((a, b) => (a.viewOrder > b.viewOrder ? 1 : -1));
        this.changeDetectorRef.markForCheck();
      })
    );

    this.subscriptions.add(
      forkJoin([
        this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.Card_Filters),
        this.accountSettingsService.getAccountSettingByType(AccountSettingTypes.Default_Card_Filters)
      ]).subscribe(([employeeSettings, accountSettings]) => {
        this.employeeSetting = employeeSettings;
        if (employeeSettings && employeeSettings.value !== '') {
          this.employeeCardFilterSettings = JSON.parse(employeeSettings.value) as EmployeeCardFilterSetting[];
        } else {
          if (accountSettings && accountSettings.value) {
            this.employeeCardFilterSettings = JSON.parse(accountSettings.value) as EmployeeCardFilterSetting[];
          }
        }

        if (accountSettings && accountSettings.value) {
          this.defaultEmployeeCardFilterSetting = JSON.parse(accountSettings.value) as EmployeeCardFilterSetting[];
        }

        const settingBycurrentType = this.employeeCardFilterSettings.find((s) => s.objectType == this.objectType);
        if (settingBycurrentType) {
          this.employeeCardFilterSetting = settingBycurrentType;
        } else {
          this.employeeCardFilterSetting = new EmployeeCardFilterSetting();
          this.employeeCardFilterSetting.objectType = this.objectType;
          this.employeeCardFilterSetting.showingFilters = [];
        }

        if (this.objectType === ObjectTypes.Task_Group && this.employeeCardFilterSetting) {
          this.selectedTaskGroupOption = this.taskGroupSortOptions.find(
            (t) => t.key === this.employeeCardFilterSetting.primarySortFilter
          );
        }

        this.changeDetectorRef.markForCheck();
      })
    );
  }


  private removeFiltersBasedOnAccountSetting(filters: FilterTypeSelectorViewModel[]): FilterTypeSelectorViewModel[] {
    if (!this.useRiskAndIssues || !this.account.showRiskAndIssues) {
      filters = filters.filter((o) => {
        return (
          o.filterType !== FilterTypes.Risk_Breakdown &&
          o.filterType !== FilterTypes.Issue_Breakdown &&
          o.filterType !== FilterTypes.Opportunity_Breakdown
        );
      });
    }
    if (!this.account.useIndicatorFrameworks) {
      filters = filters.filter((f) => f.filterType !== FilterTypes.Organisation);
    }
    return filters;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSaveAndExit() {
    const existingSetting = this.employeeCardFilterSettings.find((s) => s.objectType === this.objectType);
    if (!existingSetting) {
      this.employeeCardFilterSettings.push(this.employeeCardFilterSetting);
    } else {
      this.employeeCardFilterSettings[this.employeeCardFilterSettings.indexOf(existingSetting)] = this.employeeCardFilterSetting;
    }

    this.employeeSetting.value = JSON.stringify(this.employeeCardFilterSettings);
    this.subscriptions.add(
      this.employeeSettingsService
        .updateEmployeeSetting(EmployeeSettingTypes.Card_Filters, this.employeeSetting).pipe(
          switchMap(() => this.sortingService.getRefreshedEmployeeCardFilterSettingAll().pipe(take(1)))
        )
        .subscribe(() => {
          this.sortingFiltersService.clearSortingModalVMByObjectType([this.objectType]);
          this.bsModalRef.hide();
        })
    );

    if(this.subtitleSettingChanged && this.subTitleSettingType && this.cardSubtitleSetting) {
      this.subscriptions.add(
        this.employeeSettingsService
          .updateEmployeeSetting(this.subTitleSettingType, this.cardSubtitleSetting)
          .subscribe(() => {
            this.employeeSettingsService.getToken(this.account.id).subscribe();
          })
      );
    }
  }

  isFilterChecked(filter: FilterTypeSelectorViewModel): boolean {
    let result = false;

    if (filter.isModalReadOnly) {
      result = true;
    } else {
      if (this.employeeCardFilterSetting && this.employeeCardFilterSetting.showingFilters) {
        if (
          this.employeeCardFilterSetting.showingFilters.findIndex(
            (a) => a.filterDateOption === filter.dateProperty && a.filterType === filter.filterType
          ) > -1
        ) {
          result = true;
        }
      }
    }

    return result;
  }

  changeFilterSetting(filter: FilterTypeSelectorViewModel): void {
    if (!this.employeeCardFilterSetting) {
      this.employeeCardFilterSetting = new EmployeeCardFilterSetting();
      this.employeeCardFilterSetting.objectType = this.objectType;
      this.employeeCardFilterSetting.showingFilters = [];
    } else if (!this.employeeCardFilterSetting.showingFilters) {
      this.employeeCardFilterSetting.showingFilters = [];
    }

    if (
      this.employeeCardFilterSetting.showingFilters.findIndex(
        (a) => a.filterDateOption === filter.dateProperty && a.filterType === filter.filterType
      ) === -1
    ) {
      const newemployee = new EmployeeCardShowingFilter();
      newemployee.filterType = filter.filterType;
      newemployee.filterDateOption = filter.dateProperty;

      this.employeeCardFilterSetting.showingFilters.push(newemployee);
    } else {
      this.employeeCardFilterSetting.showingFilters.splice(
        this.employeeCardFilterSetting.showingFilters.findIndex(
          (s) => s.filterDateOption === filter.dateProperty && s.filterType === filter.filterType
        ),
        1
      );
      if (this.isAppliedSorting(filter, true, false)) {
        const someCheckedFilter = this.filters.find((f) => this.isFilterChecked(f));
        if (someCheckedFilter) {
          this.changeAppliedSorting(someCheckedFilter, true, false);
        }
      } else if (this.isAppliedSorting(filter, true, true)) {
        const someCheckedFilter = this.filters.find((f) => this.isFilterChecked(f));
        if (someCheckedFilter) {
          this.changeAppliedSorting(someCheckedFilter, true, true);
        }
      }
    }

    // this.changeDetectorRef.markForCheck();
  }

  getFilterName(filter: FilterTypeSelectorViewModel) {
    const fType = this.localisationService.localiseFilterType(filter.filterType);

    if (fType) {
      if (filter.filterType === FilterTypes.Date) {
        return FilterDateOptions.translatedDateOptionWithDateSuffix(filter.dateProperty, this.translateService);
      } else {
        return fType;
      }
    }
  }

  isAppliedSorting(filter: FilterTypeSelectorViewModel, isPrimary: boolean, isAsc: boolean) {
    let result = false;
    if (this.employeeCardFilterSetting) {
      if (
        isPrimary &&
        this.employeeCardFilterSetting.primarySortFilter === filter.filterType &&
        this.employeeCardFilterSetting.primarySortAscending === isAsc
      ) {
        if (this.employeeCardFilterSetting.primarySortFilter === FilterTypes.Date) {
          if (this.employeeCardFilterSetting.primarySortFilterDateProperty === filter.dateProperty) {
            result = true;
          } else {
            result = false;
          }
        } else {
          result = true;
        }
      } else if (
        !isPrimary &&
        this.employeeCardFilterSetting.secondarySortFilter === filter.filterType &&
        this.employeeCardFilterSetting.secondarySortAscending === isAsc
      ) {
        if (this.employeeCardFilterSetting.secondarySortFilter === FilterTypes.Date) {
          if (this.employeeCardFilterSetting.secondarySortFilterDateProperty === filter.dateProperty) {
            result = true;
          } else {
            result = false;
          }
        } else {
          result = true;
        }
      }
    }
    return result;
  }

  changeAppliedSorting(filter: FilterTypeSelectorViewModel, isPrimary: boolean, isAsc: boolean) {
    if (this.isFilterChecked(filter)) {
      if (isPrimary) {
        this.employeeCardFilterSetting.primarySortFilter = filter.filterType;
        if (filter.filterType === FilterTypes.Date) {
          this.employeeCardFilterSetting.primarySortFilterDateProperty = filter.dateProperty;
        }
        this.employeeCardFilterSetting.primarySortAscending = isAsc;
      } else {
        this.employeeCardFilterSetting.secondarySortFilter = filter.filterType;
        if (filter.filterType === FilterTypes.Date) {
          this.employeeCardFilterSetting.secondarySortFilterDateProperty = filter.dateProperty;
        }
        this.employeeCardFilterSetting.secondarySortAscending = isAsc;
      }
    }
  }

  onTaskGroupSortMethodChanged(option: { key: number; value: string }) {
    this.selectedTaskGroupOption = option;
    this.employeeCardFilterSetting.primarySortFilter = option.key;
    this.employeeCardFilterSetting.primarySortAscending = true;
  }

  isChecked(index, item: FilterTypeSelectorViewModel) {
    return item.filterType;
  }

  get showEmptyLozenges() {
    if (this.employeeCardFilterSetting) {
      return this.employeeCardFilterSetting.showEmptyLozenges ? 'unchecked' : 'checked';
    }

    return 'unchecked';
  }

  changeEmptyLozengeState() {
    this.employeeCardFilterSetting.showEmptyLozenges = !this.employeeCardFilterSetting.showEmptyLozenges;
    this.changeDetectorRef.markForCheck();
  }

  resetSettings() {
    if (this.defaultEmployeeCardFilterSetting) {
      const matchingFilter = this.defaultEmployeeCardFilterSetting.find((s) => s.objectType === this.objectType);
      if (matchingFilter) {
        this.employeeCardFilterSetting = JSON.parse(JSON.stringify(matchingFilter)) as EmployeeCardFilterSetting;
      }
    }
  }

  changeSubtitleSetting(result: {type: EmployeeSettingTypes, setting: EmployeeSettingViewModel}) {
    this.subtitleSettingChanged = true;
    this.subTitleSettingType = result.type;
    this.cardSubtitleSetting = result.setting;
  }
}
