import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GoogleGeolocationResponseModel } from '../viewModels/GoogleGeolocationResponseModel';
import { MapsLoaderService } from './mapsLoader.service';
import { What3WordResponseModel } from '../../incidents/models/what3WordResponseModel';

@Injectable()
export class GeoLocationService {
  public static readonly W3W_API_KEY = 'H5RBLMXW';
  private static readonly WHAT3WORDS_CONVERT_TO_COORDINATES_URL: string =
    'https://api.what3words.com/v3/convert-to-coordinates?key=H5RBLMXW';
  private static readonly WHAT3WORDS_CONVERT_TO_ADDRESS_URL: string = 'https://api.what3words.com/v3/convert-to-3wa?key=H5RBLMXW';
  private static readonly WHAT3WORDS_GRID_SECTION_URL: string = 'https://api.what3words.com/v3/grid-section?key=H5RBLMXW';
  private static readonly WHAT3WORDS_AUTOSUGGEST_URL: string = 'https://api.what3words.com/v3/autosuggest?key=H5RBLMXW';

  geocoder: google.maps.Geocoder;
  constructor(
    private httpClient: HttpClient,
    private readonly mapsLoaderService: MapsLoaderService
  ) {
    this.mapsLoaderService.loadGoogleMapsAPIs().subscribe(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  public getPosition(): Observable<any> {
    return new Observable((observer) => {
      navigator.geolocation.getCurrentPosition(
        (pos) => observer.next(pos),
        (_err) => undefined,
        { enableHighAccuracy: true }
      );
    });
  }

  public getPositionFromGoogleGeolocationAPI(): Observable<GoogleGeolocationResponseModel> {
    const googleKey = this.mapsLoaderService.mapConfiguration.key;
    return this.httpClient.post<GoogleGeolocationResponseModel>(
      'https://www.googleapis.com/geolocation/v1/geolocate?key=' + googleKey,
      {}
    );
  }

  public getAddressFromCoordinates(lat: number, lng: number): Observable<google.maps.GeocoderResult[]> {
    return new Observable((observer) => {
      this.geocoder.geocode({
            location: {
              lat: lat,
              lng: lng,
            },
          },
          (results: google.maps.GeocoderResult[], _status: google.maps.GeocoderStatus) => {
            observer.next(results);
          }
      )
      .catch(() => undefined);
    });
  }

  public getWhat3WordsFromAddress(what3words: string): Observable<any> {
    const url = GeoLocationService.WHAT3WORDS_CONVERT_TO_COORDINATES_URL + '&words=' + what3words;
    return this.httpClient.get(url);
  }

  public getWhat3WordsFromCoordinates(lat: number, lng: number): Observable<What3WordResponseModel> {
    const url = `${GeoLocationService.WHAT3WORDS_CONVERT_TO_ADDRESS_URL}&coordinates=${lat},${lng}`;
    return this.httpClient.get<What3WordResponseModel>(url);
  }

  public getWhat3WordsGridSection(lat: number, lng: number, lat2: number, lng2: number): Observable<any> {
    const url = `${GeoLocationService.WHAT3WORDS_GRID_SECTION_URL}&bounding-box=${lat},${lng},${lat2},${lng2}&format=json`;
    return this.httpClient.get(url, {});
  }

  public getWhat3WordsAutoSuggest(input: string): Observable<any> {
    const url = GeoLocationService.WHAT3WORDS_AUTOSUGGEST_URL + '&input=' + input;
    return this.httpClient.get(url, {});
  }
}
