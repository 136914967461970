import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FilterTypes } from '../../../enums/filterTypes';
import { Constants } from '../../../models/constants';
import { UserAgentService } from '../../../services/user-agent.service';
import { Subscription } from 'rxjs';
import { FormHelperService } from '../../../services/form-helper.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextFieldComponent),
  multi: true,
};

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFieldComponent implements ControlValueAccessor, AfterViewChecked, AfterViewInit, OnDestroy {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(IonInput) mobileInput: IonInput;

  @Input() placeholder: string;
  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() required: boolean;
  @Input()
  set autofocus(value: boolean) {
    this._autofocus = value;
  }
  @Input() formGroup: UntypedFormGroup;
  @Input() formControlName: string;
  @Input() useNgDateMask: boolean;
  @Input() format: string;
  @Input() separator: string;
  @Input() icon: string;
  @Input() svg: string;
  @Input() disabled = false;
  @Input() helperText: string;
  @Input() disableMargin = false;
  @Input() errorMessage: string;
  @Input() displayError: boolean;
  @Input() pattern: string;
  @Input() useReactiveMessages: boolean = false;
  @Input() showInputCountdown: boolean = true;
  @Input() type: string = 'text';
  @Input() filterType: FilterTypes;
  @Input() removeBorders: boolean = false;

  @Input() allowSpecialCharacters: boolean = true;
  @Input() restrictedCharacters: string[] = [];

  @Output() onChange = new EventEmitter<string | number | undefined | null>();
  @Output() focus = new EventEmitter<Event>();
  @Output() blur = new EventEmitter<Event>();

  // the internal data model
  private _value: string | number | undefined | null;
  // placeholders for the callbacks which are later provided by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (value: string | number | undefined | null) => void = () => {};
  private _autofocus: boolean;
  private subscriptions = new Subscription();

  /**
   * Errors to be displayed below the input
   */
  public errors: string[] = [];

  /**
   * Errors from Angular reactive forms
   */
  private reactiveErrorMessages: string[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private userAgentService: UserAgentService,
    private formHelperService: FormHelperService
  ) {}

  /**
   * Workaround for autofocus on mobile devices (https://github.com/ionic-team/ionic-framework/issues/18132)
   */
  ngAfterViewInit(): void {
    if (this.mobileInput && this.autofocus) {
      setTimeout(() => {
        void this.mobileInput.setFocus();
      }, 200);
    }

    if (this.formGroup && this.formControlName) {
      // Subscribes for fromGroup status changes to obtain errors
      this.subscriptions.add(
        this.formGroup?.controls?.[this.formControlName].statusChanges.subscribe((v: 'VALID' | 'INVALID') => {
          const controlErrors = this.formGroup.controls[this.formControlName].errors;
          this.reactiveErrorMessages = this.formHelperService.getErrorStringMessages(controlErrors, this.placeholder);
          this.setErrors();
        })
      );
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get autofocus() {
    // return this.userAgentService.isTouch ? false : this._autofocus
    // TODO - the above is commented in case this needs to be reverted
    return this._autofocus;
  }

  get inputIsPristine(): boolean {
    if (!this.input) {
      return false;
    }

    return this.input.nativeElement.classList.contains('ng-pristine');
  }

  get inputIsValid(): boolean {
    if (!this.input) {
      return false;
    }

    let valid: boolean = this.input.nativeElement.checkValidity();

    if (this.formGroup && this.formControlName) {
      valid = valid && this.formGroup.controls[this.formControlName] && this.formGroup.controls[this.formControlName].valid;
    }

    return valid;
  }

  get valueLength(): number {
    return typeof this.value === 'number' ? this.value.toString().length : this.value ? this.value.length : 0;
  }

  // get accessor
  get value(): string | number | undefined | null {
    return this._value;
  }

  // set accessor including call the onchange callback
  set value(v: string | number | undefined | null) {
    if (v !== this._value) {
      if (!this.allowSpecialCharacters || v.toString().indexOf('<') >= 0 || v.toString().toLowerCase().indexOf('javascript:') >= 0) {
        v = this.escapeHtml(v as string);
      }

      this._value = v;
      this.onChangeCallback(v);
      if (!this.useReactiveMessages) {
        this.setErrors();
      }
    }

    this.onChange.next(v);
  }

  onKeyDown(event: KeyboardEvent): void {
    if(this.restrictedCharacters.includes(event.key)){
      event.preventDefault();
    }
  }

  onFocus(e: Event) {
    this.focus.emit(e);
  }

  // set touched on blur
  onBlur(e: Event) {
    this.onTouchedCallback();

    this.blur.emit(e);
  }

  // from ControlValueAccessor interface
  writeValue(value: string) {
    if (value !== this._value) {
      this._value = value;
      this.changeDetectorRef.markForCheck();
    }
  }
  escapeHtml(value: string): string {
    if ((!this.allowSpecialCharacters && (this.type === 'text' || this.type === 'password')) || this.type === 'email') {
      value = value
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"');
    }

    if ((!this.allowSpecialCharacters && (this.type === 'text' || this.type === 'password'))) {
      value = value
        .replace(/&#039;/g, "'");
    }

    if(value.toLowerCase().indexOf('javascript:') !== -1) {
      value = value.replace('javascript:', '');
    }

    return value;
  }

  // from ControlValueAccessor interface
  registerOnChange(fn: (value: string) => void) {
    this.onChangeCallback = fn;
  }

  // from ControlValueAccessor interface
  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  get showHelperText() {
    return this.helperText && (this.inputIsPristine || (!this.inputIsPristine && this.inputIsValid)) && !this.displayError;
  }

  /**
   * Returns true if screen size is mobile
   */
  get isMobile() {
    return window.innerWidth <= Constants.sm;
  }

  /**
   * Set focus on input field, depending on platform (mobile or desktop).
   * Mobile uses Ionic's focus method, desktop uses native focus method.
   */
  public async setFocus() {
    if (this.input && !this.isMobile) {
      this.input.nativeElement.focus();
    } else if (this.mobileInput && this.isMobile) {
      await this.mobileInput.setFocus();
    }
  }

  /**
   * Set blur on input field, depending on platform (mobile or desktop).
   * Mobile uses Ionic's blur method, desktop uses native blur method.
   */
  public async setBlur() {
    if (this.input && !this.isMobile) {
      this.input.nativeElement.blur();
    } else if (this.mobileInput && this.isMobile) {
      const el = await this.mobileInput.getInputElement();
      el.blur();
    }
  }

  /**
   * set errors to display below input field
   */
  private setErrors() {
    this.errors = [];
    if (this.useReactiveMessages) {
      this.errors = [...this.reactiveErrorMessages];
    } else {
      if (this.displayError && !this.inputIsValid) {
        this.errors = [this.errorMessage];
      } else if (!this.inputIsPristine && !this.inputIsValid) {
        this.errors = [`${this.placeholder} is required`];
      }
    }

    this.errors = [...new Set(this.errors)];
  }

  /**
   * @returns input element, depending on platform (mobile or desktop).
   */
  public async getInputElement(): Promise<HTMLInputElement> {
    if (this.input && !this.isMobile) {
      return this.input.nativeElement;
    } else if (this.mobileInput && this.isMobile) {
      return await this.mobileInput.getInputElement();
    }
  }
}
