import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { PlanningDashboardService } from 'src/app/modules/planning/services/dashboard.service';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { DashboardWidgetsID } from '../../../enums/dashboardWidgetsID';

@Component({
  selector: 'app-dashboard-widget-config-modal',
  templateUrl: './dashboard-widget-config-modal.component.html',
  styleUrls: ['./dashboard-widget-config-modal.component.scss'],
})
export class DashboardWidgetConfigModalComponent {
  @Input() dashboardWidgetName: string;
  @Input() moduleType: ModuleTypes;

  private modalActionHandleClicked = new Subject<void>();
  public modalActionHandleClicked$ = this.modalActionHandleClicked.asObservable();

  public dashboardWidgetsID = DashboardWidgetsID;
  public readonly T = T;

  constructor(private planningDashboardService: PlanningDashboardService, public bsModalRef: BsModalRef) {}

  public onConfirm(): void {
    this.modalActionHandleClicked.next();
    this.onCancel();
  }

  public onCancel(): void {
    this.bsModalRef.hide();
  }
}
