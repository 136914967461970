import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RiskImpactTypeViewModel } from '../../shared/viewModels/planning/riskImpactTypeViewMode';
import { RiskRAGMatrixModel } from '../../shared/viewModels/planning/riskRAGMatrix';
import { TaskAutoUpdateViewModel } from '../viewModels/taskAutoUpdateViewModel';
import { ImpactTypeDescriptionsViewModel } from '../../shared/models/risks/impactTypeDescriptionsViewModel';
import { ImpactTypeDescriptionViewModel } from '../../shared/models/risks/impactTypeDescriptionViewModel';
import { ActionItemTypeViewModel } from '../../shared/viewModels/planning/actionItemTypeViewModel';
import { RiskStrategyViewModel } from '../../shared/viewModels/planning/riskStrategyViewModel';

@Injectable({
  providedIn: 'root',
})
export class PlanningSettingsService {
  private actionUrl: string;
  public taskAutoUpdates: TaskAutoUpdateViewModel[] = [];

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`PlanningSettings/`);
  }

  //#region Risk Impact Types

  getRiskImpactTypes(): Observable<RiskImpactTypeViewModel[]> {
    return this.http.get<RiskImpactTypeViewModel[]>(`${this.actionUrl}ImpactTypes/List`);
  }

  addRiskImpactType(riskImpactTypeVM: RiskImpactTypeViewModel): Observable<RiskImpactTypeViewModel> {
    return this.http.post<RiskImpactTypeViewModel>(`${this.actionUrl}ImpactTypes/Add`, riskImpactTypeVM);
  }

  updateRiskImpactType(riskImpactTypeVM: RiskImpactTypeViewModel) {
    return this.http.put(`${this.actionUrl}ImpactTypes/Update`, riskImpactTypeVM);
  }

  deleteRiskImpactType(riskImpactTypeId: number) {
    return this.http.delete(`${this.actionUrl}ImpactTypes/Delete/${riskImpactTypeId}`);
  }

  //#endregion

  //#region risk rag matrix

  getRiskRagMatrix(): Observable<RiskRAGMatrixModel[]> {
    return this.http.get<RiskRAGMatrixModel[]>(`${this.actionUrl}RiskRAGMatrix/Get`);
  }

  updateRiskRagmatrix(riskRAGMatrix: RiskRAGMatrixModel[]) {
    return this.http.put(`${this.actionUrl}RiskRAGMatrix/Update`, riskRAGMatrix);
  }

  resetRiskRagMatrix() {
    return this.http.post(`${this.actionUrl}RiskRAGMatrix/Reset`, null);
  }

  //#endregion

  //#region  task auto updates

  getAutoUpdates() {
    this.http.get<TaskAutoUpdateViewModel[]>(`${this.actionUrl}TaskAutoUpdates/All`).subscribe((autoUpdates) => {
      this.taskAutoUpdates = autoUpdates;
    });
  }

  getTaskAutoUpdates(): Observable<TaskAutoUpdateViewModel[]> {
    return this.http.get<TaskAutoUpdateViewModel[]>(this.actionUrl + `TaskAutoUpdates/All`);
  }

  addTaskAutoUpdate(model: TaskAutoUpdateViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + `TaskAutoUpdates/Add`, model);
  }

  updateTaskAutoUpdate(model: TaskAutoUpdateViewModel): Observable<void> {
    return this.http.put<void>(this.actionUrl + `TaskAutoUpdates/Update`, model);
  }

  deleteTaskAutoUpdate(taskAutoUpdateID: number): Observable<void> {
    return this.http.delete<void>(this.actionUrl + `TaskAutoUpdates/Delete/${taskAutoUpdateID}`);
  }

  runAutoRAGUpdates(): Observable<void> {
    return this.http.post<void>(this.actionUrl + `TaskAutoUpdates/Run`, null);
  }

  //#endregion

  //#region Risk Impact Type Descriptoins

  getImpactTypeDescriptions(): Observable<ImpactTypeDescriptionsViewModel[]>{
    return this.http.get<ImpactTypeDescriptionsViewModel[]>(`${this.actionUrl}ImpactTypeDescriptions/List`);
  }

  getmpactTypeDescriptionByImpactType(impactTypeId: number): Observable<ImpactTypeDescriptionsViewModel>{
    return this.http.get<ImpactTypeDescriptionsViewModel>(`${this.actionUrl}ImpactTypeDescriptions/ListByImpact/${impactTypeId}`);
  }

  addImpactTypeDescription(model: ImpactTypeDescriptionViewModel): Observable<ImpactTypeDescriptionViewModel> {
    return this.http.post<ImpactTypeDescriptionViewModel>(`${this.actionUrl}ImpactTypeDescriptions/Add`, model);
  }

  addImpactTypeDescriptions(model: ImpactTypeDescriptionsViewModel): Observable<void> {
    return this.http.post<void>(`${this.actionUrl}ImpactTypeDescriptions/AddForImpactType`, model);
  }

  updateImpactTypeDescriptions(model: ImpactTypeDescriptionsViewModel): Observable<void> {
    return this.http.put<void>(`${this.actionUrl}ImpactTypeDescriptions/UpdateForImpactType`, model);
  }

  updateImpactTypeDescription(model: ImpactTypeDescriptionViewModel): Observable<ImpactTypeDescriptionViewModel> {
    return this.http.put<ImpactTypeDescriptionViewModel>(`${this.actionUrl}ImpactTypeDescriptions/Update`, model);
  }
  //#endregion


    //#region Risk Action Types

    getActionTypes(): Observable<ActionItemTypeViewModel[]> {
      return this.http.get<ActionItemTypeViewModel[]>(`${this.actionUrl}ActionTypes/List`);
    }

    addActionType(vm: ActionItemTypeViewModel): Observable<ActionItemTypeViewModel> {
      return this.http.post<ActionItemTypeViewModel>(`${this.actionUrl}ActionTypes/Add`, vm);
    }

    updateActionType(vm: ActionItemTypeViewModel) {
      return this.http.put(`${this.actionUrl}ActionTypes/Update`, vm);
    }

    deleteActionType(actionTypeId: number) {
      return this.http.delete(`${this.actionUrl}ActionTypes/Delete/${actionTypeId}`);
    }

    getRelatedActionsCount(actionTypeId: number) {
      return this.http.get<number>(`${this.actionUrl}ActionTypes/GetRelatedActionsCount/${actionTypeId}`);
    }
    //#endregion

     //#region Risk Impact Types

     getRiskStrategies(): Observable<RiskStrategyViewModel[]> {
      return this.http.get<RiskStrategyViewModel[]>(`${this.actionUrl}Strategies/List`);
    }

    addRiskStrategy(riskStrategyVM: RiskStrategyViewModel): Observable<RiskStrategyViewModel> {
      return this.http.post<RiskStrategyViewModel>(`${this.actionUrl}Strategies/Add`, riskStrategyVM);
    }

    updateRiskStrategy(riskStrategyVM: RiskStrategyViewModel) {
      return this.http.put(`${this.actionUrl}Strategies/Update`, riskStrategyVM);
    }

    deleteRiskStrategy(riskStrategyId: number) {
      return this.http.delete(`${this.actionUrl}Strategies/Delete/${riskStrategyId}`);
    }

  //#endregion

}
