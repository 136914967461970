import { Pipe, PipeTransform } from '@angular/core';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { DepartmentVisibilities } from '../enums/departmentVisibility.enum';
import { EmployeeRoleTypes } from '../enums/employees/EmployeeRoleTypes';
import { FilterTypes } from '../enums/filterTypes';
import { Employee } from '../models/employee';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { AuthenticationService } from '../services/authentication.service';
import { EmployeeUtil } from '../utilities/employee.utilities';

@Pipe({
  name: 'canCreate',
})
export class CanCreatePipe implements PipeTransform {
  private employee: Employee;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.employee = authenticationService.getCurrentEmployee();
  }

  transform(
    moduleType: ModuleTypes,
    filterType: FilterTypes,
    obj?: { id: number; filters: FilterViewModel[]; permissionFilters: FilterViewModel[] }
  ): boolean {
    if (EmployeeUtil.IsAdmin(this.employee)) {
      return true;
    }

    const primaryFilters = {
      [FilterTypes.Risk]: [FilterTypes.Risk],
      [FilterTypes.Project]: [FilterTypes.Project, FilterTypes.Task, FilterTypes.Task_Group],
      [FilterTypes.Incident]: [FilterTypes.Incident],
      [FilterTypes.Job]: [FilterTypes.Job],
    };

    const primaryFilterType = Object.keys(primaryFilters).find((r) => primaryFilters[r].some((r) => r == filterType));

    const availableProjects = obj
      ? obj.filters.filter((r) => r.filterType == FilterTypes.Project).map((r) => +r.filterValue)
      : [];

    const haveProjectRightPermissions = EmployeeUtil.hasWritePermission(
      this.employee,
      ModuleTypes.Planning,
      FilterTypes.Project,
      availableProjects,
      obj
    );

    const hasDepartmentPermissions = (filters: FilterViewModel[]) =>
      this.haveDepartmentPermissions(+primaryFilterType, moduleType, filters, haveProjectRightPermissions);

    let isOwner = false;

    if (obj) isOwner = obj.filters.some((e) => e.filterType == FilterTypes.Owner && e.filterValue == this.employee.id);

    if (obj && !isOwner) {
      isOwner = obj.permissionFilters.some((e) => e.filterType == FilterTypes.Owner && e.filterValue == this.employee.id);
    }

    if (isOwner) {
      return true;
    }

    if (primaryFilters[FilterTypes.Project].some((f) => f == filterType)) {
      if (EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Project_Admin)) {
        return true;
      }

      if (filterType == FilterTypes.Task_Group || filterType == FilterTypes.Task) {
        // If no object is passed that means that this is comming from regular lists and we need to check about hasAnyPermissions
        if (!obj) {
          return EmployeeUtil.hasAnyWritePermission(this.employee, ModuleTypes.Planning, FilterTypes.Project);
        }
        // If there is object we will continue to the next conditions
        if (haveProjectRightPermissions && hasDepartmentPermissions(obj.filters)) {
          return true;
        }

        //validate if you have Project owner permissions
        if (filterType == FilterTypes.Task_Group) {
        }
      }
    }

    if (
      filterType == FilterTypes.Risk &&
      (EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Risk_Manager) ||
        EmployeeUtil.hasAnyWritePermission(this.employee, ModuleTypes.Planning, FilterTypes.Risk))
    ) {
      return true;
    }

    if (
      filterType == FilterTypes.Incident &&
      (EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Control_Admin) ||
        EmployeeUtil.hasAnyWritePermission(this.employee, ModuleTypes.Incidents, FilterTypes.Incident))
    ) {
      return true;
    }

    if (
      filterType == FilterTypes.Job &&
      (EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Control_Admin) ||
        EmployeeUtil.hasAnyWritePermission(this.employee, ModuleTypes.Incidents, FilterTypes.Job))
    ) {
      return true;
    }

    if (
      (filterType == FilterTypes.Runsheet || filterType == FilterTypes.Runsheet_Item) &&
      (EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Runsheets_User) ||
        EmployeeUtil.hasAnyWritePermission(this.employee, ModuleTypes.Runsheets, FilterTypes.Runsheet) ||
        EmployeeUtil.hasAnyWritePermission(this.employee, ModuleTypes.Runsheets, FilterTypes.Runsheet_Item))
    ) {
      return true;
    }

    return false;
  }

  private haveDepartmentPermissions(
    primaryFilterType: FilterTypes,
    moduleType: ModuleTypes,
    objectFilters: FilterViewModel[],
    haveProjectRightPermissions: boolean
  ): boolean {
    const applicablePermissions = this.employee.permissions.find((e) => e.type == +primaryFilterType && e.write && e.value > -1);

    if (applicablePermissions) {
      if (applicablePermissions.departmentVisibility == DepartmentVisibilities.All_Departments) {
        return true;
      }

      if (applicablePermissions.departmentVisibility == DepartmentVisibilities.Nothing) {
        return false;
      }

      if (
        objectFilters.length == 1 &&
        objectFilters.some((e) => e.filterType == FilterTypes.Project) &&
        haveProjectRightPermissions
      ) {
        return true;
      } else {
        return objectFilters.some((e) => e.filterType == FilterTypes.Department && e.filterValue == this.employee.departmentId);
      }
    }

    return false;
  }

  /**
   *
   * Summary
   * Sometimes we will have add buttons like those in headers where we wont have obj: {id, filters} and then we need to search for HasAnyWritePermissions
   * The other case is where in the nested lists in details pages we need to look for particular object
   *
   * Logic
   * # Project
   *   ## Project can by created by Project Admin || Admins
   *   IF User Is Project Admin || Admins
   *
   * # Task Group
   *   ## Task_Groups can by created by anyone with Edit Permission for their parrent project Also ( Admins and Project_admin)
   *   IF User Is Project Admin || Admins || Has Write Permissions for the project
   * # Task
   *   ## Tasks can be created by anyone with Edit Permissions For for their parrent project Also  (Ganttt_Editor , Project_Admin and admins)
   *   IF User Is Project Admin || Admins || Has Write Permissions for the project
   *
   *
   * #Risk
   *   ## Risks Can be created from anyone With EditPermissions and also Risk_Manager and Admins
   *   IF Risk_Manager || Has WritePermissions
   * #Actions
   *   ## Actions follow the same logic for now
   *
   *
   *
   * Incidents
   *  Everyone who have Write Permissions can add incident,
   */
}
