import { Component, OnInit, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Employee } from '../../../models/employee';
import { AuthenticationService } from '../../../services/authentication.service';
import { Subscription, Subject } from 'rxjs';
import { LinkedItemsService } from '../../../services/linked-items.service';
import { ObjectLinkTypes } from '../../../enums/objectLinkTypes.enum';
import { ConfirmationService } from '../../../services/confirmation.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ObjectTypes } from '../../../enums/objectTypes';
import { IconUtilities } from '../../../utilities/icon.utilities';
import { IconTypes } from '../../../types/iconTypes';
import { LinkedItemObjectViewModel } from '../../../models/linkedItemObjects/linkedItemObjectViewModel';
import { ObjectSimpleViewModel } from '../../../models/linkedItemObjects/objectSimpleViewModel';
import { LinkedItemObjectsViewModel } from '../../../models/linkedItemObjects/linkedItemObjectsViewModel';
import { ObjectLinkDependencyTypes } from '../../../enums/objectLinkDependencyType.enum';
import { ListFilteringService } from '../../../services/utilities/list-filtering.service';
import { SortDropdownItem } from '../../../models/sortDropdownItem';
import { LinkedItemSortOpitions } from '../../rebuild/linked-items-list/linked-items-list.component';
import { RAGStatuses } from '../../../enums/ragStatuses';
import { LocalisationService } from '../../../services/localisation.service';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { EmployeeUtil } from '../../../utilities/employee.utilities';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { Account } from 'src/app/modules/shared/models/account';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';
import { FilterUtilities } from '../../../utilities/filter.utilities';

interface AllowedTypeModel {
  title: string;
  objectType: ObjectTypes;
  svg?: string;
}
@Component({
  selector: 'app-linked-items-add-modal',
  templateUrl: './linked-items-add-modal.component.html',
  styleUrls: ['./linked-items-add-modal.component.scss'],
})
export class LinkedItemsAddModalComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public allowedItems = [];
  public filteredallowedItems = [];
  public currentLinkedObj: ObjectSimpleViewModel;
  public publicIncidentReportsToLink: ObjectSimpleViewModel[];
  public selectedItemsToLink: LinkedItemObjectViewModel[] = [];
  public searchText: string = '';
  public readonly linkTypes = ObjectLinkTypes;
  public readonly dependencyTypes = ObjectLinkDependencyTypes;
  public searchPlaceholder: string;
  public loading = false;
  public currentlySelectedAllowedType: AllowedTypeModel;
  public itemCreated: Subject<boolean> = new Subject();
  public selectedSortOption: LinkedItemSortOpitions = LinkedItemSortOpitions.Selected;
  public filters: FilterViewModel[] = [];
  public useRiskAndIssues: boolean;
  public isPublicIncidentReport: boolean = false;
  public objectTypeToLink: ObjectTypes;

  public allowedItemTypes: AllowedTypeModel[] = [];
  public sortDropdownItems: SortDropdownItem[] = [
    {
      value: this.translateService.instant(T.common.sort_edit.a_z_alphabetically),
      handler: this.setSort.bind(this),
      parameters: [LinkedItemSortOpitions.A_Z],
    },
    {
      value: this.translateService.instant(T.common.sort_edit.rag_with_colors),
      handler: this.setSort.bind(this),
      parameters: [LinkedItemSortOpitions.RAG],
    },
    {
      value: this.translateService.instant(T.common.sort_edit.selected_first),
      handler: this.setSort.bind(this),
      parameters: [LinkedItemSortOpitions.Selected],
      default: true,
    },
  ];
  public account: Account;
  public readonly T = T;
  public localisedTasksAndSubtasks = 'Tasks/Sub-Tasks';
  public localisedProjects = 'Projects';
  public localisedRiskIssuesAndOpportunities = 'Risks, Issues & Opportunities';
  public localisedIndicators = 'Indicators';
  public localisedJobs = 'Jobs';
  public localisedLogs = 'Logs';
  public localisedIncidents = 'Incidents';
  private employee: Employee;
  private subscriptions = new Subscription();

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly elemRef: ElementRef,
    private readonly linkedItemService: LinkedItemsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService,
    private readonly bsModalRef: BsModalRef,
    private readonly listFilteringService: ListFilteringService,
    private readonly localisationService: LocalisationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.useRiskAndIssues = EmployeeUtil.hasAnyReadPermission(this.employee, ModuleTypes.Planning, FilterTypes.Risk);
    this.localiseItems();

    if(!this.isPublicIncidentReport) {
      if (this.currentLinkedObj?.objectType !== ObjectTypes.Job && this.currentLinkedObj?.objectType !== ObjectTypes.IncidentItem) {
        if (this.account.usePlanning) {
          this.allowedItemTypes.push({
            title: this.localisedTasksAndSubtasks,
            objectType: ObjectTypes.Task,
            svg: IconUtilities.getSvgForIconType(IconTypes.Task),
          });
          this.allowedItemTypes.push({
            title: 'Project',
            objectType: ObjectTypes.Project,
            svg: IconUtilities.getSvgForIconType(IconTypes.Project),
          });
        }
        if (this.useRiskAndIssues || this.account.showRiskAndIssues) {
          const localisedRisks = this.localisationService.localiseObjectType(ObjectTypes.Risk, true);
          const localisedIssues = this.translateService.instant(T.defaultLocalizations.issue.many);
          const localisedOpportunities = this.translateService.instant(T.defaultLocalizations.opportunity.many);
          this.allowedItemTypes.push({
            title: this.localisedRiskIssuesAndOpportunities,
            objectType: ObjectTypes.Risk,
            svg: IconUtilities.getSvgForIconType(IconTypes.Risk),
          });
        }
        if (this.account.useSustainability) {
          this.allowedItemTypes.push({
            title: this.localisedIndicators,
            objectType: ObjectTypes.Indicator,
            svg: IconUtilities.getSvgForIconType(IconTypes.Action),
          });
        }
      }

      if (this.currentLinkedObj?.objectType === ObjectTypes.Job) {
        if (this.account.useIMS || this.account.useIMSOnly) {
              this.allowedItemTypes.push({
              title: this.localisedIncidents,
              objectType: ObjectTypes.IncidentItem,
              svg: IconUtilities.getSvgForIconType(IconTypes.Task),
            });
        }
      } else if (this.currentLinkedObj.objectType === ObjectTypes.IncidentItem) {
        if (this.account.useIMS || this.account.useIMSOnly) {
            this.allowedItemTypes.push(
            {
              title: this.localisedJobs,
              objectType: ObjectTypes.Job,
              svg: IconUtilities.getSvgForIconType(IconTypes.Task),
            },
            {
              title: this.localisedIncidents,
              objectType: ObjectTypes.IncidentItem,
              svg: IconUtilities.getSvgForIconType(IconTypes.Issue),
            }
          );

        }
      }
    }

    if (this.isPublicIncidentReport && (this.account.useIMS || this.account.useIMSOnly)) {
      if (this.objectTypeToLink === ObjectTypes.Job) {
        this.allowedItemTypes.push({
          title: this.localisedJobs,
          objectType: ObjectTypes.Job,
        })
      } else if (this.objectTypeToLink === ObjectTypes.IncidentItem) {
        this.allowedItemTypes.push({
          title: this.localisedIncidents,
          objectType: ObjectTypes.IncidentItem,
        })
      } else if (this.objectTypeToLink === ObjectTypes.Log) {
        this.allowedItemTypes.push({
          title: this.localisedLogs,
          objectType: ObjectTypes.IncidentItem,
        })
      }
    }

    this.allowedItemTypes.forEach((i) => {
      i.title = this.localisationService.localise(i.title);
    });

    this.currentlySelectedAllowedType = this.allowedItemTypes[0];
    this.allowedItemTypes = this.allowedItemTypes.slice();
    this.initLinkedItems();
    this.initAllowedItemsToLink();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initAllowedItemsToLink() {
    if(this.objectTypeToLink === ObjectTypes.Log){
      const logFilter = FilterUtilities.GenerateFilter(FilterTypes.Item_Type, IncidentItemTypes.Log);

      this.filters = [logFilter];
    }

    this.loading = true;
    this.subscriptions.add(
      this.linkedItemService
        .getObjectSimpleList(this.currentlySelectedAllowedType.objectType, this.filters)
        .subscribe((items) => {
          if (this.currentLinkedObj?.objectType === this.currentlySelectedAllowedType.objectType) {
            this.allowedItems = items.filter((x) => x.id !== this.currentLinkedObj.id);
            this.filteredallowedItems = items.filter((x) => x.id !== this.currentLinkedObj.id);
          } else {
            this.allowedItems = items;
            this.filteredallowedItems = items;
          }
          this.setSort(this.selectedSortOption);
          this.filterList();
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        })
    );
    this.initSearchPlaceholder();

  }

  initLinkedItems() {
    if(!this.isPublicIncidentReport) {
      this.subscriptions.add(
        this.linkedItemService
          .getDetails(this.currentLinkedObj.id, this.currentLinkedObj.objectType, ObjectLinkTypes.Relation)
          .subscribe((res) => {
            this.selectedItemsToLink = res.linkedObjects.filter(
              (linkedObj) => linkedObj.objectLinkType === ObjectLinkTypes.Relation
            );

            this.changeDetectorRef.markForCheck();
          })
      );
    } else {
      this.publicIncidentReportsToLink.forEach((report) => {
        this.subscriptions.add(
          this.linkedItemService
            .getDetails(report.id, ObjectTypes.Public_Incident_Report, ObjectLinkTypes.Relation)
            .subscribe((res) => {
              const linkedItem = res.linkedObjects.filter(
                (linkedObj) => linkedObj.objectLinkType === ObjectLinkTypes.Relation
              );

              this.selectedItemsToLink = [...this.selectedItemsToLink, ...linkedItem];

              this.changeDetectorRef.markForCheck();
            })
        );
      })

    }


  }

  initSearchPlaceholder() {
    const selectedAllowedType = this.isPublicIncidentReport ? this.objectTypeToLink : this.currentlySelectedAllowedType.objectType;

    switch (selectedAllowedType) {
      case ObjectTypes.Task:
        this.searchPlaceholder = this.translateService.instant(T.common.search_item, { item: this.localisedTasksAndSubtasks });
        break;
      case ObjectTypes.Project:
        this.searchPlaceholder = this.translateService.instant(T.common.search_item, { item: this.localisedProjects });
        break;
      case ObjectTypes.Risk:
        this.searchPlaceholder = this.translateService.instant(T.common.search_item, {
          item: this.localisedRiskIssuesAndOpportunities,
        });
        break;
      case ObjectTypes.Indicator:
        this.searchPlaceholder = this.translateService.instant(T.common.search_item, { item: this.localisedIndicators });
        break;
      case ObjectTypes.Job:
        this.searchPlaceholder = this.translateService.instant(T.common.search_item, { item: this.localisedJobs });
        break;
      case ObjectTypes.IncidentItem:
        this.searchPlaceholder = this.translateService.instant(T.common.search_item, { item: this.localisedIncidents });
        break;
      case ObjectTypes.Log:
        this.searchPlaceholder = this.translateService.instant(T.common.search_item, { item: this.localisedLogs });
        break;
      default:
        break;
    }
  }

  onListSearch(searchText: string) {
    this.searchText = searchText;
    this.filterList();
  }

  filterList() {
    const items = this.allowedItems;
    this.filteredallowedItems = this.listFilteringService.performListSearchFilter(items, ['title'], this.searchText);
  }

  onItemTypeSelected(itemType: AllowedTypeModel) {
    this.currentlySelectedAllowedType = itemType;
    this.filters = [];
    this.initAllowedItemsToLink();
    this.setSort(this.selectedSortOption);
    this.filterList();
  }

  setSort(sortOption: LinkedItemSortOpitions) {
    this.selectedSortOption = sortOption;
    switch (sortOption) {
      case LinkedItemSortOpitions.A_Z: {
        this.filteredallowedItems = this.filteredallowedItems.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        break;
      }
      case LinkedItemSortOpitions.RAG: {
        this.filteredallowedItems = this.filteredallowedItems.sort(
          (a, b) => this.getRagSortOrder(a.status) - this.getRagSortOrder(b.status)
        );
        break;
      }
      case LinkedItemSortOpitions.Selected: {
        this.filteredallowedItems = this.filteredallowedItems.sort(
          (a, b) =>
            Number(this.selectedItemsToLink.some((c) => c.objectId == b.id)) -
            Number(this.selectedItemsToLink.some((c) => c.objectId == a.id))
        );
        break;
      }
    }
    this.filteredallowedItems = this.filteredallowedItems.slice();
    this.changeDetectorRef.markForCheck();
  }
  getRagSortOrder(rag: RAGStatuses) {
    if (rag === RAGStatuses.Red) {
      return 1;
    }
    if (rag === RAGStatuses.Amber) {
      return 2;
    }
    if (rag === RAGStatuses.Green) {
      return 3;
    }
    if (rag === RAGStatuses.Grey) {
      return 4;
    }
    if (rag === RAGStatuses.Blue) {
      return 5;
    }
    return 5;
  }

  confirmClose() {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
      () => {
        this.closeModal();
      },
      () => {
        //handle cancel
      },
      this.translateService.instant(T.common.confirm),
      false,
      this.translateService.instant(T.common.stay),
      this.translateService.instant(T.common.leave),
      'danger'
    );
  }

  onItemSelect(selectedItems: LinkedItemObjectViewModel[]) {
    this.selectedItemsToLink = selectedItems;
  }

  handleAction() {
    if(this.isPublicIncidentReport) {
      this.linkManyPIRs();
      return;
    }

    const currentLinkedObj: LinkedItemObjectsViewModel = {
      objectId: this.currentLinkedObj.id,
      objectType: this.currentLinkedObj.objectType,
      linkedObjects: this.selectedItemsToLink,
    };

    this.subscriptions.add(
      this.linkedItemService.create(currentLinkedObj, ObjectLinkTypes.Relation).subscribe((res) => {
        this.itemCreated.next(true);
        this.closeModal();
      })
    );
  }

  linkManyPIRs() {
    const payload = { Objects: [], linkedObjects: this.selectedItemsToLink};

    this.publicIncidentReportsToLink.forEach(report => {
      const PIRtoLink = {
        objectId: report.id,
        objectType: ObjectTypes.Public_Incident_Report
      }

      payload.Objects.push(PIRtoLink);
    })

    this.subscriptions.add(
      this.linkedItemService.linkMultipleItems(payload, ObjectLinkTypes.Relation).subscribe((res) => {
        this.itemCreated.next(true);
        this.closeModal();
      })
    );
  }

  onFiltersChanged(filters: FilterViewModel[]) {
    this.filters = filters;
    this.initAllowedItemsToLink();
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  /**
   * Localises or translate necessary strings
   */
  private localiseItems(): void {
    this.localisedTasksAndSubtasks = `${this.localisationService.localiseObjectType(
      ObjectTypes.Task,
      true
    )}/${this.localisationService.localiseSubTask(true)}`;
    this.localisedProjects = this.localisationService.localiseObjectType(ObjectTypes.Project, true);
    this.localisedRiskIssuesAndOpportunities = `${this.localisationService.localiseObjectType(
      ObjectTypes.Risk,
      true
    )}, ${this.translateService.instant(T.defaultLocalizations.issue.many)} & ${this.translateService.instant(
      T.defaultLocalizations.opportunity.many
    )}`;
    this.localisedIndicators = this.localisationService.localiseObjectType(ObjectTypes.Indicator, true);
    this.localisedJobs = this.localisationService.localiseObjectType(ObjectTypes.Job, true);
    this.localisedIncidents = this.localisationService.localiseObjectType(ObjectTypes.IncidentItem, true);
  }
}
