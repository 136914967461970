import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { ReportingService } from '../../../services/reporting.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { ReportItemViewModel } from '../../../viewModels/reportItemViewModel';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { OutputFormats } from '../../../../shared/enums/outputFormats';
import { saveAs } from 'file-saver';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

enum SaveReportModalTypes {
  Save_Modal = 1,
  Selection_Modal = 2,
}

@Component({
  selector: 'app-pdf-export-report-modal',
  templateUrl: './pdf-export-report-modal.component.html',
  styleUrls: ['./pdf-export-report-modal.component.scss'],
})
export class PdfExportReportModalComponent implements OnInit, OnDestroy {
  public triggerErrors: boolean;
  public currentEmployee: Employee;
  public reportItem: ReportItemViewModel;
  public reportSectionIndex: number = 0;
  public singleSectionExport: boolean;
  public form: UntypedFormGroup;
  public subscription = new Subscription();
  public loading;
  public readonly T = T;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly confirmationService: ConfirmationService,
    private readonly elemRef: ElementRef,
    private readonly authenticationService: AuthenticationService,
    private readonly reportingService: ReportingService,
    private readonly cacheService: CachingService,
    private readonly alertService: AlertService,
    private fb: UntypedFormBuilder,
    private fileOpener: FileOpener,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      reportTitle: [
        '',
        [Validators.minLength(3), Validators.maxLength(80), Validators.required, Validators.pattern(/([^\s].{1,}[^\s])/)],
      ],
      reportDescription: ['', Validators.maxLength(250)],
    });

    if (this.reportItem) {
      if (this.reportItem.title != 'New Report') {
        this.form.get('reportTitle').setValue(this.reportItem.title);
      }
      this.form.get('reportDescription').setValue(this.reportItem.description);
    }

    this.currentEmployee = this.authenticationService.getCurrentEmployee();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onApply() {
    if (!this.form.valid) {
      this.triggerErrors = true;
      return;
    }

    this.loading = true;
    this.reportItem.updatedById = this.currentEmployee.id;
    this.reportItem.title = this.form.controls.reportTitle.value.trim();

    this.reportItem.description = '';
    if (this.form.controls.reportDescription && this.form.controls.reportDescription.value) {
      this.reportItem.description = this.form.controls.reportDescription.value.trim();
    }
    this.reportingService.updateReportItemTitleAndDescription(this.reportItem).subscribe((a) => {
      if (this.singleSectionExport) {
        const singleSection = this.reportItem.reportItemSections.find((x) => x.viewOrder == this.reportSectionIndex);
        this.reportingService.download(OutputFormats.PDF, this.reportItem.id, singleSection.id).subscribe(async (file) => {
          if (Capacitor.getPlatform() === 'web') {
            saveAs(`data:${file.contentType};base64,${file.contents}`, `${file.name}${file.extension}`);
          } else {
            const result = await Filesystem.writeFile({
              path: `${file.name}${file.extension}`,
              data: `data:${file.contentType};base64,${file.contents}`,
              directory: Directory.Documents,
            });
            this.fileOpener
              .showOpenWithDialog(result.uri, file.contentType)
              .then(() => {})
              .catch((e) => console.log('Error opening file', e));
          }

          this.loading = false;
          this.bsModalRef.hide();
        });
      } else {
        this.reportingService.download(OutputFormats.PDF, this.reportItem.id, 0).subscribe(async (file) => {
          if (Capacitor.getPlatform() === 'web') {
            saveAs(`data:${file.contentType};base64,${file.contents}`, `${file.name}${file.extension}`);
          } else {
            const result = await Filesystem.writeFile({
              path: `${file.name}${file.extension}`,
              data: `data:${file.contentType};base64,${file.contents}`,
              directory: Directory.Documents,
            });
            this.fileOpener
              .showOpenWithDialog(result.uri, file.contentType)
              .then(() => {})
              .catch((e) => console.log('Error opening file', e));
          }
          this.loading = false;
          this.bsModalRef.hide();
        });
      }
    });
  }

  confirmClose() {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.reports.confirm_leave_without_exporting),
      () => {
        this.bsModalRef.hide();
      },
      () => {},
      this.translateService.instant(T.common.confirm),
      false,
      this.translateService.instant(T.common.stay),
      this.translateService.instant(T.common.leave),
      'danger'
    );
  }
}
