<app-modal [fullScreen]="true">
  <app-modal-header
    style="z-index: 50"
    [title]="T.sustainability.create_indicator_template | translate"
    [showDelete]="false"
    (closed)="bsModalRef.hide()"
  ></app-modal-header>

  <div class="top-navigation-container">
    <div class="top-back-icon" (click)="decreaseStep()">
      <span class="material-icons chevron-icon">chevron_left</span>
    </div>
    <div class="top-step-text">{{ currentStepText[currentStep] }}</div>
  </div>

  <app-threaded-beads style="padding: 0" [beads]="beads" [showBubbles]="false"></app-threaded-beads>

  <app-modal-body class="custom-scrollbar" style="flex: 1">
    <div class="modal-body-container wrapper" #wrapper>
      <div class="left-side">
        <ng-container>
          <ng-container *ngIf="currentStep === 0">
            <div class="left-side-wrapper">
              <ng-container *ngTemplateOutlet="addDetailsLeftSide"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="currentStep === 1">
            <div class="left-side-wrapper">
              <ng-container *ngTemplateOutlet="assignIndicatorLeftSide"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="currentStep === 2">
            <div class="left-side-wrapper" [style.width]="'700px !important'">
              <ng-container *ngTemplateOutlet="indicatorUpdateTypeLeftSide"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="currentStep === 3">
            <div class="left-side-wrapper" [style.width]="'460px !important'">
              <ng-container *ngTemplateOutlet="indicatorTrackingDetailsLeftSide"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="currentStep === 4">
            <ng-container *ngTemplateOutlet="finalStepLeftSide"></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="right-side">
        <ng-container *ngIf="!loading">
          <ng-container *ngIf="currentStep === 0">
            <ng-container *ngTemplateOutlet="addDetailsRightSide"></ng-container>
          </ng-container>
           <!--here update types  -->
          <ng-container *ngIf="currentStep === 1">
            <ng-container *ngTemplateOutlet="assignIndicatorRightSide"></ng-container>
          </ng-container>
          <ng-container *ngIf="currentStep === 2">
            <ng-container *ngTemplateOutlet="indicatorUpdateTypesRightSide"></ng-container>
          </ng-container>
          <ng-container *ngIf="currentStep === 3">
            <ng-container *ngTemplateOutlet="indicatorTrackingDetailsRightSide"></ng-container>
          </ng-container>
          <ng-container *ngIf="currentStep === 4">
            <ng-container *ngTemplateOutlet="finalStepRightSide"></ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="loading">
          <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
        </ng-container>
      </div>
    </div>
  </app-modal-body>

  <app-modal-footer>
    <app-button-rectangle
      *ngIf="currentStep && currentStep !== 5"
      [buttonTheme]="'white'"
      [class]="'button'"
      [fitToContainer]="true"
      [size]="'large'"
      [text]="T.common.cancel | translate"
      (clicked)="cancel()"
    ></app-button-rectangle>
    <app-button-rectangle
      *ngIf="currentStep !== 5"
      [disabled]="stepDisabled"
      [class]="'button'"
      [fitToContainer]="true"
      [size]="'large'"
      [text]="footerButtonText[currentStep]"
      (clicked)="increaseStep()"
    ></app-button-rectangle>
  </app-modal-footer>
</app-modal>


<ng-template #addDetailsLeftSide>
  <div class="left-side-information">
    <div class="left-side-top">
      <div class="left-side-title">{{ T.defaultLocalizations.indicator.many | translate }}</div>
      <div class="left-side-text border-bottom">
        {{ T.sustainability.indicaor_is_something_to_be_tested | translate }}
      </div>
    </div>
    <div class="left-side-middle border-bottom">
      <div class="left-side-text">
        {{ T.sustainability.describe_your_indicator_and_add_details | translate }}
      </div>
      <div class="attributes-wrapper">
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-icons">category</i>
            </div>
            <div class="attribute-title">{{ T.common.category.one | translate }}</div>
          </div>
          <div class="attribute-right">
            {{ T.sustainability.indicators_must_be_assigned_to_a_category | translate }}
          </div>
        </div>
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-icons">public</i>
            </div>
            <div class="attribute-title">{{ T.defaultLocalizations.un_sdg.many | translate }}</div>
          </div>
          <div class="attribute-right">
            {{ T.sustainability.link_your_indicators_to_any_17_un_goals | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="left-side-bottom">
      <div class="left-side-bottom-text">
        <a href="https://support.wetrack.com/en/collections/3537136-jobs-task-management" target="_blank" rel="noopener">{{
          T.common.want_learn_more_visit_help_centre | translate
        }}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #indicatorUpdateTypeLeftSide>
  <div class="left-side-information">
    <div class="left-side-top">
      <div class="left-side-title">{{ T.defaultLocalizations.indicator.many | translate }} test</div>
      <div class="left-side-text border-bottom">
        {{ T.sustainability.indicaor_is_something_to_be_tested | translate }}
      </div>
    </div>
    <div class="left-side-middle border-bottom">
      <div class="left-side-text">
        {{ T.sustainability.indicator_add_update_types_details | translate }}
      </div>
      <div class="attributes-wrapper">
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-icons">123</i>
            </div>
            <div class="attribute-title">{{ T.defaultLocalizations.update_type.many | translate }}</div>
          </div>
          <div class="attribute-right">
            {{ T.sustainability.indicator_select_update_type | translate }}
          </div>
        </div>
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-icons">currency_exchange</i>
            </div>
            <div class="attribute-title">{{ T.common.unit.many | translate }}</div>
          </div>
          <div class="attribute-right">
            {{ T.sustainability.link_unit_to_your_indicator | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="left-side-bottom">
      <div class="left-side-bottom-text">
        <a href="https://support.wetrack.com/en/collections/3537136-jobs-task-management" target="_blank"  rel="noopener">{{
          T.common.want_learn_more_visit_help_centre | translate
        }}</a>
      </div>
    </div>
  </div>
</ng-template>



<ng-template #indicatorTrackingDetailsLeftSide>
  <div class="left-side-information">
    <div class="left-side-top">
      <div class="left-side-title">{{ T.defaultLocalizations.indicator.many | translate }}</div>
      <div class="left-side-text border-bottom">
        {{ T.sustainability.indicaor_is_something_to_be_tested | translate }}
      </div>
    </div>
    <div class="left-side-middle">
      <div class="left-side-text">{{ T.sustainability.choose_how_indicator_to_be_tracked | translate }}</div>
      <div class="attributes-wrapper indicator-summary">
        <ng-container *ngTemplateOutlet="indicatorTrackingDetailsSummary"></ng-container>
      </div>
    </div>
    <div class="left-side-bottom">
      <div class="left-side-bottom-text">
        <a href="https://support.wetrack.com/en/collections/3537136-jobs-task-management" target="_blank"  rel="noopener">{{
          T.common.want_learn_more_visit_help_centre | translate
        }}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #assignIndicatorLeftSide>
  <div class="left-side-information">
    <div class="left-side-top">
      <div class="left-side-title">{{ T.defaultLocalizations.indicator.many | translate }}</div>
      <div class="left-side-text border-bottom">
        {{ T.sustainability.indicaor_is_something_to_be_tested | translate }}
      </div>
    </div>
    <div class="left-side-middle border-bottom">
      <div class="left-side-text">{{ T.sustainability.assign_indicator_to_individuals | translate }}</div>
      <div class="attributes-wrapper">
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-icons">account_circle</i>
            </div>
            <div class="attribute-title">{{ T.defaultLocalizations.owner.one | translate }}</div>
          </div>
          <div class="attribute-right">
            {{ T.sustainability.owners_are_those_responsible_for_completing | translate }}
          </div>
        </div>
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-icons">group</i>
            </div>
            <div class="attribute-title">{{ localisedDepartment }}</div>
          </div>
          <div class="attribute-right">
            {{
              T.sustainability.localisedDepartments_can_be_assigned_to_indicators
                | translate: { localisedDepartments: localisedDepartments }
            }}
          </div>
        </div>
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-icons">domain</i>
            </div>
            <div class="attribute-title">{{ localisedOrganisation }}</div>
          </div>
          <div class="attribute-right">
            {{
              T.sustainability.assign_indicators_to_localisedOrganisations
                | translate: { localisedOrganisations: localisedOrganisations }
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="left-side-bottom">
      <div class="left-side-bottom-text">
        <a href="https://support.wetrack.com/en/collections/3537136-jobs-task-management" target="_blank"  rel="noopener">{{
          T.common.want_learn_more_visit_help_centre | translate
        }}</a>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #addDetailsRightSide>
  <div class="add-details-container wrapper" [formGroup]="indicatorForm" #wrapper>
    <div class="mb-32 add-details-text-info">{{ T.sustainability.start_by_describing_indicator | translate }}</div>

    <div class="mb-32">
      <app-text-field
        [placeholder]="
          (T.common.item_title | translate: { item: T.defaultLocalizations.indicator.one | translate }) + '*'
        "
        [displayError]="triggerIndicatorErrors"
        formControlName="title"
        [formGroup]="indicatorForm"
        [maxLength]="250"
        [required]="true"
        [useReactiveMessages]="true"
        [autofocus]="true"
        [required]="true"
        [disableMargin]="true"
        [icon]="'event_note'"
        name="title"
      >
      </app-text-field>
      <div class="bottom-element d-flex mt-2 ms-2">
        <app-button-transparent
          [app-tooltip]="{ message: T.sustainability.add_indicator_title_helper_text | translate }"
          size="small"
          [text]="T.common.learn_more | translate"
        ></app-button-transparent>
      </div>
    </div>

    <div class="mb-32 category-container-wrapper">
      <div class="category-container">
        <app-filter-dropdown-new
          style="width: 100%"
          [filterType]="filterTypes.Indicator_Category"
          [appliedFilters]="indicatorTemplate.filters"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="(T.common.category.one | translate) + '*'"
          [displayError]="triggerIndicatorErrors"
          [required]="true"
          (onFilterUpdate)="updateIndicatorCategory($event)"
          [singleSelect]="true"
        >
        </app-filter-dropdown-new>
      </div>

      <div class="category-container">
        <app-filter-dropdown-new
          style="width: 100%"
          [filterType]="filterTypes.Sustainability_Scope"
          [appliedFilters]="indicatorTemplate.filters"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="T.settings.sustainability.scope | translate"
          [displayError]="triggerIndicatorErrors"
          [required]="true"
          (onFilterUpdate)="updateIndicatorScope($event)"
          [singleSelect]="true"
        >
        </app-filter-dropdown-new>
      </div>

      <div class="rating-star-container">
        <div class='priority-starts-container text-center w-100' >
          <app-priority
            [selectedPriority]="currentSelectedPriorityFilter"
            (priorityChanged)="onApply($event)"
            [showSelectPriorityLabel]="true"
            [filterType]="filterTypes.Indicator_Priority">
          </app-priority>
        </div>
      </div>
    </div>

    <div class="mb-32">
      <app-filter-dropdown-new
        [displayError]="triggerIndicatorErrors"
        [filterType]="filterTypes.UNSDG"
        [appliedFilters]="indicatorTemplate.filters"
        [typeahead]="true"
        [disableMargin]="true"
        [label]="T.sustainability.un_sustainability_goals | translate"
        (onFilterUpdate)="updateViewModel($event)"
      >
      </app-filter-dropdown-new>
      <div class="bottom-element d-flex mt-2 ms-2">
        <app-button-transparent
        [app-tooltip]="{ message: T.sustainability.unsdg_helper_text | translate }"
        size="small"
          [text]="T.common.learn_more | translate"
        ></app-button-transparent>
      </div>
    </div>

    <div class="mb-32">
      <app-details-editable-field
        [input]="indicatorTemplate.description ? indicatorTemplate.description : ''"
        [header]="T.defaultLocalizations.description.one | translate"
        [type]="editableFieldTypes.RichText"
        [canEdit]="true"
        (onUpdate)="onDescriptionChange($event)"
        (onInputChange)="onDescriptionChange($event)"
        [placeholder]="T.common.add_description | translate"
        [maxLength]="2000"
      >
      </app-details-editable-field>
    </div>
  </div>
</ng-template>

<ng-template #assignIndicatorRightSide>
  <div class="assign-details-container" [formGroup]="indicatorForm">
    <div class="mb-32 assign-details-text-info">
      {{ T.sustainability.who_is_responsible_for_these_indicators | translate }}
    </div>

    <div class="mb-32">
      <app-filter-dropdown-new
        [filterType]="filterTypes.Owner"
        [appliedFilters]="indicatorTemplate.filters"
        [disableMargin]="true"
        [typeahead]="true"
        [label]="T.common.assign_owner.many | translate"
        (onFilterUpdate)="updateViewModel($event)"
      >
      </app-filter-dropdown-new>
      <div class="bottom-element d-flex">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="T.common.assign_me | translate"
          (clicked)="addCurrentUserAsOwner()"
        ></app-button-transparent>
      </div>
    </div>

    <div class="mb-32">
      <app-filter-dropdown-new
        [filterType]="filterTypes.Department"
        [appliedFilters]="indicatorTemplate.filters"
        [disableMargin]="true"
        [typeahead]="true"
        [label]="T.common.assign_items | translate: { items: localisedDepartments }"
        (onFilterUpdate)="updateViewModel($event)"
      >
      </app-filter-dropdown-new>
      <div class="bottom-element d-flex">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
          (clicked)="addCurrentUserDepartment()"
        ></app-button-transparent>
      </div>
    </div>

    <!-- <div class="mb-32">{{ T.defaultLocalizations.tag.many | translate }}</div> -->
    <app-add-modal-tag-groups
      [filters]="indicatorTemplate.filters"
      [layoutClasses]="'col-12'"
      [tagGroupObjectType]="objectType"
      (onFiltersUpdated)="updateViewModel($event)"
    ></app-add-modal-tag-groups>
  </div>
</ng-template>

<ng-template #indicatorUpdateTypesRightSide>
  <div class="indicator-tracking-details-container" [formGroup]="indicatorForm">
    <div class="mb-32 indicator-tracking-details-text-info">
      {{ T.sustainability.how_do_you_want_indicators_to_be_updated | translate }}
    </div>

    <div class="mb-32">
      <app-filter-dropdown-new
        [filterType]="filterTypes.Indicator_Update_Type"
        [appliedFilters]="indicatorTemplate.filters"
        [disableMargin]="true"
        [typeahead]="true"
        [label]="(T.sustainability.update_type.one | translate) + '*'"
        [displayError]="triggerIndicatorErrors"
        [required]="true"
        (onFilterUpdate)="updateIndicatorUpdateType($event)"
        [singleSelect]="true"
      >
      </app-filter-dropdown-new>
      <div class="bottom-element d-flex mt-2 ms-2">
        <app-button-transparent
          [app-tooltip]="{  message: T.sustainability.update_type_helper_text | translate }"
          size="small"
          [text]="T.common.learn_more | translate"
        ></app-button-transparent>
      </div>
    </div>

    <div class="mb-32" *ngIf="indicatorTemplate.updateType === indicatorUpdateTypes.Numeric">

      <app-filter-dropdown-new
        [filterType]="filterTypes.Expected_Direction"
        [appliedFilters]="expectedDirectionFilters"
        [disableMargin]="true"
        [typeahead]="true"
        [label]="(T.sustainability.expected_direction.one | translate) + '*'"
        [displayError]="triggerIndicatorErrors"
        [required]="true"
        [currentlySelectedSettingValue]="expectedDirectionSelectedFilter?.filterValue"
        (onFilterUpdate)="updatedExpectedDirection($event)"
        [singleSelect]="true"
      >
      </app-filter-dropdown-new>
    </div>


    <div class="mb-32" *ngIf="indicatorTemplate.updateType === indicatorUpdateTypes.Numeric">
      <div class="mb-32 d-flex col-12">
        <div class="me-3 col-6">
          <app-filter-dropdown-new
            [filterType]="filterTypes.Unit_Type"
            [appliedFilters]="[unitTypeSelectedFilter]"
            [disableMargin]="true"
            [typeahead]="true"
            [label]="T.settings.sustainability.unit_measurements.unit_Type_label | translate"
            [displayError]="triggerIndicatorErrors"
            [required]="true"
            [currentlySelectedSettingValue]="unitTypeSelectedFilter?.filterValue"
            (onFilterUpdate)="updateIndicatorUnitType($event)"
            [singleSelect]="true"
          >
          </app-filter-dropdown-new>
          <div class="bottom-element d-flex mt-2 ms-2">
            <app-button-transparent
              [app-tooltip]="{ message: T.sustainability.add_indicator_unit_type_helper_text | translate }"
              size="small"
              [text]="T.common.learn_more | translate"
            ></app-button-transparent>
          </div>
        </div>
        <div class="me-3 col-6">
          <app-filter-dropdown-new
            [filterType]="filterTypes.Unit"
            [usePredefinedFilters]="true"
            [predefinedFilters]="unitFilters"
            [appliedFilters]="[unitSelectedFilter]"
            [disableMargin]="true"
            [typeahead]="true"
            [label]="T.settings.sustainability.unit_measurements.unit_label | translate"
            [currentlySelectedSettingValue]="unitSelectedFilter?.filterValue"
            (onFilterUpdate)="updateIndicatorUnit($event)"
            [singleSelect]="true"
            class="col-6"
          >
          </app-filter-dropdown-new>
          <div class="bottom-element d-flex mt-2 ms-2">
            <app-button-transparent
              [app-tooltip]="{ message: T.sustainability.add_indicator_unit_helper_text | translate }"
              size="small"
              [text]="T.common.learn_more | translate"
            ></app-button-transparent>
          </div>
        </div>
      </div>
      <div class="mb-32">
        <app-filter-dropdown-new
          [filterType]="filterTypes.Unit"
          [usePredefinedFilters]="true"
          [predefinedFilters]="unitFilters"
          [appliedFilters]="[convertionSelectedUnitFilter]"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="T.settings.sustainability.unit_measurements.unit_conversion_label | translate"
          [displayError]="triggerIndicatorErrors"
          [required]="true"
          [currentlySelectedSettingValue]="convertionSelectedUnitFilter?.filterValue"
          (onFilterUpdate)="updateConversionIndicatorUnit($event)"
          [singleSelect]="true"
          class="col-6"
          [disabled]="isConversionDisabled"

        >
        </app-filter-dropdown-new>
        <div class="bottom-element d-flex mt-2 ms-2">
          <app-button-transparent
            [app-tooltip]="{ message: T.sustainability.add_indicator_convert_value_to_helper_text | translate }"
            size="small"
            [text]="T.common.learn_more | translate"
          ></app-button-transparent>
        </div>
      </div>
    </div>


  </div>
</ng-template>

<ng-template #indicatorTrackingDetailsRightSide>
  <div class="indicator-tracking-details-container" [formGroup]="indicatorForm">
    <div class="mb-32 indicator-tracking-details-text-info">
      {{ T.sustainability.how_often_indicators_to_be_updated | translate }}
    </div>

    <div class="mb-32">
      <div class="mb-32 single-indicator-frequency-container">
        <div class="frequency-text-info">
          {{ T.sustainability.updates_for_indicator_should_be_provided | translate }}
        </div>

        <div class="tracking-date-value">
          <app-text-field
            [placeholder]="''"
            [displayError]="triggerIndicatorErrors"
            [maxLength]="2"
            [useReactiveMessages]="true"
            [required]="true"
            [disableMargin]="true"
            formControlName="trackingDateValue"
            [formGroup]="indicatorForm"
          >
          </app-text-field>
        </div>

        <div class="frequency-value">
          <app-filter-dropdown-new
            [filterType]="filterTypes.Indicator_Tracking_Date_Type"
            [appliedFilters]="indicatorTemplate.filters"
            [disableMargin]="true"
            [typeahead]="true"
            [label]="(T.sustainability.frequency | translate) + '*'"
            (onFilterUpdate)="updateTrackingDateType($event)"
            [singleSelect]="true"
          >
          </app-filter-dropdown-new>
        </div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #finalStepRightSide>


  <div
    class="final-step-buttons-wrapper"
    *ngIf="indicatorTemplate.updateType === indicatorUpdateTypes.Numeric"
  >

    <div class="mb-32">
      <app-button-rectangle
        [buttonTheme]="'primary'"
        [class]="'button'"
        [fitToContainer]="true"
        [size]="'large'"
        [text]="T.sustainability.go_to_indicator_template_details | translate"
        (clicked)="navigateToIndicatorTemplateDetailsPage()"
      ></app-button-rectangle>
    </div>
    <div class="mb-32">
      <app-button-rectangle
        [buttonTheme]="'white'"
        [class]="'button'"
        [fitToContainer]="true"
        [size]="'large'"
        [text]="T.sustainability.add_another_indicator_template | translate"
        (clicked)="addAnotherIndicator()"
      ></app-button-rectangle>
    </div>
  </div>

  <div
    class="final-step-buttons-wrapper"
    *ngIf="indicatorTemplate.updateType === indicatorUpdateTypes.Pass_Or_Fail"
  >
    <div class="mb-32">
      <app-button-rectangle
        [buttonTheme]="'white'"
        [class]="'button'"
        [fitToContainer]="true"
        [size]="'large'"
        [text]="T.sustainability.go_to_indicator_template_details | translate"
        (clicked)="navigateToIndicatorTemplateDetailsPage()"
      ></app-button-rectangle>
    </div>
    <div class="mb-32">
      <app-button-rectangle
        [buttonTheme]="'white'"
        [class]="'button'"
        [fitToContainer]="true"
        [size]="'large'"
        [text]="T.sustainability.add_another_indicator_template | translate"
        (clicked)="addAnotherIndicator()"
      ></app-button-rectangle>
    </div>
  </div>
</ng-template>

<ng-template #indicatorTrackingDetailsSummary>
    <div class="indicator-tracking-details-left-side">
      <div class="indicator-tracking-details-container">
        <div class="info-row">
          <div class="info-row-title">{{ T.sustainability.indicator_title | translate }}</div>
          <div class="info-row-text">{{ indicatorTemplate.title }}</div>
        </div>
        <div class="info-row">
          <div class="info-row-title">{{ T.sustainability.these_updates_will_happen | translate }}</div>
          <div class="info-row-text">{{ formatFrequencyText(indicatorTemplate.trackingDateType) }}</div>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #finalStepLeftSide>
  <div class="final-step-left-side">
    <div class="final-step-left-side-icon">
      <span class="material-icons check_circle-icon">check_circle</span>
    </div>
    <div class="final-step-left-side-title">
      {{ T.sustainability.indicator_template_added | translate }}
    </div>
    <div class="final-step-left-side-text">
      {{ T.sustainability.now_that_have_added_your_indicators | translate }}
    </div>
  </div>
</ng-template>

<ng-template #ratingStarTemplate let-i="index" let-v="value">
  <span class="material-icons-outlined" [ngStyle]="{ color: i < v ? 'var(--wt-black)' : 'var(--wt-grey-light)' }"
    >star</span
  >
</ng-template>

<ng-template #loadingTemplate><app-loading [displayLogo]="true"></app-loading></ng-template>
