<button
  class="rectangle-icon-btn"
  (click)="onClick($event)"
  [disabled]="disabled"
  [class.fit-to-container]="fitToContainer"
  [ngClass]="getButtonClasses()"
  [style.border-color]="borderColor"
>
  <div class="rectangle-icon-btn-container">
    <ng-container *ngIf="!loading">
      <i class="material-icons" *ngIf="icon" [style.color]="textColor" [style.margin.px]="text ? '0 8 0 -3' : '0'">{{
        icon
      }}</i>
      <div class="button-text text-truncate" [style.color]="textColor">{{ text }}</div>
      <span
        *ngIf="svg && svg.length"
        [style.margin-left.px]="!!text ? 8 : 'unset'"
        [class.disabled]="disabled"
        [innerHtml]="svg | safeHtml"
      >
      </span>
    </ng-container>
    <ng-container *ngIf="loading">
      <app-button-loader [ngClass]="getButtonClasses()"></app-button-loader>
    </ng-container>
  </div>
</button>
