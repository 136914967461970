import { Component, OnInit, HostListener, ChangeDetectorRef, ElementRef, OnDestroy } from '@angular/core';
import { Employee } from 'src/app/modules/shared/models/employee';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AddModalButtonOptions } from 'src/app/modules/shared/enums/addModalButtonOptions.enum';
import { AddModalKeyboardShortcuts } from 'src/app/modules/shared/enums/addModalKeyboardShortcuts.enum';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { RiskActionDetailsViewModel } from '../../../../risk/models/riskActionDetailsViewModel';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { ValidatedViewModel } from 'src/app/modules/shared/viewModels/validatedViewModel';
import { ActionRiskService } from '../../../services/action-risk.service';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { IconTypes } from 'src/app/modules/shared/types/iconTypes';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { RiskActionDetailsModalComponent } from 'src/app/modules/risk/components/control_actions/risk-action-details-modal/risk-action-details-modal.component';
import { RiskActionStatuses } from 'src/app/modules/risk/enums/riskActionStatusType.enum';

@Component({
  selector: 'app-action-risk-add-modal',
  templateUrl: './action-risk-add-modal.component.html',
  styleUrls: ['./action-risk-add-modal.component.scss']
})
export class ActionRiskAddModalComponent implements OnInit, OnDestroy {
  public action: RiskActionDetailsViewModel = new RiskActionDetailsViewModel();
  public filters: FilterViewModel[] = [];
  public filterTypes = FilterTypes;
  public isLoading = false;
  public isHiddenOptionVisible = false;
  public form: UntypedFormGroup;
  public dateReseter = false;
  public addModalButtonOptions = AddModalButtonOptions;
  public buttonOptions = AddModalButtonOptions.getOptions(this.translateService);
  public keyboardShortcuts = AddModalKeyboardShortcuts.getKeyShortsAsObj(this.translateService);
  public editableFieldTypes = EditableFieldTypes;
  public statusValue = 1;
  public typeValue = 1;
  public triggerErrors = false;
  public selectedRisk: FilterViewModel;
  public validationErrors: string[];
  public localisedAction = 'Action';
  svg = IconUtilities.getSvgForIconType(IconTypes.Risk_Action);

  private mobileMaxWidth = Constants.sm;
  private allowedFilters: FilterViewModel[];
  private subscriptions = new Subscription();
  private employee: Employee;
  private isTouched = false;
  public readonly T = T;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      event.preventDefault();
      if (event.ctrlKey || event.metaKey) {
        if (event.shiftKey) {
          this.handleAction(
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
          );
          return;
        }
        this.handleAction(
          AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
        );
        return;
      }
      this.handleAction(AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService));
      return;
    }
  }

  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    if (event.target.innerWidth < this.mobileMaxWidth) {
      this.isHiddenOptionVisible = true;
    }
  }

  constructor(
    private bsModalRef: BsModalRef,
    private router: Router,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private elemRef: ElementRef,
    private allowedFiltersService: AllowedFiltersService,
    private authenticationService: AuthenticationService,
    private actionService: ActionRiskService,
    private confirmationService: ConfirmationService,
    private readonly localisationService: LocalisationService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly bsModalService: BsModalService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.localisedAction = this.localisationService.localiseObjectType(ObjectTypes.Risk_Action_Item);

    this.form = this.fb.group({
      actionTitle: [
        '',
        { validators: [Validators.required, this.wtValidators.title(), this.wtValidators.restrictedChars([';'])], updateOn: 'blur' }
      ],
      description: ['', Validators.maxLength(1000)]
    });

    if (this.selectedRisk) {
      this.filters.push(this.selectedRisk);
    }

    this.subscriptions.add(
      this.form.valueChanges.subscribe((res) => {
        this.isTouched = true;
      })
    );

    this.initAllowedFilters();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleAction(action: string) {
    const isFiltersValid = this.validateRequiredFilters([FilterTypes.Risk]);
    if (!this.form.valid || !isFiltersValid) {
      this.triggerErrors = true;
      return;
    }

    this.addFilterByType(FilterTypes.Risk_Action_Type, this.typeValue);
    this.addFilterByType(FilterTypes.Risk_Action_Status, this.statusValue);

    if (action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService)) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res: ValidatedViewModel) => {
          const errors = res.errorList;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.isLoading = false;
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedAction }));
            this.closeModal();
          }
        })
      );
    } else if (
      action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
    ) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res: ValidatedViewModel) => {
          const errors = res.errorList;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.isLoading = false;
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedAction }));
            const currentAction = res.returnModel;
            const modalConfig: ModalOptions<RiskActionDetailsModalComponent> = {
              backdrop: true,
              ignoreBackdropClick: true,
              keyboard: false,
              class: 'modal-lg',
              initialState: {
                actionId: currentAction.id
              }
            };
            this.bsModalService.show(RiskActionDetailsModalComponent, modalConfig);
            this.changeDetectorRef.detectChanges();
            this.closeModal();
          }
        })
      );
    } else if (
      action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
    ) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res: ValidatedViewModel) => {
          const errors = res.errorList;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.isLoading = false;
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedAction }));
            this.resetModal();
          }
        })
      );
    }
  }

  handleSubmit() {
    this.action.filters = this.filters;
    // Get form values
    this.action.description = this.form.controls.description.value.trim();
    this.action.title = this.form.controls.actionTitle.value.trim();
    // Populate primitive properties from Filter list
    this.action.type = this.getFilterValue(FilterTypes.Risk_Action_Type);
    this.action.status = this.getFilterValue(FilterTypes.Risk_Action_Status);

    if(!this.action.status) {
      this.action.status = RiskActionStatuses.NotStarted;
    }

    return this.actionService.create(this.action);
  }

  validateRequiredFilters(filtersForSearch: FilterTypes[]): boolean {
    let isValid = true;
    filtersForSearch.forEach((currentFilterType) => {
      if (!this.filters.find((f) => f.filterType === currentFilterType)) {
        isValid = false;
      }
    });

    return isValid;
  }

  addFilterByType(filterType: FilterTypes, filterValue: number) {
    const existingFilter = this.filters.find((d) => d.filterType === filterType);
    if (!existingFilter) {
      const newFilter = FilterUtilities.GenerateFilter(
        filterType,
        filterValue,
        '',
        undefined,
        true,
        ObjectTypes.Risk_Action_Item
      );
      this.filters.push(newFilter);
    }
  }

  resetModal() {
    this.form.reset();
    this.filters = [];
    this.resetDateComponent();
  }

  resetDateComponent() {
    this.dateReseter = true;
    this.changeDetectorRef.detectChanges();
    this.dateReseter = false;
    this.changeDetectorRef.detectChanges();
  }

  getFilterValue(filterType: FilterTypes): number {
    const filter: FilterViewModel = this.action?.filters?.find((f) => f.filterType === filterType);
    return filter.filterValue ? (filter.filterValue as number) : 0;
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  showHiddenOptions() {
    this.isHiddenOptionVisible = !this.isHiddenOptionVisible;
    this.changeDetectorRef.detectChanges();
  }

  setAccountForCurrentOwner() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (s: any) => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.employee.id.toString()
      );
      if (empFilter !== null && this.filters.indexOf(empFilter) < 0) {
        this.filters = this.filters.slice();
        this.filters.push(empFilter);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  handleFilterChange(filters: FilterViewModel[]) {
    this.filters = filters;
    this.statusValue = this.filters.find((f) => f.filterType === FilterTypes.Risk_Action_Status)?.filterValue;
    this.typeValue = this.filters.find((f) => f.filterType === FilterTypes.Risk_Action_Type)?.filterValue;

    // Use this aproach because afterViewInit is triggered before emmiting the initial filters.
    // 4 are the initial filters, so thats the hacky way instead of AfterViewInit
    if (filters.length > 2) {
      this.isTouched = true;
    }
  }

  initAllowedFilters() {
    // Load them on ngOnInit (in the beggining) so when the client click on "Assign Me" the assign of the filter will be instant;
    this.allowedFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Owner);
  }

  confirmClose() {
    if (this.isTouched) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
        () => {
          this.closeModal();
        },
        () => {
          //handle cancel
        },
        this.translateService.instant(T.common.confirm),
        false,
        this.translateService.instant(T.common.stay),
        this.translateService.instant(T.common.leave),
        'danger'
      );
    } else {
      this.closeModal();
    }
  }

  updateDescription($event) {
    this.form.controls.description.setValue($event);
  }

  // get getStartDate() {
  //   if (this.action.startDate) {
  //     return this.action.startDate;
  //   }
  // }

  get getDueDate() {
    if (this.action.dueDate) {
      return this.action.dueDate;
    }
    return '';
  }
  // Set dates
  // setStartDate(dateAsIsoString: string) {
  //   this.action.startDate = dateAsIsoString;
  // }

  setDueDate(dateAsIsoString: string) {
    this.action.dueDate = dateAsIsoString;
  }
}
