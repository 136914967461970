import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, switchMap } from 'rxjs';
import { EmployeeCardFilterSetting } from '../models/employeeCardFilterSetting';
import { EmployeeSettingsService } from './employee-settings.service';
import { AuthenticationService } from './authentication.service';
import { Employee } from '../models/employee';
import { EmployeeSettingTypes } from '../../settings/enums/employeeSettingTypes';
import { ObjectEventEmitters } from '../events/object.events';
import { ObjectTypes } from '../enums/objectTypes';
import { AccountSettingsService } from './account-settings.service';
import { AccountSettingTypes } from '../enums/accountSettingTypes';
import { FilterTypes } from '../enums/filterTypes';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { FilterDateOptions } from '../enums/filter/filterDateOptions';
import { AllowedFiltersService } from './allowed-filters.service';
import { RAGStatuses } from '../enums/ragStatuses';
import { AuthService } from '../../auth/auth.service';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { PublicIncidentReportStatuses } from '../enums/publicIncidentReportStatuses';

@Injectable()
export class SortingService {
  public filtersForSortBasedOnEnum: FilterTypes[] = [
    FilterTypes.Milestone_Type,
    FilterTypes.Risk_Likelihood,
    FilterTypes.Risk_Impact,
    FilterTypes.Risk_Action_Type,
    FilterTypes.RAG,
    FilterTypes.Risk_RAG,
    FilterTypes.Incident_Severity,
    FilterTypes.Risk_Action_Status,
    FilterTypes.Incident_Resolution_Time,
    FilterTypes.Priority,
    FilterTypes.Indicator_Priority
  ];

  private sortRAGValuesMapper = {
    [RAGStatuses.Blue]: '0',
    [RAGStatuses.Red]: '1',
    [RAGStatuses.Amber]: '2',
    [RAGStatuses.Green]: '3',
    [RAGStatuses.Grey]: '4',
  };

  private sortPIRStatusMapper = {
    [PublicIncidentReportStatuses.New]: '0',
    [PublicIncidentReportStatuses.On_Hold]: '1',
    [PublicIncidentReportStatuses.Linked]: '2',
    [PublicIncidentReportStatuses.Closed]: '3',
  }

  public MappedEnumBasedFilterTypes = {
    [FilterTypes.RAG]: this.sortRAGValuesMapper,
    [FilterTypes.Public_Incident_Report_Status]: this.sortPIRStatusMapper
  };

  public filtersToSortByFilterValue = [
    FilterTypes.Title,
    FilterTypes.Date,
    FilterTypes.RAG,
    FilterTypes.Risk_RAG,
    FilterTypes.Risk_Impact,
    FilterTypes.Risk_Likelihood,
    FilterTypes.Incident_Severity,
    FilterTypes.Public_Incident_Report_Status
  ];

  private employee: Employee;
  private readonly _employeeSetting = new BehaviorSubject<EmployeeCardFilterSetting[]>([]);

  readonly employeeSetting$ = this._employeeSetting.asObservable();

  private readonly _employeeAccountSetting = new BehaviorSubject<EmployeeCardFilterSetting[]>([]);

  readonly employeeAccountSetting$ = this._employeeAccountSetting.asObservable();

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly authService: AuthService,
    private readonly employeeService: EmployeeSettingsService,
    private readonly accountService: AccountSettingsService,
    private readonly objectEventEmitter: ObjectEventEmitters,
    private readonly allowedFiltersService: AllowedFiltersService
  ) {
    this.subscribeForAuthentication();
  }

  subscribeForAuthentication() {
    this.authenticationService.getCurrentEmployeeObservable().subscribe((r) => {
      if (r) {
        this.employee = r;
        this.refreshEmployeeCardFilterSetting();
      }
    });
  }

  refreshEmployeeCardFilterSetting() {
    this.refreshEmployeeCardFilterSettingAsObservable$().subscribe();
  }
  refreshEmployeeCardFilterSettingAsObservable$() {
    if (!this.employee) {
      this.employee = this.authenticationService.getCurrentEmployee();
    }

    return forkJoin([
      this.employeeService.getEmployeeSetting(EmployeeSettingTypes.Card_Filters),
      this.accountService.getAccountSettingByType(AccountSettingTypes.Default_Card_Filters),
    ]).pipe(map(([employeeSettings, accountSetting]) => {
      let setting: EmployeeCardFilterSetting[] = [];
      if (employeeSettings && employeeSettings.value) {
        setting = JSON.parse(employeeSettings.value) as EmployeeCardFilterSetting[];
      } else if (accountSetting && accountSetting.value) {
        setting = JSON.parse(accountSetting.value) as EmployeeCardFilterSetting[];
      }

      if(accountSetting && accountSetting.value) {
        const accountSettings = JSON.parse(accountSetting.value) as EmployeeCardFilterSetting[];
        this._employeeAccountSetting.next(accountSettings);
      }

      this._employeeSetting.next(setting);
    }));
  }

  getRefreshedEmployeeCardFilterSettingAll(): Observable<EmployeeCardFilterSetting[]> {
    return this.refreshEmployeeCardFilterSettingAsObservable$().pipe(switchMap(() => this.employeeSetting$));
  }

  getRefreshedEmployeeCardFilterSetting(objectType: ObjectTypes): Observable<EmployeeCardFilterSetting> {
    this.refreshEmployeeCardFilterSetting();
    return this.employeeSetting$.pipe(map((d) => d.find((r) => r.objectType === objectType)));
  }

  getEmployeeCardFilterSetting(objectType: ObjectTypes) {
    const currentValue = this._employeeSetting.getValue();
    let employeeCardFilterSetting = currentValue?.find((s) => s.objectType === objectType);

    if(!employeeCardFilterSetting) {
      const employeeAccountSeeings = this._employeeAccountSetting.getValue();
      employeeCardFilterSetting = employeeAccountSeeings?.find((s) => s.objectType === objectType)
    }
    return employeeCardFilterSetting;
  }

  /**
   * Compare Function for strings that compares string by length first and if they have same length , compare them by characters.
   * (First - Second) is the strategy , so swap the params when you pass them to achieve DESCENDING or ASCENDING
   * @param first swap with second(param) to change the order (descending / ascending)
   * @param second swap with second(param) to change the order (descending / ascending)
   */
  public stringsCompareFn(first: string, second: string) {
    return first.localeCompare(second) || first.length - second.length;
  }

  public datesCompareFn(first: string | Date, second: string | Date) {
    return new Date(first).getTime() - new Date(second).getTime();
  }

  /**
   * This is for sorting filter based on FilterType
   */

  sortFilters(filters: FilterViewModel[], filterType: FilterTypes): FilterViewModel[] {
    let coppiedFilters = filters.slice();

    if (this.filtersForSortBasedOnEnum.some((ft) => ft === filterType)) {
      coppiedFilters = coppiedFilters.sort((a, b) => +a.filterValue - +b.filterValue);
    } else {
      coppiedFilters = coppiedFilters.sort((a, b) => a.filterText && a.filterText?.localeCompare(b.filterText));
    }

    return coppiedFilters;
  }

  /**
   * We are using that in lists
   */

  sortListByFilters<T extends ModifiableEntityViewModel>(
    arr: T[],
    filterType: FilterTypes,
    ascending: boolean,
    dateProperty?: FilterDateOptions
  ): T[] {
    const allowedFiltersByFilterType = this.allowedFiltersService.getCachedAllowedFiltersByType(filterType);

    // We are using this one in cases when the filters dont have FilterText, so this means that we need to extract them from the allowedFilters
    const filterTypesToGetFromAllowedFilters = [
      FilterTypes.Owner,
      FilterTypes.Department,
      FilterTypes.Organisation,
    ];
    const isUsingAllowedFiltersToCompare = filterTypesToGetFromAllowedFilters.some((ft) => ft === filterType);

    const isBasedOnFilterValue = this.filtersToSortByFilterValue.some((ft) => ft === filterType);
    const isDateSorting = filterType === FilterTypes.Date && dateProperty;

    const compareMappedAllowedFilters = (a: FilterViewModel, b: FilterViewModel) => {
      const aFoundedFilter = allowedFiltersByFilterType.find((f) => f.filterValue == a.filterValue);
      const bFoundedFilter = allowedFiltersByFilterType.find((f) => f.filterValue == b.filterValue);
      if (aFoundedFilter && bFoundedFilter) {
        return ascending
          ? aFoundedFilter.filterText.localeCompare(bFoundedFilter.filterText)
          : bFoundedFilter.filterText.localeCompare(aFoundedFilter.filterText);
      } else if (!aFoundedFilter) {
        return ascending ? 1 : -1;
      } else if (!bFoundedFilter) {
        return ascending ? -1 : 1;
      }
    };

    return [...arr].sort((a, b) => {
      let result = 0;

      let aMatchingFilters: FilterViewModel[];
      let bMatchingFilters: FilterViewModel[];

      if (isDateSorting) {
        aMatchingFilters = a.filters.filter((f: FilterViewModel) => f.filterType === filterType && f.dateProperty === dateProperty);
        bMatchingFilters = b.filters.filter((f: FilterViewModel) => f.filterType === filterType && f.dateProperty === dateProperty);
      } else {
        const aListItemFiltersByFT: FilterViewModel[] = a.filters.filter((f) => f.filterType === filterType);
        const bListItemFiltersByFT: FilterViewModel[] = b.filters.filter((f) => f.filterType === filterType);
        if (isUsingAllowedFiltersToCompare) {
          aMatchingFilters = aListItemFiltersByFT?.sort((c, d) => compareMappedAllowedFilters(c, d));
          bMatchingFilters = bListItemFiltersByFT?.sort((c, d) => compareMappedAllowedFilters(c, d));
        } else {
          aMatchingFilters = aListItemFiltersByFT?.sort((c, d) =>
            ascending ? c.filterText.localeCompare(d.filterText) : d.filterText.localeCompare(c.filterText)
          );
          bMatchingFilters = bListItemFiltersByFT?.sort((c, d) =>
            ascending ? c.filterText.localeCompare(d.filterText) : d.filterText.localeCompare(c.filterText)
          );
        }
      }

      if (aMatchingFilters?.length && bMatchingFilters?.length) {
        if (isBasedOnFilterValue) {
          if (isDateSorting) {
            result = ascending
              ? this.datesCompareFn(aMatchingFilters[0].filterValue, bMatchingFilters[0].filterValue)
              : this.datesCompareFn(bMatchingFilters[0].filterValue, aMatchingFilters[0].filterValue);
          } else if (this.MappedEnumBasedFilterTypes[filterType]) {
            const mappedFirstFilterValue = this.MappedEnumBasedFilterTypes[filterType][aMatchingFilters[0].filterValue];
            const mappedSecondFilterValue = this.MappedEnumBasedFilterTypes[filterType][bMatchingFilters[0].filterValue];
            result = ascending
              ? this.stringsCompareFn(mappedFirstFilterValue, mappedSecondFilterValue)
              : this.stringsCompareFn(mappedSecondFilterValue, mappedFirstFilterValue);
          } else {
            result = ascending
              ? this.stringsCompareFn(aMatchingFilters[0].filterValue, bMatchingFilters[0].filterValue)
              : this.stringsCompareFn(bMatchingFilters[0].filterValue, aMatchingFilters[0].filterValue);
          }
        } else {
          if (isUsingAllowedFiltersToCompare) {
            result = compareMappedAllowedFilters(aMatchingFilters[0], bMatchingFilters[0]);
          } else {
            result = ascending
              ? aMatchingFilters[0].filterText.localeCompare(bMatchingFilters[0].filterText)
              : bMatchingFilters[0].filterText.localeCompare(aMatchingFilters[0].filterText);
          }
        }
      } else if (!aMatchingFilters || !aMatchingFilters.length) {
        result = ascending ? 1 : -1;
      } else if (!bMatchingFilters || !bMatchingFilters.length) {
        result = ascending ? -1 : 1;
      }

      return result;
    });
  }
}
