import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { JobsService } from 'src/app/modules/incidents/services/jobs/jobs.service';
import { JobDetailsItemViewModel } from 'src/app/modules/incidents/viewModels/jobs/jobDetailsItemViewModel';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { NgDropdownDirective } from 'src/app/modules/shared/directives/ngDropdown.directive';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { JobPriorities } from 'src/app/modules/shared/enums/jobs/jobpriorities';
import { JobStatuses } from 'src/app/modules/shared/enums/jobs/jobStatuses';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { PrivacyStatuses } from 'src/app/modules/shared/enums/privacyStatuses';
import { Account } from 'src/app/modules/shared/models/account';
import { Employee } from 'src/app/modules/shared/models/employee';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { ThreadedBead } from 'src/app/modules/shared/models/threadedBead';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { FilterToViewModelUtilityService } from 'src/app/modules/shared/services/utilities/filterToViewModelUtilityService';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { LocationUtilities } from 'src/app/modules/shared/utilities/location.utilities';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { ZoneViewModel } from 'src/app/modules/shared/viewModels/zoneViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { EnumUtilities } from 'src/app/modules/shared/utilities/enum.utilities';
import { FixedFilters } from 'src/app/modules/shared/enums/fixedFilters.enum';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { FixedZoneFiltersService } from 'src/app/modules/shared/services/fixed-zone-filters.service';
import { JobPrivacyStatusViewModel } from 'src/app/modules/incidents/viewModels/jobs/jobPrivacyStatusViewModel';
import { JobStatusViewModel } from 'src/app/modules/incidents/viewModels/jobs/jobStatusViewModel';

@Component({
  selector: 'app-job-add-modal',
  templateUrl: './job-add-modal.component.html',
  styleUrls: ['./job-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobAddModalComponent implements OnInit, OnDestroy {
  @ViewChild('datepicker') datepicker: ElementRef<HTMLElement>;
  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;
  @Output() closed = new EventEmitter();

  @ViewChild('job_add_modal_dropdown') jobAddModalDropdown: ElementRef;

  public currentSelectedPriorityFilter: FilterViewModel;
  public job: JobDetailsItemViewModel = new JobDetailsItemViewModel();
  public privacyStatuses: JobPrivacyStatusViewModel[] = EnumUtilities.items(PrivacyStatuses);
  public privacyStatus: JobPrivacyStatusViewModel;
  public jobStatuses: JobStatusViewModel[] = EnumUtilities.items(JobStatuses);
  public jobStatus: JobStatusViewModel;
  public triggerIndicatorErrors: boolean = false;
  public validationErrors: string[];
  public filters: FilterViewModel[] = [];
  public jobForm: UntypedFormGroup;
  public loading: boolean = false;
  public currentStep: number = 0;
  public jobStartTime: string = '';
  public jobEndTime: string = '';
  public quickAddTitle: string = '';
  public setDefaultOwner: boolean = true;
  public setDefaultDepartment: boolean = true;
  public currentAccount: Account;
  public currentEmployee: Employee;
  public localisedJob: string;
  public localisedJobs: string;
  public localisedZone: string;
  public localisedArea: string;
  public filterTypes = FilterTypes;
  public objectTypes = ObjectTypes;
  public editableFieldTypes = EditableFieldTypes;
  public objectType: ObjectTypes = ObjectTypes.Job;
  public priorityTitles: string[] = [];
  public zonesViewModels: ZoneViewModel[] = [];
  public areasViewModels: AreaViewModel[] = [];
  public mapZones: ZoneViewModel[] = [];
  public mapAreas: AreaViewModel[] = [];
  public selectedZone: FilterViewModel;
  public areaFilters: FilterViewModel[] = [];
  public zoneFilters: FilterViewModel[] = [];
  public applicableAreaFilters: FilterViewModel[] = [];
  public initialSelectedZone: FilterViewModel;
  public isCalledFromPIR: boolean = false;
  public preselectedLocation: LocationViewModel = null;

  public symbols: Set<string> = new Set();
  private zones: ZoneViewModel[] = [];

  // Translated in method translateTexts
  public currentStepText: string[] = [
    'Job Title & Summary',
    'Job Details',
    'Job Location',
    // 'Add Checklists and Save',
  ];

  public beads: ThreadedBead[] = [
    { number: 0, active: true },
    { number: 1, active: false },
    { number: 2, active: false },
    { number: 3, active: false },
  ];

  public readonly T = T;
  private subscriptions: Subscription = new Subscription();

  // Translated in method translateTexts
  public footerButtonText: string[] = [
    'Add Details',
    'Add Location',
    'Save and Go To Job Details',
    // 'Add Checklists',
  ];

  public localisedDepartment = 'Department';
  public localisedDepartments = 'Departments';
  public localisedPrivacyStatuses: JobPrivacyStatusViewModel[];
  public selectedJobCategory: FilterViewModel;
  selectedJobType: FilterViewModel;

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly router: Router,
    private readonly fb: UntypedFormBuilder,
    private readonly alertService: AlertService,
    private readonly jobsService: JobsService,
    private readonly locationService: LocationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly authenticationService: AuthenticationService,
    private readonly filterToViewModelUtilityService: FilterToViewModelUtilityService,
    private readonly translateService: TranslateService,
    private readonly fixedZoneService: FixedZoneFiltersService,
    private readonly cachingService: CachingService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit() {
    this.closed.emit();
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.currentAccount = this.authenticationService.getCurrentAccount();
    this.jobStatus = this.jobStatuses.find((x) => x.key === JobStatuses.New);

    this.localisedPrivacyStatuses = this.localisationService.getLocalizedIncidentPrivacyStatuses();

    this.privacyStatus = this.localisedPrivacyStatuses.find((x) => x.key === PrivacyStatuses.Open);

    this.setDefaultStartEndTimes();
    this.getZones();
    this.initAreaZonesFilters();

    if(this.filters && this.filters.length) {
      const jobTypeFilter = this.filters.find(f => f.filterType === FilterTypes.Job_Type);

      if(!jobTypeFilter) {
        this.initDefaultTypeFilter();
      } else {
        this.selectedJobType = jobTypeFilter;
        this.selectedJobCategory = this.filters.find(f => f.filterType === FilterTypes.Job_Category);
      }
    } else {
      this.initDefaultTypeFilter();
    }

    this.initJobForm();
    this.initLocalisations();
    this.initPriorityFilterTitles();
    this.initLiveEventZoneFilters();

    if(this.preselectedLocation) {
      this.onJobLocationUpdated(this.preselectedLocation);
    }

    if (this.setDefaultDepartment) {
      this.addCurrentUserDepartment();
    }

    if (this.setDefaultOwner) {
      this.addCurrentUserAsOwner();
    }

    if(this.quickAddTitle.length)
      this.jobForm.controls.title.setValue(this.quickAddTitle)

    this.translateTexts();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get position(): {lat: number, lng: number} {
    return {
      lat: this.job.latitude,
      lng: this.job.longitude
    }
  }

  public closeModal(): void {
    this.bsModalRef.hide();
  }

  public increaseStep(): void {
    this.currentStep++;
    this.updateBeads();

    if (this.currentStep === 3 && this.isCalledFromPIR) {
      this.onSave(false, false);
    }

    if(this.currentStep === 3 && !this.isCalledFromPIR) {
      this.onSave(false, true);
    }
  }

  public decreaseStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.updateBeads();
    }
  }

  public onJobStatusSelected(jobStatus: { key: JobStatuses; value: string }): void {
    this.jobStatus = jobStatus;
    this.job.status = jobStatus.key;
  }

  public onPrivacyStatusSelected(privacyStatus: { key: PrivacyStatuses; value: string }) {
    this.privacyStatus = privacyStatus;
    this.job.privacyStatus = privacyStatus.key;
  }

  public handleFilterChange(filters: FilterViewModel[]) {
    this.filters = filters.slice();
    this.selectedZone = filters.find((f) => f.filterType === FilterTypes.Zone);
    this.setZonesAndAreas();

    this.changeDetectorRef.detectChanges();
  }

  public handleJobTypeChange(filters: FilterViewModel[]) {
    this.filters = filters.slice();
    this.selectedJobType = filters.find((f) => f.filterType === FilterTypes.Job_Type);

    this.changeDetectorRef.detectChanges();
  }

  public onDescriptionChange(text: string): void {
    this.jobForm.controls.description.setValue(text);
  }

  public localiseString(s: string): string {
    return this.localisationService.localise(s);
  }

  public clearPriority(): void {
    this.jobForm.controls.priority.setValue(0);
  }

  public addCurrentUserDepartment() {
    const filters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Department);

    if (filters) {
      const departmentFilter = filters.find(
        (f) => f.filterType === FilterTypes.Department && f.filterValue === this.currentEmployee.departmentId
      );
      if (
        departmentFilter &&
        !this.filters.some((f) => f.filterType === FilterTypes.Department && f.filterValue === this.currentEmployee.departmentId)
      ) {
        this.filters = this.filters.slice();
        this.filters.push(departmentFilter);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  public addCurrentUserAsOwner(): void {
    const filters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Owner);

    if (filters) {
      const empFilter = filters.find(
        s => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.currentEmployee.id.toString()
      );
      if (empFilter && !this.filters.some((f) => f.filterType === FilterTypes.Owner && f.filterValue === this.currentEmployee.id)) {
        this.filters = this.filters.slice();
        this.filters.push(empFilter);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  public onJobLocationUpdated(location: LocationViewModel) {
    this.job.locationDetails = location.locationDetails;
    this.job.latitude = location.latitude;
    this.job.longitude = location.longitude;
  }

  get validateSteps(): boolean {
    if (this.currentStep === 0) {
      return (
        !(
          this.jobForm.controls.title.valid &&
          this.jobForm.controls.headlineStatus.valid &&
          this.jobForm.controls.description.valid
        ) || !this.filters.some((x) => x.filterType === FilterTypes.Job_Type)
      );
    }

    return false;
  }

  private initLiveEventZoneFilters() {
    if (this.currentAccount.fixedFilters === FixedFilters.Event) {
      const eventID = Number(this.cachingService.selectedEventID) || 0;
      this.job.eventId = eventID;
      this.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Event, this.job.eventId.toString(), ''));
    } else if (this.currentAccount.fixedFilters === FixedFilters.VenueZone) {
      const zoneId = +this.cachingService.selectedZoneID;
      if (zoneId > 0) {
        this.initialSelectedZone = this.fixedZoneService.fixedFilter;
        this.filters.push(this.initialSelectedZone);
      }
    }
  }

  private setZonesAndAreas() {
    this.mapZones = this.filterToViewModelUtilityService.getZoneViewModelsFromFilters(this.filters, this.zones);
    this.mapAreas = this.filterToViewModelUtilityService.getAreaViewModelsFromFilters(this.filters);

    this.setApplicableFilters();
    this.changeDetectorRef.markForCheck();
  }

  private updateBeads(): void {
    this.beads = this.beads.slice();
    this.beads.forEach((b) => (b.active = false));

    for (let i = 0; i <= this.currentStep; i++) {
      this.beads[i].active = true;
    }
  }

  public onSave(addAnother: boolean = false, navigateToDetailsPage: boolean = false): void {
    this.loading = true;

    this.job.filters = this.filters;
    this.job.jobTypeId = this.filters.find((x) => x.filterType === FilterTypes.Job_Type).filterValue;
    this.job.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Date, this.jobStartTime, '', FilterDateOptions.Start_Date));
    this.job.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Date, this.jobEndTime, '', FilterDateOptions.Due_Date));
    this.job.start = this.jobStartTime;
    this.job.due = this.jobEndTime;

    const item: JobDetailsItemViewModel = { ...this.job, ...this.jobForm.value };

    this.jobsService.add(item).subscribe((validateModel) => {
      const errors = validateModel.errorList;

      if (errors?.length > 0) {
        this.validationErrors = errors;
        this.loading = false;
      } else {
        if (addAnother) {
          this.addAnotherJob();
        } else if (navigateToDetailsPage) {
          void this.router.navigate(['v2/control/jobs/' + String(validateModel.returnModel.id)]);
          this.closeModal();
        } else {
          this.closeModal();
        }

        this.loading = false;
        void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedJob }));
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  public onDatesChange(dates: { startDate: string; endDate: string }): void {
    this.jobStartTime = dates.startDate;
    this.jobEndTime = dates.endDate;
    this.jobForm.controls.startTime.setValue(moment(dates.startDate).format('HH:mm, MM/DD/YYYY'));
    this.jobForm.controls.endTime.setValue(moment(dates.endDate).format('HH:mm, MM/DD/YYYY'));

    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }

    this.changeDetectorRef.detectChanges();
  }

  public onClosedDatepicker(isClose: boolean): void {
    if (this.ngDropdownDirective && isClose) {
      this.ngDropdownDirective.onEscape();
      this.setDefaultStartEndTimes();
    }
  }

  private setDefaultStartEndTimes(): void {
    this.jobStartTime = moment().set({ hour: 7, minute: 0, second: 0, millisecond: 0 }).toISOString();
    this.jobEndTime = moment().set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).toISOString();
  }

  private initJobForm(): void {
    this.jobForm = this.fb.group({
      title: [
        '',
        {
          validators: [
            Validators.minLength(3),
            Validators.maxLength(250),
            Validators.required,
            this.wtValidators.title(),
            this.wtValidators.restrictedChars([';']),
          ],
        },
      ],
      headlineStatus: [
        '',
        { validators: [Validators.minLength(3), Validators.maxLength(255), this.wtValidators.restrictedChars([';'])] },
      ],
      description: ['', { validators: [Validators.minLength(0), Validators.maxLength(2000)] }],
      startTime: [''],
      endTime: [''],
      priority: [0],
    });
  }

  private initLocalisations(): void {
    this.localisedJob = this.localisationService.localiseObjectType(ObjectTypes.Job);
    this.localisedJobs = this.localisationService.localiseObjectType(ObjectTypes.Job, true);
    this.localisedZone = this.localisationService.localiseObjectType(ObjectTypes.Zone);
    this.localisedArea = this.localisationService.localiseObjectType(ObjectTypes.Area);
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);
    this.localisedDepartments = this.localisationService.localiseObjectType(ObjectTypes.Department, true);
  }

  private initPriorityFilterTitles(): void {
    const level_one = this.localisationService.localiseFilterValueByFilterType(JobPriorities.One_Star, FilterTypes.Job_Priority);
    const level_two = this.localisationService.localiseFilterValueByFilterType(JobPriorities.Two_Stars, FilterTypes.Job_Priority);
    const level_three = this.localisationService.localiseFilterValueByFilterType(
      JobPriorities.Three_Stars,
      FilterTypes.Job_Priority
    );

    this.priorityTitles = [level_one, level_two, level_three];
  }

  private initAreaZonesFilters(): void {
    this.areaFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Area);
    this.zoneFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Zone);
  }

  private initDefaultTypeFilter(): void {
    const defaultJobTypeFilter = this.allowedFiltersService
      .getCachedAllAllowedFilters()
      .filter((x) => x.filterType === FilterTypes.Job_Type)[0];
    this.filters.push(defaultJobTypeFilter);
    this.selectedJobType = defaultJobTypeFilter;
  }

  private setApplicableFilters(): void {
    if (this.mapZones && this.mapZones.length) {
      const areas: FilterViewModel[] = [];
      this.mapZones.forEach((zone) => {
        const zoneAreas = this.areaFilters.filter((a) => +a.relatedObjectId === zone.id);

        if (zoneAreas) {
          areas.push(...zoneAreas);
        }
      });

      this.applicableAreaFilters = areas.slice();

      this.filters.forEach((filter) => {
        if (filter.filterType === FilterTypes.Area) {
          if (!this.mapZones.some((zone) => zone.id === filter.relatedObjectId)) {
            this.filters = this.filters.filter((x) => x.filterValue !== filter.filterValue);
          }
        }
      });

      if (!this.job.latitude && !this.job.longitude && this.filters.some((x) => x.filterType === FilterTypes.Zone)) {
        this.setInitialZonePin();
      }
    } else {
      this.mapAreas = [];
      this.applicableAreaFilters = [];
      this.filters = this.filters.filter((x) => x.filterType !== FilterTypes.Area);
    }
  }

  private setInitialZonePin() {
    this.job = LocationUtilities.setLocationDetails(this.mapZones, this.job) as JobDetailsItemViewModel;

    this.changeDetectorRef.detectChanges();
  }

  private getZones(): void {
    this.locationService.getZones().subscribe((res) => {
      this.zones = res;
      this.setZonesAndAreas();

      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  private addAnotherJob(): void {
    this.job = new JobDetailsItemViewModel();
    this.currentStep = 0;
    this.filters = [];
    this.initJobForm();
    this.updateBeads();
    this.jobStartTime = moment().toISOString();
    this.jobEndTime = moment().toISOString();
  }

  private translateTexts(): void {
    this.footerButtonText = [
      this.translateService.instant(T.common.add_details),
      this.translateService.instant(T.common.add_location),
      this.translateService.instant(T.common.save_and_go_to_item_details, { item: this.localisedJob }),
    ];

    this.currentStepText = [
      this.translateService.instant(T.common.item_title_and_summary, { item: this.localisedJob }),
      this.translateService.instant(T.common.item_details, { item: this.localisedJob }),
      this.translateService.instant(T.common.item_location, { item: this.localisedJob }),
    ];
  }

  public onApply($event): void {
    this.currentSelectedPriorityFilter = $event;
    this.jobForm.patchValue({priority: this.currentSelectedPriorityFilter.filterValue})
  }

}
