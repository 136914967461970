import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { SlackAddIntegrationResponse } from '../models/slack/slackAddIntegrationResponse';

@Injectable()
export class SlackService {
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    //this.actionUrl = this.configuration.Server + this.configuration.ApiVersion+ '/Report/';
    this.actionUrl = this.configuration.buildEndpoint(`Slack/`);
  }

  public GetSlackAuthLink = (): Observable<SlackAddIntegrationResponse> => {
    return this.http.get<SlackAddIntegrationResponse>(this.configuration.buildEndpoint(`Slack/Add`));
  };
}
