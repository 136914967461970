<app-modal [fullScreen]="true">
  <app-modal-header
    style="z-index: 50"
    [showDelete]="false"
    (closed)="bsModalRef.hide()"
  >
  </app-modal-header>

  <section *ngIf="currentStep > 0" class="top-navigation-container">
    <div class="top-back-icon" (click)="decreaseStep()">
      <span class="material-icons chevron-icon">chevron_left</span>
    </div>
    <span class="back-label">Back</span>
    <ng-container *ngIf="currentStep >= 4" >
      <div class="vertical-separator"></div>
      <ng-container [ngTemplateOutlet]="formSectionHeaderInfo"></ng-container>
    </ng-container>
  </section>
  <app-threaded-beads [beads]="beads" [showBubbles]="false"></app-threaded-beads>

  <app-modal-body class="custom-scrollbar">
    <main class="modal-body-container" #wrapper>
      <section class="left-side">
        <ng-container [ngSwitch]="currentStep">
          <ng-container *ngSwitchCase="0">
            <div class="left-side-wrapper">
              <ng-container *ngTemplateOutlet="addDetailsLeftSide"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="1"></ng-container>
          <ng-container *ngSwitchCase="2"></ng-container>
          <ng-container *ngSwitchCase="3"></ng-container>
          <ng-container *ngSwitchCase="4"></ng-container>
        </ng-container>
      </section>
      <section class="right-side">
        <ng-container [ngSwitch]="currentStep">
          <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="addDetailsRightSide"></ng-container>
          <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="assignIndicatorRightSide"></ng-container>
          <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="indicatorUpdateTypesRightSide"></ng-container>
          <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="indicatorTrackingDetailsRightSide"></ng-container>
          <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="setEmissionsFactorsRightSide"></ng-container>
        </ng-container>
      </section>
    </main>
  </app-modal-body>

  <app-modal-footer>
    <app-button-rectangle
      [buttonTheme]="'white'"
      [class]="'button'"
      [fitToContainer]="true"
      [size]="'large'"
      [text]="T.common.cancel | translate"
      (clicked)="cancel()"
    ></app-button-rectangle>
    <app-button-rectangle
      [disabled]="stepDisabled"
      [class]="'button'"
      [fitToContainer]="true"
      [size]="'large'"
      [text]="footerButtonText[currentStep]"
      (clicked)="increaseStep()"
    ></app-button-rectangle>
  </app-modal-footer>

</app-modal>

<ng-container *ngIf="indicatorCreated"
  [ngTemplateOutlet]="indicatorCreatedTemplate">
</ng-container>


<ng-template #formSectionHeaderTitle let-title="title" let-subtitle="subtitle">
  <div class="form-title-description-wrapper">
    <div class="title">{{ title }}</div>
    <div class="subtitle">{{ subtitle }}</div>
  </div>
</ng-template>

<ng-template #formSectionHeaderInfo>
  <div class="form-header-info">
    <i class="material-icons">category</i>
    <span class="category">{{indicatorCategory}}</span>: <span class="unknown">{{indicator.title}}</span>
  </div>
</ng-template>


<ng-template #addDetailsLeftSide>
  <div class="left-side-information">
    <div class="left-side-top">
      <div class="left-side-title">{{ T.defaultLocalizations.indicator.many | translate }}</div>
      <div class="left-side-text border-bottom">
        {{ T.sustainability.indicaor_is_something_to_be_tested | translate }}
      </div>
    </div>
    <div class="left-side-middle border-bottom">
      <div class="left-side-text">
        {{ T.sustainability.describe_your_indicator_and_add_details | translate }}
      </div>
      <div class="attributes-wrapper">
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-icons">category</i>
            </div>
            <div class="attribute-title">{{ T.common.category.one | translate }}</div>
          </div>
          <div class="attribute-right">
            {{ T.sustainability.indicators_must_be_assigned_to_a_category | translate }}
          </div>
        </div>
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-icons">public</i>
            </div>
            <div class="attribute-title">{{ T.defaultLocalizations.un_sdg.many | translate }}</div>
          </div>
          <div class="attribute-right">
            {{ T.sustainability.link_your_indicators_to_any_17_un_goals | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="left-side-bottom">
      <div class="left-side-bottom-text">
        <a href="https://supportcenter.ungerboeck.com/#wetrack" target="_blank" rel="noopener">{{
          T.common.want_learn_more_visit_help_centre | translate
        }}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addDetailsRightSide>
  <div class="add-details-container" [formGroup]="indicatorForm" #wrapper>
    <ng-container
      [ngTemplateOutlet]="formSectionHeaderTitle"
      [ngTemplateOutletContext]="{
        title: 'Describe the indicator',
        subtitle: 'Select an indicator category and describe what you want to track, giving your indicator a title and optional description.'
      }"
    ></ng-container>
    <div>
      <app-text-field
        [placeholder]="
          (T.common.item_title | translate: { item: T.defaultLocalizations.indicator.one | translate }) + '*'
        "
        [displayError]="triggerIndicatorErrors"
        formControlName="title"
        [formGroup]="indicatorForm"
        [maxLength]="250"
        [required]="true"
        [useReactiveMessages]="true"
        [autofocus]="true"
        [required]="true"
        [disableMargin]="true"
        [icon]="'event_note'"
        name="title"
      >
      </app-text-field>
      <div class="d-flex mt-2 ms-2">
        <app-button-transparent
          [app-tooltip]="{ message: T.sustainability.add_indicator_title_helper_text | translate }"
          size="small"
          [text]="T.common.learn_more | translate"
        ></app-button-transparent>
      </div>
    </div>

    <div>
      <app-filter-dropdown-new
        style="width: 100%"
        [filterType]="filterTypes.Indicator_Category"
        [appliedFilters]="indicator.filters"
        [disableMargin]="true"
        [typeahead]="true"
        [label]="(T.common.category.one | translate) + '*'"
        [displayError]="triggerIndicatorErrors"
        [required]="true"
        (onFilterUpdate)="updateIndicatorCategory($event)"
        [singleSelect]="true"
      >
      </app-filter-dropdown-new>
    </div>

    <div>
      <app-filter-dropdown-new
        style="width: 100%"
        [filterType]="filterTypes.Sustainability_Scope"
        [appliedFilters]="indicator.filters"
        [disableMargin]="true"
        [typeahead]="true"
        [label]="T.settings.sustainability.scope | translate"
        [displayError]="triggerIndicatorErrors"
        [required]="true"
        (onFilterUpdate)="updateIndicatorScope($event)"
        [singleSelect]="true"
      >
      </app-filter-dropdown-new>
    </div>

    <div class="priority-select-wrapper">
      <app-priority
        [selectedPriority]="currentSelectedPriorityFilter"
        [showSelectPriorityLabel]="true"
        [filterType]="filterTypes.Indicator_Priority"
        [widthPx]="640"
        [topOffsetPx]="4"
        (priorityChanged)="onApply($event)">
      </app-priority>
    </div>

    <div>
      <app-filter-dropdown-new
        [displayError]="triggerIndicatorErrors"
        [filterType]="filterTypes.UNSDG"
        [appliedFilters]="indicator.filters"
        [typeahead]="true"
        [disableMargin]="true"
        [label]="T.sustainability.un_sustainability_goals | translate"
        (onFilterUpdate)="updateViewModel($event)"
      >
      </app-filter-dropdown-new>
      <div class="bottom-element d-flex mt-2 ms-2">
        <app-button-transparent
        [app-tooltip]="{ message: T.sustainability.unsdg_helper_text | translate }"
        size="small"
          [text]="T.common.learn_more | translate"
        ></app-button-transparent>
      </div>
    </div>

    <div>
      <app-details-editable-field
        [input]="indicator.description ? indicator.description : ''"
        [header]="T.defaultLocalizations.description.one | translate"
        [type]="editableFieldTypes.RichText"
        [canEdit]="true"
        (onUpdate)="onDescriptionChange($event)"
        (onInputChange)="onDescriptionChange($event)"
        [placeholder]="T.common.add_description | translate"
        [maxLength]="2000"
      >
      </app-details-editable-field>
    </div>
  </div>
</ng-template>

<ng-template #assignIndicatorRightSide>
  <div class="assign-details-container" [formGroup]="indicatorForm">
    <ng-container [ngTemplateOutlet]="formSectionHeaderInfo"></ng-container>
    <ng-container
      [ngTemplateOutlet]="formSectionHeaderTitle"
      [ngTemplateOutletContext]="{
        title: 'Assign and tag the indicator',
        subtitle: 'Assign ownership of the indicator and other attributes to help with filtering and reporting.'
      }"
    ></ng-container>
    <div>
      <app-filter-dropdown-new
        [filterType]="filterTypes.Owner"
        [appliedFilters]="indicator.filters"
        [disableMargin]="true"
        [typeahead]="true"
        [label]="T.common.assign_owner.many | translate"
        (onFilterUpdate)="updateViewModel($event)"
      >
      </app-filter-dropdown-new>
      <div class="bottom-element d-flex">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="T.common.assign_me | translate"
          (clicked)="addCurrentUserAsOwner()"
        ></app-button-transparent>
      </div>
    </div>

    <div>
      <app-filter-dropdown-new
        [filterType]="filterTypes.Department_Category"
        [appliedFilters]="indicator.filters"
        [disableMargin]="true"
        [typeahead]="true"
        [label]="T.common.assign_items | translate: { items: localisedDepartmentCategories }"
        (onFilterUpdate)="updateViewModel($event)"
      >
      </app-filter-dropdown-new>
    </div>

    <div>
      <app-filter-dropdown-new
        [filterType]="filterTypes.Department"
        [appliedFilters]="indicator.filters"
        [disableMargin]="true"
        [typeahead]="true"
        [label]="T.common.assign_items | translate: { items: localisedDepartments }"
        (onFilterUpdate)="updateViewModel($event)"
      >
      </app-filter-dropdown-new>
      <div class="bottom-element d-flex">
        <app-button-transparent
          size="small"
          icon="add"
          [text]="T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
          (clicked)="addCurrentUserDepartment()"
        ></app-button-transparent>
      </div>
    </div>

    <div class="tags-wrapper">
      <app-add-modal-tag-groups
        [showTitle]="false"
        [filters]="indicator.filters"
        [layoutClasses]="'col-12'"
        [tagGroupObjectType]="objectTypes.Indicator"
        (onFiltersUpdated)="updateViewModel($event)"
      ></app-add-modal-tag-groups>
    </div>
  </div>
</ng-template>

<ng-template #indicatorUpdateTypesRightSide>
  <div class="indicator-tracking-details-container" [formGroup]="indicatorForm">
    <ng-container [ngTemplateOutlet]="formSectionHeaderInfo"></ng-container>
    <ng-container
      [ngTemplateOutlet]="formSectionHeaderTitle"
      [ngTemplateOutletContext]="{
        title: 'Assign and tag the indicator',
        subtitle: 'Choose how you want to track this indicator.'
      }"
    ></ng-container>

    <div class="tracking-radio-buttons-wrapper">
      <app-radio-button
        [checked]="indicator.updateType === indicatorUpdateTypes.Numeric || !indicator.updateType"
        [text]="'Numerical Tracking'"
        (checkedChanged)="updateIndicatorUpdateType(indicatorUpdateTypes.Numeric)"
      ></app-radio-button>

      <app-radio-button
        [checked]="indicator.updateType === indicatorUpdateTypes.Pass_Or_Fail"
        [text]="'Pass/Fail (Audit) Tracking'"
        (checkedChanged)="updateIndicatorUpdateType(indicatorUpdateTypes.Pass_Or_Fail)"
      ></app-radio-button>
    </div>

    <ng-container *ngIf="indicator.updateType === indicatorUpdateTypes.Numeric">
      <div>
        <app-filter-dropdown-new
          [filterType]="filterTypes.Expected_Direction"
          [appliedFilters]="expectedDirectionFilters"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="(T.sustainability.expected_direction.one | translate) + '*'"
          [displayError]="triggerIndicatorErrors"
          [required]="true"
          [currentlySelectedSettingValue]="expectedDirectionSelectedFilter?.filterValue"
          (onFilterUpdate)="updatedExpectedDirection($event)"
          [singleSelect]="true"
        >
        </app-filter-dropdown-new>
      </div>
      <div>
        <app-filter-dropdown-new
          [filterType]="filterTypes.Unit_Type"
          [appliedFilters]="[unitTypeSelectedFilter]"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="T.settings.sustainability.unit_measurements.unit_Type_label | translate"
          [displayError]="triggerIndicatorErrors"
          [required]="true"
          [currentlySelectedSettingValue]="unitTypeSelectedFilter?.filterValue"
          (onFilterUpdate)="updateIndicatorUnitType($event)"
          [singleSelect]="true"
        >
        </app-filter-dropdown-new>
        <div class="bottom-element d-flex mt-2 ms-2">
          <app-button-transparent
            [app-tooltip]="{ message: T.sustainability.add_indicator_unit_type_helper_text | translate }"
            size="small"
            [text]="T.common.learn_more | translate"
          ></app-button-transparent>
        </div>
      </div>
      <div>
        <app-filter-dropdown-new
          [filterType]="filterTypes.Unit"
          [usePredefinedFilters]="true"
          [predefinedFilters]="unitFilters"
          [appliedFilters]="[unitSelectedFilter]"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="T.settings.sustainability.unit_measurements.unit_label | translate"
          [currentlySelectedSettingValue]="unitSelectedFilter?.filterValue"
          (onFilterUpdate)="updateIndicatorUnit($event)"
          [singleSelect]="true"
          class="col-6"
        >
        </app-filter-dropdown-new>
        <div class="bottom-element d-flex mt-2 ms-2">
          <app-button-transparent
            [app-tooltip]="{ message: T.sustainability.add_indicator_unit_helper_text | translate }"
            size="small"
            [text]="T.common.learn_more | translate"
          ></app-button-transparent>
        </div>
      </div>

      <div>
        <app-filter-dropdown-new
          [filterType]="filterTypes.Unit"
          [usePredefinedFilters]="true"
          [predefinedFilters]="unitFilters"
          [appliedFilters]="[convertionSelectedUnitFilter]"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="T.settings.sustainability.unit_measurements.unit_conversion_label | translate"
          [displayError]="triggerIndicatorErrors"
          [required]="true"
          [currentlySelectedSettingValue]="convertionSelectedUnitFilter?.filterValue"
          (onFilterUpdate)="updateConversionIndicatorUnit($event)"
          [singleSelect]="true"
          class="col-6"
          [disabled]="isConversionDisabled"

        >
        </app-filter-dropdown-new>
        <div class="bottom-element d-flex mt-2 ms-2">
          <app-button-transparent
            [app-tooltip]="{ message: T.sustainability.add_indicator_convert_value_to_helper_text | translate }"
            size="small"
            [text]="T.common.learn_more | translate"
          ></app-button-transparent>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #indicatorTrackingDetailsRightSide>
  <div class="indicator-tracking-details-container" [formGroup]="indicatorForm">
    <ng-container [ngTemplateOutlet]="formSectionHeaderInfo"></ng-container>
    <ng-container
      [ngTemplateOutlet]="formSectionHeaderTitle"
      [ngTemplateOutletContext]="{
        title: T.sustainability.how_often_indicators_to_be_updated | translate}"
    ></ng-container>

    <div class="form-wrapper">
      <div class="frequency-text-info">
        {{ T.sustainability.updates_for_indicator_should_be_provided | translate }}
      </div>
      <div class="single-indicator-frequency-container">
        <div class="tracking-date-value">
          <app-text-field
            [placeholder]="''"
            [displayError]="triggerIndicatorErrors"
            [maxLength]="2"
            [useReactiveMessages]="true"
            [required]="true"
            [disableMargin]="true"
            formControlName="trackingDateValue"
            [formGroup]="indicatorForm"
          >
          </app-text-field>
        </div>

        <div class="frequency-value">
          <app-filter-dropdown-new
            [filterType]="filterTypes.Indicator_Tracking_Date_Type"
            [appliedFilters]="indicator.filters"
            [disableMargin]="true"
            [typeahead]="true"
            [label]="(T.sustainability.frequency | translate) + '*'"
            (onFilterUpdate)="updateTrackingDateType($event)"
            [singleSelect]="true"
          >
          </app-filter-dropdown-new>
        </div>
      </div>

      <div class="start-end-tracking-container">
        <div class="start-tracking">
          <app-datepicker
            [showHeader]="true"
            [showFooter]="false"
            [range]="true"
            [endDate]="getDueDate"
            [date]="getStartDate"
            (dateChanged)="updateStartDate($event)"
            (endDateChanged)="updateDueDate($event)"
            [placeholder]="T.sustainability.start_tracking | translate"
            [showPlaceholderFirst]="true"
            [disableMargin]="true"
          >
          </app-datepicker>
        </div>
        <div class="end-tracking">
          <app-datepicker
            [showFooter]="false"
            [showHeader]="true"
            [range]="true"
            [endDate]="getDueDate"
            [date]="getStartDate"
            [isEndDateField]="true"
            (dateChanged)="updateStartDate($event)"
            (endDateChanged)="updateDueDate($event)"
            [placeholder]="T.sustainability.end_tracking | translate"
            [showPlaceholderFirst]="true"
            [disableMargin]="true"
          >
          </app-datepicker>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #setEmissionsFactorsRightSide>
  <div class="indicator-tracking-emissions-factors-container" [formGroup]="indicatorForm">
    <ng-container
      [ngTemplateOutlet]="formSectionHeaderTitle"
      [ngTemplateOutletContext]="{
        title: T.sustainability.track_carbon_emissions | translate,
        subtitle: T.sustainability.set_emissions_factors_sub_title | translate
      }"
    ></ng-container>
    <ng-container *ngIf="!climatiqDataIsLoading; else climatiqDataLoadingTemplate">
      <div class="tracking-radio-buttons-wrapper">
        <app-radio-button
          [disabled]="!matchedClimaticIndicatorCategory || !matchedClimaticUnitType"
          [checked]="trackCarbonEmissions"
          [text]="T.sustainability.track_carbon_emissions | translate"
          (checkedChanged)="onCarbonEmissionsTrackingChanged(true)"
        ></app-radio-button>

        <app-radio-button
          [disabled]="!matchedClimaticIndicatorCategory || !matchedClimaticUnitType"
          [checked]="!trackCarbonEmissions"
          [text]="T.sustainability.dont_track_emissions | translate"
          (checkedChanged)="onCarbonEmissionsTrackingChanged(false)"
        ></app-radio-button>
      </div>

      <ng-container *ngIf="trackCarbonEmissions">
        <h2>{{ T.sustainability.based_on_your_account | translate}}</h2>
        <div class="search-info-wrapper">
          <div class="info-row">
            <div class="label">{{ T.common.category.one | translate }}</div>
            <div class="value">{{ matchedClimaticIndicatorCategory?.title }}</div>
          </div>
          <div class="info-row">
            <div class="label">{{ T.settings.sustainability.unit_measurements.unit_Type_label | translate }}</div>
            <div class="value">{{ matchedClimaticUnitType?.title }}</div>
          </div>
        </div>

        <div class="region-source-container">
          <app-search-dropdown-r
            [label]="T.settings.sustainability.region | translate"
            [objects]="climatiqRegions"
            [currentlySelectedObject]="selectedClimatiqRegion"
            [multiSelect]="false"
            [currentlySelectedObject]=""
            bindProperty="name"
            (objectSelected)="onClimatiqRegionChanged($event)"
          ></app-search-dropdown-r>
          <app-search-dropdown-r
            [label]="T.settings.sustainability.source | translate"
            [objects]="climatiqSources"
            [currentlySelectedObject]="selectedClimatiqSource"
            [multiSelect]="false"
            [currentlySelectedObject]=""
            bindProperty="title"
            (objectSelected)="onClimatiqSourceChanged($event)"
          ></app-search-dropdown-r>
        </div>

        <ng-container *ngIf="!emissionsFactorIsLoading; else emissionsFactorsLoadingTemplate">
          <div class="search-container">
            <app-text-field
              [placeholder]="T.sustainability.enter_emissions_keyword | translate"
              [maxLength]="250"
              [required]="false"
              [autofocus]="true"
              [disableMargin]="true"
              [icon]="'search'"
              name="Keyword"
              (onChange)="emissionFactorSearchSubject.next($event)"
            >
            </app-text-field>
          </div>

          <div class="climatiq-search-result-container">
            <h2>{{ T.sustainability.emissions_factors | translate }}</h2>

            <ng-container *ngIf="filteredClimaticIndicatorEmissionsData?.length; else noEmissionsFactorFound">
              <div class="search-result-description">
                <div class="description">
                  {{ T.sustainability.choose_factors | translate }}:
                </div>
              </div>
              <div class="search-result-list">
                <div class="search-result-item" *ngFor="let e of filteredClimaticIndicatorEmissionsData">
                  <div class="radio-btn">
                    <app-radio-button
                      [name]="'emissionFactor'"
                      [checked]="indicator.emissionsData?.activity_id === e.activity_id"
                      text=""
                      (checkedChanged)="onEmissionFactorSelected(e)"
                    ></app-radio-button>
                  </div>
                  <div class="search-result-item-title">{{ e.name }}</div>
                  <div class="search-result-item-source">{{ e.source }}</div>
                  <div class="search-result-item-region">{{ e.region }}</div>
                  <div class="search-result-item-source-link">
                    <a href="{{e.source_link}}" target="_new">{{ T.settings.sustainability.source_link | translate}}</a>
                  </div>
                </div>
              </div>
            </ng-container>

          </div>
        </ng-container>

      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #noEmissionsFactorFound>
  <div class="no-emissions-factor-found">
    <div class="no-emissions-factor-found-description">
      {{ T.sustainability.no_options_found | translate }}
    </div>
  </div>
</ng-template>

<ng-template #emissionsFactorsLoadingTemplate>
  <app-loading [displayLogo]="false">{{ T.sustainability.getting_emissions_factors | translate}}</app-loading>
</ng-template>

<ng-template #climatiqDataLoadingTemplate>
  <app-loading [displayLogo]="false"></app-loading>
</ng-template>

<ng-template #indicatorCreatedTemplate>
  <div class="backdrop-filter-wrapper">
    <div class="indicator-created-container">
      <span class="material-icons check_circle-icon">check_circle</span>
      <div class="indicator-created-title">{{ T.sustainability.indicator_added| translate }}
      </div>
      <div class="indicator-created-description">
        {{ T.settings.sustainability.indicator_created_text | translate }}
      </div>
      <div class="indicator-created-button">
        <app-button-rectangle
          [buttonTheme]="'primary'"
          [size]="'large'"
          [text]="T.sustainability.go_to_indicator_details | translate"
          (clicked)="onNavigateToIndicatorDetailsPageClicked()"
        ></app-button-rectangle>
      </div>
      <div class="indicator-created-button">
        <app-button-transparent
          [size]="'medium'"
          [text]="T.sustainability.create_another_indicator | translate"
          (clicked)="onAddAnotherIndicatorClicked()"
        ></app-button-transparent>
      </div>
    </div>
  </div>

</ng-template>
