import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Account } from '../models/account';
import { Employee } from '../models/employee';
import { BaseConfigurationViewModel } from '../viewModels/baseConfigurationViewModel';
import { ReportItemViewModel } from '../../reporting/viewModels/reportItemViewModel';
import { ReportTypesViewModel } from '../../reporting/viewModels/reportTypesViewModel';
import { ObjectTypes } from '../enums/objectTypes';
import { KeyValueItem } from '../models/keyValueItem';
import { BehaviorSubject, Observable } from 'rxjs';
import { WtStorageService } from './wt-storage.service';
import { AccountSelectItemViewModel } from '../viewModels/accountSelectItemViewModel';
import { LogAndIncidentSettings } from '../types/LogAndIncidentsSettings';

@Injectable()
export class CachingService {
  private accountConfiguraitonCacheKey: string = 'accountConfig';
  private currentEmployeeCacheKey: string = 'currentEmployee';
  private fixedEventPromptCookieName = 'liveEventPrompt';
  private fixedZonePromptCookieName = 'fixedZonePrompt';
  private currentReportItemKey = 'currentReportItem';
  private reportTypesKey = 'reportTypesKey';
  private reportExtraRowKey = 'reportExtraRowKey';
  private currentAccountIdKey = 'currentAccountIdKey';
  private addLogSettingsKey: string = 'addLogSettings';
  private addJobSettingsKey: string = 'addJobSettings';
  private addIncidentSettingsKey: string = 'addIncidentSettings';

  private accountData: Account;
  private employee: Employee;
  private helper: JwtHelperService;

  public employeeSubject = new BehaviorSubject<Employee>(null);
  public employee$: Observable<Employee> = this.employeeSubject.asObservable();

  constructor(private wtStorage: WtStorageService) {
    this.helper = new JwtHelperService();
  }

  AddAccountConfiguration(config: BaseConfigurationViewModel) {
    this.wtStorage.setItem(this.accountConfiguraitonCacheKey, JSON.stringify(config));
    this.accountData = config.accountData;
  }

  GetAccountConfiguration(): BaseConfigurationViewModel {
    const accountJwt = this.wtStorage.getItem(this.accountConfiguraitonCacheKey);

    if (!accountJwt) {
      return null;
    }

    const actualObject: BaseConfigurationViewModel = JSON.parse(accountJwt) as BaseConfigurationViewModel;
    return actualObject;
  }

  GetAccountInfo(): Account {
    if (this.accountData) return this.accountData;

    let actualObject: Account;

    const accConfig = this.GetAccountConfiguration();
    if (accConfig) {
      actualObject = accConfig.accountData;
      this.accountData = accConfig.accountData;
    } else {
      return null;
    }

    return actualObject;
  }

  RemoveAccountData() {
    this.accountData = undefined;
  }

  public IsAccountStored(): boolean {
    const isAccountStored = this.GetAccountConfiguration();

    if (isAccountStored) {
      const employeeJwt = this.wtStorage.getItem(this.currentEmployeeCacheKey);

      if (!employeeJwt) {
        return false;
      }
    } else {
      return false;
    }
  }

  UpdateCurrentReportItem(employeeId: number, report: ReportItemViewModel): void {
    this.RemoveCurrentReportItem(employeeId);
    this.SetCurrentReportItem(employeeId, report);
  }

  RemoveCurrentReportItem(employeeId: number): void {
    this.wtStorage.removeItem(`${this.currentReportItemKey}${employeeId}`);
  }

  SetCurrentReportItem(employeeId: number, report: ReportItemViewModel): void {
    const reportItemJSON = JSON.stringify(report);
    this.wtStorage.setItem(`${this.currentReportItemKey}${employeeId}`, reportItemJSON);
  }

  GetCurrentReportItem(employeeId: number): ReportItemViewModel {
    const riJSON = this.wtStorage.getItem(`${this.currentReportItemKey}${employeeId}`);
    if (!riJSON || riJSON == undefined) return null;

    const result = JSON.parse(riJSON) as ReportItemViewModel;
    return result;
  }

  GetEmployee(accountId: number): Employee {
    if (this.employee) return this.employee;
    const employeeJwt = this.wtStorage.getItem(this.currentEmployeeCacheKey + accountId);

    if (!employeeJwt) {
      return null;
    }

    const employeeJSON = this.JWTDecode(employeeJwt);
    const employee = JSON.parse(employeeJSON) as Employee;
    this.employee = employee;
    return employee;
  }

  AddEmployeeData(employeeData: string, accountId: number) {
    this.wtStorage.setItem(this.currentEmployeeCacheKey + accountId, employeeData);
    this.employee = undefined;

    const employee = this.GetEmployee(accountId);
    this.employeeSubject.next(employee);
  }

  RemoveEmployeeData(accountId: number) {
    this.employee = undefined;
    this.wtStorage.removeItem(this.currentEmployeeCacheKey + accountId);
    this.removeSelectedFixedEventId();
    this.removeSelectedFixedZoneId();
    this.employeeSubject.next(null);
  }

  IsEmployeeStored(accountId: number): boolean {
    return !!this.wtStorage.getItem(this.currentEmployeeCacheKey + accountId);
  }

  get selectedEventID() {
    return this.wtStorage.getItem('selectedLiveEventId');
  }

  set selectedEventID(eventId: string) {
    if (eventId != '') {
      this.wtStorage.setItem('selectedLiveEventId', eventId.toString());
    } else {
      this.removeSelectedFixedEventId();
    }
  }

  private removeSelectedFixedEventId() {
    this.wtStorage.removeItem('selectedLiveEventId');
  }

  get selectedZoneID() {
    return this.wtStorage.getItem('selectedLiveZoneId');
  }

  set selectedZoneID(zoneId: string) {
    if (zoneId != '') {
      this.wtStorage.setItem('selectedLiveZoneId', zoneId.toString());
    } else {
      this.removeSelectedFixedZoneId();
    }
  }

  private removeSelectedFixedZoneId() {
    this.wtStorage.removeItem('selectedLiveZoneId');
  }

  public JWTDecode(jsonWebToken: string): string {
    return this.helper.decodeToken(jsonWebToken).tObject;
  }

  public GetEmployeeIdByJWT(jwt: string): string {
    const decodedObject = this.helper.decodeToken(jwt);

    if (decodedObject) {
      return decodedObject.sub;
    }

    return null;
  }

  get fixedEventPromptCookie() {
    return getCookie(this.fixedEventPromptCookieName);
  }

  set fixedEventPromptCookie(input: string) {
    setCookie(this.fixedEventPromptCookieName, input);
  }

  removeFixedEventPromptCookie() {
    deleteCookie(this.fixedEventPromptCookieName);
  }

  get fixedZonePromptCookie() {
    return getCookie(this.fixedZonePromptCookieName);
  }

  set fixedZonePromptCookie(input: string) {
    setCookie(this.fixedZonePromptCookieName, input);
  }

  removeFixedZonePromptCookie() {
    deleteCookie(this.fixedZonePromptCookieName);
  }

  /** #### REPORTING Specific */

  removeReportExtraRow(reportTypeId: number): void {
    this.wtStorage.removeItem(this.reportExtraRowKey + reportTypeId);
  }

  addReportExtraRow(reportTypeId: number, data: KeyValueItem[]): void {
    this.wtStorage.setItem(this.reportExtraRowKey + reportTypeId, JSON.stringify(data));
  }

  getReportExtraRow(reportTypeId: number): KeyValueItem[] {
    const model = this.wtStorage.getItem(this.reportExtraRowKey + reportTypeId);

    if (!model) {
      return null;
    }

    return JSON.parse(model);
  }

  removeReportTypes(objectType: ObjectTypes[]): void {
    const key = objectType.join('');
    this.wtStorage.removeItem(this.reportTypesKey + key);
  }

  addReportTypes(objectType: ObjectTypes[], model: ReportTypesViewModel): void {
    const key = objectType.join('');
    this.wtStorage.setItem(this.reportTypesKey + key, JSON.stringify(model));
  }

  getReportTypes(objectType: ObjectTypes[]): ReportTypesViewModel {
    const key = objectType.join('');
    const model = this.wtStorage.getItem(this.reportTypesKey + key);

    if (!model) {
      return null;
    }

    return JSON.parse(model);
  }

  setCurrentAccount(account: AccountSelectItemViewModel) {
    this.getCurrentAccount;
    this.wtStorage.setItem(this.currentAccountIdKey, JSON.stringify(account));
    this.accountData = null;
  }

  getCurrentAccount(): AccountSelectItemViewModel {
    const account = this.wtStorage.getItem(this.currentAccountIdKey);
    if (!account) {
      return null;
    }
    return JSON.parse(account);
  }

  setAddJobSettings(settings: LogAndIncidentSettings): void {
    localStorage.setItem(this.addJobSettingsKey, JSON.stringify(settings));
  }

  getAddJobSettings(): LogAndIncidentSettings | null {
    const settings = localStorage.getItem(this.addJobSettingsKey);
    return JSON.parse(settings);
  }

  setAddLogSettings(settings: LogAndIncidentSettings): void {
    localStorage.setItem(this.addLogSettingsKey, JSON.stringify(settings));
  }

  getAddLogSettings(): LogAndIncidentSettings | null {
    const settings = localStorage.getItem(this.addLogSettingsKey);
    return JSON.parse(settings);
  }

  setAddIncidentSettings(settings: LogAndIncidentSettings): void {
    localStorage.setItem(this.addIncidentSettingsKey, JSON.stringify(settings));
  }

  getAddIncidentSettings(): LogAndIncidentSettings | null {
    const settings = localStorage.getItem(this.addIncidentSettingsKey);
    return JSON.parse(settings);
  }
}

export function setCookie(name: string, val: string) {
  const date = new Date();
  const value = val;

  // Set it expire in 7 days
  date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

  // Set it
  document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
}

export function getCookie(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  if (parts.length == 2) {
    return parts.pop().split(';').shift();
  }
}

export function deleteCookie(name: string) {
  const date = new Date();

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

  // Set it
  document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
}
