import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { ChecklistItemViewModel } from '../models/checklists/checklistItemViewModel';
import { ValidatedViewModel } from '../viewModels/validatedViewModel';

@Injectable()
export class ChecklistItemService {
  private actionUrl: string = '';

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`ChecklistItem/`);
  }

  add(chechlistTemplateViewModel: ChecklistItemViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(
      this.actionUrl + `Add`,
      chechlistTemplateViewModel,
      this.authenticationService.getHttpOptions()
    );
  }

  update(chechlistTemplateViewModel: ChecklistItemViewModel): Observable<ChecklistItemViewModel> {
    return this.http.put<ChecklistItemViewModel>(
      this.actionUrl + `Update`,
      chechlistTemplateViewModel,
      this.authenticationService.getHttpOptions()
    );
  }

  updateItems(chechlistTemplateViewModels: ChecklistItemViewModel[]): Observable<ChecklistItemViewModel[]> {
    return this.http.put<ChecklistItemViewModel[]>(
      this.actionUrl + `UpdateList`,
      chechlistTemplateViewModels,
      this.authenticationService.getHttpOptions()
    );
  }

  delete(checklistTemplateId: number): Observable<ChecklistItemViewModel> {
    return this.http.delete<ChecklistItemViewModel>(
      this.actionUrl + `Delete/${checklistTemplateId}`,
      this.authenticationService.getHttpOptions()
    );
  }

  getList(): Observable<ChecklistItemViewModel[]> {
    return this.http.get<ChecklistItemViewModel[]>(this.actionUrl + `GetList`, this.authenticationService.getHttpOptions());
  }

  getListByGlobalObjectId(globalObjectId: number, globalObjectType: number): Observable<ChecklistItemViewModel[]> {
    return this.http.get<ChecklistItemViewModel[]>(
      this.actionUrl + `GetListById/${globalObjectId}/${globalObjectType}`,
      this.authenticationService.getHttpOptions()
    );
  }

  addFromTemplate(
    checklistTemplateIds: number[],
    globalObjectId: number,
    globalObjectType: number
  ): Observable<ChecklistItemViewModel[]> {
    return this.http.post<ChecklistItemViewModel[]>(
      this.actionUrl + `AddFromTemplate/${globalObjectId}/${globalObjectType}`,
      checklistTemplateIds,
      this.authenticationService.getHttpOptions()
    );
  }
}
