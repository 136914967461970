import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { FilterTypes } from '../enums/filterTypes';
import { MarkerType } from '../enums/maps/marketType';
import { ModuleIconTypes } from '../enums/moduleIconTypes';
import { IconTypes } from '../types/iconTypes';

export class IconUtilities {
  public static getIconForFilterType(filterType: FilterTypes) {
    switch (filterType) {
      case FilterTypes.Task_Group: {
        return '';
      }
      case FilterTypes.Milestone_Type: {
        return 'flag';
      }
      case FilterTypes.Project: {
        return 'folder_open';
      }
      case FilterTypes.Owner: {
        return 'person';
      }
      case FilterTypes.Employee: {
        return 'person';
      }
      case FilterTypes.Approver: {
        return 'person';
      }
      case FilterTypes.Department: {
        return 'people';
      }
      case FilterTypes.Venue_Category: {
        return 'folder_open';
      }
      case FilterTypes.Venue_Type: {
        return 'folder_open';
      }
      case FilterTypes.Client_Group: {
        return 'label';
      }
      case FilterTypes.Health_and_Safety: {
        return 'label';
      }
      case FilterTypes.Department_Category: {
        return 'location_city';
      }
      case FilterTypes.Venue_Tag: {
        return 'folder_open';
      }
      case FilterTypes.Tag:
      case FilterTypes.Tag_Group: {
        return 'label';
      }
      case FilterTypes.Zone: {
        return 'location_searching';
      }
      case FilterTypes.Area: {
        return 'gps_fixed';
      }
      case FilterTypes.Job_Category:
      case FilterTypes.Incident_Category:
      case FilterTypes.Indicator_Category: {
        return 'category';
      }
      case FilterTypes.Event: {
        return 'event';
      }
      case FilterTypes.Incident_Channel: {
        return 'record_voice_over';
      }
      case FilterTypes.Project_Privacy_Status: {
        return 'lock';
      }

      case FilterTypes.Runsheet_Item_Dependencies_Count: {
        return 'link';
      }
      case FilterTypes.User_Group: {
        return 'group_add';
      }
      case FilterTypes.External_User: {
        return 'person_outline';
      }
      case FilterTypes.Job: {
        return 'assignment_turned_in';
      }
    }
    return '';
  }

  public static getSvgForFilterType(
    filterType: FilterTypes,
    size: number = 24,
    outlined: boolean = true,
    colour: string = '#939699'
  ) {
    switch (filterType) {
      case FilterTypes.Task: {
        const taskOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0H32V32H0z"/>
                  <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="${colour}" rx="4"/>
                  <g fill="${colour}" fill-rule="nonzero">
                    <path d="M5.298 10.667L.667 6 2.533 4.124 5.298 6.898 12.133 0 14 1.876zM.222 13.333H14.443999999999999V15.111H.222z" transform="translate(8.667 8)"/>
                  </g>
                </g>
              </svg>`;

        const taskNotOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
              <g fill="none" fill-rule="evenodd">
                  <path d="M0 0H48V48H0z"/>
                  <rect width="40" height="40" x="4" y="4" fill="${colour}" rx="6"/>
                  <g fill="#FFF" fill-rule="nonzero">
                      <path d="M7.947 16L1 9 3.8 6.187 7.947 10.347 18.2 0 21 2.813zM.333 20H21.665999999999997V22.667H.333z" transform="translate(13 12)"/>
                  </g>
              </g>
          </svg>`;
        return outlined ? taskOutlinedSvg : taskNotOutlinedSvg;
      }
      case FilterTypes.Project: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 0H32V32H0z"/>
                    <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="${colour}" rx="4"/>
                    <path fill="${colour}" fill-rule="nonzero" d="M23.111 9.778H16L14.222 8H8.89c-.982 0-1.778.796-1.778 1.778v12.444c0 .982.796 1.778 1.778 1.778H23.11c.982 0 1.778-.796 1.778-1.778V11.556c0-.982-.796-1.778-1.778-1.778zm0 12.444H8.89V11.556H23.11v10.666z"/>
                </g>
              </svg>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
              <g fill="none" fill-rule="evenodd">
                  <path d="M0 0H48V48H0z"/>
                  <rect width="40" height="40" x="4" y="4" fill="${colour}" rx="6"/>
                  <path fill="#FFF" fill-rule="nonzero" d="M34.667 14.667H24L21.333 12h-8c-1.472 0-2.666 1.194-2.666 2.667v18.666c0 1.473 1.194 2.667 2.666 2.667h21.334c1.472 0 2.666-1.194 2.666-2.667v-16c0-1.472-1.194-2.666-2.666-2.666zm0 18.666H13.333v-16h21.334v16z"/>
              </g>
          </svg>`;
        return outlined ? outlinedSvg : notOutlinedSvg;
      }
      case FilterTypes.Risk: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 0H32V32H0z"/>
                    <path fill="${colour}" fill-rule="nonzero" d="M16 2.33c.656.016 1.246.404 1.52 1l11.86 21.8c.41.712.41 1.588 0 2.3-.295.545-.86.888-1.48.9H4.14c-.62-.012-1.185-.355-1.48-.9-.41-.712-.41-1.588 0-2.3L14.48 3.31c.274-.596.864-.984 1.52-1m0-1c-1.018.012-1.944.59-2.4 1.5L1.74 24.65c-1.12 2.06.22 4.68 2.4 4.68h23.72c2.18 0 3.52-2.62 2.4-4.68L18.4 2.83c-.456-.91-1.382-1.488-2.4-1.5v-.02z"/>
                    <path fill="${colour}" fill-rule="nonzero" d="M17.33 21.33L14.67 21.33 14.67 24 17.33 24 17.33 21.33zM17.33 10.67L14.67 10.67 14.67 18.67 17.33 18.67z"/>
                </g>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 0H48V48H0z"/>
                    <path fill="${colour}" fill-rule="nonzero" d="M45.4 36.98L27.6 4.26c-.668-1.38-2.067-2.256-3.6-2.256s-2.932.876-3.6 2.256L2.6 36.98c-.817 1.378-.87 3.079-.139 4.504C3.192 42.91 4.604 43.86 6.2 44h35.6c1.596-.14 3.008-1.09 3.739-2.516.73-1.425.678-3.126-.139-4.504zM26 36h-4v-4h4v4zm0-8h-4V16h4v12z"/>
                </g>
             </svg>`;
        return outlined ? outlinedSvg : notOutlinedSvg;
      }
      case FilterTypes.Risk_Breakdown: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 0H32V32H0z"/>
                    <path fill="${colour}" fill-rule="nonzero" d="M16 2.33c.656.016 1.246.404 1.52 1l11.86 21.8c.41.712.41 1.588 0 2.3-.295.545-.86.888-1.48.9H4.14c-.62-.012-1.185-.355-1.48-.9-.41-.712-.41-1.588 0-2.3L14.48 3.31c.274-.596.864-.984 1.52-1m0-1c-1.018.012-1.944.59-2.4 1.5L1.74 24.65c-1.12 2.06.22 4.68 2.4 4.68h23.72c2.18 0 3.52-2.62 2.4-4.68L18.4 2.83c-.456-.91-1.382-1.488-2.4-1.5v-.02z"/>
                    <path fill="${colour}" fill-rule="nonzero" d="M17.33 21.33L14.67 21.33 14.67 24 17.33 24 17.33 21.33zM17.33 10.67L14.67 10.67 14.67 18.67 17.33 18.67z"/>
                </g>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                    <path d="M0 0H48V48H0z"/>
                    <path fill="${colour}" fill-rule="nonzero" d="M45.4 36.98L27.6 4.26c-.668-1.38-2.067-2.256-3.6-2.256s-2.932.876-3.6 2.256L2.6 36.98c-.817 1.378-.87 3.079-.139 4.504C3.192 42.91 4.604 43.86 6.2 44h35.6c1.596-.14 3.008-1.09 3.739-2.516.73-1.425.678-3.126-.139-4.504zM26 36h-4v-4h4v4zm0-8h-4V16h4v12z"/>
                </g>
             </svg>`;
        return outlined ? outlinedSvg : notOutlinedSvg;
      }
      case FilterTypes.Issue_Breakdown: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0H32V32H0z">
                  <path fill="${colour}" fill-rule="nonzero" d="M16 2.33c.534.005 1.057.157 1.51.44L26.87 8c.913.571 1.465 1.574 1.46 2.65v10.68c.022 1.079-.52 2.09-1.43 2.67l-9.42 5.29c-.916.578-2.084.578-3 0L5.13 24c-.913-.571-1.465-1.574-1.46-2.65V10.67C3.648 9.591 4.19 8.58 5.1 8l9.42-5.29c.45-.258.961-.389 1.48-.38m0-1c-.705.002-1.396.196-2 .56L4.61 7.16c-1.212.754-1.947 2.082-1.94 3.51v10.66c-.007 1.428.728 2.756 1.94 3.51L14 30.11c1.229.747 2.771.747 4 0l9.39-5.27c1.212-.754 1.947-2.082 1.94-3.51V10.67c.007-1.428-.728-2.756-1.94-3.51L18 1.89c-.604-.364-1.295-.558-2-.56z" />
                  <path fill="${colour}" fill-rule="nonzero" d="M17.33 20L14.67 20 14.67 22.67 17.33 22.67zM17.33 9.33L14.67 9.33 14.67 17.33 17.33 17.33 17.33 9.33z" />
                </g>
              </svg>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0H48V48H0z" />
                  <path fill="${colour}" fill-rule="nonzero" d="M41.08 10.74l-14-7.9c-1.843-1.12-4.157-1.12-6 0l-14 8C5.221 11.903 4.053 13.86 4 16v16c-.015 2.144 1.093 4.14 2.92 5.26l14 7.9c1.843 1.12 4.157 1.12 6 0l14-7.9c1.889-1.081 3.062-3.084 3.08-5.26V16c.015-2.144-1.093-4.14-2.92-5.26zM26 34h-4v-4h4v4zm0-8h-4V14h4v12z" />
                </g>
              </svg>`;
        return outlined ? outlinedSvg : notOutlinedSvg;
      }
      case FilterTypes.Opportunity_Breakdown: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0H32V32H0z"/>
                  <path fill="${colour}" fill-rule="nonzero"
                    d="M16 2.33c.706 0 1.382.28 1.88.78l11 11c1.038 1.039 1.038 2.721 0 3.76l-11 11c-1.039 1.038-2.721 1.038-3.76 0l-11-11c-1.038-1.039-1.038-2.721 0-3.76l11-11c.498-.5 1.174-.78 1.88-.78m0-1c-.971-.001-1.903.384-2.59 1.07l-11 11c-1.421 1.434-1.421 3.746 0 5.18l11 11c1.432 1.426 3.748 1.426 5.18 0l11-11c1.421-1.434 1.421-3.746 0-5.18l-11-11c-.687-.686-1.619-1.071-2.59-1.07z" />
                  <path fill="${colour}" fill-rule="nonzero"
                    d="M6.96 23.15L15.45 14.67 12 14.67 12 12 17.33 12 20 12 20 14.67 20 20 17.33 20 17.33 16.55 8.85 25.04" />
                </g>
              </svg>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0H48V48H0z" />
                  <path fill="${colour}" fill-rule="nonzero"
                    d="M44.4 20.12L27.88 3.6c-2.146-2.136-5.614-2.136-7.76 0L3.6 20.12c-2.136 2.146-2.136 5.614 0 7.76l6.84 6.84L23.18 22H18v-4h12v12h-4v-5.18L13.28 37.56l6.84 6.84c2.146 2.136 5.614 2.136 7.76 0L44.4 27.88c2.136-2.146 2.136-5.614 0-7.76z" />
                </g>
              </svg>`;
        return outlined ? outlinedSvg : notOutlinedSvg;
      }
      case FilterTypes.Risk_Action_Breakdown: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0H32V32H0z"/>
                                            <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="${colour}" rx="4"/>
                                            <path d="M6 6L26 6 26 26 6 26z"/>
                                            <path fill="${colour}" fill-rule="nonzero" d="M22.667 9.333v10h-10v-10h10m0-1.666h-10c-.917 0-1.667.75-1.667 1.666v10c0 .917.75 1.667 1.667 1.667h10c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666zm-6.275 10L13.5 14.75l1.167-1.175 1.725 1.733L20.667 11l1.166 1.175-5.441 5.492zM9.333 11H7.667v11.667c0 .916.75 1.666 1.666 1.666H21v-1.666H9.333V11z"/>
                                        </g>
                                    </svg>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0L48 0 48 48 0 48z"/>
                                                <path fill="${colour}" fill-rule="nonzero" d="M40 8v24H16V8h24m0-4H16c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4zM24.94 28L18 21l2.8-2.82 4.14 4.16L35.2 12l2.8 2.82L24.94 28zM8 12H4v28c0 2.2 1.8 4 4 4h28v-4H8V12z"/>
                                            </g>
                                        </svg>`;
        return outlined ? outlinedSvg : notOutlinedSvg;
      }

      case FilterTypes.Task_Group: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                    <g fill="none" fill-rule="evenodd">
                    <path d="M0 0L48 0 48 48 0 48z" />
                    <path fill="${colour}" fill-rule="nonzero"
                        d="M20.94 34L14 27 16.8 24.18 20.94 28.34 31.2 18 34 20.82z" />
                    <path fill="${colour}" fill-rule="nonzero"
                        d="M40 10H24l-4-4H8c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 28H8V14h32v24z" />
                    </g>
                </svg>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                    <g fill="none" fill-rule="evenodd">
                    <path d="M0 0L48 0 48 48 0 48z" />
                    <path fill="${colour}" fill-rule="nonzero"
                        d="M20.94 34L14 27 16.8 24.18 20.94 28.34 31.2 18 34 20.82z" />
                    <path fill="${colour}" fill-rule="nonzero"
                        d="M40 10H24l-4-4H8c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 28H8V14h32v24z" />
                    </g>
                </svg>`;
        return outlined ? outlinedSvg : notOutlinedSvg;
      }
    }

    return '';
  }

  public static getSvgForIconType(
    iconType: IconTypes,
    size: number = 24,
    outlined: boolean = true,
    colour: string = '#939699',
    achieved: boolean = false
  ) {
    switch (iconType) {
      case IconTypes.Risk: {
        return IconUtilities.getSvgForFilterType(FilterTypes.Risk, size, outlined, colour);
      }
      case IconTypes.Task: {
        return IconUtilities.getSvgForFilterType(FilterTypes.Task, size, outlined, colour);
      }
      case IconTypes.Project: {
        return IconUtilities.getSvgForFilterType(FilterTypes.Project, size, outlined, colour);
      }
      case IconTypes.Task_Group: {
        return IconUtilities.getSvgForFilterType(FilterTypes.Task_Group, size, outlined, colour);
      }
      case IconTypes.Sub_Task: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0H32V32H0z"/>
                                            <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="${colour}" rx="4"/>
                                            <g fill="${colour}" fill-rule="nonzero">
                                                <path d="M4.338 9.333L.568 5.531 2.089 3.999 4.338 6.259 9.911.642 11.432 2.174zM4.338 18.025L.568 14.222 2.089 12.69 4.338 14.95 9.911 9.333 11.432 10.865z" transform="translate(10 6.667)"/>
                                            </g>
                                        </g>
                                    </svg>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0H48V48H0z"/>
                                                <rect width="40" height="40" x="4" y="4" fill="${colour}" rx="6"/>
                                                <g fill="#FFF" fill-rule="nonzero">
                                                    <path d="M6.507 14L.852 8.296 3.133 5.999 6.507 9.388 14.867.963 17.148 3.261zM6.507 27.037L.852 21.333 3.133 19.036 6.507 22.425 14.867 14 17.148 16.298z" transform="translate(15 10)"/>
                                                </g>
                                            </g>
                                        </svg>`;

        return outlined ? outlinedSvg : notOutlinedSvg;
      }
      case IconTypes.Risk_Action: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0H32V32H0z"/>
                                            <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="${colour}" rx="4"/>
                                            <path d="M6 6L26 6 26 26 6 26z"/>
                                            <path fill="${colour}" fill-rule="nonzero" d="M22.667 9.333v10h-10v-10h10m0-1.666h-10c-.917 0-1.667.75-1.667 1.666v10c0 .917.75 1.667 1.667 1.667h10c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666zm-6.275 10L13.5 14.75l1.167-1.175 1.725 1.733L20.667 11l1.166 1.175-5.441 5.492zM9.333 11H7.667v11.667c0 .916.75 1.666 1.666 1.666H21v-1.666H9.333V11z"/>
                                        </g>
                                    </svg>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0L48 0 48 48 0 48z"/>
                                                <path fill="${colour}" fill-rule="nonzero" d="M40 8v24H16V8h24m0-4H16c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4zM24.94 28L18 21l2.8-2.82 4.14 4.16L35.2 12l2.8 2.82L24.94 28zM8 12H4v28c0 2.2 1.8 4 4 4h28v-4H8V12z"/>
                                            </g>
                                        </svg>`;
        return outlined ? outlinedSvg : notOutlinedSvg;
      }
      case IconTypes.Opportunity: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0H32V32H0z" />
                                            <path fill="${colour}" fill-rule="nonzero" d="M16 2.33c.706 0 1.382.28 1.88.78l11 11c1.038 1.039 1.038 2.721 0 3.76l-11 11c-1.039 1.038-2.721 1.038-3.76 0l-11-11c-1.038-1.039-1.038-2.721 0-3.76l11-11c.498-.5 1.174-.78 1.88-.78m0-1c-.971-.001-1.903.384-2.59 1.07l-11 11c-1.421 1.434-1.421 3.746 0 5.18l11 11c1.432 1.426 3.748 1.426 5.18 0l11-11c1.421-1.434 1.421-3.746 0-5.18l-11-11c-.687-.686-1.619-1.071-2.59-1.07z"/>
                                            <path fill="${colour}" fill-rule="nonzero" d="M6.96 23.15L15.45 14.67 12 14.67 12 12 17.33 12 20 12 20 14.67 20 20 17.33 20 17.33 16.55 8.85 25.04" />
                                        </g>
                                    </svg>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0H48V48H0z"/>
                                                <path fill="${colour}" fill-rule="nonzero" d="M44.4 20.12L27.88 3.6c-2.146-2.136-5.614-2.136-7.76 0L3.6 20.12c-2.136 2.146-2.136 5.614 0 7.76l6.84 6.84L23.18 22H18v-4h12v12h-4v-5.18L13.28 37.56l6.84 6.84c2.146 2.136 5.614 2.136 7.76 0L44.4 27.88c2.136-2.146 2.136-5.614 0-7.76z"/>
                                            </g>
                                        </svg>`;
        return outlined ? outlinedSvg : notOutlinedSvg;
      }
      case IconTypes.Issue: {
        const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 32 32">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0H32V32H0z" />
                                            <path fill="${colour}" fill-rule="nonzero"  d="M16 2.33c.534.005 1.057.157 1.51.44L26.87 8c.913.571 1.465 1.574 1.46 2.65v10.68c.022 1.079-.52 2.09-1.43 2.67l-9.42 5.29c-.916.578-2.084.578-3 0L5.13 24c-.913-.571-1.465-1.574-1.46-2.65V10.67C3.648 9.591 4.19 8.58 5.1 8l9.42-5.29c.45-.258.961-.389 1.48-.38m0-1c-.705.002-1.396.196-2 .56L4.61 7.16c-1.212.754-1.947 2.082-1.94 3.51v10.66c-.007 1.428.728 2.756 1.94 3.51L14 30.11c1.229.747 2.771.747 4 0l9.39-5.27c1.212-.754 1.947-2.082 1.94-3.51V10.67c.007-1.428-.728-2.756-1.94-3.51L18 1.89c-.604-.364-1.295-.558-2-.56z" />
                                            <path fill="${colour}" fill-rule="nonzero" d="M17.33 20L14.67 20 14.67 22.67 17.33 22.67zM17.33 9.33L14.67 9.33 14.67 17.33 17.33 17.33 17.33 9.33z" />
                                        </g>
                                    </svg>`;

        const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 48 48">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0H48V48H0z"/>
                                                <path fill="${colour}" fill-rule="nonzero" d="M41.08 10.74l-14-7.9c-1.843-1.12-4.157-1.12-6 0l-14 8C5.221 11.903 4.053 13.86 4 16v16c-.015 2.144 1.093 4.14 2.92 5.26l14 7.9c1.843 1.12 4.157 1.12 6 0l14-7.9c1.889-1.081 3.062-3.084 3.08-5.26V16c.015-2.144-1.093-4.14-2.92-5.26zM26 34h-4v-4h4v4zm0-8h-4V14h4v12z"/>
                                            </g>
                                        </svg>`;

        return outlined ? outlinedSvg : notOutlinedSvg;
      }
      case IconTypes.Target_Badge: {
        switch (achieved) {
          case false: {
            const outlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="${size}" viewBox="0 0 24 24" width="${size}" fill="#000000">
                                                <path d="M0 0h24v24H0V0z" fill="none"/>
                                                <g fill="${colour}" stroke="${colour}" fill-rule="nonzero">
                                                    <path stroke-width="0.00001" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm7.48 7.16l-5.01-.43-2-4.71c3.21.19 5.91 2.27 7.01 5.14zm-5.07 6.26L12 13.98l-2.39 1.44.63-2.72-2.11-1.83 2.78-.24L12 8.06l1.09 2.56 2.78.24-2.11 1.83.64 2.73zm-2.86-11.4l-2 4.72-5.02.43c1.1-2.88 3.8-4.97 7.02-5.15zM4 12c0-.64.08-1.26.23-1.86l3.79 3.28-1.11 4.75C5.13 16.7 4 14.48 4 12zm3.84 6.82L12 16.31l4.16 2.5c-1.22.75-2.64 1.19-4.17 1.19-1.52 0-2.94-.44-4.15-1.18zm9.25-.65l-1.11-4.75 3.79-3.28c.14.59.23 1.22.23 1.86 0 2.48-1.14 4.7-2.91 6.17z"/>
                                                </g>
                                            </svg>`;
            const notOutlinedSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="${size}" viewBox="0 0 24 24" width="${size}" fill="#000000">
                                                    <path d="M0 0h24v24H0z" fill="none"/>
                                                    <path fill="${colour}" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/>
                                                </svg>`;
            return outlined ? outlinedSvg : notOutlinedSvg;
          }
          case true: {
            const avhievedSvg = `<svg width="${size}" height="${size}" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="24.5385" cy="25.5384" r="13.8462" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.15332 25.5383C9.15332 17.046 16.0302 10.1537 24.5225 10.1537C33.0302 10.1537 39.9226 17.046 39.9226 25.5383C39.9226 34.0306 33.0302 40.9229 24.5225 40.9229C16.0302 40.9229 9.15332 34.0306 9.15332 25.5383ZM24.5381 30.8459L31.0457 34.769L29.3227 27.3536L35.0611 22.3843L27.4919 21.7382L24.5381 14.769L21.5842 21.7536L14.015 22.3997L19.7534 27.369L18.0304 34.769L24.5381 30.8459Z" fill="${colour}"/>
                                <g filter="url(#filter0_d_618_20065)">
                                <path d="M12.2304 5.53845C7.98424 5.53845 4.53809 8.98461 4.53809 13.2308C4.53809 17.4769 7.98424 20.9231 12.2304 20.9231C16.4765 20.9231 19.9227 17.4769 19.9227 13.2308C19.9227 8.98461 16.4765 5.53845 12.2304 5.53845Z" fill="#0086D6"/>
                                <path d="M10.6919 17.077L6.8457 13.2309L7.93032 12.1463L10.6919 14.9001L16.5303 9.06165L17.6149 10.154L10.6919 17.077Z" fill="white"/>
                                </g>
                                <defs>
                                <filter id="filter0_d_618_20065" x="3.53809" y="4.53845" width="19.3848" height="19.3846" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dx="1" dy="1"/>
                                <feGaussianBlur stdDeviation="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_618_20065"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_618_20065" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                        `;

            return avhievedSvg;
          }
        }
      }
    }

    return '';
  }

  public static getModuleIcon(moduleType: ModuleTypes, size = 24, moduleIconType: ModuleIconTypes) {
    let svgString = '';
    switch (moduleType) {
      case ModuleTypes.My_Track: {
        switch (moduleIconType) {
          case ModuleIconTypes.White: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-132 -144)"/>
                                <g transform="translate(-132 -144) translate(132 144)">
                                    <circle cx="12.004" cy="6.002" r="4" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="rotate(-89.6 12.004 6.002)"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <circle cx="12" cy="21.5" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.75 17C18 14.452 15.09 12.95 12 13c-3.1-.058-6.023 1.444-7.78 4"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Yellow: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-132 -144)"/>
                                <g transform="translate(-132 -144) translate(132 144)">
                                    <circle cx="12.004" cy="6.002" r="4" stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="rotate(-89.6 12.004 6.002)"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <circle cx="12" cy="21.5" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.75 17C18 14.452 15.09 12.95 12 13c-3.1-.058-6.023 1.444-7.78 4"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Grey_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-132 -144)"/>
                                <g transform="translate(-132 -144) translate(132 144)">
                                    <circle cx="12.004" cy="6.002" r="4" stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="rotate(-89.6 12.004 6.002)"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <circle cx="12" cy="21.5" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.75 17C18 14.452 15.09 12.95 12 13c-3.1-.058-6.023 1.444-7.78 4"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Blue_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-132 -144)"/>
                                <g transform="translate(-132 -144) translate(132 144)">
                                    <circle cx="12.004" cy="6.002" r="4" stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="rotate(-89.6 12.004 6.002)"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <circle cx="12" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.75 17C18 14.452 15.09 12.95 12 13c-3.1-.058-6.023 1.444-7.78 4"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_1: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                            <path fill="" d="M0 0H474V200H0z" transform="translate(-132 -32)"/>
                                <g transform="translate(-132 -32) translate(132 32)">
                                    <circle cx="12.004" cy="6.002" r="4" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="rotate(-89.6 12.004 6.002)"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                    <circle cx="12" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.75 17C18 14.452 15.09 12.95 12 13c-3.1-.058-6.023 1.444-7.78 4"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_2: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                            <path fill="" d="M0 0H474V200H0z" transform="translate(-132 -88)"/>
                                <g transform="translate(-132 -88) translate(132 88)">
                                    <circle cx="12.004" cy="6.002" r="4" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="rotate(-89.6 12.004 6.002)"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <circle cx="12" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.75 17C18 14.452 15.09 12.95 12 13c-3.1-.058-6.023 1.444-7.78 4"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
        }
        break;
      }
      case ModuleTypes.Reporting: {
        switch (moduleIconType) {
          case ModuleIconTypes.White: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-188 -144)"/>
                                <g transform="translate(-188 -144) translate(188 144)">
                                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 3L21 3M3 9L21 9"/>
                                    <circle cx="3.5" cy="3" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <circle cx="3.5" cy="15" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 15L21 15M3 21L21 21"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Yellow: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-188 -144)"/>
                                <g transform="translate(-188 -144) translate(188 144)">
                                    <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 3L21 3M3 9L21 9"/>
                                    <circle cx="3.5" cy="3" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <circle cx="3.5" cy="15" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 15L21 15M3 21L21 21"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Grey_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-188 -144)"/>
                                <g transform="translate(-188 -144) translate(188 144)">
                                    <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 3L21 3M3 9L21 9"/>
                                    <circle cx="3.5" cy="3" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <circle cx="3.5" cy="15" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 15L21 15M3 21L21 21"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Blue_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-188 -144)"/>
                                <g transform="translate(-188 -144) translate(188 144)">
                                    <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 3L21 3M3 9L21 9"/>
                                    <circle cx="3.5" cy="3" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <circle cx="3.5" cy="15" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 15L21 15M3 21L21 21"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_1: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-188 -32)"/>
                                <g transform="translate(-188 -32) translate(188 32)">
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 3L21 3M3 9L21 9"/>
                                    <circle cx="3.5" cy="3" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                    <circle cx="3.5" cy="15" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 15L21 15M3 21L21 21"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_2: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-188 -88)"/>
                                <g transform="translate(-188 -88) translate(188 88)">
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 3L21 3M3 9L21 9"/>
                                    <circle cx="3.5" cy="3" r="1.75" fill="#C54644" fill-rule="nonzero"/>
                                    <circle cx="3.5" cy="15" r="1.75" fill="#C54644" fill-rule="nonzero"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 15L21 15M3 21L21 21"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
        }
        break;
      }
      case ModuleTypes.Planning: {
        switch (moduleIconType) {
          case ModuleIconTypes.White: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-244 -144)"/>
                                <g transform="translate(-244 -144) translate(244 144)">
                                    <circle cx="12" cy="21.5" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 17V9c0-1.105-.895-2-2-2H9C7.62 7 6.5 5.88 6.5 4.5h0C6.5 3.12 7.62 2 9 2h6c1.38 0 2.5 1.12 2.5 2.5h0c0 .663-.263 1.299-.732 1.768C16.298 6.737 15.663 7 15 7"/>
                                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 17v-2c0-1.38-1.12-2.5-2.5-2.5h-13C4.12 12.5 3 13.62 3 15v2"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Yellow: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-244 -144)"/>
                                <g transform="translate(-244 -144) translate(244 144)">
                                    <circle cx="12" cy="21.5" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 17V9c0-1.105-.895-2-2-2H9C7.62 7 6.5 5.88 6.5 4.5h0C6.5 3.12 7.62 2 9 2h6c1.38 0 2.5 1.12 2.5 2.5h0c0 .663-.263 1.299-.732 1.768C16.298 6.737 15.663 7 15 7"/>
                                    <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 17v-2c0-1.38-1.12-2.5-2.5-2.5h-13C4.12 12.5 3 13.62 3 15v2"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Grey_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-244 -144)"/>
                                <g transform="translate(-244 -144) translate(244 144)">
                                    <circle cx="12" cy="21.5" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 17V9c0-1.105-.895-2-2-2H9C7.62 7 6.5 5.88 6.5 4.5h0C6.5 3.12 7.62 2 9 2h6c1.38 0 2.5 1.12 2.5 2.5h0c0 .663-.263 1.299-.732 1.768C16.298 6.737 15.663 7 15 7"/>
                                    <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 17v-2c0-1.38-1.12-2.5-2.5-2.5h-13C4.12 12.5 3 13.62 3 15v2"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Blue_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-244 -144)"/>
                                <g transform="translate(-244 -144) translate(244 144)">
                                    <circle cx="12" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 17V9c0-1.105-.895-2-2-2H9C7.62 7 6.5 5.88 6.5 4.5h0C6.5 3.12 7.62 2 9 2h6c1.38 0 2.5 1.12 2.5 2.5h0c0 .663-.263 1.299-.732 1.768C16.298 6.737 15.663 7 15 7"/>
                                    <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 17v-2c0-1.38-1.12-2.5-2.5-2.5h-13C4.12 12.5 3 13.62 3 15v2"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_1: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-244 -32)"/>
                                <g transform="translate(-244 -32) translate(244 32)">
                                    <circle cx="12" cy="21.5" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 17V9c0-1.105-.895-2-2-2H9C7.62 7 6.5 5.88 6.5 4.5h0C6.5 3.12 7.62 2 9 2h6c1.38 0 2.5 1.12 2.5 2.5h0c0 .663-.263 1.299-.732 1.768C16.298 6.737 15.663 7 15 7"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 17v-2c0-1.38-1.12-2.5-2.5-2.5h-13C4.12 12.5 3 13.62 3 15v2"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_2: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-244 -88)"/>
                                <g transform="translate(-244 -88) translate(244 88)">
                                    <circle cx="12" cy="21.5" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                    <circle cx="21" cy="21.5" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                    <circle cx="3" cy="21.5" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 17V9c0-1.105-.895-2-2-2H9C7.62 7 6.5 5.88 6.5 4.5h0C6.5 3.12 7.62 2 9 2h6c1.38 0 2.5 1.12 2.5 2.5h0c0 .663-.263 1.299-.732 1.768C16.298 6.737 15.663 7 15 7"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 17v-2c0-1.38-1.12-2.5-2.5-2.5h-13C4.12 12.5 3 13.62 3 15v2"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
        }
        break;
      }
      case ModuleTypes.Incidents: {
        switch (moduleIconType) {
          case ModuleIconTypes.White: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-300 -144)"/>
                                <g transform="translate(-300 -144) translate(300 144)">
                                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6c-2.737.058-4.942 2.263-5 5 0 3 5 6 5 6"/>
                                    <circle cx="12" cy="11" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c-4.97 0-9 4.03-9 9 0 6 9 11 9 11M12 6c2.743.043 4.957 2.257 5 5 0 3-5 6-5 6"/>
                                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c4.97 0 9 4.03 9 9 0 4-3.36 6.82-6 9"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Yellow: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-300 -144)"/>
                                <g transform="translate(-300 -144) translate(300 144)">
                                    <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6c-2.737.058-4.942 2.263-5 5 0 3 5 6 5 6"/>
                                    <circle cx="12" cy="11" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c-4.97 0-9 4.03-9 9 0 6 9 11 9 11M12 6c2.743.043 4.957 2.257 5 5 0 3-5 6-5 6"/>
                                    <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c4.97 0 9 4.03 9 9 0 4-3.36 6.82-6 9"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Grey_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-300 -144)"/>
                                <g transform="translate(-300 -144) translate(300 144)">
                                    <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6c-2.737.058-4.942 2.263-5 5 0 3 5 6 5 6"/>
                                    <circle cx="12" cy="11" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c-4.97 0-9 4.03-9 9 0 6 9 11 9 11M12 6c2.743.043 4.957 2.257 5 5 0 3-5 6-5 6"/>
                                    <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c4.97 0 9 4.03 9 9 0 4-3.36 6.82-6 9"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Blue_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-300 -144)"/>
                                <g transform="translate(-300 -144) translate(300 144)">
                                    <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6c-2.737.058-4.942 2.263-5 5 0 3 5 6 5 6"/>
                                    <circle cx="12" cy="11" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c-4.97 0-9 4.03-9 9 0 6 9 11 9 11M12 6c2.743.043 4.957 2.257 5 5 0 3-5 6-5 6"/>
                                    <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c4.97 0 9 4.03 9 9 0 4-3.36 6.82-6 9"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_1: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-300 -32)"/>
                                <g transform="translate(-300 -32) translate(300 32)">
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6c-2.737.058-4.942 2.263-5 5 0 3 5 6 5 6"/>
                                    <circle cx="12" cy="11" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c-4.97 0-9 4.03-9 9 0 6 9 11 9 11M12 6c2.743.043 4.957 2.257 5 5 0 3-5 6-5 6"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c4.97 0 9 4.03 9 9 0 4-3.36 6.82-6 9"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_2: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-300 -88)"/>
                                <g transform="translate(-300 -88) translate(300 88)">
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6c-2.737.058-4.942 2.263-5 5 0 3 5 6 5 6"/>
                                    <circle cx="12" cy="11" r="1.75" fill="#F87C56" fill-rule="nonzero"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c-4.97 0-9 4.03-9 9 0 6 9 11 9 11M12 6c2.743.043 4.957 2.257 5 5 0 3-5 6-5 6"/>
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 2c4.97 0 9 4.03 9 9 0 4-3.36 6.82-6 9"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
        }
        break;
      }
      case ModuleTypes.Runsheets: {
        switch (moduleIconType) {
          case ModuleIconTypes.White: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-412 -144)"/>
                                <g transform="translate(-412 -144) translate(412 144)">
                                    <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 2h3.5C19.88 2 21 3.12 21 4.5h0c0 .663-.263 1.299-.732 1.768-.47.469-1.105.732-1.768.732h-13C4.12 7 3 8.12 3 9.5h0C3 10.88 4.12 12 5.5 12h13c1.38 0 2.5 1.12 2.5 2.5h0c0 1.38-1.12 2.5-2.5 2.5h-13C4.12 17 3 18.12 3 19.5h0C3 20.88 4.12 22 5.5 22H9"/>
                                    <circle cx="13" cy="22" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <circle cx="11" cy="2" r="1.75" fill="white" fill-rule="nonzero"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Yellow: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-412 -144)"/>
                                <g transform="translate(-412 -144) translate(412 144)">
                                    <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 2h3.5C19.88 2 21 3.12 21 4.5h0c0 .663-.263 1.299-.732 1.768-.47.469-1.105.732-1.768.732h-13C4.12 7 3 8.12 3 9.5h0C3 10.88 4.12 12 5.5 12h13c1.38 0 2.5 1.12 2.5 2.5h0c0 1.38-1.12 2.5-2.5 2.5h-13C4.12 17 3 18.12 3 19.5h0C3 20.88 4.12 22 5.5 22H9"/>
                                    <circle cx="13" cy="22" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <circle cx="11" cy="2" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Grey_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-412 -144)"/>
                                <g transform="translate(-412 -144) translate(412 144)">
                                    <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 2h3.5C19.88 2 21 3.12 21 4.5h0c0 .663-.263 1.299-.732 1.768-.47.469-1.105.732-1.768.732h-13C4.12 7 3 8.12 3 9.5h0C3 10.88 4.12 12 5.5 12h13c1.38 0 2.5 1.12 2.5 2.5h0c0 1.38-1.12 2.5-2.5 2.5h-13C4.12 17 3 18.12 3 19.5h0C3 20.88 4.12 22 5.5 22H9"/>
                                    <circle cx="13" cy="22" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <circle cx="11" cy="2" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Blue_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-412 -144)"/>
                                <g transform="translate(-412 -144) translate(412 144)">
                                    <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 2h3.5C19.88 2 21 3.12 21 4.5h0c0 .663-.263 1.299-.732 1.768-.47.469-1.105.732-1.768.732h-13C4.12 7 3 8.12 3 9.5h0C3 10.88 4.12 12 5.5 12h13c1.38 0 2.5 1.12 2.5 2.5h0c0 1.38-1.12 2.5-2.5 2.5h-13C4.12 17 3 18.12 3 19.5h0C3 20.88 4.12 22 5.5 22H9"/>
                                    <circle cx="13" cy="22" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <circle cx="11" cy="2" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_1: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-412 -32)"/>
                                <g transform="translate(-412 -32) translate(412 32)">
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 2h3.5C19.88 2 21 3.12 21 4.5h0c0 .663-.263 1.299-.732 1.768-.47.469-1.105.732-1.768.732h-13C4.12 7 3 8.12 3 9.5h0C3 10.88 4.12 12 5.5 12h13c1.38 0 2.5 1.12 2.5 2.5h0c0 1.38-1.12 2.5-2.5 2.5h-13C4.12 17 3 18.12 3 19.5h0C3 20.88 4.12 22 5.5 22H9"/>
                                    <circle cx="13" cy="22" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                    <circle cx="11" cy="2" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_2: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <path fill="" d="M0 0H474V200H0z" transform="translate(-412 -88)"/>
                                <g transform="translate(-412 -88) translate(412 88)">
                                    <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 2h3.5C19.88 2 21 3.12 21 4.5h0c0 .663-.263 1.299-.732 1.768-.47.469-1.105.732-1.768.732h-13C4.12 7 3 8.12 3 9.5h0C3 10.88 4.12 12 5.5 12h13c1.38 0 2.5 1.12 2.5 2.5h0c0 1.38-1.12 2.5-2.5 2.5h-13C4.12 17 3 18.12 3 19.5h0C3 20.88 4.12 22 5.5 22H9"/>
                                    <circle cx="13" cy="22" r="1.75" fill="#F8AD6D" fill-rule="nonzero"/>
                                    <circle cx="11" cy="2" r="1.75" fill="#F8AD6D" fill-rule="nonzero"/>
                                    <path d="M0 0H24V24H0z"/>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
        }
        break;
      }
      case ModuleTypes.AccountHub: {
        switch (moduleIconType) {
          case ModuleIconTypes.Grey_Colour: {
            svgString = `<svg fill="#b6afaf" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" stroke="#b6afaf"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M73.9,27c-3.9,0-7.1,3.2-7.1,7.1c0,0.6,0.1,1.1,0.2,1.6l-6.7,4.4c-2.1-2.4-5.1-4-8.6-4 c-2.6,0-5,0.9-6.9,2.4l-11-7.6c0.7-2.9-0.8-6.1-3.6-7.4c-3.3-1.5-7.2-0.1-8.7,3.1s-0.1,7.2,3.1,8.7c1.7,0.8,3.6,0.8,5.3,0.1 l11.3,7.9c-0.5,1.2-0.8,2.6-0.8,4c0,0.8,0.1,1.5,0.2,2.3l-6.9,2.5c-0.9-1-2.1-1.8-3.6-2.2c-3.9-1.1-8,1.2-9.1,5.2 c-1.1,3.9,1.2,8,5.2,9.1c3.9,1.1,8-1.2,9.1-5.2c0.1-0.4,0.2-0.8,0.2-1.2l7.9-2.8c2.1,2.2,5,3.6,8.2,3.6c0.3,0,0.6,0,0.9,0l1.1,5.6 c-2.1,1.7-3.3,4.4-3,7.2c0.4,4.6,4.5,7.9,9.1,7.5c4.6-0.4,7.9-4.5,7.5-9.1c-0.4-4.1-3.7-7.2-7.7-7.5l-1.2-5.9 c2.8-2,4.7-5.4,4.7-9.1c0-0.6-0.1-1.2-0.1-1.7l7.9-5.2c1,0.5,2.1,0.8,3.2,0.8c3.9,0,7.1-3.2,7.1-7.1C81,30.2,77.8,27,73.9,27z M51.7,54.6c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2C58.9,51.3,55.7,54.6,51.7,54.6z"></path> <circle cx="51.8" cy="47.4" r="3.2"></circle> </g> </g></svg>`;
            break;
          }
          case ModuleIconTypes.Blue_Colour: {
            svgString = `<svg fill="var(--wt-primary)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" stroke="var(--wt-primary)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M73.9,27c-3.9,0-7.1,3.2-7.1,7.1c0,0.6,0.1,1.1,0.2,1.6l-6.7,4.4c-2.1-2.4-5.1-4-8.6-4 c-2.6,0-5,0.9-6.9,2.4l-11-7.6c0.7-2.9-0.8-6.1-3.6-7.4c-3.3-1.5-7.2-0.1-8.7,3.1s-0.1,7.2,3.1,8.7c1.7,0.8,3.6,0.8,5.3,0.1 l11.3,7.9c-0.5,1.2-0.8,2.6-0.8,4c0,0.8,0.1,1.5,0.2,2.3l-6.9,2.5c-0.9-1-2.1-1.8-3.6-2.2c-3.9-1.1-8,1.2-9.1,5.2 c-1.1,3.9,1.2,8,5.2,9.1c3.9,1.1,8-1.2,9.1-5.2c0.1-0.4,0.2-0.8,0.2-1.2l7.9-2.8c2.1,2.2,5,3.6,8.2,3.6c0.3,0,0.6,0,0.9,0l1.1,5.6 c-2.1,1.7-3.3,4.4-3,7.2c0.4,4.6,4.5,7.9,9.1,7.5c4.6-0.4,7.9-4.5,7.5-9.1c-0.4-4.1-3.7-7.2-7.7-7.5l-1.2-5.9 c2.8-2,4.7-5.4,4.7-9.1c0-0.6-0.1-1.2-0.1-1.7l7.9-5.2c1,0.5,2.1,0.8,3.2,0.8c3.9,0,7.1-3.2,7.1-7.1C81,30.2,77.8,27,73.9,27z M51.7,54.6c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2C58.9,51.3,55.7,54.6,51.7,54.6z"></path> <circle cx="51.8" cy="47.4" r="3.2"></circle> </g> </g></svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_1: {
            svgString = `<svg fill="#56C271" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve" stroke="#56C271"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M73.9,27c-3.9,0-7.1,3.2-7.1,7.1c0,0.6,0.1,1.1,0.2,1.6l-6.7,4.4c-2.1-2.4-5.1-4-8.6-4 c-2.6,0-5,0.9-6.9,2.4l-11-7.6c0.7-2.9-0.8-6.1-3.6-7.4c-3.3-1.5-7.2-0.1-8.7,3.1s-0.1,7.2,3.1,8.7c1.7,0.8,3.6,0.8,5.3,0.1 l11.3,7.9c-0.5,1.2-0.8,2.6-0.8,4c0,0.8,0.1,1.5,0.2,2.3l-6.9,2.5c-0.9-1-2.1-1.8-3.6-2.2c-3.9-1.1-8,1.2-9.1,5.2 c-1.1,3.9,1.2,8,5.2,9.1c3.9,1.1,8-1.2,9.1-5.2c0.1-0.4,0.2-0.8,0.2-1.2l7.9-2.8c2.1,2.2,5,3.6,8.2,3.6c0.3,0,0.6,0,0.9,0l1.1,5.6 c-2.1,1.7-3.3,4.4-3,7.2c0.4,4.6,4.5,7.9,9.1,7.5c4.6-0.4,7.9-4.5,7.5-9.1c-0.4-4.1-3.7-7.2-7.7-7.5l-1.2-5.9 c2.8-2,4.7-5.4,4.7-9.1c0-0.6-0.1-1.2-0.1-1.7l7.9-5.2c1,0.5,2.1,0.8,3.2,0.8c3.9,0,7.1-3.2,7.1-7.1C81,30.2,77.8,27,73.9,27z M51.7,54.6c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2C58.9,51.3,55.7,54.6,51.7,54.6z"></path> <circle cx="51.8" cy="47.4" r="3.2"></circle> </g> </g></svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_2: {
            svgString = `<svg fill="#000000" viewBox="0 0 100 100" width="${size}" height="${size}" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m47.44 61.66a1 1 0 0 1 1 .91v14.37a3.06 3.06 0 0 1 -2.87 3h-20.49a3.06 3.06 0 0 1 -3-2.88v-14.38a1 1 0 0 1 .91-1h24.5zm29.51 0a1 1 0 0 1 1 .91v14.37a3.06 3.06 0 0 1 -2.87 3h-20.49a3.06 3.06 0 0 1 -3-2.88v-14.38a1 1 0 0 1 .91-1h24.5zm-37.36 4.23-.09.11-5.82 6.32-2.63-2.55a.77.77 0 0 0 -1-.08l-.09.08-1.09 1a.62.62 0 0 0 -.07.9l.07.08 3.73 3.54a1.56 1.56 0 0 0 1.08.45 1.43 1.43 0 0 0 1.09-.45l3.14-3.32.63-.67 3.14-3.31a.78.78 0 0 0 .06-.9l-.06-.08-1.09-1a.76.76 0 0 0 -1-.12zm29.51 0-.1.11-5.82 6.32-2.64-2.55a.75.75 0 0 0 -1-.08l-.09.08-1.09 1a.62.62 0 0 0 -.07.9l.07.08 3.73 3.54a1.54 1.54 0 0 0 1.08.45 1.43 1.43 0 0 0 1.09-.45l3.14-3.32.63-.67 3.14-3.31a.78.78 0 0 0 .06-.9l-.06-.08-1.07-1.01a.76.76 0 0 0 -1-.11zm-23.43-14.41a3 3 0 0 1 2.85 2.87v3.24a1 1 0 0 1 -.84 1h-26.68a1 1 0 0 1 -.94-.9v-3.16a3 3 0 0 1 2.69-3.05h23zm31.48 0a3 3 0 0 1 2.85 2.87v3.24a1 1 0 0 1 -.84 1h-26.73a1 1 0 0 1 -1-.9v-3.16a3 3 0 0 1 2.68-3.05h23zm-15-21.29a1 1 0 0 1 1 .91v14.37a3.06 3.06 0 0 1 -2.87 3.05h-20.44a3.06 3.06 0 0 1 -3.05-2.87v-14.44a1 1 0 0 1 .9-1h24.51zm-7.85 4.22-.09.08-5.82 6.32-2.59-2.56a.76.76 0 0 0 -1-.07l-.09.07-1.08 1a.61.61 0 0 0 -.07.9l.07.08 3.72 3.53a1.56 1.56 0 0 0 1.09.45 1.43 1.43 0 0 0 1.08-.45l3.14-3.31.64-.67 3.13-3.32a.78.78 0 0 0 .06-.9l-.06-.07-1.08-1a.77.77 0 0 0 -1-.08zm7.9-14.41a3.06 3.06 0 0 1 3 2.88v3.23a1 1 0 0 1 -.91 1h-28.52a1 1 0 0 1 -1-.91v-3.14a3.06 3.06 0 0 1 2.87-3h24.56z"></path></g></svg>`;
            break;
          }
        }

        break;
      }
      case ModuleTypes.Sustainability: {
        switch (moduleIconType) {
          case ModuleIconTypes.White: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <g>
                                    <g>
                                        <g>
                                            <path d="M0 0H24V24H0z" transform="translate(-468 -144) translate(468 144)"/>
                                        </g>
                                        <g transform="translate(-468 -144) translate(468 144) translate(2 2)">
                                            <circle cx="10" cy="10" r="5" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <circle cx="3" cy="17" r="1.75" fill="white" fill-rule="nonzero"/>
                                            <circle cx="10" cy="10" r="1.75" fill="white" fill-rule="nonzero"/>
                                            <circle cx="17" cy="3" r="1.75" fill="white" fill-rule="nonzero"/>
                                            <path stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 0C6.628.002 3.483 1.703 1.637 4.525-.209 7.347-.509 10.909.84 14M10 20c3.372-.002 6.517-1.703 8.363-4.525 1.846-2.822 2.146-6.384.797-9.475"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg> `;
            break;
          }
          case ModuleIconTypes.Yellow: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <g>
                                    <g>
                                        <g>
                                            <path d="M0 0H24V24H0z" transform="translate(-468 -144) translate(468 144)"/>
                                        </g>
                                        <g transform="translate(-468 -144) translate(468 144) translate(2 2)">
                                            <circle cx="10" cy="10" r="5" stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <circle cx="3" cy="17" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                            <circle cx="10" cy="10" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                            <circle cx="17" cy="3" r="1.75" fill="#fff35f" fill-rule="nonzero"/>
                                            <path stroke="#fff35f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 0C6.628.002 3.483 1.703 1.637 4.525-.209 7.347-.509 10.909.84 14M10 20c3.372-.002 6.517-1.703 8.363-4.525 1.846-2.822 2.146-6.384.797-9.475"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg> `;
            break;
          }
          case ModuleIconTypes.Grey_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <g>
                                    <g>
                                        <g>
                                            <path d="M0 0H24V24H0z" transform="translate(-468 -144) translate(468 144)"/>
                                        </g>
                                        <g transform="translate(-468 -144) translate(468 144) translate(2 2)">
                                            <circle cx="10" cy="10" r="5" stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <circle cx="3" cy="17" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                            <circle cx="10" cy="10" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                            <circle cx="17" cy="3" r="1.75" fill="#939699" fill-rule="nonzero"/>
                                            <path stroke="#939699" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 0C6.628.002 3.483 1.703 1.637 4.525-.209 7.347-.509 10.909.84 14M10 20c3.372-.002 6.517-1.703 8.363-4.525 1.846-2.822 2.146-6.384.797-9.475"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg> `;
            break;
          }
          case ModuleIconTypes.Black: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <g>
                                    <g>
                                        <g>
                                            <path d="M0 0H24V24H0z" transform="translate(-468 -144) translate(468 144)"/>
                                        </g>
                                        <g transform="translate(-468 -144) translate(468 144) translate(2 2)">
                                            <circle cx="10" cy="10" r="5" stroke="var(--wt-black)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <circle cx="3" cy="17" r="1.75" fill="var(--wt-black)" fill-rule="nonzero"/>
                                            <circle cx="10" cy="10" r="1.75" fill="var(--wt-black)" fill-rule="nonzero"/>
                                            <circle cx="17" cy="3" r="1.75" fill="var(--wt-black)" fill-rule="nonzero"/>
                                            <path stroke="var(--wt-black)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 0C6.628.002 3.483 1.703 1.637 4.525-.209 7.347-.509 10.909.84 14M10 20c3.372-.002 6.517-1.703 8.363-4.525 1.846-2.822 2.146-6.384.797-9.475"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg> `;
            break;
          }
          case ModuleIconTypes.Blue_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <g>
                                    <g>
                                        <g>
                                            <path fill="" d="M0 0H24V24H0z" transform="translate(-468 -32) translate(468 32)"/>
                                        </g>
                                        <g transform="translate(-468 -32) translate(468 32) translate(2 2)">
                                            <circle cx="10" cy="10" r="5" stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <circle cx="3" cy="17" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                            <circle cx="10" cy="10" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                            <circle cx="17" cy="3" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                            <path stroke="var(--wt-primary)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 0C6.628.002 3.483 1.703 1.637 4.525-.209 7.347-.509 10.909.84 14M10 20c3.372-.002 6.517-1.703 8.363-4.525 1.846-2.822 2.146-6.384.797-9.475"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_1: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <g>
                                    <g>
                                        <g>
                                            <path d="M0 0H24V24H0z" transform="translate(-468 -32) translate(468 32)"/>
                                        </g>
                                        <g transform="translate(-468 -32) translate(468 32) translate(2 2)">
                                            <circle cx="10" cy="10" r="5" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <circle cx="3" cy="17" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                            <circle cx="10" cy="10" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                            <circle cx="17" cy="3" r="1.75" fill="var(--wt-primary)" fill-rule="nonzero"/>
                                            <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 0C6.628.002 3.483 1.703 1.637 4.525-.209 7.347-.509 10.909.84 14M10 20c3.372-.002 6.517-1.703 8.363-4.525 1.846-2.822 2.146-6.384.797-9.475"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
          case ModuleIconTypes.Colourway_2: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <g>
                                    <g>
                                        <g>
                                            <path d="M0 0H24V24H0z" transform="translate(-468 -88) translate(468 88)"/>
                                        </g>
                                        <g transform="translate(-468 -88) translate(468 88) translate(2 2)">
                                            <circle cx="10" cy="10" r="5" stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                            <circle cx="3" cy="17" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                            <circle cx="10" cy="10" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                            <circle cx="17" cy="3" r="1.75" fill="#56C271" fill-rule="nonzero"/>
                                            <path stroke="#003057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 0C6.628.002 3.483 1.703 1.637 4.525-.209 7.347-.509 10.909.84 14M10 20c3.372-.002 6.517-1.703 8.363-4.525 1.846-2.822 2.146-6.384.797-9.475"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>`;
            break;
          }
        }
        break;
      }
      case ModuleTypes.Risk: {
        switch (moduleIconType) {
          case ModuleIconTypes.White: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 21 18">
            <path d="M2.05134 14.3627L8.99162 2.56425C9.66825 1.41397 11.3317 1.41397 12.0084 2.56425L18.9487 14.3627C19.6349 15.5293 18.7937 17 17.4403 17H3.55973C2.20625 17 1.3651 15.5293 2.05134 14.3627Z"
              stroke="#FFFFFF" stroke-width="1.67" fill="transparent"/>
            <path d="M11.1667 11H9.5V6H11.1667V11Z" fill="#FFFFFF"/>
            <path d="M11.1667 14.6667H9.5V13H11.1667V14.6667Z" fill="#FFFFFF"/>
            </svg>`
            break;
          }
          case ModuleIconTypes.Grey_Colour: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 21 18">
            <path d="M2.05134 14.3627L8.99162 2.56425C9.66825 1.41397 11.3317 1.41397 12.0084 2.56425L18.9487 14.3627C19.6349 15.5293 18.7937 17 17.4403 17H3.55973C2.20625 17 1.3651 15.5293 2.05134 14.3627Z"
              stroke="#939699" stroke-width="1.67" fill="transparent"/>
            <path d="M11.1667 11H9.5V6H11.1667V11Z" fill="#939699"/>
            <path d="M11.1667 14.6667H9.5V13H11.1667V14.6667Z" fill="#939699"/>
            </svg>`
            break;
          }
          case ModuleIconTypes.Colourway_2: {
            svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 21 18">
            <path d="M2.05134 14.3627L8.99162 2.56425C9.66825 1.41397 11.3317 1.41397 12.0084 2.56425L18.9487 14.3627C19.6349 15.5293 18.7937 17 17.4403 17H3.55973C2.20625 17 1.3651 15.5293 2.05134 14.3627Z"
              stroke="#003057" stroke-width="1.67" fill="transparent"/>
            <path d="M11.1667 11H9.5V6H11.1667V11Z" fill="#56C271"/>
            <path d="M11.1667 14.6667H9.5V13H11.1667V14.6667Z" fill="#56C271"/>
            </svg>`
            break;
          }
        }
        break;
      }
    }

    return svgString;
  }

  public static buildMarkerSVG(markerType: MarkerType, rgbColor: string): string {
    const markerShape = `
      <path id="marker" fill="${rgbColor}"
        d="M 32 0 C 20.039062 0 10.3125 9.730469 10.3125 21.6875 C 10.3125 30.445312 22.679688 51.335938 30.308594 63.082031 C 31.105469 64.304688 32.894531 64.304688 33.691406 63.082031 C 41.320312 51.335938 53.6875 30.445312 53.6875 21.6875 C 53.6875 9.730469 43.960938 0 32 0 Z M 32 0 "
      />`;

    let svgShape =
      `<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
        <defs>
          ${markerShape}
            <clipPath id="clip">
            <use xlink:href="#marker"/>
          </clipPath>
        </defs>
        <g>
          <use xlink:href="#marker" stroke="white" stroke-width="4" clip-path="url(#clip)"/>
          @innerShape
        </g>
      </svg>`;

    switch (markerType) {
      case MarkerType.INCIDENT: {
        svgShape = svgShape.replace('@innerShape', '');
        break;
      }
      case MarkerType.LOG: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<circle cx="50%" cy="50%" r="8" fill="rgb(255,255,255)" transform="translate(0 -7)" />`
        );
        break;
      }
      case MarkerType.JOB: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<circle cx="50%" cy="50%" r="8" fill="rgb(255,255,255)" transform="translate(0 -7)" />`
        );
        break;
      }
      case MarkerType.PROJECT: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<path
            fill="rgb(255,255,255)"
            fill-rule="nonzero"
            d="M34.667 14.667H24L21.333 12h-8c-1.472 0-2.666 1.194-2.666 2.667v18.666c0 1.473 1.194 2.667 2.666 2.667h21.334c1.472 0 2.666-1.194 2.666-2.667v-16c0-1.472-1.194-2.666-2.666-2.666zm0 18.666H13.333v-16h21.334v16z"
            transform="translate(8 0)"
          />`
        );
        break;
      }
      case MarkerType.TASK_GROUP: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<g transform="scale(0.7 0.7)">
            <svg x="22" y="5">
                <path fill="rgb(255,255,255)" fill-rule="nonzero" d="M20.94 34L14 27 16.8 24.18 20.94 28.34 31.2 18 34 20.82z" />
                <path fill="rgb(255,255,255)" fill-rule="nonzero" d="M40 10H24l-4-4H8c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 28H8V14h32v24z" />
            </svg>
          </g>`
        );
        break;
      }
      case MarkerType.TASK: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<path fill="rgb(255,255,255)" fill-rule="nonzero" d="M7.947 16L1 9 3.8 6.187 7.947 10.347 18.2 0 21 2.813zM.333 20H21.665999999999997V22.667H.333z" transform="translate(21 12)" />`
        );
        break;
      }
      case MarkerType.SUB_TASK: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<path fill="rgb(255,255,255)" fill-rule="nonzero" d="M6.507 14L.852 8.296 3.133 5.999 6.507 9.388 14.867.963 17.148 3.261zM6.507 27.037L.852 21.333 3.133 19.036 6.507 22.425 14.867 14 17.148 16.298z" transform="translate(23 10)" />`
        );
        break;
      }
      case MarkerType.RISK: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<svg x="8">
            <path font-weight="bold" fill="rgb(255,255,255)" fill-rule="nonzero" d="M16 2.33c.656.016 1.246.404 1.52 1l11.86 21.8c.41.712.41 1.588 0 2.3-.295.545-.86.888-1.48.9H4.14c-.62-.012-1.185-.355-1.48-.9-.41-.712-.41-1.588 0-2.3L14.48 3.31c.274-.596.864-.984 1.52-1m0-1c-1.018.012-1.944.59-2.4 1.5L1.74 24.65c-1.12 2.06.22 4.68 2.4 4.68h23.72c2.18 0 3.52-2.62 2.4-4.68L18.4 2.83c-.456-.91-1.382-1.488-2.4-1.5v-.02z" transform="translate(8 6)"/>
            <path fill="rgb(255,255,255)" fill-rule="nonzero" d="M17.33 21.33L14.67 21.33 14.67 24 17.33 24 17.33 21.33zM17.33 10.67L14.67 10.67 14.67 18.67 17.33 18.67z" transform="translate(8 6)"/>
          </svg>`
        );
        break;
      }
      case MarkerType.ISSUE: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<svg x="8">
            <path fill="rgb(255,255,255)" fill-rule="nonzero" d="M16 2.33c.534.005 1.057.157 1.51.44L26.87 8c.913.571 1.465 1.574 1.46 2.65v10.68c.022 1.079-.52 2.09-1.43 2.67l-9.42 5.29c-.916.578-2.084.578-3 0L5.13 24c-.913-.571-1.465-1.574-1.46-2.65V10.67C3.648 9.591 4.19 8.58 5.1 8l9.42-5.29c.45-.258.961-.389 1.48-.38m0-1c-.705.002-1.396.196-2 .56L4.61 7.16c-1.212.754-1.947 2.082-1.94 3.51v10.66c-.007 1.428.728 2.756 1.94 3.51L14 30.11c1.229.747 2.771.747 4 0l9.39-5.27c1.212-.754 1.947-2.082 1.94-3.51V10.67c.007-1.428-.728-2.756-1.94-3.51L18 1.89c-.604-.364-1.295-.558-2-.56z" transform="translate(8 6)"/>
            <path fill="rgb(255,255,255)" fill-rule="nonzero" d="M17.33 20L14.67 20 14.67 22.67 17.33 22.67zM17.33 9.33L14.67 9.33 14.67 17.33 17.33 17.33 17.33 9.33z" transform="translate(8 6)"/>
          </svg>`
        );
        break;
      }
      case MarkerType.OPPORTUNITY: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<svg x="8">
            <path fill="rgb(255,255,255)" fill-rule="nonzero" d="M16 2.33c.706 0 1.382.28 1.88.78l11 11c1.038 1.039 1.038 2.721 0 3.76l-11 11c-1.039 1.038-2.721 1.038-3.76 0l-11-11c-1.038-1.039-1.038-2.721 0-3.76l11-11c.498-.5 1.174-.78 1.88-.78m0-1c-.971-.001-1.903.384-2.59 1.07l-11 11c-1.421 1.434-1.421 3.746 0 5.18l11 11c1.432 1.426 3.748 1.426 5.18 0l11-11c1.421-1.434 1.421-3.746 0-5.18l-11-11c-.687-.686-1.619-1.071-2.59-1.07z" transform="translate(8 6)"/>
            <path fill="rgb(255,255,255)" fill-rule="nonzero" d="M6.96 23.15L15.45 14.67 12 14.67 12 12 17.33 12 20 12 20 14.67 20 20 17.33 20 17.33 16.55 8.85 25.04" transform="translate(8 6)"/>
          </svg>`
        );
        break;
      }
      case MarkerType.EVALUATION: {
        svgShape = svgShape.replace(
          '@innerShape',
          `<g transform="scale(1 1)">
            <svg x="8" y="0">
              <path fill="rgb(255,255,255)" fill-rule="nonzero" d="M20.94 34L14 27 16.8 24.18 20.94 28.34 31.2 18 34 20.82z" />
            </svg>
          </g>`
        );
        break;
      }
      case MarkerType.RUNSHEET_ITEM:
        svgShape = `<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                            <g>
                                <svg x="8">
                                    <path fill="${rgbColor}" d="M 32 0 C 20.039062 0 10.3125 9.730469 10.3125 21.6875 C 10.3125 30.445312 22.679688 51.335938 30.308594 63.082031 C 31.105469 64.304688 32.894531 64.304688 33.691406 63.082031 C 41.320312 51.335938 53.6875 30.445312 53.6875 21.6875 C 53.6875 9.730469 43.960938 0 32 0 Z M 32 0 "/>
                                    <circle fill="rgb(255,255,255)" fill-rule="nonzero" cx="32" cy="24" r="10"/>
                                </svg>
                            </g>
                            </svg>`;
        break;
    }

    return svgShape;
  }
}
