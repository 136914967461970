import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddUpdateModal } from 'src/app/modules/shared/components/modals/add-update/add-update-modal.component';
import { UpdateTypes } from 'src/app/modules/shared/enums/updateTypes';
import { PopupEventsEmitter } from 'src/app/modules/shared/events/popup.events';
import { Employee } from 'src/app/modules/shared/models/employee';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { BsModalConfig } from '../../../../../shared/config/modal.config';
import { FilterTypes } from '../../../../../shared/enums/filterTypes';
import { ObjectTypes } from '../../../../../shared/enums/objectTypes';
import { IncidentItemTypes } from '../../../../enums/incidentItemTypes';
import { ValidationUtilities } from 'src/app/modules/shared/utilities/validation.utilities';
import { DocumentService } from 'src/app/modules/shared/services/document.service';
import { ObjectEventEmitters } from 'src/app/modules/shared/events/object.events';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { IncidentListItemViewModel } from 'src/app/modules/incidents/viewModels/incidentListItemViewModel';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { IncidentDetailsItemViewModel } from 'src/app/modules/incidents/viewModels/incidentDetailsItemViewModel';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TabsetComponent } from 'src/app/modules/shared/components/common/tabset/tabset.component';
import { IncidentsManager } from 'src/app/modules/shared/managers/incidents.manager';
import { FormViewMode } from 'src/app/modules/shared/enums/formViewMode';
import { LocationDetailsEditModalComponent } from 'src/app/modules/planning/components/modals/location-details-edit-modal/location-details-edit-modal.component';
import { MarkerType } from 'src/app/modules/shared/enums/maps/marketType';
import { HasWritePermissionPipe } from 'src/app/modules/shared/pipes/has-write-permission.pipe';
import { DetailsViewBaseClass } from 'src/app/modules/shared/interfaces/detailsViewBase';
import { ActivatedRoute, Router } from '@angular/router';
import { PinningService } from 'src/app/modules/shared/services/pinning.service';
import { SubscriptionService } from 'src/app/modules/shared/services/subscription.service';
import { ModalUtilityService } from 'src/app/modules/shared/services/utilities/modals-utilities.service';
import { IncidentsUtilities } from 'src/app/modules/incidents/utilities/incidents-utilities.util';
import { IncidentLogsService } from 'src/app/modules/shared/services/incident-logs.service';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterActionTypes } from 'src/app/modules/shared/enums/filter/filterActionTypes.enum';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { LightFilterUpdateModel } from 'src/app/modules/shared/models/filter/LightFilterUpdateModel';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { Colors } from 'src/app/modules/shared/enums/colors';

@Component({
  selector: 'app-incidents-view-log',
  templateUrl: './view-log.component.html',
  styleUrls: ['./view-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentsViewLogModalComponent extends DetailsViewBaseClass implements OnInit, OnDestroy {
  private defaultDate = new Date('0001-01-01T00:00:00Z');

  public showTimepickers = false;
  public currentDetailsItem: IncidentListItemViewModel = new IncidentListItemViewModel();
  public originalCurrentDetailsItem: IncidentListItemViewModel;
  public readonly objectTypes = ObjectTypes;
  public objectType = ObjectTypes.IncidentItem;
  public activityItemsTypes: IncidentItemTypes[] = [IncidentItemTypes.Log];
  public editMode: boolean = false;
  public filterTypes = FilterTypes;
  public startDate: string;
  public endDate: string;
  public startDateDateHours: string;
  public endDateDateHours: string;
  public diffInHours: number = 0;
  public employee: Employee;
  public minDate: Date;
  public showDateError: boolean = false;
  public commentType = UpdateTypes.Incident_Comment_Added;
  public logHasSetTime = false;
  public userHasWriteAccess: boolean = false;
  public editableFieldTypes = EditableFieldTypes;
  public logId: number;
  public logModalTabIds = ['details', 'location', 'history', 'attachments'];
  public selectedFooterTabId = 'details';
  public isMobile: boolean = false;
  public mobileWidth = Constants.xs;
  public saveButtonText: string = this.translateService.instant(T.common.save);
  public localisedEvent: string = 'Event';
  public formViewMode: FormViewMode = FormViewMode.ReadOnly;
  public isExpanded: boolean = false;
  public historyExpanded = false;
  public locationContainerDirty = false;
  public markerType = MarkerType.LOG;

  onUpdatedItem = new EventEmitter<IncidentListItemViewModel>();

  constructor(
    public bsModalRef: BsModalRef,
    private readonly popupEventsEmitter: PopupEventsEmitter,
    private readonly incidentLogsService: IncidentLogsService,
    private readonly modalConfig: BsModalConfig,
    private readonly documentService: DocumentService,
    private readonly elemRef: ElementRef,
    public readonly incidentsManager: IncidentsManager,
    protected readonly route: ActivatedRoute,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly router: Router,
    protected readonly confirmationService: ConfirmationService,
    protected readonly alertService: AlertService,
    protected readonly authenticationService: AuthenticationService,
    protected readonly localisationService: LocalisationService,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly hasWritePermissionPipe: HasWritePermissionPipe,
    protected readonly subscriptionService: SubscriptionService,
    protected readonly bsModalService: BsModalService,
    protected readonly pinningService: PinningService,
    protected readonly utilityModalService: ModalUtilityService,
    protected readonly translateService: TranslateService,
    private readonly allowedFiltersService: AllowedFiltersService
  ) {
    super(
      ObjectTypes.IncidentItem,
      localisationService,
      changeDetectorRef,
      bsModalService,
      utilityModalService,
      authenticationService,
      subscriptionService,
      objectEventEmitters,
      alertService,
      confirmationService,
      router,
      route,
      hasWritePermissionPipe,
      pinningService,
      translateService
    );
    this.activeLightUpdate = true;
  }

  @HostListener('window:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'escape') {
      event.stopImmediatePropagation();
    }
  }

  ngOnInit() {
    this.activeLightUpdate = true;
    this.localisedEvent = this.localisationService.localiseObjectType(ObjectTypes.Event);
    this.isMobile = window.innerWidth <= this.mobileWidth;
    this.employee = this.authenticationService.getCurrentEmployee();
    if (!this.userHasWriteAccess) {
      this.saveButtonText = 'Ok';
    }

    if (!this.currentDetailsItem.archived) {
      this.formViewMode = FormViewMode.FullAccsess;
    }

    if (this.isMobile) {
      this.isExpanded = true;
    }
    this.localisedItem = this.translateService.instant(T.defaultLocalizations.log.one);

    if (this.logId) {
      this.loading = true;
      this.incidentLogsService.details(this.logId).subscribe((log) => {
        this.currentDetailsItem = log;
        this.originalCurrentDetailsItem = JSON.parse(JSON.stringify(this.currentDetailsItem)) as IncidentListItemViewModel;
        this.initTime();
        this.initSubscriptions();
        this.loading = false;
        this.userHasWriteAccess = this.hasWritePermissionPipe.transform(
          this.originalCurrentDetailsItem,
          ModuleTypes.Incidents,
          FilterTypes.Incident
        );
        this.changeDetectorRef.detectChanges();
      });
    } else {
      this.originalCurrentDetailsItem = JSON.parse(JSON.stringify(this.currentDetailsItem)) as IncidentListItemViewModel;
      this.initTime();
      this.initSubscriptions();
      this.userHasWriteAccess = this.hasWritePermissionPipe.transform(
        this.originalCurrentDetailsItem,
        ModuleTypes.Incidents,
        FilterTypes.Incident
      );
      this.loading = false;
    }

    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initTime() {
    if (
      this.currentDetailsItem.startTime &&
      this.currentDetailsItem.endTime &&
      !this.currentDetailsItem.startTime.startsWith('0001') &&
      !this.currentDetailsItem.startTime.startsWith('0001')
    ) {
      this.logHasSetTime = true;
      this.showTimepickers = true;
    }
  }

  initSubscriptions() {
    this.subscriptions.add(
      this.objectEventEmitters.objectArchived$.subscribe((res) => {
        if (res.globalObjectType === ObjectTypes.IncidentItem && res.globalObjectId === this.currentDetailsItem.id) {
          const model = res.model as IncidentDetailsItemViewModel;
          if (model.archived != this.currentDetailsItem.archived) {
            this.currentDetailsItem.archived = model.archived;
            this.changeDetectorRef.detectChanges();
          }
        }
      })
    );

    this.subscriptions.add(
      this.documentService.getByObjectId(this.currentDetailsItem.id, this.objectType).subscribe((docs) => {
        this.attachments = docs;
      })
    );
  }

  get isValid() {
    return ValidationUtilities.validateAgainstMinLength(this.currentDetailsItem.title, 3);
  }

  /**
   * Open 'AddUpdateModal'
   */
  public onAddUpdate(): void {
    this.popupEventsEmitter.broadcastPopupCancelClicked();
    const initialState = {
      objectId: this.currentDetailsItem.id,
      objectType: ObjectTypes.IncidentItem,
      objectSubType: IncidentItemTypes.Log,
      commentType: UpdateTypes.Incident_Comment_Added,
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.bsModalService.show(AddUpdateModal, modalParams);
  }

  /**
   * Change the log to be incident
   */
  public onMakeIncident(): void {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.control.confirm_conver_log_to_incident),
      () => {
        IncidentsUtilities.updateLogItemPropertiesToEscalateToIncident(this.currentDetailsItem);

        this.incidentLogsService.update(this.currentDetailsItem).subscribe((a) => {
          void this.alertService.success(this.translateService.instant(T.control.log_was_changed_to_incident));
          this.bsModalService.hide();
        });
      },
      () => {
        //handle cancel
      }
    );
  }

  /**
   * Archive log
   * User is prompt with confirmation modal
   */
  onArchiveIncident(archiveIt: boolean) {
    this.confirmationService.confirmThis(
      this.confirmArchiveText,
      () => {
        this.incidentLogsService.archive(this.currentDetailsItem.id, archiveIt).subscribe((a) => {
          if (archiveIt) {
            void this.alertService.success(this.itemArchivedText);
          } else {
            void this.alertService.success(this.itemUnarchivedText);
          }

          this.currentDetailsItem.archived = archiveIt;
          this.changeDetectorRef.markForCheck();
          this.onClose();
        });
      },
      () => {
        //handle cancel
      }
    );
  }

  getBoundingRect(element: HTMLElement): ClientRect {
    return element.getBoundingClientRect();
  }

  /**
   * Notify the parrent for log changes and send BE update request
   */
  public onStartDateChanged(dateAsIsoString: string): void {
    this.currentDetailsItem.startTime = dateAsIsoString;
    const startDateFilter = this.currentDetailsItem.filters.find(
      (f) => f.filterType == FilterTypes.Date && f.dateProperty == FilterDateOptions.Start_Time
    );
    startDateFilter.filterValue = this.currentDetailsItem.startTime;
    startDateFilter.filterAction = FilterActionTypes.Update;
    this.onFiltersUpdated([startDateFilter]);
  }

  /**
   * Notify the parrent for log changes and send BE update request
   */
  public onEndDateChanged(dateAsIsoString: string): void {
    this.currentDetailsItem.endTime = dateAsIsoString;
    const dueDateFilter = this.currentDetailsItem.filters.find(
      (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Time
    );
    dueDateFilter.filterValue = this.currentDetailsItem.endTime;
    dueDateFilter.filterAction = FilterActionTypes.Update;
    this.onFiltersUpdated([dueDateFilter]);
  }

  public onDatesChanged(date): void {
    const updatedFilters: FilterViewModel[] = [];
    if (date.startDate) {
      this.currentDetailsItem.startTime = date.startDate;
      const startDateFilter = this.currentDetailsItem.filters.find(
        (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Time
      );
      startDateFilter.filterValue = this.currentDetailsItem.startTime;
      startDateFilter.filterAction = FilterActionTypes.Update;
      updatedFilters.push(startDateFilter);
    }

    if (date.endDate) {
      this.currentDetailsItem.endTime = date.endDate;
      const dueDateFilter = this.currentDetailsItem.filters.find(
        (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Time
      );
      dueDateFilter.filterValue = this.currentDetailsItem.endTime;
      dueDateFilter.filterAction = FilterActionTypes.Update;
      updatedFilters.push(dueDateFilter);
      if (!this.currentDetailsItem.startTime) {
        this.currentDetailsItem.startTime = date.endDate;
        const startDateFilter = this.currentDetailsItem.filters.find(
          (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Time
        )
        startDateFilter.filterValue = this.currentDetailsItem.startTime;
        startDateFilter.filterAction = FilterActionTypes.Update;
        updatedFilters.push(startDateFilter);
      }
    }

    this.onFiltersUpdated(updatedFilters);
  }


  /**
   * Update the log filters filter and notify the parrent to save the changes
   */
  onFiltersUpdated(filters: FilterViewModel[]) {
    if (this.isValid && this.userHasWriteAccess) {

      const onlyFiltersWithActions = filters.filter((a) => a.filterAction !== FilterActionTypes.None);
      const requestModel: LightFilterUpdateModel = {
        globalObjectId: this.currentDetailsItem.id,
        objectType: this.objectType,
        accountId: this.currentDetailsItem.accountId,
        changes: onlyFiltersWithActions,
        updated: ''
      };

      this.incidentLogsService.updateLight(requestModel).subscribe((res) => {
        this.currentDetailsItem.filters = FilterUtilities.ApplyFilterActions(this.currentDetailsItem.filters, onlyFiltersWithActions);
        void this.alertService.success(this.translateService.instant(T.common.changes_were_saved_successfully));
        this.originalCurrentDetailsItem = JSON.parse(JSON.stringify(this.currentDetailsItem)) as IncidentListItemViewModel;
        this.currentDetailsItem = Object.assign({},this.currentDetailsItem);
        this.changeDetectorRef.detectChanges();
        this.onUpdatedItem.emit(this.currentDetailsItem);
        });
    }
  }

  get event(): string {
    const matchingFilter = this.currentDetailsItem.filters.find((f) => f.filterType === FilterTypes.Event);
    if (matchingFilter) {
      return matchingFilter.filterText;
    }
    return '';
  }

  get channel(): string {
    const matchingFilter = this.currentDetailsItem.filters.find((f) => f.filterType === FilterTypes.Incident_Channel);
    if (matchingFilter) {
      return matchingFilter.filterText;
    }
    return '';
  }

  /**
   * Update the log title, title filter and notify the parrent to save the changes
   */
  public onTitleUpdate(title: string): void {
    this.currentDetailsItem.title = title;
    const titleFilter = this.currentDetailsItem.filters.find((f) => f.filterType === FilterTypes.Title);
    if (titleFilter && titleFilter.filterValue !== this.currentDetailsItem.title) {
      titleFilter.filterValue = this.currentDetailsItem.title;
      titleFilter.filterAction = FilterActionTypes.Update;
    }

    this.onFiltersUpdated([titleFilter]);
  }

  /**
   * Show or hide the timepicker
   */
  public toggleTimepickers(): void {
    this.showTimepickers = !this.showTimepickers;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Update the log description, description filter and notify the parrent to save the changes
   */
  public updateDescription(description: string): void {
    this.currentDetailsItem.description = description;
    const descriptionFilter = this.currentDetailsItem.filters.find((f) => f.filterType === FilterTypes.Description);

    if (descriptionFilter) {
      descriptionFilter.filterValue = description;
      descriptionFilter.filterAction = FilterActionTypes.Update;
      this.onFiltersUpdated([descriptionFilter]);
    }
  }

  get markerColor(): string {
    return Colors.Blue;
  }

  locationMapView(tabset: TabsetComponent): boolean {
    return tabset.activeTab.id === 'location';
  }

  isReadonlyProperty(): boolean {
    return this.formViewMode == FormViewMode.ReadOnly;
  }

  /**
   * Open the LocationDetailsEditModalComponent
   */
  openLocationEditModal() {
    const modalConfig: ModalOptions<LocationDetailsEditModalComponent> = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      animated: true,
      initialState: {
        filters: this.currentDetailsItem.filters,
        locationDetails: this.currentDetailsItem.locationDetails,
        latitude: this.currentDetailsItem.latitude,
        longitude: this.currentDetailsItem.longitude,
        markerColor: this.markerColor,
        markerType: MarkerType.LOG,
      },
    };

    const modalRef = this.bsModalService.show(LocationDetailsEditModalComponent, modalConfig);
    this.subscriptions.add(modalRef.content.onSave.subscribe((res) => {
      if (res) {
        this.currentDetailsItem.latitude = res.latitude;
        this.currentDetailsItem.longitude = res.longitude;
        this.currentDetailsItem.locationDetails = res.locationDetails;
        this.changeDetectorRef.markForCheck();

        const itemZone = this.setFilterActions(this.currentDetailsItem.filters, res.filters, FilterTypes.Zone);
        const itemArea = this.setFilterActions(this.currentDetailsItem.filters, res.filters, FilterTypes.Area);
        res.filters = res.filters.filter((a: FilterViewModel) => a.filterType !== FilterTypes.Zone && a.filterType !== FilterTypes.Area);
        res.filters.push(...itemZone, ...itemArea);

        this.setMatchingFilterValue(res.longitude, FilterTypes.locationLongitude);
        this.setMatchingFilterValue(res.latitude, FilterTypes.locationLatitude);
        this.setMatchingFilterValue(res.locationDetails, FilterTypes.locationDetails);

        this.onFiltersUpdated(res.filters);
        //this.onUpdateItem.emit(this.currentDetailsItem);
      }
    }));
  }

  private setMatchingFilterValue(value: unknown, filterType: FilterTypes) {
    let matchingFilter = this.currentDetailsItem.filters.find((f) => f.filterType === filterType);

    if (matchingFilter) {
      matchingFilter.filterValue = value;
      matchingFilter.filterAction = FilterActionTypes.Update;
    } else {
      matchingFilter = new FilterViewModel();
      matchingFilter.filterType = filterType;
      matchingFilter.filterValue = value;
      matchingFilter.filterAction = FilterActionTypes.Update;
      this.currentDetailsItem.filters.push(matchingFilter);
    }
  }

  setFilterActions(currentFilters: FilterViewModel[], newFilters: FilterViewModel[], filterType: FilterTypes) {
    const currentFiltersByType = currentFilters.filter((a) => a.filterType === filterType);
    const newFiltersByType = newFilters.filter((a) => a.filterType === filterType);

    const removedFilters = currentFiltersByType.filter((a) => !newFiltersByType.find((b) => b.id == a.id));
    removedFilters.forEach((a) => (a.filterAction = FilterActionTypes.Remove));

    const addedFilters = newFiltersByType.filter((a) => !currentFiltersByType.find((b) => b.id == a.id));
    addedFilters.forEach((a) => (a.filterAction = FilterActionTypes.Add));

    return [...removedFilters, ...addedFilters];
  }

  public isOnHistoryTab(): boolean {
    return this.selectedFooterTabId === 'history';
  }

  /**
   * Close the log view modal
   */
  onClose() {
    this.bsModalService.hide();
  }

  /**
   * Return current risk zones as a comma separated string
   */
  get logZones(): string {
    const currentZones = this.currentDetailsItem.filters
      .filter((f) => f.filterType === FilterTypes.Zone)
      .map((f) => f.filterValue);
    return this.allowedFiltersService
      .getCachedAllowedFiltersByType(FilterTypes.Zone)
      .filter((f) => currentZones.includes(f.filterValue))
      .map((f) => f.filterText)
      .join(', ');
  }

  /**
   * Return current risk areas  as a comma separated string
   */
  get logAreas(): string {
    const currentZones = this.currentDetailsItem.filters
      .filter((f) => f.filterType === FilterTypes.Area)
      .map((f) => f.filterValue);
    return this.allowedFiltersService
      .getCachedAllowedFiltersByType(FilterTypes.Area)
      .filter((f) => currentZones.includes(f.filterValue))
      .map((f) => f.filterText)
      .join(', ');
  }

  public onItemIndexClicked(index: number) {
   if (index === 1 && !this.locationContainerDirty) {
    this.locationContainerDirty = true;
   }

    if(index === 2) {
      this.historyExpanded = !this.historyExpanded;
    }
  }

  public get createdByUserName(): string {
    return this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Created_By).find((f) => f.filterValue === this.currentDetailsItem.createdById).filterText;
  }
}
