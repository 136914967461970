<div *ngIf="loading">
  <app-loading>
    <p>{{ T.common.loading | translate }}...</p>
  </app-loading>
</div>

<app-modal *ngIf="!loading">
  <app-modal-header
    [iconType]="modalHeaderIconType.Rich"
    [richIconType]="iconType"
    [title]="headerText"
    (closed)="close()"
  >
  </app-modal-header>
  <app-modal-body>
    <div class="body-wrapper">
      <div class="data-row task-title-wrapper">
        <span class="label">{{ titleLabel }}:</span>
        <app-simple-editable-field
          [input]="title"
          [canEdit]="canEditTask"
          [maxLength]="250"
          [required]="true"
          [fontSize]="14"
          (onUpdate)="onTitleChanged($event)"
        ></app-simple-editable-field>

        <app-headline-status
          class="mb-2"
          [useSmallContainer]="true"
          [object]="task"
          (onChanged)="onHeadlineStatusUpdated($event)"
        ></app-headline-status>
      </div>

      <div class="data-row horizontal">
        <app-rag-dropdown
          [ragDropdownType]="'Icon'"
          [showInfo]="true"
          [showEditDropdownIcon]="true"
          [infoLabel]="T.common.rag_status.one | translate"
          [iconType]="ragIconType"
          [disabled]="!canEditTask"
          [appliedFilters]="task.filters"
          (onFilterUpdated)="updateRagFilter($event)"
        >
        </app-rag-dropdown>

        <div class="data-row" style="justify-content: center">
          <app-details-dropdown
            [canEdit]="canEditTask"
            [label]="T.defaultLocalizations.milestone_type.one | translate"
            [filterType]="filterTypes.Milestone_Type"
            [appliedFilters]="task.filters"
            (onFiltersUpdated)="onMilestoneFilterUpdate($event)"
          ></app-details-dropdown>
        </div>
      </div>

      <div class="data-row date-slider">
        <app-date-slider
          [canEdit]="canEditTask"
          [isCompleted]="isCompleted"
          [baselineStart]="baselineStart"
          [baselineEnd]="baselineEnd"
          [actualStart]="taskStart"
          [actualEnd]="taskEnd"
          [showTime]="false"
          [onDateSelectCallbackFn]="onDatesSelectMilestoneCallback"
          [baselineInfo]="T.common.task_date_slider_baselineInfo | translate: { localisedTask: getLocalizedTask() }"
          (datesChanged)="onDatesChange($event)"
        >
        </app-date-slider>
      </div>

      <div class="data-row default">
        <app-details-lozenge
          [canEdit]="canEditTask"
          [filters]="task.filters"
          [filterType]="filterTypes.Department"
          (onFiltersUpdated)="onFilterUpdated($event, filterTypes.Department)"
        >
        </app-details-lozenge>
      </div>
      <div class="data-row default">
        <app-details-lozenge
          [canEdit]="canEditTask"
          [filters]="task.filters"
          [filterType]="filterTypes.Owner"
          (onFiltersUpdated)="onFilterUpdated($event, filterTypes.Owner)"
        >
        </app-details-lozenge>
      </div>
      <div class="data-row default">
        <app-details-lozenge [canEdit]="false" [filters]="task.filters" [filterType]="filterTypes.Project">
        </app-details-lozenge>
      </div>
      <div class="data-row default">
        <app-details-lozenge [canEdit]="false" [filters]="task.filters" [filterType]="filterTypes.Task_Group">
        </app-details-lozenge>
      </div>
      <div class="data-row default">
        <app-details-lozenge [canEdit]="false" [filters]="task.filters" [filterType]="filterTypes.Event">
        </app-details-lozenge>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <div class="footer-wrapper">
      <app-button-rectangle
        [text]="T.common.open_details_page | translate"
        [size]="'medium'"
        [buttonTheme]="'primary'"
        (clicked)="navigateToDetaislPage()"
      ></app-button-rectangle>
    </div>
  </app-modal-footer>
</app-modal>
