import { Injectable } from '@angular/core';
import { CachingService } from './caching.service';
import { Account } from '../models/account';
import { Employee } from '../models/employee';
import { EmployeeRoleTypes } from '../enums/employees/EmployeeRoleTypes';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { environment } from 'src/environments/environment';
declare let pendo: any;

@Injectable({
  providedIn: 'root'
})
export class PendoService {
  employee: Employee
  account: Account;
  constructor(private cachingService: CachingService) {}

  init() {
    this.account = this.cachingService.GetAccountInfo();
    this.employee = this.cachingService.GetEmployee(this.account.id);
    const clusterName = this.getClusterName();

    pendo.initialize({
      visitor: {
        id: `${clusterName}-${this.employee.id}`,
        Email: this.employee.username,
        FirstName: this.employee.firstName,
        LastName: this.employee.surname,
        Roles: this.employee.employeeRole.map(item => EmployeeRoleTypes[item.roleId]).join(","),
        ModuleAccess: this.employee.permissions.map(item => ModuleTypes[item.module]).join(","),
        language: "en-US"
      },
      account: {
        id: `${clusterName}-${this.account.id}`,
        name: this.account.title
      }
    });
  }
  private getClusterName(): string {
    let currentEnvironment = environment?.CLUSTER_NAME;
    return currentEnvironment;
  }
}
