import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ObjectTypes } from '../../../enums/objectTypes';
import { Employee } from '../../../models/employee';
import { AuthenticationService } from '../../../services/authentication.service';
import { NotificationItemViewModel, NotificationsService } from '../../../services/notifications.service';
import { convertToRelativeTimeObject } from '../../../utilities/date.utilities';
import { NotificationTypes } from '../../../enums/notificationTypes';
import { Router } from '@angular/router';
import { UtilitiesService } from '../../../services/utilities.service';
import { Constants } from 'src/app/modules/shared/models/constants';
import { DetailIconTypes } from 'src/app/modules/shared/types/DetailsIcon.types';
import { ButtonSizes } from 'src/app/modules/shared/enums/styles/buttonSizes.enum';
import { ButtonThemes } from 'src/app/modules/shared/enums/styles/buttonThemes.enum';

@Component({
  selector: 'app-notifications-center',
  templateUrl: 'notifications-center.component.html',
  styleUrls: ['notifications-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsCenterComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  employee: Employee;
  notifications: NotificationItemViewModel[] = [];
  unread: number = 0;
  showMoreButtonVisible = true;
  notificationTypeOptions = [
    {
      value: 'All',
      notificationType: 0,
    },
    {
      value: 'Owned',
      notificationType: NotificationTypes.Owner,
    },
    {
      value: 'Subscribed',
      notificationType: NotificationTypes.Subscriber,
    },
  ];

  selectedOption: { value: string; notificationType: number } = this.notificationTypeOptions[0];
  filteredNotifications: NotificationItemViewModel[] = [];
  private readonly mobileWidth = Constants.xs;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly notificationsService: NotificationsService,
    private readonly bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly utilitiesService: UtilitiesService
  ) {}

  get isMobile(): boolean {
    return window.innerWidth <= this.mobileWidth;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.isMobile) {
      const modalContainerEl = document.getElementsByTagName('modal-container');
      const sidebarNavEls = document.getElementsByTagName('app-side-navbar');
      let width = 216;

      if (sidebarNavEls && sidebarNavEls.length) {
        const el = sidebarNavEls[0] as HTMLElement;
        if (el) {
          width = el.clientWidth;
        }
      }

      if (modalContainerEl && modalContainerEl.length) {
        const el = modalContainerEl[0] as HTMLElement;

        if (el) {
          el.style.textAlign = 'initial';
          el.style.left = `${width}px`;
        }
      }
    }

    this.employee = this.authenticationService.getCurrentEmployee();

    this.subscriptions.add(
      this.notificationsService.notifications.subscribe((res) => {
        this.notifications = res.map(this.parseNotificationMessage);
        this.filteredNotifications = this.notifications.slice(
          0,
          this.filteredNotifications.length || this.filteredNotifications.length + 7
        );
        this.changeDetectorRef.markForCheck();
      })
    );

    this.subscriptions.add(
      this.notificationsService.unreadNotificationsCount.subscribe((res) => {
        this.unread = res;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  openSettings() {
    if (this.isMobile) {
      this.utilitiesService.broadcastSidebarToggle(false);
    }

    void this.router.navigate(['/v2/settings/preferences/notifications']);
    this.onNavigatationLinkClick();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  goToObjectDetails(notification: NotificationItemViewModel) {
    void this.router.navigateByUrl(notification.link);
    this.onNavigatationLinkClick();
  }

  onNavigatationLinkClick() {
    if (this.isMobile) {
      this.utilitiesService.broadcastSidebarToggle(false);
    }
    this.onClose();
  }

  markAsRead(notification: NotificationItemViewModel) {
    notification.isRead = true;
    this.notificationsService.markAsRead(notification);
  }

  markAsUnread(notification: NotificationItemViewModel) {
    notification.isRead = false;
    this.notificationsService.markAsUnread(notification);
  }

  clear(notification: NotificationItemViewModel) {
    notification.isDeleted = true;
    this.notificationsService.clear(notification);
  }

  getReceivedText(notification: NotificationItemViewModel) {
    const now = new Date();
    const updated = new Date(notification.created);
    const deltaTime: number = now.getTime() - updated.getTime();
    const { days, hours, minutes, seconds } = convertToRelativeTimeObject(deltaTime);

    if (days <= 7) {
      if (days < 1) {
        if (hours) {
          return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
        } else if (minutes && minutes > 15) {
          return `${minutes} minutes ago`;
        } else if (minutes && minutes <= 15) {
          return `Just now`;
        } else {
          return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`;
        }
      } else if (days === 1) {
        return `Yesterday`;
      } else {
        return `${days} days ago`;
      }
    } else {
      return 'more than 7 days ago';
    }
  }

  getIconType(notification: NotificationItemViewModel) {
    return ObjectTypes[notification.globalObjectType] as DetailIconTypes;
  }

  getDecodedLink(notificationLink: string) {
    return decodeURIComponent(notificationLink);
  }

  changeNotificationType(option: { value: string; notificationType: number }) {
    this.selectedOption = option;

    if (this.selectedOption.notificationType == 0) {
      this.filteredNotifications = [];
      this.showMore();
    } else {
      this.filteredNotifications = this.notifications.filter((f) => f.notificationType === option.notificationType);
    }

    this.filteredNotifications = this.filteredNotifications.slice();
    this.changeDetectorRef.markForCheck();
  }

  markAllRead() {
    this.notificationsService.markAllRead();
  }

  clearAll() {
    this.notificationsService.clearAll();
  }

  showMore() {
    let allFilteredNotifications = [];

    if (this.selectedOption.notificationType == 0) {
      allFilteredNotifications = this.notifications.slice();
    } else {
      allFilteredNotifications = this.notifications.filter((f) => f.notificationType === this.selectedOption.notificationType);
    }

    // If there is more items, add another up tp 7 elements to the filtered notifications list.
    if (this.filteredNotifications.length < allFilteredNotifications.length) {
      this.filteredNotifications = allFilteredNotifications.slice(0, this.filteredNotifications.length + 7);
    }

    this.showMoreButtonVisible = !(this.filteredNotifications.length == allFilteredNotifications.length);

    this.filteredNotifications = this.filteredNotifications.slice();
    this.changeDetectorRef.markForCheck();
  }

  parseNotificationMessage(notification: NotificationItemViewModel): NotificationItemViewModel {
    const message = notification.message;
    const dateStampPattern = new RegExp(/^[0-9]{2}:[0-9]{2};/, 'u');

    // Check for timestamp and remove it.
    if (dateStampPattern.test(message)) {
      // Timestamp is six characters long.
      notification.message = notification.message.slice(6);
    }

    // Discard any semicolon.
    notification.message = Array.from(notification.message)
      .filter((e) => e !== ';')
      .join('')
      .trim();

    return notification;
  }

  protected readonly ButtonSizes = ButtonSizes;
  protected readonly ButtonThemes = ButtonThemes;
}
