<div [style.display]="state.value === 'inactive' ? 'none' : ''">
  <img class="toastr-icon icon-close" src="assets/images/icon-alert-close.png" (click)="remove()" />

  <div [class]="options.titleClass" [attr.aria-label]="title">
    <img class="toastr-icon icon-alert" [src]="iconImage" /> {{ title }}
  </div>

  <div role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
    {{ message }}
  </div>
</div>
