import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterTypes } from '../shared/enums/filterTypes';
import { ObjectTypes } from '../shared/enums/objectTypes';
import {
  FilterTypesTranslations,
  FilterValueTranslations,
  ObjectTypesTranslations,
} from './default-localization-translations-mapping';

@Injectable({
  providedIn: 'root',
})
export class WtTranslationService {
  private _locale = 'en';

  constructor(private readonly translateService: TranslateService) {}

  /**
   * Sets the translation language which to be used in the app.
   * @param lang language abbreviation (en, bg, de, etc.).
   */
  private useLanguage(lang: string) {
    this.translateService.use(lang);
  }

  /**
   * Returns the translation key (path) for ObjectType which to be used to get the translated string
   */
  public getDefaultObjectTypeKey(objectType: ObjectTypes, plural: boolean = false) {
    const translationKey = ObjectTypesTranslations[objectType]?.[plural ? 'many' : 'one'];
    if (!translationKey) {
      console.warn(`Translation missing for object type ${objectType}`);
    }
    return translationKey;
  }
  /**
   * Returns the translated string for ObjectType
   */
  public getDefaultObjectTypeTranslation(objectType: ObjectTypes, plural: boolean = false): string {
    const translationKey = this.getDefaultObjectTypeKey(objectType, plural);
    return translationKey ? this.translateService.instant(translationKey) : undefined;
  }

  /**
   * Returns the translation key (path) for FilterType which to be used to get the translation
   */
  public getDefaultFilterTypeKey(filterType: FilterTypes, plural: boolean = false): string {
    const translationKey = FilterTypesTranslations[filterType]?.[plural ? 'many' : 'one'];
    if (!translationKey) {
      console.warn(`Translation missing for filter type ${filterType}`);
    }
    return translationKey;
  }

  /**
   * Returns the translated string for FilterType
   */
  public getDefaultFilterTypeTranslation(filterType: FilterTypes, plural: boolean = false): string {
    const translationKey = this.getDefaultFilterTypeKey(filterType, plural);
    return translationKey ? this.translateService.instant(translationKey) : undefined;
  }

  /**
   * Returns the translation key (path) for default enum based FilterValue which to be used to get the translation
   */
  public getDefaultFilterValueKey(filterType: FilterTypes, filterValue: number): string {
    const translationKey = FilterValueTranslations[filterType]?.[filterValue];
    if (!translationKey) {
      console.warn(`Translation missing for filter value: ${filterValue} of filterType: ${filterValue}`);
    }
    return translationKey;
  }

  /**
   * Returns translated string based on enum value of the FilterType
   * @param filterType FilterType
   * @param filterValue The enum based value for the filter type
   */
  public getDefaultFilterValueTranslation(filterType: FilterTypes, filterValue: number) {
    const translationKey = this.getDefaultFilterValueKey(filterType, filterValue);
    return translationKey ? this.translateService.instant(translationKey) : undefined;
  }

  /**
   * Returns plural or singular translation key based on the count
   */
  public pluralizeKey(key: { one: string; many: string }, count: number): string {
    return count === 1 ? key.one : key.many;
  }

  public get locale(): string {
    return this._locale;
  }

  public set locale(value: string) {
    if (value) {
      this._locale = value;
    }
  }

  /**
   * Sets language to be used in the app.
   * Sets locale which is used for external packages that support localization (e.g. mwl-calendar).
   * @param langCode language code (en, bg, de, etc.)
   */
  public setLanguageAndLocale(langCode: string) {
    this.useLanguage(langCode);
    this.locale = langCode;
  }
}
