import { Injectable } from '@angular/core';
import { BehaviorSubject, skip } from 'rxjs';
import { FilterViewModel } from '../models/filter/filterViewModel';

@Injectable({ providedIn: 'root' })
export class HeaderFiltersService {
  private readonly _currentFilters = new BehaviorSubject<FilterViewModel[]>([]);
  readonly currentFilters$ = this._currentFilters.asObservable();
  get currentFilters(): FilterViewModel[] {
    return this._currentFilters.value.slice();
  }

  readonly filtersChanged$ = this.currentFilters$.pipe(skip(1));

  setCurrentFilters(filters: FilterViewModel[]): void {
    const newFilters = JSON.parse(JSON.stringify(filters)) as FilterViewModel[];
    this._currentFilters.next(newFilters);
  }
}
