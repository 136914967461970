import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { SortingService } from '../../../services/sorting.service';
import { EqualityUtilities } from '../../../utilities/equality.utilities';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationService } from '../../../services/localisation.service';

@Component({
  selector: 'app-floating-dropdown',
  templateUrl: './floating-dropdown.component.html',
  styleUrls: ['./floating-dropdown.component.scss'],
  host: {
    '[class.filters-multiselect-mobile]': 'mobile',
    '[style.height]': 'lozengeInlineEdit ? "unset" : ""',
  },
})
export class FloatingDropdownComponent implements OnInit, OnChanges {
  @Input() mobile: boolean;
  @Input() lozengeInlineEdit: boolean;
  @Input() filterType: FilterTypes;
  @Input() filters: FilterViewModel[];
  @Input() appliedFilters: FilterViewModel[];
  @Input() showOnlyActive: boolean = false;
  @Input() showTitle: boolean = true;
  @Input() customTitle: string = '';
  @Input() canClickOnStar = true;
  @Input() isFilterRequired: boolean = false;
  @Input() singleSelect: boolean = false;
  @Input() useClearAllButton: boolean = true;
  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]>();

  private hasBeenInitExecuted = false;
  filteredFilters: FilterViewModel[] = [];
  searchValue: string;

  public localAppliedFilters: FilterViewModel[] = [];
  public originalAppliedFilters: FilterViewModel[] = [];
  public excludeState: boolean = false;
  public primaryState: boolean = false;
  public isDepartmentFilter = false;
  public areAllSelected: boolean = false;
  public readonly T = T;
  public headerTitle = '';

  constructor(private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly sortingService: SortingService,
    private readonly translateService: TranslateService,
    private readonly localisationService: LocalisationService) {}

  ngOnInit() {
    this.filters = this.sortingService.sortFilters(this.filters, this.filterType);

    if (!this.appliedFilters) {
      this.appliedFilters = [];
    }

    this.filteredFilters = JSON.parse(JSON.stringify(this.filters)) as FilterViewModel[];
    this.filterSpecialCases();
    this.appliedFilters = this.appliedFilters.filter((f) => f.filterType === this.filterType);

    this.localAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters.slice())) as FilterViewModel[];
    this.originalAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];

    this.setInitialExcludeState();
    this.setInitialPrimaryState();
    this.isDepartmentFilter = this.filterType === FilterTypes.Department;
    this.hasBeenInitExecuted = true;

    if(this.customTitle && this.customTitle.length) {
      this.headerTitle = this.customTitle;
    } else {
      this.headerTitle = this.localisationService.localiseFilterType(this.filterType);
    }

    this.checkIfAllAreSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appliedFilters && this.hasBeenInitExecuted) {
      this.appliedFilters = this.appliedFilters.filter((f) => f.filterType === this.filterType);
      this.localAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];
      if (this.filterType === FilterTypes.Department) {
        this.localAppliedFilters.sort((a, b) => +b.isPrimary - +a.isPrimary);
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  setInitialExcludeState() {
    this.excludeState = this.localAppliedFilters[0]?.exclude;
  }

  setInitialPrimaryState() {
    this.primaryState = this.localAppliedFilters[0]?.isPrimary;
  }

  get isFilterChanged(): boolean {
    if (this.isFilterRequired && !this.localAppliedFilters.length) {
      return true;
    }
    return EqualityUtilities.arraysEqual(this.appliedFilters, this.localAppliedFilters);
  }

  onEntrySelected(entry: FilterViewModel): void {
    if (this.singleSelect) {
      entry.filterAction = FilterActionTypes.Update;
      if (!(this.isFilterRequired && entry.filterValue == this.localAppliedFilters[0].filterValue)) {
        if (
          !this.localAppliedFilters ||
          !this.localAppliedFilters.length ||
          this.localAppliedFilters.find((f) => f.filterValue == entry.filterValue) === undefined
        ) {
          this.localAppliedFilters = this.localAppliedFilters.filter((a) => a.filterType !== entry.filterType);
          this.localAppliedFilters.push(entry);
          // this.appliedFilters = [entry];
          // this.selected = [entry];
          // this.onFiltersUpdated.next(this.appliedFilters);
          this.onApply();
        } else {
          this.localAppliedFilters = this.localAppliedFilters.filter((a) => a.id !== entry.id);
          // this.appliedFilters = [];
          // this.selected = [];
          // this.onFiltersUpdated.next(this.appliedFilters);
          this.onApply();
        }
      }

      this.onCancel();

      this.changeDetectorRef.markForCheck();
    } else {
      entry.filterAction = FilterActionTypes.Add;
      const selectedIndex = this.getItemIndex(entry);

      if (selectedIndex > -1) {
        this.localAppliedFilters = this.localAppliedFilters.filter((a) => a.id !== entry.id);
      } else {
        this.localAppliedFilters.push(entry);
      }

      this.localAppliedFilters = this.localAppliedFilters.slice();

      this.checkIfAllAreSelected();
    }
  }

  private getItemIndex(entry: FilterViewModel): number {
    return this.localAppliedFilters.findIndex((a) => a.filterType === entry.filterType && a.filterValue == entry.filterValue);
  }

  onSearch(value: string): void {
    this.searchValue = value?.toLowerCase().trim();

    if (value) {
      this.filteredFilters = this.filters.filter(
        (f) => f.filterText && f.filterText.toLowerCase().indexOf(this.searchValue) > -1
      );
    } else {
      this.filteredFilters = this.filters.slice();
    }

    if (this.showOnlyActive) {
      this.filteredFilters = this.filteredFilters.filter((f) => f.isActive);
    }
    this.filterSpecialCases();
    this.checkIfAllAreSelected();

    this.changeDetectorRef.markForCheck();
  }

  private filterSpecialCases() {
    if (this.filterType === FilterTypes.Owner) {
      this.filteredFilters = this.filteredFilters.filter((f) => !f.isExternal);
    }
  }

  /**
   * this is event which is called internali from app-filters-inline-edit-lozenges
   * when an lozenge is deleted or lozenges are cleared all. It retursn only the left lozenges
   */
  onFilterDeleted(deletedFilter: FilterViewModel): void {
    if(this.singleSelect && this.isFilterRequired){
      return;
    }
    this.localAppliedFilters = this.localAppliedFilters.filter(
      (f) => !(f.filterType === deletedFilter.filterType && f.filterValue == deletedFilter.filterValue)
    );

    this.checkIfAllAreSelected();
  }

  onPrimaryFilterClicked(primaryFilter: FilterViewModel): void {
    if (primaryFilter.isPrimary) {
      this.localAppliedFilters.forEach((f) => (f.isPrimary = false));
      primaryFilter.isPrimary = true;
    }
  }

  onExclude(excluded: boolean): void {
    this.localAppliedFilters.forEach((f) => (f.exclude = excluded));
    this.excludeState = excluded;
  }

  onPrimarySelect(selected: boolean): void {
    this.localAppliedFilters.forEach((f) => (f.isPrimary = selected));
    this.primaryState = selected;
  }

  onCancel(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  onSelectAll(): void {
    this.localAppliedFilters = this.filters.slice();
    this.localAppliedFilters.forEach((a) => (a.filterAction = FilterActionTypes.Add));

    this.checkIfAllAreSelected();

    this.changeDetectorRef.markForCheck();
  }

  onClearAll(): void {
    this.localAppliedFilters = [];
    this.changeDetectorRef.markForCheck();
    this.checkIfAllAreSelected();
  }

  onApply(): void {
    this.localAppliedFilters.forEach((f) => (f.exclude = this.excludeState));
    this.filtersUpdated.next(this.localAppliedFilters);
    this.onCancel();
  }

  private checkIfAllAreSelected(): void {
    if (!this.filters.length || this.searchValue) {
      this.areAllSelected = true;
    } else {
      this.areAllSelected = this.localAppliedFilters.length === this.filters.length;
    }
  }

  public getSelectedEntriesCount(): number {
    // Handle all channels case (normally when single select)
    if(this.localAppliedFilters[0]?.filterText === 'All Channels' || this.localAppliedFilters[0]?.filterText === this.translateService.instant(T.common.all_channels)) {
      return 0;
    }
    return this.localAppliedFilters.length;
  }
}
