import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ElementRef,
} from '@angular/core';
import { DatepickerView } from '../../../enums/datepickerView.enum';
import { NgDate } from '../../../models/ngDate';
import { TimeZoneService } from '../../../services/timeZone.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { NgMinMaxDateStrategies } from '../../../models/rangeDatepicker/rangeDatepickerModels.model';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-ngdatepicker',
  templateUrl: './ngdatepicker.component.html',
  styleUrls: ['./ngdatepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class.single-picker]': '!isInRangePicker' },
})
export class NgDatepickerComponent implements OnChanges {
  private readonly yearCellsCount: number = 16;
  private readonly dayTimespan: number = 24 * 3600 * 1000;
  private readonly datesRows: number = 6;
  private readonly minYear: number = 1895;

  // It is setter here because directive setts the date value without passing it in the input,
  // so we cant catch to set a prop within onChanges
  @Input() set date(date: string | Date) {
    if (date) {
      this.localisedLocalDate = this.timeZoneService.getCurrentMomentInLocalisedTimezone(date);
    } else {
      this.localisedLocalDate = undefined;
    }
  }
  // helperDate is for second date that cannot be changed.this is only for visualization for the range.
  @Input() helperDate: string | Date;
  @Input() isEndDate: boolean;
  @Input() minDate: string | Date;
  @Input() maxDate: string | Date;
  @Input() component: boolean;
  @Input() isInRangePicker: boolean;
  // minMaxDateStrategy delegates what will be the behaviour if the date is before the minDate or after the maxDate
  @Input() minMaxDateStrategy: NgMinMaxDateStrategies = NgMinMaxDateStrategies.DisableDate;
  // In case where the calendar needs to use predefined active dates , set this to true
  @Input() usePredefinedAvailableDates: boolean = false;
  // Predefined dates that will be shown in the calendar as clickable, !!!EVERY OTHER DATE will be DISABLED
  @Input() predefinedAvailableDates: string[];
  // In case where we want show specific month
  @Input() externalMonth: number;
  // In case where we want show specific year
  @Input() externalYear: number;

  @Output() dateChanged: EventEmitter<string> = new EventEmitter();
  public onHide: Subject<boolean> = new Subject();
  public readonly T = T;

  visible: boolean;
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  days: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  datepickerView = DatepickerView;
  currentDatepickerView: DatepickerView = DatepickerView.Days;
  month: number;
  year: number;

  public focusedAvailableDate: string;

  directiveElementRef: ElementRef<HTMLElement>;
  public localisedLocalDate: moment.Moment;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, private timeZoneService: TimeZoneService) {}

  ngOnChanges() {
    if (!this.component) {
      return;
    }

    this.visible = true;
    this.initMonth();
    this.initYear();
  }

  get monthString(): string {
    return this.months[this.month];
  }

  get startYear(): number {
    return this.year - this.yearCellsCount / 2 + 1;
  }

  get endYear(): number {
    return this.year + this.yearCellsCount / 2;
  }

  get surroundingYears(): number[] {
    const surroundingYears: number[] = [];

    for (let y = this.startYear; y <= this.endYear; y++) {
      surroundingYears.push(y);
    }

    return surroundingYears;
  }

  get dates(): NgDate[] {
    const dates: NgDate[] = [];
    // let date = this.timeZoneService.getCurrentMomentInLocalisedTimezone({year: this.year, month: this.month, date: 1});
    let date = this.timeZoneService.getCurrentMomentInLocalisedTimezone();
    date.set({ year: this.year, month: this.month, date: 1 });
    if (this.localisedLocalDate) {
      this.setTime(date, this.localisedLocalDate);
    } else {
      this.setTime(date, this.timeZoneService.getCurrentMomentInLocalisedTimezone());
    }

    let prev = date.clone();

    const prevDay = prev.day();
    const day = !prevDay ? 6 : prevDay - 1;

    for (let i = day; i > 0; i--) {
      prev = prev.clone().subtract(1, 'day');

      dates.unshift({
        date: prev,
        disabled: true,
      });
    }

    while (date.month() === this.month) {
      dates.push({
        date,
        disabled: this.isDateDisabled(date),
      });
      date = date.clone().add(1, 'day');
    }

    while (dates.length < this.datesRows * this.days.length) {
      dates.push({
        date,
        disabled: true,
      });
      date = date.clone().add(1, 'day');
    }

    return dates;
  }

  setTime(target: moment.Moment, source: moment.Moment): void {
    target.set({
      hours: source.get('hours'),
      minutes: source.get('minutes'),
      seconds: source.get('seconds'),
      milliseconds: source.get('milliseconds'),
    });
  }

  isActive(date: moment.Moment) {
    if (!this.localisedLocalDate) {
      return;
    }

    return date.isSame(this.localisedLocalDate.toISOString(), 'day');
  }

  isToday(date: moment.Moment) {
    const today = this.timeZoneService.getCurrentMomentInLocalisedTimezone();

    return date.isSame(today.toISOString(), 'day');
  }

  isBetween(date: moment.Moment) {
    if (!this.localisedLocalDate || !this.helperDate) {
      return;
    }
    if (!this.isEndDate) {
      return date.isAfter(this.localisedLocalDate, 'day') && date.isBefore(this.helperDate, 'day');
    }
    return date.isBefore(this.localisedLocalDate, 'day') && date.isAfter(this.helperDate, 'day');
  }

  isHelperDate(date: moment.Moment) {
    if (!this.localisedLocalDate || !this.helperDate) {
      return;
    }

    return date.isSame(this.helperDate, 'day');
  }

  isLeftHelper(date: moment.Moment) {
    if (!this.localisedLocalDate || !this.helperDate) {
      return;
    }

    return (
      (this.isActive(date) || this.isHelperDate(date)) && !this.isEndDate && !this.localisedLocalDate.isSame(this.helperDate)
    );
  }
  isRightHelper(date: moment.Moment) {
    if (!this.localisedLocalDate || !this.helperDate) {
      return;
    }

    return (this.isActive(date) || this.isHelperDate(date)) && this.isEndDate && !this.localisedLocalDate.isSame(this.helperDate);
  }

  isBeforeMinDate(ngDate: NgDate): boolean {
    if (!this.minDate) {
      return;
    }

    return ngDate.date.isBefore(this.minDate, 'days');
  }

  isAfterMaxDate(ngDate: NgDate): boolean {
    if (!this.maxDate) {
      return;
    }
    return ngDate.date.isAfter(this.maxDate, 'days');
  }

  isDayFocused(date: moment.Moment): boolean {
    if (!this.usePredefinedAvailableDates || !this.focusedAvailableDate || this.predefinedAvailableDates?.length == 0) {
      return false;
    }

    return date.isSame(this.focusedAvailableDate, 'day');
  }

  show() {
    this.visible = true;
    this.changeDetectorRef.markForCheck();
  }

  hide() {
    this.visible = false;
    this.clearExternalMonthAndYear();
    this.focusedAvailableDate = undefined;
    this.changeDetectorRef.markForCheck();
  }

  initMonth() {
    if (this.externalMonth) {
      this.month = this.externalMonth;
    } else if (this.localisedLocalDate) {
      this.month = this.localisedLocalDate.month();
    } else {
      const today = this.timeZoneService.getCurrentMomentInLocalisedTimezone();
      this.month = today.month();
    }
  }

  initYear() {
    if (this.externalYear) {
      this.year = this.externalYear;
    } else if (this.localisedLocalDate) {
      this.year = this.localisedLocalDate.year();
    } else {
      const today = this.timeZoneService.getCurrentMomentInLocalisedTimezone();
      this.year = today.year();
    }
  }

  backwards(e: Event) {
    if (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    switch (this.currentDatepickerView) {
      case DatepickerView.Days:
        this.month--;

        if (this.month < 0) {
          if (this.year - 1 >= this.minYear) {
            this.year--;
            this.month = this.months.length - 1;
          } else {
            this.month = 0;
          }
        }

        break;
      case DatepickerView.Months:
        this.year--;

        if (this.year < this.minYear) {
          this.year = this.minYear;
        }

        break;
      case DatepickerView.Years:
        this.year -= this.yearCellsCount;

        if (this.year < this.minYear) {
          this.year = this.minYear + this.yearCellsCount / 2 - 1;
        }

        break;
    }

    this.changeDetectorRef.markForCheck();
  }

  forwards(e: Event) {
    if (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    switch (this.currentDatepickerView) {
      case DatepickerView.Days:
        this.month++;

        if (this.month >= this.months.length) {
          this.year++;
          this.month = 0;
        }

        break;
      case DatepickerView.Months:
        this.year++;

        break;
      case DatepickerView.Years:
        this.year += this.yearCellsCount;

        break;
    }

    this.changeDetectorRef.markForCheck();
  }

  toggleView(e: Event, datepickerView: DatepickerView) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.currentDatepickerView = datepickerView;
    this.changeDetectorRef.markForCheck();
  }

  onMonthChosen(e: Event, month: number) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.month = month;
    this.currentDatepickerView = DatepickerView.Days;
    this.changeDetectorRef.markForCheck();
  }

  onYearChosen(e: Event, year: number) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.year = year;
    this.currentDatepickerView = DatepickerView.Months;
    this.changeDetectorRef.markForCheck();
  }

  getDate(date: moment.Moment) {
    return date.date();
  }

  onDateChosen(e: Event, ngDate: NgDate) {
    e?.stopPropagation();
    e?.stopImmediatePropagation();

    if (ngDate.disabled) {
      if (
        (ngDate.date.month() < this.month && ngDate.date.year() === this.year) ||
        (ngDate.date.month() > this.month && ngDate.date.year() < this.year)
      ) {
        this.backwards(e);
      } else if (
        (ngDate.date.month() > this.month && ngDate.date.year() === this.year) ||
        (ngDate.date.month() < this.month && ngDate.date.year() > this.year)
      ) {
        this.forwards(e);
      }
    } else {
      if (this.minDate) {
        if (ngDate.date.isBefore(this.minDate, 'day') && this.minMaxDateStrategy === NgMinMaxDateStrategies.DisableDate) {
          return;
        }
      }

      if (this.maxDate) {
        if (ngDate.date.isAfter(this.maxDate, 'day') && this.minMaxDateStrategy === NgMinMaxDateStrategies.DisableDate) {
          return;
        }
      }

      this.dateChanged.emit(ngDate.date.toISOString());
      if (!this.component) {
        this.hide();
        this.onHide.next(true);
      }
    }
  }

  trackByFn(index: number, date: NgDate) {
    return date.date.toISOString();
  }

  isDateDisabled(date: moment.Moment): boolean {
    if (this.usePredefinedAvailableDates) {
      const isDisabled = !this.predefinedAvailableDates.some((d) => moment(d).isSame(date, 'day'));
      return isDisabled;
    } else if (this.minDate && !this.helperDate && moment(date).isBefore(this.minDate, 'day')) {
      return true;
    } else {
      return false;
    }
  }

  navigateToPreviousPredefined(e: MouseEvent) {
    if (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    let dateToFocus: string;
    const sortedPredefinedDates = this.getSortedPredefinedDates();
    let comparingDate = moment();

    if (this.focusedAvailableDate) {
      comparingDate = moment(this.focusedAvailableDate);
    } else if (this.localisedLocalDate) {
      comparingDate = moment(this.localisedLocalDate);
    }

    const lastPreviousDate = [...sortedPredefinedDates].reverse().find((date) => moment(date).isBefore(comparingDate));
    if (lastPreviousDate) {
      dateToFocus = lastPreviousDate;
    } else {
      dateToFocus = sortedPredefinedDates[0];
    }

    this.month = moment(dateToFocus).get('month');
    this.year = moment(dateToFocus).get('year');

    this.focusedAvailableDate = dateToFocus;
    this.changeDetectorRef.markForCheck();
  }

  getSortedPredefinedDates(): string[] {
    const predefinedDates = this.predefinedAvailableDates.slice();

    predefinedDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    return predefinedDates;
  }

  navigateToNextPredefined(e: MouseEvent) {
    if (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    let dateToFocus: string;
    const sortedPredefinedDates = this.getSortedPredefinedDates();
    let comparingDate = moment();
    if (this.focusedAvailableDate) {
      comparingDate = moment(this.focusedAvailableDate);
    } else if (this.localisedLocalDate) {
      comparingDate = moment(this.localisedLocalDate);
    }

    const lastPreviousDate = sortedPredefinedDates.find((date) => moment(date).isAfter(comparingDate));
    if (lastPreviousDate) {
      dateToFocus = lastPreviousDate;
    } else {
      dateToFocus = sortedPredefinedDates[sortedPredefinedDates.length - 1];
    }

    this.month = moment(dateToFocus).get('month');
    this.year = moment(dateToFocus).get('year');

    this.focusedAvailableDate = dateToFocus;
    this.changeDetectorRef.markForCheck();
  }

  public setExternalYearAndMonth(yearAndMonth: { year?: number; month?: number }): void {
    if (yearAndMonth.year) {
      this.externalYear = yearAndMonth.year;
    }
    if (yearAndMonth.month) {
      this.externalMonth = yearAndMonth.month;
    }
    this.initYear();
    this.initMonth();
    this.changeDetectorRef.detectChanges();
  }

  public clearExternalMonthAndYear(): void {
    this.externalMonth = undefined;
    this.externalYear = undefined;
  }
}
