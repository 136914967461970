<ng-container *ngIf="canEdit">
  <!-- For now on mobile screens the datepicker will not able to edit -->
  <ng-container *ngIf="!range">
    <div
      class="date-details editable"
      ngdatepicker
      [date]="date"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (mouseenter)="toggleShowEditButton()"
      (mouseleave)="toggleShowEditButton()"
      (dateChanged)="onDateChanged($event)"
      [style.padding-left]="accordionLozenge ? '4px' : '0px'"
    >
      <ng-container *ngIf="!accordionLozenge">
        <div class="label">
          <span>{{ label }}</span>
          <app-button-transparent
            *ngIf="showEditButton"
            [text]="T.common.edit | translate"
            icon="edit"
            iconFontSize="16"
            size="small"
            textColor="primary-text"
            class="ms-2"
          >
          </app-button-transparent>
        </div>
      </ng-container>
      <div class="date-placeholder" style="font-size: 14px" *ngIf="!showComparer">
        <div *ngIf="date">
          {{ date | localisedDate: includeTime:includeTime
          }}<span class="day-of-the-week-name" *ngIf="date && dayOfTheWeekName"> ({{ date | localisedWeekday }})</span>
        </div>
        <div *ngIf="!date">{{ placeholder }}</div>
      </div>
      <div class="dates-row" *ngIf="showComparer">
        <div class="dates-text" *ngIf="date">{{ date | localisedDate: includeTime:includeTime }}</div>
        <div class="dates-text" *ngIf="!date">{{ placeholder }}</div>
        <div class="additional-compare-info">
          <ng-container *ngIf="differenceInDays !== 0 && isNumber(differenceInDays)">
            <span>&nbsp;(</span>
            <ng-container *ngIf="differenceInDays > -999 && differenceInDays < 999">
              <span class="time" [ngClass]="differenceInDays >= 0 ? 'positive-time' : 'negative-time'"
                >{{ differenceInDays >= 0 ? '+' : '-' }}{{ differenceInDays | convertNum: 'positive' }}d
              </span>
            </ng-container>
            <ng-container *ngIf="differenceInDays <= -999 && differenceInDays >= 999">
              <span class="time" [ngClass]="differenceInDays >= 0 ? 'positive-time' : 'negative-time'"
                >{{ differenceInDays >= 0 ? '+' : '-' }}999d
              </span>
            </ng-container>
            <span class="time">{{ isCurrentDateBiggerThanCompared ? 'after' : 'before' }} </span>
            <span class="time" *ngIf="comparingLabel">{{ comparingLabel }}</span>
            <span>)</span>
          </ng-container>
          <ng-container *ngIf="differenceInDays === 0 && isNumber(differenceInDays)">
            <span>&nbsp;({{ comparingLabel }} {{ sameDateHelper }})</span>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="range">
    <ng-container *ngIf="!touchDevice">
      <div
        class="date-details editable"
        ngRangeDatepicker
        [timePicker]="timePicker"
        [startDate]="startDate ? startDate : date"
        [endDate]="endDate"
        [style.padding-left]="accordionLozenge ? '4px' : '0px'"
        (mouseenter)="toggleShowEditButton()"
        (mouseleave)="toggleShowEditButton()"
        (startDateChanged)="onDateChanged($event)"
        (endDateChanged)="onEndDateChanged($event)"
        (datesChanged)="onDatesChanged($event)"
      >
        <ng-container *ngIf="!accordionLozenge">
          <div class="label">
            <span>{{ label }}</span>
            <app-button-transparent
              *ngIf="showEditButton"
              [text]="T.common.edit | translate"
              icon="edit"
              iconFontSize="16"
              size="small"
              textColor="primary-text"
              class="ms-2"
            >
            </app-button-transparent>
          </div>
        </ng-container>
        <div style="font-size: 14px">
          <div *ngIf="date && !isEndDateField">{{ date | localisedDate: includeTime:includeTime }}</div>
          <div *ngIf="endDate && isEndDateField">{{ endDate | localisedDate: includeTime:includeTime }}</div>
          <div *ngIf="!date && !isEndDateField">{{ placeholder }}</div>
          <div *ngIf="!endDate && isEndDateField">{{ placeholder }}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="touchDevice">
      <div class="date-details" [style.padding-left]="accordionLozenge ? '4px' : '0px'">
        <ng-container *ngIf="!accordionLozenge">
          <div class="label">
            <span>{{ label }}</span>
            <app-button-transparent
              [text]="T.common.edit | translate"
              icon="edit"
              iconFontSize="16"
              size="small"
              textColor="primary-text"
              class="ms-2"
            >
            </app-button-transparent>
          </div>
        </ng-container>
        <div style="font-size: 14px">
          <div *ngIf="date && !isEndDateField">{{ date | localisedDate: includeTime:includeTime }}</div>
          <div *ngIf="endDate && isEndDateField">{{ endDate | localisedDate: includeTime:includeTime }}</div>
          <div *ngIf="!date">{{ placeholder }}</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="!canEdit">
  <div class="date-details" [style.padding-left]="accordionLozenge ? '4px' : '0px'">
    <ng-container *ngIf="!accordionLozenge">
      <div class="label">
        <span>{{ label }}</span>
      </div>
    </ng-container>
    <div class="dates-row">
      <div class="dates-text" *ngIf="date">{{ date | localisedDate: includeTime:includeTime }}</div>
      <div class="dates-text" *ngIf="!date">{{ placeholder }}</div>
      <div class="additional-compare-info">
        <ng-container *ngIf="differenceInDays !== 0 && isNumber(differenceInDays)">
          <span>&nbsp;(</span>
          <ng-container *ngIf="differenceInDays > -999 && differenceInDays < 999">
            <span class="time" [ngClass]="differenceInDays >= 0 ? 'positive-time' : 'negative-time'"
              >{{ differenceInDays >= 0 ? '+' : '-' }}{{ differenceInDays | convertNum: 'positive' }}d
            </span>
          </ng-container>
          <ng-container *ngIf="differenceInDays <= -999 && differenceInDays >= 999">
            <span class="time" [ngClass]="differenceInDays >= 0 ? 'positive-time' : 'negative-time'"
              >{{ differenceInDays >= 0 ? '+' : '-' }}999d
            </span>
          </ng-container>
          <span class="time">{{ isCurrentDateBiggerThanCompared ? 'after' : 'before' }} </span>
          <span class="time" *ngIf="comparingLabel">{{ comparingLabel }}</span>
          <span>)</span>
        </ng-container>
        <ng-container *ngIf="differenceInDays === 0 && isNumber(differenceInDays)">
          <span>&nbsp;({{ comparingLabel }} {{ sameDateHelper }})</span>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
