export enum ObjectTypes {
  Global = 0,
  Employee = 1,
  Account = 2,
  Department = 5,
  Message = 6,
  Department_Category = 7,
  Organisation = 8,
  MediaCategory = 31,
  Task = 60,
  TaskSystem = 61,
  TaskHistory = 62,
  IncidentItem = 100,
  Job = 101,
  Public_Incident_Report = 102,
  Chat_Message = 103,
  Prepopulated_Text_Message = 104,
  Keyword_Specification = 105,
  ProductCategory = 200,
  Product = 201,
  Context = 202,
  GeographicArea = 300,
  Trend_Rule = 401,
  Venue = 500,
  Venue_Category = 501,
  Venue_Type = 502,
  Venue_Tag = 503,
  Help = 901,
  Log = 10103,
  Runsheet = 603,
  Runsheet_Item = 604,

  Tag = 800,
  Zone = 801,
  Area = 802,
  Incident_Category = 803,
  Checklist = 804,
  Checklist_Item_Template = 805,
  Checklist_Item = 806,

  Event = 810,
  Event_Type = 811,
  Incident_Channel = 812,

  Report_History = 813,
  Tag_Group = 814,
  Tag_Category = 815,
  Job_Category = 816,
  Job_Type = 817,
  Project = 900,
  Task_Group = 901,
  Risk = 902,
  Risk_Action_Item = 903,
  Risk_Impact_Type = 904,
  Risk_Action_Type = 905,
  Risk_Assesment = 906,
  Filter = 1000,
  Favorite_Filters = 1001,
  External_Filter = 1002,
  Reporting = 1003,
  PEAP_Checkpoint_Type = 1004,
  PEAP = 1005,
  PEAP_Check = 1006,
  PEAP_Checkpoint = 1007,
  PEAP_Group = 1008,
  Document_Category = 1009,

  // This is for system use only in order to use the same SignalR Flow like the one which is implemented now
  Allowed_Filter = 2000,
  User_Group = 2001,
  Api_Access = 2002,
  Risk_Impact = 2003,
  Risk_Likelihood = 2004,
  Risk_Baseline_Impact = 2005,
  Risk_Baseline_Likelihood = 2006,

  Indicator = 3000,
  Indicator_Category = 3001,
  Indicator_Target = 3002,
  Indicator_Target_Type = 3003,
  Indicator_Update = 3004,
  UN_Sustainable_Development_Goal = 3005,
  Indicator_Framework = 3006,
  Indicator_Template = 3007,

  Account_Hub = 5000,
  Account_Customisation = 10000,
}
