<div #sideNavbar class="side-menu" id="side-navbar" [class.toggled]="isMobileScreen ? true : isSidebarToggled">
  <ng-container *ngIf="isChildHubAccount">
    <div class="hub-navigation">
        <div class="hub-image">
          <img [src]="hubAccountUrl" alt="" />
        </div>
      <div class="hub-navigation-button" (click)="navigateToAccountHub()">{{ T.hub.common.go_to_hub | translate }}</div>
    </div>
  </ng-container>

  <ng-container *ngIf="isHubAccount">
    <div class="brand-hub-account">
      <div class="brand-hub-image brand-image">
        <img [src]="logoUrl" alt="" />
      </div>
      <div class="short-brand-name short-brand-hub-name">{{ currentAccount.title }}</div>
    </div>
    <div class="module-container account-hub-module-container dropdown-module">
      <div class="module-items account-hub-module-items">
        <ng-container *ngIf="!isSidebarToggled">
          <a
            *ngFor="let item of accountHubNavElements"
            class="single-module-item"
            [id]="item.idAttr"
            [routerLink]="[item.url]"
            routerLinkActive="active-menu-item"
            [target]="item.target"
            [app-tooltip]="{ message: [item.name], position: ['right'] }"
            (click)="collapseSidebarOnMenuItemSelect()"
          >
            <i
              *ngIf="item.idAttr !== 'hub_planning' && item.idAttr !== 'hub_control' && item.idAttr !== 'hub_risk'; else customIcon"
              class="material-icons item-icon hub-item-icon">
              {{ item.icon }}
            </i>
            <ng-template #customIcon>
              <div class="item-svg-container" [innerHTML]="item.icon | safeHtml"></div>
            </ng-template>
            <span class="item-name">{{ item.name }}</span>
          </a>
          <hr/>
          <a
            class="single-module-item"
            [id]="hubManagementNavigationItem.idAttr"
            [routerLink]="[hubManagementNavigationItem.url]"
            routerLinkActive="active-menu-item"
            [target]="hubManagementNavigationItem.target"
            [app-tooltip]="{ message: [hubManagementNavigationItem.name], position: ['right'] }"
            (click)="collapseSidebarOnMenuItemSelect()"
          >
            <i class="material-icons item-icon hub-item-icon">{{ hubManagementNavigationItem.icon }}</i>
            <span class="item-name">{{ hubManagementNavigationItem.name }}</span>
          </a>
        </ng-container>
        <ng-container *ngIf="isSidebarToggled">
          <a
            *ngFor="let item of accountHubNavElements"
            class="single-module-item"
            [id]="item.idAttr"
            [routerLink]="[item.url]"
            routerLinkActive="active-menu-item"
            [target]="item.target"
            (click)="collapseSidebarOnMenuItemSelect()"
          >
          <i
            *ngIf="item.idAttr !== 'hub_planning' && item.idAttr !== 'hub_control' && item.idAttr !== 'hub_risk'; else customIcon"
            class="material-icons item-icon hub-item-icon">
            {{ item.icon }}
          </i>
          <ng-template #customIcon>
            <div class="item-svg-container" [innerHTML]="item.icon | safeHtml"></div>
          </ng-template>
            <span class="item-name">{{ item.name }}</span>
          </a>
          <hr/>
          <a
            class="single-module-item"
            [id]="hubManagementNavigationItem.idAttr"
            [routerLink]="[hubManagementNavigationItem.url]"
            routerLinkActive="active-menu-item"
            [target]="hubManagementNavigationItem.target"
            [app-tooltip]="{ message: [hubManagementNavigationItem.name], position: ['right'] }"
            (click)="collapseSidebarOnMenuItemSelect()"
          >
            <i class="material-icons item-icon hub-item-icon">{{ hubManagementNavigationItem.icon }}</i>
            <span class="item-name">{{ hubManagementNavigationItem.name }}</span>
          </a>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isHubAccount">
    <div class="brand-account">
      <div class="left-side">
        <div class="brand-image">
          <img [src]="logoUrl" alt="" />
        </div>
        <div class="brand-account-info">
          <div class="short-brand-name">{{ currentAccount.title }}</div>
          <div
            class="switch-brand-account"
            (click)="openSwitchAccountModal()"
            *ngIf="currentEmployee.accounts.length > 0"
          >
            {{ T.common.switch_account | translate }}
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="close-icon" (click)="collapseSidebar()">
          <i class="material-icons">close</i>
        </div>
      </div>
    </div>
    <div class="module-container">
      <div class="module-items">
        <div class="non-group">
          <ng-container *ngFor="let module of nonGroupedNavigationModuleItem; let i = index">
            <a
              *ngIf="!isSidebarToggled"
              class="single-module-item"
              [attr.id]="module.idAttr"
              [routerLink]="module.url"
              [class.active-menu-item]="module.moduleType === moduleService.currentModule"
              [app-tooltip]="{ message: [module.name], position: ['right'] }"
              (click)="changeSelectedViewMode(module); collapseSidebarOnMenuItemSelect()"
            >
              <div class="item-svg-container" [innerHTML]="getSelectedModuleIcon(module.moduleType) | safeHtml"></div>
              <span class="item-name big">{{ module.name }}</span>
            </a>
            <a
              *ngIf="isSidebarToggled"
              class="single-module-item"
              [attr.id]="module.idAttr"
              [routerLink]="module.url"
              [class.active-menu-item]="module.moduleType === moduleService.currentModule"
              (click)="changeSelectedViewMode(module); collapseSidebarOnMenuItemSelect()"
            >
              <div class="item-svg-container" [innerHTML]="getSelectedModuleIcon(module.moduleType) | safeHtml"></div>
              <span class="item-name big">{{ module.name }}</span>
            </a>
          </ng-container>
        </div>
        <div class="group-modules">
        <div *ngFor="let module of groupedNavigationModuleItems" [class.d-none]="module.isDisabled">
          <div [class.active_module]="checkSelectedModule(module)">
            <a
              class="single-module-item"
              [id]="module.idAttr"
              (click)="onGroupedModuleClicked(module)"
              [target]="module.target"
              [app-tooltip]="{ message: [module.name], position: ['right'] }"
            >
              <div class="item-svg-container" [innerHTML]="getSelectedModuleIcon(module.moduleType) | safeHtml"></div>
              <!-- <i class="material-icons item-icon">{{ item.icon }}</i> -->
              <span class="item-name big">{{ module.name }}</span>
            </a>
            <div class="module-menu" *ngIf="checkSelectedModule(module)">
              <ng-container *ngIf="!isSidebarToggled">
                <a
                  *ngFor="let item of module.children;"
                  class="single-module-menu"
                  [id]="item.idAttr"
                  [routerLink]="[item.url]"
                  routerLinkActive="active-menu"
                  [target]="item.target"
                  [app-tooltip]="{ message: [item.name], position: ['right'] }"
                >
                  <i class="material-icons menu-icon">{{ item.icon }}</i>
                  <span class="menu-name">{{ item.name }}</span>
                </a>
              </ng-container>
              <ng-container *ngIf="isSidebarToggled">
                <a
                  *ngFor="let item of module.children"
                  class="single-module-menu"
                  [id]="item.idAttr"
                  [routerLink]="[item.url]"
                  routerLinkActive="active-menu"
                  [target]="item.target"
                  (click)="collapseSidebarOnMenuItemSelect()"
                >
                  <i class="material-icons menu-icon">{{ item.icon }}</i>
                  <span class="menu-name">{{ item.name }}</span>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="options-menu" [class.toggled]="isSidebarToggled">
    <div class="left-container">
      <div data-intercom-target="Profile and Settings" class="current-account-container" [closeUponSelection]="true"
      ngDropdown
      [templateRef]="accountOptionsTemplate"
      (click)="$event.stopPropagation()">
        <div
          class="current-account"

        >
          <app-employee-avatar [employee]="currentEmployee" [size]="avatarSize"></app-employee-avatar>
        </div>
        <span class="item-name">Profile</span>
      </div>
      <div class="messages" (click)="$event.stopPropagation()"
      ngDropdown
      [templateRef]="supportTemplate"
      [closeUponSelection]="true"
      [app-tooltip]="{ message: 'Momentus Support', position: ['right'] }"
      data-intercom-target="Support Options">

        <ng-container *ngIf="!isSidebarToggled">
          <i class="material-icons-outlined" >help_outline</i>
        </ng-container>
        <ng-container *ngIf="isSidebarToggled">
          <i class="material-icons-outlined">help_outline</i>
        </ng-container>
        <span class="item-name">Help</span>
      </div>
      <div class="notifications" data-intercom-target="Notification Centre" (click)="openNotifications()">
        <div class="notification-btn-container">
          <i class="material-icons">notifications</i>
          <span class="notification-counter" *ngIf="pendingNotifications">{{ pendingNotifications }}</span>
        </div>
        <span class="item-name">Notifications</span>
      </div>
    </div>
    <div class="right-container">
      <div class="logo-container">
        <img class="wetrack-logo" [src]="brandingUrl" alt="" />
        <img class="wetrack-logo-small" src="/assets/images/layout/wt-small-logo.svg" alt="" />
      </div>
    </div>
  </div>
</div>

<ng-template #moduleDropdownTemplate>
  <div class="modules-dropdown">
    <div
      class="single-module-dropdown"
      *ngFor="let module of groupedNavigationModuleItems"
      [class.dropdown-active-module]="module.moduleType === moduleService.currentModule"
      [id]="module.idAttr"
      (click)="changeSelectedViewMode(module, true)"
    >
      <div class="item-svg-container" [innerHTML]="module.icon | safeHtml"></div>
      <span class="module-name" [class.disabled-module]="module.isDisabled">{{ module.name }}</span>
      <i *ngIf="sideNavModel.selectedItem.name === module.name" class="material-icons active-module-check">check</i>
    </div>
  </div>
</ng-template>

<ng-template #supportTemplate>
  <div class="more-info-popup-container">
    <div class="more-info-popup-header">Momentus {{ T.support.support | translate }}</div>
    <div class="more-info-popup-body">
      <a href="https://supportcenter.ungerboeck.com/#wetrack" class="more-info-popup-row" target="_blank">
        <i class="material-icons">help_outline</i>
        <div>{{ T.support.help_articles | translate }}</div>
      </a>
      <a href="mailto:wetracksupport@gomomentus.com" class="more-info-popup-row">
        <i class="material-icons-outlined">email</i>
        <div>{{ T.support.email_our_team | translate }}</div>
      </a>

    </div>
  </div>
</ng-template>

<ng-template #accountOptionsTemplate>
  <div class="more-info-popup-container">
    <div class="more-info-popup-header">{{ currentEmployee.firstName }} {{ currentEmployee.surname }}</div>
    <div class="more-info-popup-body">
      <ng-container  *ngIf="!isHubAccount"  >
        <div class="more-info-popup-row" [routerLink]="'v2/settings'" (click)="collapseSidebarOnMenuItemSelect()">
          <i class="material-icons-outlined">settings</i> <span>{{ T.settings.settings | translate }}</span>
        </div>
      </ng-container>
      <div
        class="more-info-popup-row"
        [routerLink]="'v2/settings/preferences'"
        (click)="collapseSidebarOnMenuItemSelect()"
      >
        <i class="material-icons-outlined">account_circle</i>
        <span>{{ T.settings.my_preferences.my_preferences_text | translate }}</span>
      </div>
      <div *ngIf="!isHubAccount"
        class="more-info-popup-row"
        [routerLink]="'v2/settings/documents-manager'"
        (click)="collapseSidebarOnMenuItemSelect()"
      >
        <i class="material-icons-outlined">attachment</i> <span>{{ T.settings.document_manager | translate }}</span>
      </div>
      <div class="more-info-popup-row" (click)="logout()">
        <i class="material-icons-outlined">exit_to_app</i> <span>{{ T.settings.logout | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>
