<ng-container *ngIf="!mobile">
  <div class="filters-header" *ngIf="showTitle">
    <app-modal-header [title]="headerTitle" (closed)="onCancel()"></app-modal-header>
  </div>
  <app-filters-inline-edit-lozenges
    [appliedFilters]="localAppliedFilters"
    [showHeader]="false"
    [filterType]="filterType"
    [canClickOnStar]="canClickOnStar"
    (filterRemoved)="onFilterDeleted($event)"
    (makePrimary)="onPrimaryFilterClicked($event)"
  ></app-filters-inline-edit-lozenges>

  <app-filters-search-input
    [filterTitle]="filterType | localiseFilterType"
    (search)="onSearch($event)"
  ></app-filters-search-input>

  <app-filters-entries-information
    [entriesCount]="filters.length"
    [selectedEntriesCount]="getSelectedEntriesCount()"
    [matchedEntriesCount]="searchValue ? filteredFilters.length : undefined"
    [isMultiSelect]="!singleSelect"
    (selectAll)="onSelectAll()"
    (clearAll)="onClearAll()"
    [areAllSelected]="areAllSelected"
  ></app-filters-entries-information>

  <app-filters-list-entries
    [entries]="filteredFilters"
    [selectedEntries]="localAppliedFilters"
    [required]="isFilterRequired"
    [filterType]="filterType"
    [mobile]="mobile"
    (entrySelected)="onEntrySelected($event)"
  ></app-filters-list-entries>

  <div class="multiselect-bottom-checkboxes">
    <app-filters-exclude-selected
      *ngIf="!lozengeInlineEdit"
      [checkState]="excludeState"
      [disabled]="!localAppliedFilters.length"
      (excluded)="onExclude($event)"
    >
    </app-filters-exclude-selected>
    <app-filters-exclude-selected
      *ngIf="!lozengeInlineEdit && isDepartmentFilter"
      [label]="T.common.primary_only | translate"
      [message]="T.common.check_to_show_primary_only | translate"
      [checkState]="primaryState"
      [disabled]="!localAppliedFilters.length"
      (excluded)="onPrimarySelect($event)"
    >
    </app-filters-exclude-selected>
  </div>

  <app-filters-desktop-footer
    [useClearAllButton]="useClearAllButton"
    [useCancelApplyButtons]="true"
    [clearDisabled]="!localAppliedFilters.length"
    [applyDisabled]="isFilterChanged"
    (clear)="onClearAll()"
    (cancel)="onCancel()"
    (apply)="onApply()"
  ></app-filters-desktop-footer>
</ng-container>
<ng-container *ngIf="mobile">
  <app-filters-mobile-header
    *ngIf="mobile"
    [title]="filterType | localiseFilterType"
    [useClear]="true"
    [clearDisabled]="!localAppliedFilters.length"
    (cancel)="onCancel()"
    (clear)="onClearAll()"
  ></app-filters-mobile-header>

  <div class="filters-multiselect-mobile">
    <app-filters-search-input
      [filterTitle]="filterType | localiseFilterType"
      (search)="onSearch($event)"
    ></app-filters-search-input>

    <ng-container *ngIf="!searchValue">
      <app-filters-inline-edit-lozenges
        [appliedFilters]="localAppliedFilters"
        (filterRemoved)="onFilterDeleted($event)"
      ></app-filters-inline-edit-lozenges>

      <div class="multiselect-bottom-checkboxes">
        <app-filters-exclude-selected
          *ngIf="!lozengeInlineEdit"
          [checkState]="excludeState"
          [disabled]="!localAppliedFilters.length"
          (excluded)="onExclude($event)"
        >
        </app-filters-exclude-selected>
        <app-filters-exclude-selected
          *ngIf="!lozengeInlineEdit && isDepartmentFilter"
          [label]="T.common.primary_only | translate"
          [message]="T.common.check_to_show_primary_only | translate"
          [checkState]="primaryState"
          [disabled]="!localAppliedFilters.length"
          (excluded)="onPrimarySelect($event)"
        >
        </app-filters-exclude-selected>
      </div>
    </ng-container>

    <app-filters-entries-information
      [entriesCount]="filters.length"
      [selectedEntriesCount]="localAppliedFilters.length"
      [matchedEntriesCount]="searchValue ? filteredFilters.length : undefined"
      (selectAll)="onSelectAll()"
      (clearAll)="onClearAll()"
      [areAllSelected]="areAllSelected"
    ></app-filters-entries-information>

    <app-filters-list-entries
      [entries]="filteredFilters"
      [selectedEntries]="localAppliedFilters"
      [mobile]="mobile"
      (entrySelected)="onEntrySelected($event)"
    ></app-filters-list-entries>
  </div>

  <app-filters-mobile-footer
    *ngIf="mobile"
    [applyDisabled]="isFilterChanged"
    (apply)="onApply()"
  ></app-filters-mobile-footer>
</ng-container>
