import { RiskTypes } from '../../risk/enums/riskTypes';
import { ObjectTypes } from '../enums/objectTypes';

export class RouteHelper {
  // create static dictionary of object type and routes
  private static routes: { [key: number]: string } = {
    [ObjectTypes.Project]: '/v2/planning/list/projects/',
    [ObjectTypes.Task]: '/v2/planning/list/tasks/',
    [ObjectTypes.Risk]: '/v2/risk/risks-issues',
    [ObjectTypes.IncidentItem]: '/v2/control/list/',
    [ObjectTypes.Log]: '/v2/control/logs/',
    [ObjectTypes.Job]: '/v2/control/jobs/',
    [ObjectTypes.Runsheet]: '/v2/runsheets/list/',
    [ObjectTypes.Runsheet_Item]: '/v2/runsheets/list/',
    [ObjectTypes.Indicator]: '/v2/sustainability/indicators/',
  };

  /**
   *  Get Route to specific Details page based on the Object Type and Risk Type (if it is applicable)
   * @param objectType  - Object Type
   * @param riskType  - Risk Type (if it is applicable)
   * @returns  - Route to specific Details page
   */
  public static getRoute(objectType: ObjectTypes, riskType: RiskTypes = undefined): string {
    let generalRoute = this.routes[objectType];
    if (riskType) {
      generalRoute += this.getRiskSubTypeRoute(riskType);
    }

    return generalRoute;
  }

  private static getRiskSubTypeRoute(riskType: RiskTypes) {
    switch (riskType) {
      case RiskTypes.Risk:
        return 'risk/';
      case RiskTypes.Issue:
        return 'issue/';
      case RiskTypes.Opportunity:
        return 'opportunity/';
    }
  }
}
