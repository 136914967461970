import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { LocalisationService } from '../../services/localisation.service';
import { ObjectTypes } from '../objectTypes';

interface TranslationKeyAndParam {
  pathKey: string;
  getInterpolateObject?: (localisationService: LocalisationService) => object;
}

export enum EmployeeRoleTypes {
  Objective_Manager = 16,
  Risk_Manager = 17,
  Employee_Manager = 20,
  Activity_Manager = 21,
  Sees_Confidential_Risks = 22,

  Finance_Manager = 24,
  Can_See_Confidential_Incidents = 25,
  Incident_Management_User = 31,
  Main_Operations_Centre_User = 32,
  Venue_Manager = 34,
  Write_Admin = 35,

  // General_User default starts here
  Incidents_User = 37,
  Runsheets_User = 38,
  PEAPS_Controller = 39,
  Planning_User = 40,
  AccountHub_User = 41,

  // New structure

  General_User = 41,
  Account_Admin = 42,
  User_Admin = 43,
  Admin = 44,
  Project_Admin = 45,

  // New Incidents roles
  PEAPS_User = 46,
  Control_Admin = 47,
  Sustainability_User = 49,
  Jobs_User = 50,
  //Hub Roles
  Hub_Admin = 200,
  Hub_General = 201,
}

export namespace EmployeeRoleTypes {
  export function translateRole(
    role: EmployeeRoleTypes,
    translateService: TranslateService,
    localisationService: LocalisationService
  ): string {
    const translationKey = employeeRoleTypesTranslationMaping[role].pathKey;
    const interpolateParams = employeeRoleTypesTranslationMaping[role].getInterpolateObject?.(localisationService);
    return translateService.instant(translationKey, interpolateParams);
  }
}

const employeeRoleTypesTranslationMaping: Record<string, TranslationKeyAndParam> = {
  [EmployeeRoleTypes.Objective_Manager]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.objective_manager,
  },
  [EmployeeRoleTypes.Risk_Manager]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.risk_manager,
  },
  [EmployeeRoleTypes.Employee_Manager]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.employee_manager,
  },
  [EmployeeRoleTypes.Activity_Manager]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.activity_manager,
  },
  [EmployeeRoleTypes.Sees_Confidential_Risks]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.sees_confidential_risks,
  },
  [EmployeeRoleTypes.Finance_Manager]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.finance_manager,
  },
  [EmployeeRoleTypes.Can_See_Confidential_Incidents]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.can_see_confidential_incidents,
  },
  [EmployeeRoleTypes.Incident_Management_User]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.incident_management_user,
  },
  [EmployeeRoleTypes.Main_Operations_Centre_User]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.main_operations_center_user,
  },
  [EmployeeRoleTypes.Venue_Manager]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.venue_manager,
  },
  [EmployeeRoleTypes.Write_Admin]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.write_admin,
  },
  // General_User default starts here
  [EmployeeRoleTypes.Incidents_User]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.incidents_user,
  },
  [EmployeeRoleTypes.Runsheets_User]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.runsheets_user,
  },
  [EmployeeRoleTypes.PEAPS_Controller]: {
    pathKey: T.settings.employees_departments_org.manage_employee.localisedPEAPs_controller,
    getInterpolateObject: (localisationService: LocalisationService) => {
      return { localisedPEAPs: localisationService.localiseObjectType(ObjectTypes.PEAP, true) };
    },
  },
  [EmployeeRoleTypes.Planning_User]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.planning_user,
  },
  // New structure
  [EmployeeRoleTypes.General_User]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.general_user,
  },
  [EmployeeRoleTypes.Account_Admin]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.account_admin,
  },
  [EmployeeRoleTypes.User_Admin]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.user_admin,
  },
  [EmployeeRoleTypes.Admin]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.admin,
  },
  [EmployeeRoleTypes.Project_Admin]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.project_admin,
  },
  // New Incidents roles
  [EmployeeRoleTypes.PEAPS_User]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.peaps_user,
  },
  [EmployeeRoleTypes.Control_Admin]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.control_admin,
  },
  [EmployeeRoleTypes.Sustainability_User]: {
    pathKey: T.settings.my_preferences.permissions.employee_roles.sustainability_user,
  },
};
