import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { Employee } from '../../../models/employee';
import { AuthenticationService } from '../../../services/authentication.service';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { ModuleService } from '../../../services/module.service';
import { LocalisationService } from '../../../services/localisation.service';
import { ObjectTypes } from '../../../enums/objectTypes';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-filters-add',
  templateUrl: './filters-add.component.html',
  styleUrls: ['./filters-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.filters-add-mobile]': 'mobile',
  },
})
export class FiltersAddComponent implements OnInit {
  private readonly objectTypesWithoutTags: ObjectTypes[] = [
    ObjectTypes.Runsheet,
  ];

  @Input() mobile: boolean;
  @Input() filterTypeSelectorViewModels: FilterTypeSelectorViewModel[];
  @Input() filters: FilterViewModel[];
  @Input() objectTypes: ObjectTypes[];
  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() filterAdded: EventEmitter<FilterTypeSelectorViewModel> = new EventEmitter();
  @Output() tagGroupSelected: EventEmitter<FilterViewModel> = new EventEmitter();

  employee: Employee;
  public tagGroupFilters: FilterViewModel[] = [];
  public searchValue: string;

  public unitedFiltersAndFilterTypesViewModels: (FilterViewModel | FilterTypeSelectorViewModel)[];
  public filteredUnitedFiltersAndFilterTypesViewModels: (FilterViewModel | FilterTypeSelectorViewModel)[];
  public isAllFiltersShowed = false;
  public readonly T = T;

  constructor(
    public readonly elementRef: ElementRef<HTMLElement>,
    private readonly authService: AuthenticationService,
    private readonly moduleService: ModuleService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService
  ) {}

  ngOnInit() {
    this.employee = this.authService.getCurrentEmployee();
    this.initTagGroupFilters();

    // Remove Tag filter type as only Tag Groups displayed here
    this.filterTypeSelectorViewModels = this.filterTypeSelectorViewModels.filter(f => f.filterType !== FilterTypes.Tag);

    const coppiedFilterTypesViewModels = JSON.parse(JSON.stringify(this.filterTypeSelectorViewModels));
    const coppiedTagGroupsFilters = JSON.parse(JSON.stringify(this.tagGroupFilters));

    this.unitedFiltersAndFilterTypesViewModels = [...coppiedFilterTypesViewModels, ...coppiedTagGroupsFilters];
    this.filteredUnitedFiltersAndFilterTypesViewModels = this.unitedFiltersAndFilterTypesViewModels.slice(0, 10);
  }

  onCancel(): void {
    this.closed.emit();
  }

  onAddFilter(filterTypeSelectorViewModel: FilterTypeSelectorViewModel): void {
    this.filterAdded.emit(filterTypeSelectorViewModel);
  }

  initTagGroupFilters() {
    let objTypes = this.moduleService.currentObjectTypes;

    if (!objTypes.length && this.objectTypes) {
      objTypes = this.objectTypes;
    }

    if (this.filters) {
      this.tagGroupFilters = this.filters.filter(
        (s) =>
          s.filterType === FilterTypes.Tag_Group &&
          objTypes &&
          objTypes.indexOf(s.displayForGlobalObjectType) > -1 &&
          !this.objectTypesWithoutTags.includes(+s.displayForGlobalObjectType)
      );

      const newFilters = [];

      this.tagGroupFilters.forEach((f) => {
        if (f.displayForGlobalObjectType == 0 && objTypes) {
          objTypes.forEach((oType) => {
            if (this.objectTypesWithoutTags.indexOf(oType) === -1) {
              const newFilter: FilterViewModel = { ...f };
              newFilter.displayForGlobalObjectType = oType;
              newFilter.filterText = this.localisationService.localiseObjectType(oType) + ` ${f.filterText}`;
              newFilters.push(newFilter);
            }
          });
        }
      });

      this.tagGroupFilters = this.tagGroupFilters.filter((f) => f.displayForGlobalObjectType);
      this.tagGroupFilters = [...this.tagGroupFilters, ...newFilters];
      this.changeDetectorRef.detectChanges();
    }
  }

  uniteFilterSelectorAndTaskGroupFilters() {}

  onTagGroupFilterSelected(tagGroupFilter: FilterViewModel) {
    this.tagGroupSelected.next(tagGroupFilter);
  }

  onSearch(value: string): void {
    this.searchValue = value?.toLowerCase().trim();

    if (value) {
      const filteredFilterTypesViewModels = this.filterTypeSelectorViewModels.filter(
        (f) => f.filterTypeText && f.filterTypeText.toLowerCase().indexOf(this.searchValue) > -1
      );
      const filteredTagGroupFilters = this.tagGroupFilters.filter(
        (f) => f.filterText && f.filterText.toLowerCase().indexOf(this.searchValue) > -1
      );
      this.filteredUnitedFiltersAndFilterTypesViewModels = [...filteredFilterTypesViewModels, ...filteredTagGroupFilters];
    } else {
      this.filteredUnitedFiltersAndFilterTypesViewModels = this.unitedFiltersAndFilterTypesViewModels;
    }

    this.isAllFiltersShowed = true;
    this.changeDetectorRef.markForCheck();
  }

  onShowAllFilters() {
    this.filteredUnitedFiltersAndFilterTypesViewModels = this.unitedFiltersAndFilterTypesViewModels;
    this.isAllFiltersShowed = true;
  }
}
